import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { MemoryChestsPageComponent } from './memory-chests-page/memory-chests-page.component';
import { MemoryChestsRoutingModule } from './memory-chests.routing';
import { MemoryChestSelectorModule } from 'src/app/widgets/memory-chest-selector/memory-chest-selector.module';
import { RearviewInterfaceModule } from 'src/app/shared/interface/rearview-interface.module';
import { MemoryChestComponent } from './memory-chest/memory-chest.component';
import { ChestPreviewComponent } from './chest-preview/chest-preview.component';
import { MemoryChestPreviewService } from './models/memory-chest-preview.service';
import { MemoryChestCreatorComponent } from './memory-chest-creator/memory-chest-creator.component';
import { MemoryChestCreatorBtnComponent } from './memory-chest-creator-btn/memory-chest-creator-btn.component';
import { DropdownMenuModule } from 'src/app/widgets/dropdown-menu/dropdown-menu.module';
import { MemoryChestEditorModule } from 'src/app/widgets/memory-chest-editor/memory-chest-editor.module';

@NgModule({
  declarations: [
    MemoryChestsPageComponent,
    MemoryChestComponent,
    ChestPreviewComponent,
    MemoryChestCreatorComponent,
    MemoryChestCreatorBtnComponent
  ],
  imports: [
    CommonModule,
    MemoryChestsRoutingModule,
    MemoryChestSelectorModule,
    RearviewInterfaceModule,
    DirectivesModule,
    DropdownMenuModule,
    MemoryChestEditorModule
  ],
  providers: [
    MemoryChestPreviewService
  ]
})
export class MemoryChestsModule { }
