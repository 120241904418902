<div class="symbol-component">
	<svg viewBox="0 0 425 250" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">

		<!-- symbol -->
		<g>
			<!-- left paren -->
	    <g transform="matrix(4.367,0,0,4.367,-349.958,-1201.13)">
	        <path d="M107.136,287.919C109.873,287.919 112.09,285.702 112.09,282.965C112.09,280.229 109.873,278.011 107.136,278.011C92.975,278.011 81.455,289.531 81.455,303.691C81.455,317.852 92.975,329.372 107.136,329.372C109.873,329.372 112.09,327.154 112.09,324.418C112.09,321.682 109.873,319.464 107.136,319.464C98.439,319.464 91.363,312.388 91.363,303.691C91.363,294.995 98.439,287.919 107.136,287.919Z" style="fill:{{symbolColor}};fill-rule:nonzero;"/>
	    </g>
	    <!-- top colon -->
	    <g transform="matrix(4.367,0,0,4.367,-349.958,-1201.13)">
	        <path d="M128.826,275.899C124.924,275.899 121.76,279.063 121.76,282.965C121.76,286.868 124.924,290.031 128.826,290.031C132.729,290.031 135.892,286.868 135.892,282.965C135.892,279.063 132.729,275.899 128.826,275.899Z" style="fill:{{symbolColor}};fill-rule:nonzero;"/>
	    </g>
	    <!-- bottom colon -->
	    <g transform="matrix(4.367,0,0,4.367,-349.958,-1201.13)">
	        <path d="M128.826,317.353C124.924,317.353 121.76,320.516 121.76,324.418C121.76,328.321 124.924,331.484 128.826,331.484C132.729,331.484 135.892,328.321 135.892,324.418C135.892,320.516 132.729,317.353 128.826,317.353Z" style="fill:{{symbolColor}};fill-rule:nonzero;"/>
	    </g>
	    <!-- right paren -->
	    <g transform="matrix(4.367,0,0,4.367,-349.958,-1201.13)">
	        <path d="M150.516,278.011C147.779,278.011 145.562,280.229 145.562,282.965C145.562,285.702 147.779,287.919 150.516,287.919C159.212,287.919 166.288,294.995 166.288,303.691C166.288,312.388 159.212,319.464 150.516,319.464C147.779,319.464 145.562,321.682 145.562,324.418C145.562,327.154 147.779,329.372 150.516,329.372C164.676,329.372 176.196,317.852 176.196,303.691C176.196,289.531 164.676,278.011 150.516,278.011Z" style="fill:{{symbolColor}};fill-rule:nonzero;"/>
	    </g>
	  </g>

	</svg>
</div>