import { NativeInterfaceRequest } from './native-interface.request';
import { Capacitor } from '@capacitor/core';
import { Camera, CameraResultType, CameraSource, Photo, ImageOptions } from '@capacitor/camera';

/*

Can be set to the src of an image now:
var imageUrl = image.webPath;
document.getElementById('test_img').src = imageUrl;

CameraSource(s)
------------------------------------------------
CameraSource.Camera:
	format: "jpeg"
	saved: false
	webPath: "blob:https://localhost:4200/8bbc2eb2-c6a0-4493-88eb-84a064e811ed"

CameraSource.Photos:
	format: "jpeg"
	webPath: "blob:https://localhost:4200/8bbc2eb2-c6a0-4493-88eb-84a064e811ed"

CameraSource.Prompt:
 I. Take Photo
		format: "jpeg"
		saved: false
		webPath: "blob:https://localhost:4200/8bbc2eb2-c6a0-4493-88eb-84a064e811ed"
 II. Choose from Library
		format: "jpeg"
		webPath: "blob:https://localhost:4200/8bbc2eb2-c6a0-4493-88eb-84a064e811ed"

*/

export class CameraInterfaceRequest extends NativeInterfaceRequest {

	async getPhoto (userOpts?: ImageOptions): Promise<Photo>
	{
		const imgOpts: ImageOptions = {
		  quality: 			(userOpts&&userOpts?.quality)||90,
		  allowEditing: (userOpts&&userOpts?.allowEditing)||false,
		  resultType: 	(userOpts&&userOpts?.resultType)||CameraResultType.Uri,
		  source: 			(userOpts&&userOpts?.source)||CameraSource.Photos
		}

		await this.execute(Camera.getPhoto(imgOpts));

		return Promise.resolve(this.Photo);
	}

	get Photo (): Photo
	{
		/*
		Photo: {
			base64String:	(string)
				The base64 encoded string representation of the image, if using CameraResultType.Base64.
			dataUrl:	(string)
				The url starting with 'data:image/jpeg;base64,' and the base64 encoded string representation of the image, if using CameraResultType.DataUrl. Note: On web, the file format could change depending on the browser.
			path:	(string)
				If using CameraResultType.Uri, the path will contain a full, platform-specific file URL that can be read later using the Filesystem API.
			webPath:	(string)
				webPath returns a path that can be used to set the src attribute of an image for efficient loading and rendering.
			exif:	(any)
				Exif data, if any, retrieved from the image.
			format:	(string)
				The format of the image, ex: jpeg, png, gif. iOS and Android only support jpeg. Web supports jpeg, png and gif, but the exact availability may vary depending on the browser. gif is only supported if webUseInput is set to true or if source is set to Photos.
			saved:	(boolean)
				Whether if the image was saved to the gallery or not. On Android and iOS, saving to the gallery can fail if the user didn't grant the required permissions. On Web there is no gallery, so always returns false.
		}
		*/
		return this.getPayload() as Photo;
	}

	async checkPermissions () 
	{
		if (Capacitor.isNative) {
			if (await Camera.checkPermissions())
				return true;
			else
				return !!(await Camera.requestPermissions());
		}

		return true;
	}

	constructor (opts?: {[key: string]: any})
	{
		super(opts)
	}
}
