import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControlErrorDirective } from './form-control-error.directive';
import { FormSubmitDirective } from './form-submit.directive';
import { FormControlErrorComponent } from './form-control-error/form-control-error.component';
import { FormControlErrorContainerDirective } from './form-control-error-container.directive';

@NgModule({
  declarations: [
		FormControlErrorDirective,
		FormSubmitDirective,
		FormControlErrorComponent,
		FormControlErrorContainerDirective,
  ],
  imports: [
    CommonModule
  ],
  exports: [
		FormControlErrorDirective,
		FormSubmitDirective,
		FormControlErrorComponent,
		FormControlErrorContainerDirective,
  ]
})
export class DirectivesModule { }
