import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModelFactoryConfig, ModelService } from '@getrearview/model-builder';
import { ConfigService } from 'src/app/shared/services/config.service';

@Injectable()
export class MemoryChestPreviewService extends ModelService {
  constructor (injector: Injector, httpClient: HttpClient) 
  {
  	let ConfigSrvc: ConfigService = injector.get<any>(ConfigService);
		let MFC: ModelFactoryConfig = (new ModelFactoryConfig()).setAll({
  				// Model Name
  				name: "MemoryChestPreview",
  				// Model Endpoint
					endpoint: ConfigSrvc.get("api.api_url")+"/services/memory-chests/previews",
					// Model Fields
					fields: [
						'nickname'
					],
					// Model `Permitted` Fields
					permitted: [
						'nickname'
					],
					// Model `Required` Fields
					required: [],
					// Model Field Labels
					labels: {},
					// Model Field Validation Patterns
					patterns: {},
					// Model Children Types
					children_types: {},
					// Model Association Types
					association_types: {},
					// Storage Type (local, session, cookies)
					storage_type: "local"
				});

  	super(MFC, httpClient);
  }
}