import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderModule } from './header/header.module';
import { NavigationModule } from './navigation/navigation.module';
import { LogoModule } from './logo/logo.module';
import { DropdownMenuModule } from './dropdown-menu/dropdown-menu.module'
import { HeaderWidgetComponent } from './header/header-widget/header-widget.component';
import { NavigationWidgetComponent } from './navigation/navigation-widget/navigation-widget.component';
import { LogoWidgetComponent } from './logo/logo-widget/logo-widget.component';
import { ComponentLoaderWidgetComponent } from './loaders/component-loader-widget/component-loader-widget.component';
import { MemoryChestMembersWidgetModule } from './memory-chest-members-widget/memory-chest-members-widget.module';
import { SocialIconsModule } from './social-icons/social-icons.module';
import { SwayingLandscapeModule } from './swaying-landscape/swaying-landscape.module';
import { AnimatableModule } from './animatable/animatable.module';
import { FloatingCloudsModule } from './floating-clouds/floating-clouds.module';
import { ViewSelectorModule } from './view-selector/view-selector.module';
import { MemoryChestSelectorModule } from './memory-chest-selector/memory-chest-selector.module';
import { MemoryModule } from './memory/memory.module';
import { LoadersModule } from './loaders/loaders.module';
import { FloatingControlsModule } from './floating-controls/floating-controls.module';
import { FloatingControlsWidgetComponent } from './floating-controls/floating-controls-widget/floating-controls-widget.component';
import { ModalModule } from './modal/modal.module';
import { ModalBackdropComponent } from './modal/modal-backdrop/modal-backdrop.component';
import { MediaImporterModule } from './media-importer/media-importer.module';
import { MemoryEditorModule } from './memory-editor/memory-editor.module';
import { MemoryEditorWidgetComponent } from './memory-editor/memory-editor-widget/memory-editor-widget.component';
import { HashtagManagerModule } from './hashtag-manager/hashtag-manager.module';
import { HashtagManagerWidgetComponent } from './hashtag-manager/hashtag-manager-widget/hashtag-manager-widget.component';
import { MemoryChestMemberModule } from './memory-chest-member/memory-chest-member.module';
import { MilestoneEditorModule } from './milestone-editor/milestone-editor.module';
import { MilestoneCreatorModule } from './milestone-creator/milestone-creator.module';
import { MilestoneLocationCreatorModule } from './milestone-location-creator/milestone-location-creator.module';
import { MemoryChestEditorModule } from './memory-chest-editor/memory-chest-editor.module';
import { MemberModule } from './member/member.module';
import { LocationsModule } from './locations/locations.module';
import { LocationSelectorComponent } from './locations/location-selector/location-selector.component';
import { MemoryCreatorModule } from './memory-creator/memory-creator.module';
import { MemoryChestInviterModule } from './memory-chest-inviter/memory-chest-inviter.module';
import { MemoryContentCreatorModule } from './memory-content-creator/memory-content-creator.module';
import { MemoryContentCreatorWidgetComponent } from './memory-content-creator/memory-content-creator-widget/memory-content-creator-widget.component';
import { MilestoneDeleterModule } from './milestone-deleter/milestone-deleter.module';
import { MilestoneDeleterWidgetComponent } from './milestone-deleter/milestone-deleter-widget/milestone-deleter-widget.component';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HeaderModule,
    NavigationModule,
    LogoModule,
    MemoryChestMembersWidgetModule,
    SocialIconsModule,
    SwayingLandscapeModule,
    AnimatableModule,
    FloatingCloudsModule,
    ViewSelectorModule,
    LoadersModule,
    MemoryModule,
    MilestoneCreatorModule,
    DropdownMenuModule,
    MemoryChestSelectorModule,
    FloatingControlsModule,
    MilestoneLocationCreatorModule,
    ModalModule,
    MediaImporterModule,
    MemoryEditorModule,
    HashtagManagerModule,
    MemoryChestMemberModule,
    MilestoneEditorModule,
    MemoryChestEditorModule,
    MemberModule,
    LocationsModule,
    MemoryCreatorModule,
    MemoryChestInviterModule,
    MemoryContentCreatorModule,
    MilestoneDeleterModule
  ],
  exports: [
  	LogoWidgetComponent,
  	HeaderWidgetComponent,
  	ComponentLoaderWidgetComponent,
  	NavigationWidgetComponent,
  	FloatingControlsWidgetComponent,
  	MemoryEditorWidgetComponent,
  	HashtagManagerWidgetComponent,
  	LocationSelectorComponent,
  	MemoryContentCreatorWidgetComponent,
		MilestoneDeleterWidgetComponent
  ]
})
export class WidgetsModule { }
