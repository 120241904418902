import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FeedMilestoneService } from './../models/feed-milestone.service';
import { FeedService } from './../shared/feed.service';
import { ModelInstance } from '@getrearview/model-builder';
import { Subscription } from 'rxjs';
import { FloatingControlService } from './../../../widgets/floating-controls/floating-control.service';
import { ModalService, modalParams } from 'src/app/widgets/modal/shared/modal.service';
import { SessionUser } from 'src/app/core/session/session-user.model';

@Component({
  selector: 'rv-feed-page',
  templateUrl: './feed-page.component.html',
  styleUrls: ['./feed-page.component.scss']
})
export class FeedPageComponent implements OnInit {

	MemoryChests: {[id: string]: ModelInstance} = {};
	loaded: boolean = false;
	chestId: string = '';
	isModalOpen: boolean = false;
	canFilter: boolean = false;
	enableFilter: boolean = false;

	@ViewChild('feedFilter')
	feedFilter;

	previewing = [];

	#subscriptions$ = new Subscription();

	openFilter (feedFilterRef: TemplateRef<any>) 
	{
		if (this.isModalOpen) {
			this.modalService.close()
			return;
		}

		const opts:modalParams = {title: 'Filter'};

		this.modalService.open(feedFilterRef, opts);
	}

	onIntersection (FeedMilestone: ModelInstance, $evt)  // IntersectionObserverEvent
	{
		if ($evt.shift()?.isIntersecting && !this.previewing.includes(FeedMilestone.id())) 
			this.feedSrvc.preview(FeedMilestone);
	}

	getMemoryChest (Milestone: ModelInstance): ModelInstance
	{
		if (Milestone?.get('chest_id') && this.MemoryChests.hasOwnProperty(Milestone.get('chest_id')))
			return this.MemoryChests[Milestone.get('chest_id')];
	}

	onChestSelected (MemoryChest: ModelInstance): void
	{
		if (MemoryChest)
			this.router.navigate(['/memory-chests',MemoryChest.id()]);
	}

  constructor (
		private modalService: ModalService, 
		public feedSrvc: FeedService, 
		private FeedMilestoneSrvc: FeedMilestoneService, 
		private route: ActivatedRoute, 
		private router: Router, 
		private floatingControl: FloatingControlService,
		private User: SessionUser
	)
	{}

  ngOnInit (): void 
  {
  	this.#subscriptions$.add(this.modalService.isOpen.subscribe((isOpen: boolean) => this.isModalOpen = isOpen));
    this.#subscriptions$.add(this.route.params.subscribe(params => {
    	this.feedSrvc.fetchFeed(this.chestId = params?.id?params.id:'').finally(() => {
    		this.loaded = true
				this.floatingControl.setParams({memoryChestId: this.chestId});
    	});
    }));
    this.#subscriptions$.add(this.route.params.subscribe(params => {
    	this.feedSrvc.fetchFeed(this.chestId = params?.id?params.id:'').finally(() => {
    		this.loaded = true
				this.floatingControl.setParams({memoryChestId: this.chestId});
    	});
    }));
    this.#subscriptions$.add(this.User.onProfileChange.subscribe(() => {
    	this.feedSrvc.fetchFilters().finally(() => {
    		if (this.enableFilter)
    			this.canFilter = true;
    	});
    }));
  }

  ngAfterViewInit (): void
  {}

  ngOnDestroy (): void 
  {
    this.#subscriptions$.unsubscribe();
    this.feedSrvc.reset();
		this.floatingControl.reset();
  }
}