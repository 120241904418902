<div id="welcome-page-component"  class="container-fluid rv-blue-bg">
	<div class="row">
		<div class="col-xs-24 vh-100">
			<section @welcome1 #welcome1 id="welcome1" class="font-color--gray font-face--nunito-sans">
				<p>&#8220;Sometimes</p>
				<p>you will never know the</p>
				<p>value of a moment</p>
				<p>until it becomes a</p>
				<p>memory&#8221;</p>
				<small>- Dr. Suess</small>
			</section>
			<section @welcome2 #welcome2 id="welcome2" class="font-color--gray font-face--nunito-sans">
				<p>We couldn't agree more!</p>
				<p>That's why we're on a mission to take your moments & memories, and create amazing stories for you to share for years to come.</p>
			</section>
			<section @welcome3 #welcome3 id="welcome3" class="font-color--gray font-face--nunito-sans">
				<div><rv-logo-widget routerLink='' layout="horizontal" symbol="purple" name="white" /></div>
			</section>
			<div id="skip-welcome" class="font-color--gray font-face--poppins">
				<div (click)="acknowledge()" class="font-color--gray">Skip Intro ... (<span [innerHTML]="seconds"></span>)</div>
			</div>
		</div>
	</div>
</div>