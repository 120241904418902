<div id="ui-page-inputs-component" class="container-fluid">
	<div class="row">
		<div class="col-24 text-center mt-4 mb-4">
			<h4>UI Inputs</h4>
		</div>
		<div class="col-24">
			<table>
				<tbody>
					<tr><td>Plain:</td><td><rv-input focused [formGroup]="demoForm" /></td></tr>
					<tr><td>Left Icon:</td><td><rv-input iconLeft='education.open-book' [formGroup]="demoForm" /></td></tr>
					<tr><td>Shadowed:</td><td><rv-input shadowed [formGroup]="demoForm" /></td></tr>
					<tr><td>No Outline:</td><td><rv-input outline_disable [formGroup]="demoForm" /></td></tr>
					<tr><td>Mask:</td><td><rv-input mask="(000) 000-0000" [formGroup]="demoForm" [control]="demoForm.get('phone')" /></td></tr>
					<tr><td>Valid:</td><td><rv-input valid [formGroup]="demoForm" /></td></tr>
					<tr><td>Invalid:</td><td><rv-input invalid="Required" [formGroup]="demoForm" /></td></tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
