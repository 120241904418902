import { Injectable } from '@angular/core';
import { NativeInterfaceService } from './native-interface.service'
import { Clipboard, WriteOptions } from '@capacitor/clipboard';

@Injectable({
  providedIn: 'root'
})
export class ClipboardInterface extends NativeInterfaceService {

	set url (url: string) {
		this.write({url});
	}

	async write (options: WriteOptions): Promise<void>
	{
		await Clipboard.write(options)
		return Promise.resolve();
	}

  constructor () 
  {
  	super();
  }
}