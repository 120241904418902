<div class="delete-media-confirm-component">
	<rv-memory-media-router [Media]="Media" />
	<div class="row mt-4">
		<div class="col-12">
			<rv-button outline purple md block label="Keep" (click)="onCancel.emit()"/>
		</div>
		<div class="col-12">
			<rv-button purple md block label="Delete" (click)="onConfirm.emit(Media)" />
		</div>
	</div>
</div>
