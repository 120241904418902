import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModelFactoryConfig, ModelService } from '@getrearview/model-builder';
import { ConfigService } from './../../shared/services/config.service';
import { InviteeService } from './invitee.service';

@Injectable({
  providedIn: 'root'
})
export class InviteTokenService extends ModelService {

  constructor (injector: Injector, httpClient: HttpClient) 
  {
  	let ConfigSrvc: ConfigService = injector.get<any>(ConfigService);
		let MFC: ModelFactoryConfig = (new ModelFactoryConfig()).setAll({
  				// Model Name
  				name: "Invite Token",
  				// Model Endpoint
					endpoint: ConfigSrvc.get("api.api_url")+"/memories/invite-tokens",
					// Model Fields
					fields: ["id","chest_id", "milestone_id", "hash", "is_anon", "expires_at"],
					// Model `Permitted` Fields
					permitted: ["chest_id", "milestone_id", "hash", "is_anon", "expires_at"],
					// Model `Required` Fields
					required: [],
					// Model Field Labels
					labels: {},
					// Model Field Validation Patterns
					patterns: {},
					// Parent ID Field Name
					parent_id_field: "chest_id",
					// Model Children Types
					children_types: {
						invitee: injector.get<any>(InviteeService)
					},
					// Model Association Types
					association_types: {},
					// Storage Type (local, session, cookies)
					storage_type: "local"
				});

  	super(MFC, httpClient);
  }
}