import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ConfigService } from './../../../shared/services/config.service'
import { SessionUser } from './../../../core/session/session-user.model'
import { ModelInstance } from '@getrearview/model-builder'

@Component({
  selector: 'rv-payment-method-manager',
  templateUrl: './payment-method-manager.component.html',
  styleUrls: ['./payment-method-manager.component.scss']
})
export class PaymentMethodManagerComponent implements OnInit {

	isLoading: boolean = false;
	isSaving: string = '';
	isDeleting: string = '';
	paymentMethods: Array<{[key: string]: string|number|null|{[key: string]: string|number|null}}> = []
	private _customerId: string;
	private _elements!: any;
	private _stripe!: any;
	private _card!: any;

	get customerId (): string
	{
		return this._customerId;
	}

	async setAsDefault (pmId: string)
	{
		try {
			this.isSaving = `${pmId}`;
			await this.SessionUser.api.setDefaultPaymentMethod(pmId);

			this.paymentMethods = this.paymentMethods.map(pm => {
				pm.is_default = `${pm.id}` === `${pmId}`?1:0;
				return pm;
			});
		}
		catch (ex) {
			console.error(`Failed to set default payment method. ${ex}`);
		}

		this.isSaving = '';
	}

	async deletePaymentMethod (pmId: string)
	{
		try {
			this.isDeleting = `${pmId}`;
			await this.SessionUser.api.deletePaymentMethod(pmId);
			this.paymentMethods = this.paymentMethods.filter(pm => `${pm?.id}` !== `${pmId}`);
		}
		catch (ex) {
			console.error(`Failed to delete payment method. ${ex}`);
		}

		this.isDeleting = '';
	}

	private async createPaymentMethod ()
	{
		let result;
		try {
			result = await this._stripe.createPaymentMethod({
											    type: 'card',
											    card: this._card,
											    billing_details: {
											      name: this.SessionUser.instance.get('name'),
											      email: this.SessionUser.instance.get('email')
											    },
											  })
		}
		catch (ex) {
			console.error(`Failed to create new payment method. ${ex}`);
			return false;
		}

		if (result && result?.paymentMethod?.id) {
			return result.paymentMethod.id;
		}

		return result;
	}

	private async storePaymentMethod (paymentMethodId: string)
	{
		let result;
		try {
			result = await this.SessionUser.api.addPaymentMethod(paymentMethodId);
		}
		catch (ex) {
			console.error(`Failed to store new payment method. ${ex}`);
			return false;
		}

		return result;
	}

	onSubmit ($event) 
	{
		$event.preventDefault();

		this.createPaymentMethod().then(res => {

			if (typeof res === 'string') {
				this.storePaymentMethod(res).finally(() => {
					this.loadPaymentMethods();
				}) 
			}

		},err => {
			console.error("failed payment method: ",err)
		})

		return false;
	}

	private get client (): string
	{
		return this.ConfigSrvc.get('integrations.stripe.key');
	}

	private async createCustomer (): Promise<string>
	{
		let customerId;
		try {
			customerId = await this.SessionUser.api.createStripeCustomer();
		}
		catch (ex) {
			console.error(`Failed to create payment customer. ${ex}`);
		}

		return customerId;
	}

	private async loadCustomer (): Promise<string>
	{
		const User: ModelInstance = this.SessionUser.instance;

		if (User.get('stripe_id'))
			this._customerId = User.get('stripe_id');

		if (this.customerId)
			return Promise.resolve(this.customerId);

		if (!this.customerId) {
			this.isLoading = true;
			this._customerId = await this.createCustomer();
			this.isLoading = false;
		}

		return Promise.resolve(this.customerId);
	}

	private async loadPaymentMethods (): Promise<void>
	{
		this.paymentMethods = Object.values(await this.SessionUser.api.getPaymentMethods());
	}

	private initElements ()
	{
		if (this._elements && this.customerId) {
			var style = {
			  base: {
			    color: "#32325d",
			  }
			};

			this._card = this._elements.create("card", { style: style });
			this._card.mount("#card-element");

			this._card.on('change', ({error}) => {
			  let displayError = document.getElementById('card-errors');
			  if (error) {
			    displayError.textContent = error.message;
			  } else {
			    displayError.textContent = '';
			  }
			});
		}
	}

	private async storePaymentMethodId (paymentMethodId: string): Promise<void>
	{
		return Promise.resolve();
	}

  constructor (private ConfigSrvc: ConfigService, private SessionUser: SessionUser) 
  {}

  ngOnInit (): void 
  {
  	// 1. Get Stripe Customer Profile from Backend.
  	this.loadCustomer().finally(() => {
  		this.loadPaymentMethods();
  		this.initElements();
  	})
  }

  ngAfterViewInit (): void
  {
  	this._stripe = Stripe(this.ConfigSrvc.get('integrations.stripe.key'));
  	this._elements = this._stripe.elements();
  }
}
