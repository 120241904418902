import { Component } from '@angular/core';
import { ChildrenOutletContexts } from '@angular/router';
import { SessionUser } from './core/session/session-user.model';
import { Subscription, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { fadeInAnimation, slideInAnimation } from './route-change.animation';
import { Event, RouterEvent, Router, NavigationStart, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
import { MessageBusService, Channels, Message } from './core/services/message-bus.service';
import { Platform } from '@ionic/angular';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { environment } from 'src/environments/environment';
import { FacebookLogin } from '@capacitor-community/facebook-login';

const PUBLIC_ROUTES = [
	'/login',
	'/forgot-password',
	'/reset-password/:token',
	'/logout',
	'/sign-up'
],
PUBLIC_PATTERNS = [
	/^\/it\/m(i|c)\/[0-9a-fA-F-]+(\/[0-9a-fA-F-]+)?/
];

const isPublicRoute = url => (url && (PUBLIC_ROUTES.includes(url) || PUBLIC_PATTERNS.filter(pattern => pattern.test(`${url}`)).length > 0));

@Component({
  selector: 'rv-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
  		fadeInAnimation, slideInAnimation
  	]
})
export class AppComponent {

	private _isAuth: boolean = false;

	private _isAuthSub$!: Subscription;
	private _routeEventSub$!: Subscription;

	private authMustRedirect = (): boolean => isPublicRoute(this.router.url) === this._isAuth;

	private async initUser () 
	{
  	await this.SessionUser.init().then(isAuth => {
	  	this._isAuthSub$ = this.SessionUser.onAuthChange.subscribe(isAuth => {
	  		this._isAuth = isAuth
	  		if (this.authMustRedirect())
	  			this.router.navigateByUrl(isAuth?'':'/login');
	  	});
  	});
	}

	onRouteChange (e: RouterEvent): void
	{
		this.bus.emit(Channels.Route,new Message((`${e.constructor.name}`.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`).replace(/(^(-+))|(-+)$/g,'')),{ native: e }))
	}

	getRouteAnimationData (animate:string): string|void
	{
		if (this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation']) {
			if (this.contexts.getContext('primary').route.snapshot.data.animation === animate)
				return this.contexts.getContext('primary').route.snapshot.data.animation;
		}
	}

 	constructor (private router: Router, private SessionUser: SessionUser, private contexts: ChildrenOutletContexts, private bus: MessageBusService, private platform: Platform)
  {}

  ngOnInit (): void 
  {
  	this.initUser();
  	this._routeEventSub$ = this.router.events.pipe(filter((e: Event): e is RouterEvent => (e instanceof NavigationStart || e instanceof NavigationEnd || e instanceof NavigationError || e instanceof NavigationCancel))).subscribe((e: RouterEvent) => this.onRouteChange(e))
	this.googleInit();
	this.facebookInit();
  }

  ngOnDestroy (): void 
  {
  	if (this._isAuthSub$)
  		this._isAuthSub$.unsubscribe();
  	if (this._routeEventSub$)
  		this._routeEventSub$.unsubscribe();
  }

  googleInit () 
  {
    this.platform.ready().then(() => {
      GoogleAuth.initialize({
        clientId: environment.socialProviders.googleClientId,
        scopes: ['profile', 'email'],
        grantOfflineAccess: true,
      })
    })
  }

  facebookInit ()
  {
    this.platform.ready().then(async () => {
      await FacebookLogin.initialize({ appId: environment.socialProviders.facebookAppKey });
    })
  }
}