import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ModelInstance } from '@getrearview/model-builder';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { SessionUser } from 'src/app/core/session/session-user.model';
import { MessageBusService, Channels, Message } from 'src/app/core/services/message-bus.service';
import { MemoryService } from 'src/app/models/memories/memory.service';

@Component({
  selector: 'rv-memory-content-creator-widget',
  templateUrl: './memory-content-creator-widget.component.html',
  styleUrls: ['./memory-content-creator-widget.component.scss']
})
export class MemoryContentCreatorWidgetComponent {

	@Input('Milestone')
	Milestone: ModelInstance;

	@Input('Me')
	Me: ModelInstance;

	@Output('onCancel')
	onCancel: EventEmitter<void> = new EventEmitter();

	@Output('onSuccess')
	onSuccess: EventEmitter<ModelInstance> = new EventEmitter<ModelInstance>();

	@Output('onError')
	onError: EventEmitter<string> = new EventEmitter();

	isSaving: boolean = false;
	isFetching: boolean = false;
	isEditing: boolean = false;

	MemoryMessage: ModelInstance;
	memoryMessageForm: FormGroup = new FormGroup({body: new FormControl('',Validators.required)});

	_subscriptions$:Subscription = new Subscription();
	

	async saveMemoryMessage (): Promise<void>
	{
		this.isSaving = true;
		let success: boolean = false;

		if (this.MemoryMessage && this.MemoryMessage?.isNew() === false) {
			try { 
				await this.MemoryMessage.save();
				success = true;
			}
			catch (ex) {
				console.error(`Failed to save new Memory message. ${ex}`);
				/*TODO: complete error handling*/
				// this.onError.next(`${ex}`);
			}
		}
		else if (this.MemoryMessage && this.MemoryMessage?.isNew() === true) {
			try { 
				this.MemoryMessage = await this.Milestone.createChild('memory',{
					...this.MemoryMessage.attribs,
					author_id: this.Me?.id()
				}) as ModelInstance;
				success = true;
			}
			catch (ex) {
				console.error(`Failed to save new Memory message. ${ex}`);
				/*TODO: complete error handling*/
				// this.onError.next(`${ex}`);
			}
		}

		if (success)
			this.onSuccess.next(this.MemoryMessage);

		this.bus.emit(Channels.Model,new Message('memory.updated',{Memory:this.MemoryMessage}))

		this.isSaving = false;
	}

	#updateModel (changes: {[key: string]: any}): void
	{
		if (!this.MemoryMessage)
			this.MemoryMessage = this.MemorySrvc.factory().create({type: 'TEXT'});
		this.MemoryMessage.attribs = {...this.MemoryMessage.attribs, ...changes};
  }
	
  constructor (private fb: FormBuilder, private bus: MessageBusService, private MemorySrvc: MemoryService, private SessionUser: SessionUser)
  {}

  ngOnInit (): void
  {
  	this._subscriptions$.add(this.memoryMessageForm.valueChanges.pipe(distinctUntilChanged(), debounceTime(200)).subscribe(changes => this.#updateModel(changes)))
  }

  ngOnDestroy (): void
  {
  	this._subscriptions$.unsubscribe();
  }
}