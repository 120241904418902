import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'feed-date-indicator',
  templateUrl: './feed-date-indicator.component.html',
  styleUrls: ['./feed-date-indicator.component.scss']
})
export class FeedDateIndicatorComponent implements OnInit {

	@Input('date')
	date!: string;

  constructor () 
  {}

  ngOnInit (): void 
  {}
}
