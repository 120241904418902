<div id="ui-user-menu-component">
	<rv-img *ngIf="usePhoto" class="user-menu-toggle img" round [src]="url$|async" (click)="showMenu = !showMenu" (onError)="onPhotoError()" />
	<div *ngIf="!usePhoto" class="user-menu-toggle initials" (click)="showMenu = !showMenu">
		{{initial}}
	</div>
	<div class="user-menu-wrapper" [ngClass]="{'open': showMenu}" (click)="showMenu = !showMenu">
		<ul>
			<li><rv-button routerLink='/profile' iconLeft='users.account' sm open label='Profile'></rv-button></li>
			<li><rv-button routerLink='/users/settings' iconLeft='user-interface.setting' sm open label='Settings'></rv-button></li>
			<li><rv-button routerLink='/logout' iconLeft='user-interface.logout' sm open label='Logout'></rv-button></li>
			<hr *ngIf="showExtendedMenu" />
			<li *ngIf="showExtendedMenu"><rv-button routerLink='/account' sm open label='Account'></rv-button></li>
			<li *ngIf="showExtendedMenu"><rv-button routerLink='/blank' sm open label='Blank'></rv-button></li>
			<li *ngIf="showExtendedMenu"><rv-button routerLink='' sm open label='Feed'></rv-button></li>
			<li *ngIf="showExtendedMenu"><rv-button routerLink='/ui' sm open label='Interface'></rv-button></li>
		</ul>
	</div>
</div>