import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationSelectorComponent } from './location-selector/location-selector.component';
import { UiInputModule } from 'src/app/shared/interface/ui-input/ui-input.module';
import { GoogleMapsModule } from '@angular/google-maps'

@NgModule({
  declarations: [
    LocationSelectorComponent
  ],
  imports: [
    CommonModule,
    UiInputModule,
    GoogleMapsModule
  ],
  exports: [
  	LocationSelectorComponent
  ]
})
export class LocationsModule { }
