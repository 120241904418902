import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { NativeInterfaceRequest } from './native-interface.request'

@Injectable({
  providedIn: 'root'
})
export class NativeInterfaceService {

	get isNative (): boolean
	{
		return !!Capacitor.isNative;
	}

  constructor () 
  {}
}
