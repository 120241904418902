export const environment = {
    production: true,
    hmr: false,
    version: 'undefined',
	  socialProviders: {
	    facebookAppKey: '2023777574490370',
	    googleClientId: '59670370464-5bh3vdr5falf9h48qi37n46tokkbgifk.apps.googleusercontent.com',
	    authServerUrl: 'https://bauth.rearview.com/api',
	    cognitoUserPoolId: 'us-east-1_dIrcesGV4',
	    cognitoAppClientId: '5sq8v10rj0197cmkga98uvb4i3'
	  },
	  integrations: {
	    stripe: {
	      key: 'pk_test_0BxKkJD3VhACIIDUjdLJlvBB003ZngP49t'
	    },
	    google: {
	      locations: {
	        api_key: 'AIzaSyChmQ9pLA3rgVaw3UkU9Y4TPpQyAulMfhE'
	      }
	    }
	  },
	  api: {
	    api_url: 'https://bauth.rearview.com/api',
	    media_url: 'https://cdn.rearview.dev'
	  },
		app: {
			auth: {
		  	can_register: true,
		  }
		}
};
