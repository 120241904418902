<div id="account-creator-component">
	<form [formGroup]="newAccountForm" class="rv-form rv-new-account-form rv-form-bg-blue" name="newAccountForm" layout layout-align="center" layout-padding novalidate (ngSubmit)="handleSubmit()" #newAcctFormRef>
		<div class="inputs">
			<div class="form-group">
				<input class="form-control" type="text" formControlName="name" required placeholder="New Account Name" />
			</div>
		</div>
	  <button [disabled]="isSaving" class="btn-block btn-spinner" [class.spin]="isSaving" #submitBtnRef type='submit' mat-button matSuffix mat-flat-button aria-label="">
	  	<div class="btn-content">
	  		<div class="btn-label" [innerHTML]="'Save'"></div>
	  	</div>
		</button>
	</form>
</div>