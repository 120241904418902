<div class="comments-component">
	<div class="row" *ngIf="Member">
		<div class="col c-img">
			<rv-img *ngIf="url" class="user-menu-toggle" round [src]="url" />
		</div>
		<div class="col c-msg">
			<div [innerHTML]="Member.get('nickname')"></div>
			<div [innerHTML]="Comment?.get('comment')"></div>
			<div class="row">
				<div>
					<rv-comment-like-button [Comment]="Comment" [Member]="Member" />
				</div>
				<div>
					<rv-comment-reply-button [Comment]="Comment" [Member]="Member" (onReplyTo)="_onReplyTo($event)" />
				</div>
			</div>
			<rv-commenter *ngIf="ReplyTo && ReplyTo.id() === Comment?.id()" [Memory]="Memory" [Comment]="Comment" (onReplyTo)="_onReplyTo(undefined)" />
		</div>
	</div>
</div>