import { Pipe, PipeTransform } from '@angular/core';
import { ModelInstance } from '@getrearview/model-builder';

@Pipe({name: 'activeMemoryChest'})
export class ActiveMemoryChestPipe implements PipeTransform {
  transform(FeedMilestones: Array<ModelInstance>, id?: string): Array<ModelInstance> {
  	if (id && Array.isArray(FeedMilestones))
  		return FeedMilestones.filter((FeedMilestone: ModelInstance) => `${FeedMilestone.get('c_id')}` === `${id}`)
		return FeedMilestones
  }
}