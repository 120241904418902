import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RearviewInterfaceModule } from 'src/app/shared/interface/rearview-interface.module';
import { ModalModule } from 'src/app/widgets/modal/modal.module';
import { MediaImporterModule } from 'src/app/widgets/media-importer/media-importer.module';
import { MemoryEditorModule } from 'src/app/widgets/memory-editor/memory-editor.module';
import { MilestoneEditorModule } from 'src/app/widgets/milestone-editor/milestone-editor.module';
import { MilestoneCreatorModule } from 'src/app/widgets/milestone-creator/milestone-creator.module';
import { MemoryChestEditorModule } from 'src/app/widgets/memory-chest-editor/memory-chest-editor.module';
import { MemoryContentCreatorModule } from 'src/app/widgets/memory-content-creator/memory-content-creator.module';
import { FloatingControlsWidgetComponent } from './floating-controls-widget/floating-controls-widget.component';
import { FloatingControlService } from './floating-control.service';
import { MilestoneLocationCreatorModule } from 'src/app/widgets/milestone-location-creator/milestone-location-creator.module';

@NgModule({
  declarations: [
    FloatingControlsWidgetComponent
  ],
  imports: [
    CommonModule,
    ModalModule,
    MediaImporterModule,
    MemoryEditorModule,
    MilestoneEditorModule,
    MilestoneCreatorModule,
    MemoryChestEditorModule,
    RearviewInterfaceModule,
    MemoryContentCreatorModule,
    MilestoneLocationCreatorModule
  ],
  providers: [
  	{ provide: 'FloatingControlService', useClass: FloatingControlService }
  ],
  exports: [
  	FloatingControlsWidgetComponent
  ]
})
export class FloatingControlsModule { }
