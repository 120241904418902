import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AccountRoutingModule } from './account-routing.module';
import { AccountPageComponent } from './account-page/account-page.component';
import { ProductListComponent } from './product-list/product-list.component';
import { ProductCheckoutComponent } from './product-checkout/product-checkout.component';
import { PaymentMethodManagerComponent } from './payment-method-manager/payment-method-manager.component';
import { LoadersModule } from './../../widgets/loaders/loaders.module';
import { UserAccountListComponent } from './user-account-list/user-account-list.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { AccountCreatorComponent } from './account-creator/account-creator.component';
import { AccountPhoneSetterGetterComponent } from './account-phone-setter-getter/account-phone-setter-getter.component';

@NgModule({
  declarations: [AccountPageComponent, ProductListComponent, ProductCheckoutComponent, PaymentMethodManagerComponent, UserAccountListComponent, AccountSettingsComponent, AccountCreatorComponent, AccountPhoneSetterGetterComponent],
  imports: [
    CommonModule,
    AccountRoutingModule,
    ReactiveFormsModule,
    LoadersModule,
    FormsModule
  ]
})
export class AccountModule { }
