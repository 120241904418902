import { Component, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { CognitoUserPool, CognitoUserAttribute, CognitoUser, AuthenticationDetails, CognitoUserSession } from 'amazon-cognito-identity-js';
import { environment } from 'src/environments/environment'
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { SessionUser } from 'src/app/core/session/session-user.model';
import { ApiService } from 'src/app/core/services/api.service';
import { ConfigService } from 'src/app/shared/services/config.service';
import { TrackJsService } from 'src/app/shared/services/trackjs.service';
import { EMAIL as EMAIL_PATTERN } from 'src/app/shared/patterns'
import { Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

interface formDataInterface {
  "email": string;
  [key: string]: string;
};

interface cognitoUserAuthStore {
  "session"?: CognitoUserSession;
  "user"?: CognitoUser;
};

interface cognitoSignupRequest {
  "email": string; 
  "password": string; 
  "attributeList": Array<CognitoUserAttribute>
};

interface cognitoConfirmRequest {
  "email": string; 
  "code": string;
};

interface cognitoLoginRequest {
  "email": string; 
  "password": string;
};

interface cognitoResponseInterface {
  "status": boolean;
  "error": string;
};

interface cognitoAuthResponseInterface {
  "status": boolean;
  "error": string;
  "session"?: {[key: string]: any};
  "user"?: {[key: string]: any};
};

function getErrorCodeFromCognitoError (error: string): string
{
	const ERROR_CODES = {
		INVALID_PASS: 			'incorrect username or password.',
		INSECURE_PASS: 			'password did not conform with policy',
		SHORT_PASS: 				'password did not conform with policy: password not long enough',
		LONG_PASS: 					'1 validation error detected: value at \'password\' failed to satisfy constraint: member must have length less than or equal to 256',
		USERNAME_EXISTS: 		'an account with the given email already exists.',
		TOO_MANY_ATTEMPTS: 	'password attempts exceeded'
	}

	error = `${error || ''}`.trim().toLowerCase();
	let errors = Object.keys(ERROR_CODES).filter(errCode => ERROR_CODES[errCode] === `${error}` || `${error}`.includes(ERROR_CODES[errCode]))
	error = (error = errors.shift()) ? error : "UNKNOWN_ERROR";

	return error;
}

async function cognitoUserSignup (request: cognitoSignupRequest): Promise<cognitoResponseInterface>
{
  const userPool 			= new CognitoUserPool({
									    			UserPoolId: environment.socialProviders.cognitoUserPoolId,
									    			ClientId: 	environment.socialProviders.cognitoAppClientId
									  			})

	return new Promise((resolve, reject) => {
		const {email,password,attributeList} = request;
    userPool.signUp(email, password, attributeList, [], (error, data) => {
    	let response: cognitoResponseInterface = {status: false, error: ''};

      if (error) {
      	response.error = (error.message || JSON.stringify(error));
      	return resolve(response);
      }

      response.status = true;
      resolve(response);
    });
	})
};

async function cognitoUserConfirm (request: cognitoConfirmRequest): Promise<cognitoResponseInterface>
{
  const userPool 			= new CognitoUserPool({
									    			UserPoolId: environment.socialProviders.cognitoUserPoolId,
									    			ClientId: 	environment.socialProviders.cognitoAppClientId
									  			}),
  			cognitoUser 	= new CognitoUser({
  													Username: request.email, 
  													Pool: userPool
  												});

	return new Promise((resolve, reject) => {
  	cognitoUser.confirmRegistration(request.code, true, (error, data) => {
    	let response: cognitoResponseInterface = {status: false, error: ''};

      if (error) {
      	response.error = (error.message || JSON.stringify(error));
      	return resolve(response);
      }

      response.status = true;
      resolve(response);
		});
  });
};

async function cognitoLogin (request: cognitoLoginRequest): Promise<cognitoAuthResponseInterface>
{
	return new Promise((resolve, reject) => {
		let authenticationDetails = new AuthenticationDetails({
		  Username: request.email,
		  Password: request.password,
		});

		let poolData = {
		  UserPoolId: environment.socialProviders.cognitoUserPoolId,
		  ClientId: environment.socialProviders.cognitoAppClientId
		};

		let userPool = new CognitoUserPool(poolData);
		let userData = { Username: request.email, Pool: userPool };
		var cognitoUser = new CognitoUser(userData);

		cognitoUser.authenticateUser(authenticationDetails, {
		  onSuccess: function (session: CognitoUserSession) {
	    	const response: cognitoAuthResponseInterface = {status: true, error: '', session, user: cognitoUser};
	      return resolve(response);

		  },
		  onFailure: function (error) {
	    	error = (error && typeof error === 'object' && error?.error) ? error?.error : error;
	    	let response: cognitoAuthResponseInterface = {status: false, error, user: undefined, session: undefined};
	      return reject(response);
		  },
		});
	});
};

@Component({
  selector: 'rv-legacy-signin-form',
  templateUrl: './legacy-signin-form.component.html',
  styleUrls: ['./legacy-signin-form.component.scss']
})
export class LegacySigninFormComponent {

	@Input('InviteToken')
	InviteToken;

	@Output('onSuccess')
	onSuccess: EventEmitter<any> = new EventEmitter();
	// onSuccess: EventEmitter<SigninRequestResponse> = new EventEmitter();

	@Output('onError')
	onError: EventEmitter<any> = new EventEmitter();
	// onError: EventEmitter<SigninRequestResponse> = new EventEmitter();

	@Output('onStateChange') 
	onStateChange = new EventEmitter();

	// Boolean flag indicating if the login/forgot
	// pass/reset/sign up forms are being submitted.
	isSubmitting: boolean = false;

	// Boolean flag indicating if the 'email_or_phone'
	// field should be displayed when verifying code.
	showEmail: boolean = false;

	signinForm = this.fb.group({
		email_or_phone: 		['', [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
		password: 					['', [Validators.required]]
	});

	registerForm = this.fb.group({
		email_or_phone: 		['', [Validators.required]],
		password: 					['', [Validators.required]],
		code: 							['', [Validators.required]]
	});

	private errors: Array<string> = [];

	_subscriptions$ = new Subscription();


	// goToLogin = () => 
	// 	this.AuthFormSrvc.state = AuthFormStates.LOGIN;

	// goToLoginForm = () => 
	// 	this.AuthFormSrvc.state = AuthFormStates.LOGIN_FORM;

	// goToSignup = () =>
	// 	this.AuthFormSrvc.state = AuthFormStates.SIGNUP;

	// goToSignupForm = () =>
	// 	this.AuthFormSrvc.state = AuthFormStates.SIGNUP_FORM;

	// goToForgotPassword = () =>
	// 	this.AuthFormSrvc.state = AuthFormStates.FORGOT_PASSWORD;

	// isAuthState = state =>
	// 	state && AuthFormStates[this.AuthFormSrvc.state] === `${state}`.trim().toUpperCase();

	hasManualError = (): boolean => 
		this.errors.length > 0;

	isManualError = (err): boolean => 
		this.errors.indexOf(`${err}`.trim().toUpperCase()) > -1;

	// allErrors = () =>
	// 	this.errors

	reset = (inclForms?: boolean) => 
	{
		this.errors = [];

		if (inclForms === true) {

        // this.yourForm.form.markAsPristine();
        // this.yourForm.form.markAsUntouched();
        // this.yourForm.form.updateValueAndValidity();
			this.signinForm.markAsPristine();
			this.signinForm.markAsUntouched();
			this.signinForm.updateValueAndValidity();
			// this.resetForm.markAsPristine();
			// this.resetForm.markAsUntouched();
			// this.resetForm.updateValueAndValidity();
			// this.registerForm.markAsPristine();
			// this.registerForm.markAsUntouched();
			// this.registerForm.updateValueAndValidity();
		}
	}

	// private _handleSuccess = (response?: any, next?: AuthFormStates): void =>
	private _handleSuccess = (response?: any): void =>
	{
		const cognito: cognitoUserAuthStore = response && typeof response === 'object' ? response : {};
		// console.log("_handleSuccess: ",response,cognito,this.AuthFormSrvc.state);

		// switch (this.AuthFormSrvc.state)
		// {
		// 	case 1:
		// 	case AuthFormStates.LOGIN:
		// 		// console.log("_HS: LOGIN");
		// 		this.AuthFormSrvc.notify = "Great!  We'll send you to your favorite memory chest momentarily.";
		// 		this.reset(true);
		// 		break;
		// 	case 3:
		// 	case AuthFormStates.FORGOT_PASSWORD:
		// 		// console.log("_HS: FORGOT_PASSWORD");
		// 		this.AuthFormSrvc.notify = "We've sent an email or text message with a code to reset your password.";
		// 		this.AuthFormSrvc.state = AuthFormStates.FORGOT_PASSWORD_CONFIRM;
		// 		break;
		// 	case 4:
		// 	case AuthFormStates.FORGOT_PASSWORD_CONFIRM:
		// 		// console.log("_HS: FORGOT_PASSWORD_CONFIRM");
		// 		this.AuthFormSrvc.notify = "Great!  Your password has been reset.  You will be redirected to login momentarily.";
		// 		break;
		// 	case 5:
		// 	case AuthFormStates.SIGNUP:
		// 		// console.log("_HS: SIGNUP");
		// 		this.AuthFormSrvc.notify = "We've sent an email or text message with a code to complete your registration.";
		// 		this.AuthFormSrvc.state = AuthFormStates.SIGNUP_CONFIRM;
		// 		break;
		// 	case 6:
		// 	case AuthFormStates.SIGNUP_CONFIRM:
		// 		// console.log("_HS: SIGNUP_CONFIRM");
		// 		this.AuthFormSrvc.notify = "Great!  Your account has been created.  We'll send you to your account momentarily.";
		// 		// login & redirect.
		// 		break;
		// }
	}

	private _handleFailure = (response: any): boolean =>
	{
		this.isSubmitting = false;
		return (this.errors = [response]) && false;
	}

	private _handleResponse = (response: any): void|boolean =>
	{
		this.isSubmitting = false;

		// console.log('_handleResponse ',response);

		// Successes - - - - - - - - - - - - - - - - - - -
		// Forgot Pass Confirmation: Success
		// Registration Confirmation: Success <ISignUpResult> ?? 
		if (response === 'SUCCESS')
			return this._handleSuccess();

		if (response && typeof response === 'object') {

			// // Forgot Password: response object.
			// if (response?.CodeDeliveryDetails && this.AuthFormSrvc.state === AuthFormStates.FORGOT_PASSWORD)
			// 	return this._handleSuccess();

			// // Login: response object.
			// if (response instanceof CognitoUser && this.AuthFormSrvc.state === AuthFormStates.LOGIN) {
			// 	console.log("check: cognito user login -> has onboarded?");
			// 	return this._handleSuccess();
			// }

			// // Registration: response object.
			// if (response?.userSub && this.AuthFormSrvc.state === AuthFormStates.SIGNUP) {
			// 	console.log("check: cognito user registration -> go to onboarding");
			// 	return this._handleSuccess();
			// }

			// // Cognito Login: response object
			// if (response?.session && response?.user && this.AuthFormSrvc.state === AuthFormStates.LOGIN) {
			// 	console.log("check: cognito user -> reboot");
			// 	return this._handleSuccess(response);
			// }
		}



		// Errors - - - - - - - - - - - - - - - - - - - - 
		// Too many tries to update password.
		if (response === "TOO_MANY_TRIES")
			return this._handleFailure(response);

		// Invalid Password on login attempt
		if (response === "INVALID_PASS" || (response?.error === "INVALID_PASS"))
			return this._handleFailure("INVALID_PASS");

		if (response === "TOO_MANY_ATTEMPTS" || (response?.error === "TOO_MANY_ATTEMPTS"))
			return this._handleFailure("TOO_MANY_ATTEMPTS");

		// Invalid verification code when confirming reset password.
		if (response === "INVALID_CODE")
			return this._handleFailure(response);

		// Registration: Username already in use.
		if (response === "USERNAME_EXISTS" || (response?.error === "USERNAME_EXISTS"))
			return this._handleFailure("USERNAME_EXISTS");

		// this.TrackJsSrvc.track("Unknown error occurred during AWS authentication request. ",{response, formStateConfig: this.AuthFormSrvc.formStateConfig});
		return this._handleFailure("UNKNOWN_ERROR");
	}

	handleLogin = async () =>
	{
		if (!this.signinForm.valid) return;
		this.isSubmitting = true;


		const pass = async (cognitoLoginResponse: cognitoAuthResponseInterface): Promise<void> => 
		{
			// Cognito->Rearview.signin
			await this.AuthApi
										.signin({idToken: cognitoLoginResponse?.session?.idToken?.jwtToken, provider: 'COGNITO', inviteToken: this.InviteToken?.payload()})
										.subscribe(
										  (data: any) => {
										  	this.SessionUser.token(data.access_token, 'COGNITO').finally(() => this._handleResponse(cognitoLoginResponse))
										  },
										  (error) => this.onError.next(error)
										);

			return Promise.resolve();
		}

		try {
			await pass(await cognitoLogin({email: this.signinForm.get('email_or_phone').value, password: this.signinForm.get('password').value}));
		}
		catch (ex) {
			console.error(`Failed to complete login ${ex}.`)
			// return this._handleFailure(getErrorCodeFromCognitoError(((ex && typeof ex === 'object' && ex?.error) || `${ex}`))); // || 'UNKNOWN_ERROR'
		}








		// console.log("handleLogin");

		// const pass = async (cognitoLoginResponse: cognitoAuthResponseInterface): Promise<void> => 
		// {
		// 	// Cognito->Rearview.signin
		// 	console.log('Rearview.signin');
		// 	await this.AuthApi
		// 								.signin({
		// 										idToken: cognitoLoginResponse?.session?.idToken?.jwtToken, 
		// 										provider: 'COGNITO', 
		// 										inviteToken: this.InviteToken
		// 									})
		// 								.subscribe(
		// 								  (data: any) => {
		// 								  	console.log('Rearview.signin.success ',this.InviteToken);
		// 								  	this.SessionUser.token(data.access_token, 'COGNITO').finally(() => this._handleResponse(cognitoLoginResponse))
		// 								  },
		// 								  (error) => console.log(error)
		// 								);

		// 	return Promise.resolve();
		// }

		// try {
		// 	await pass(await cognitoLogin({email: this.signinForm.get('email_or_phone').value, password: this.signinForm.get('password').value}));
		// }
		// catch (ex) {
		// 	return this._handleFailure(getErrorCodeFromCognitoError(((ex && typeof ex === 'object' && ex?.error) || `${ex}`))); // || 'UNKNOWN_ERROR'
		// }
	}

	handleSignup = async () => 
	{
		// console.log('handleSignup');
		if (!this.registerForm.valid) return;
		this.isSubmitting 			= true;

    const attributeList: Array<CognitoUserAttribute> 	= 	[],
    			formData: formDataInterface 								= 	{"email": this.registerForm.get('email_or_phone').value};
    attributeList.push(new CognitoUserAttribute({Name: "email", Value: this.registerForm.get('email_or_phone').value}));
    attributeList.push(new CognitoUserAttribute({Name: "name", Value: "steven waskey"}));

    const signupResponse: cognitoResponseInterface = await cognitoUserSignup({"email": this.registerForm.get('email_or_phone').value, "password": this.registerForm.get('password').value, attributeList})
    // console.log('signupResponse:  ',signupResponse);

    this.isSubmitting 			= false;

    if (!signupResponse.status)
    	return this._handleFailure(getErrorCodeFromCognitoError(signupResponse?.error));

		this._handleSuccess();
	}

	handleSignupConfirm = async () => 
	{
		// console.log('handleSignupConfirm',this.registerForm.valid,this.registerForm.errors);
		if (!this.registerForm.valid) return;
		this.isSubmitting 			= true;

		this.registerForm.get('email_or_phone').value

    const confirmResponse: cognitoResponseInterface = await cognitoUserConfirm({"email": this.registerForm.get('email_or_phone').value, "code": this.registerForm.get('code').value})

    this.isSubmitting 			= false;

    if (!confirmResponse.status)
    	return this._handleFailure(getErrorCodeFromCognitoError(confirmResponse?.error));

		this._handleSuccess();

		const authResponse = await cognitoLogin({
															email: 		this.registerForm.get('email_or_phone').value, 
															password: this.registerForm.get('password').value
														});

		if (authResponse.status && authResponse?.session) {
	    const signupApi = await this.AuthApi
	    			.signup({idToken: authResponse?.session.idToken.jwtToken, provider: 'COGNITO'})
	    			.subscribe((data: any) => {
						    if (data.success === true && data?.access_token)
						    	this.SessionUser.token(`${data.access_token}`, 'COGNITO');
	    				},(error) => {
	    					console.error(error); 
	    				});
		}
	}

  constructor (protected SessionUser: SessionUser, protected AuthApi: ApiService, protected ConfigSrvc: ConfigService, protected fb: FormBuilder, protected TrackJsSrvc: TrackJsService)  // protected AuthFormSrvc: AuthFormService,
  {}
}







// 	private _isLoggedin?: boolean = undefined;
// 	private _subscriptions$ = new Subscription();

// 	constructor (private auth: AuthService, private socialAuthService: SocialAuthService)
// 	{}

// 	ngOnInit (): void
// 	{
// 		this._subscriptions$.add(this.socialAuthService.authState.subscribe((User: SocialUser) => {
// 		  if (User && !this._isLoggedin) {
// 		    this._isLoggedin = User != null;
// 		    // Social user has authenticated w/ Social Provider.
// 		    // We will now request an access token from the RV API.
// 		    this.auth.requestAccessToken(User, {InviteToken: this.InviteToken})
// 		    					.then((res: SigninRequestResponse) => this.onSuccess.next(res))
// 		    					.catch((err: SigninRequestResponse) => this.onError.next(err));
// 		  }
// 		}));
// 	}

// 	ngOnDestroy (): void
// 	{
// 		this._subscriptions$.unsubscribe();
// 	}
// }