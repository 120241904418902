import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleSigninButtonComponent } from './google-signin-button/google-signin-button.component';
import { FacebookSigninButtonComponent } from './facebook-signin-button/facebook-signin-button.component';
import { SocialLoginModule } from '@abacritt/angularx-social-login';
import { RearviewInterfaceModule } from 'src/app/shared/interface/rearview-interface.module';
import { SocialSigninComponent } from './social-signin/social-signin.component';
import { LegacySigninFormComponent } from './legacy-signin-form/legacy-signin-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from './../directives/directives.module';
import { FacebookButtonComponent } from './facebook-button/facebook-button.component';
import { GoogleButtonComponent } from './google-button/google-button.component';
import { SocialSignupComponent } from './social-signup/social-signup.component';

@NgModule({
  declarations: [
    GoogleSigninButtonComponent,
    FacebookSigninButtonComponent,
    SocialSigninComponent,
    LegacySigninFormComponent,
    FacebookButtonComponent,
    GoogleButtonComponent,
    SocialSignupComponent
  ],
  imports: [
    CommonModule,
    SocialLoginModule,
    DirectivesModule,
    ReactiveFormsModule,
    RearviewInterfaceModule
  ],
  exports: [
		GoogleSigninButtonComponent,
		FacebookSigninButtonComponent,
		SocialSigninComponent,
		SocialSignupComponent,
		LegacySigninFormComponent,
		GoogleButtonComponent,
		FacebookButtonComponent
  ]
})
export class AuthenticationModule { }
