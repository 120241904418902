import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { FloatingControlsModule } from './../../widgets/floating-controls/floating-controls.module';

// declared components
import { MilestoneRoutingModule } from './milestone-routing.module';
import { MilestonePageComponent } from './milestone-page/milestone-page.component';
import { DateIndicatorComponent } from './date-indicator/date-indicator.component';
import { MemoryComponent } from './memory/memory.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MemoryModule } from './../../widgets/memory/memory.module';
import { RearviewInterfaceModule } from './../../shared/interface/rearview-interface.module';
import { StoryService } from './shared/story.service';
import { PreviousBtnComponent } from './previous-btn/previous-btn.component';
import { NextBtnComponent } from './next-btn/next-btn.component';
import { CommentsIndicatorComponent } from './comments-indicator/comments-indicator.component';
import { MemoryMediaRouterComponent } from './memory-media-router/memory-media-router.component';
import { MemoryMediaImageComponent } from './memory-media-image/memory-media-image.component';
import { MemoryMediaMultiImageComponent } from './memory-media-multi-image/memory-media-multi-image.component';
// import { MemoryMediaVideoComponent } from './memory-media-video/memory-media-video.component';
// import { MemoryMediaAudioComponent } from './memory-media-audio/memory-media-audio.component';
import { CommentsComponent } from './comments/comments.component';
import { CommentComponent } from './comment/comment.component';
import { CommenterComponent } from './commenter/commenter.component';
import { CommentLikeButtonComponent } from './comment-like-button/comment-like-button.component';
import { CommentReplyButtonComponent } from './comment-reply-button/comment-reply-button.component';
import { MediaAudioService } from './shared/media-audio.service';
import { CreateMemoryFormComponent } from './create-memory-form/create-memory-form.component';
import { EditMemoryFormComponent } from './edit-memory-form/edit-memory-form.component';
import { NoMemoriesComponent } from './no-memories/no-memories.component';
import { MemoryFormComponent } from './memory-form/memory-form.component';
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';
import { LocationsModule } from 'src/app/widgets/locations/locations.module';
import { DateLocationComponent } from './date-location/date-location.component';
import { MemberModule } from 'src/app/widgets/member/member.module';
import { StripEmptyMemoriesPipe } from './shared/strip-empty-memories.pipe';
import { MemoryMediaUrlComponent } from './memory-media-url/memory-media-url.component';
import { DeleteMediaConfirmComponent } from './delete-media-confirm/delete-media-confirm.component';
import { MilestoneIntroComponent } from './milestone-intro/milestone-intro.component';
import { MemoryCreatorModule } from 'src/app/widgets/memory-creator/memory-creator.module';
import { HashtagManagerModule } from 'src/app/widgets/hashtag-manager/hashtag-manager.module';
// import { MemoryContentCreatorModule } from 'src/app/widgets/memory-content-creator/memory-content-creator.module';
// import { MilestoneLocationCreatorModule } from 'src/app/widgets/milestone-location-creator/milestone-location-creator.module';
import { DragDropModule } from '@angular/cdk/drag-drop'

@NgModule({
  declarations: [
  	MilestonePageComponent,
  	MemoryComponent,
  	HeaderComponent,
    FooterComponent,
  	DateIndicatorComponent,
   	PreviousBtnComponent,
   	NextBtnComponent,
   	CommentsIndicatorComponent,
   	MemoryMediaRouterComponent,
   	MemoryMediaImageComponent,
    MemoryMediaMultiImageComponent,
//     MemoryMediaVideoComponent,
//     MemoryMediaAudioComponent,
    CommentsComponent,
    CommentComponent,
    CommenterComponent,
    CommentLikeButtonComponent,
    CommentReplyButtonComponent,
    CreateMemoryFormComponent,
    EditMemoryFormComponent,
    MemoryFormComponent,
    ImageUploaderComponent,
    StripEmptyMemoriesPipe,
    NoMemoriesComponent,
    MemoryMediaUrlComponent,
    DeleteMediaConfirmComponent,
    MilestoneIntroComponent,
    DateLocationComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MilestoneRoutingModule,
    RearviewInterfaceModule,
    LocationsModule,
    MemberModule,
    MemoryModule,
    SharedModule,
    MemoryCreatorModule,
    HashtagManagerModule,
    FloatingControlsModule,
    DragDropModule
    // MilestoneLocationCreatorModule
  ],
  providers: [
  	StoryService,
    MediaAudioService
  ]
})
export class MilestoneModule { }


