import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

@Injectable()
export class NavigationService {

	private history: string[] = [];

	_subscriptions$ = new Subscription();

  constructor(private router: Router, private location: Location) {
  	this._subscriptions$.add(this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd)
        this.history.push(event.urlAfterRedirects);
    }));
  }

  public canGoBack (): boolean
  {
  	return this.history.length > 0;
  }

  public goBack (): void 
  {
    if (this.history.length > 0) {
    	if (this.history.pop())
      	this.location.back();
    }
  }

  public cleanUp (): void
  {
  	if (this._subscriptions$)
  		this._subscriptions$.unsubscribe();
  }
}
