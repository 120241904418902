<div id="ui-page-colors-component" class="container-fluid">
	<div class="row">
		<div class="col-24 text-center mt-4 mb-4">
			<h4>UI Colors</h4>
		</div>
		<div class="col-24">
			<h5 class="text--underline full-width-block font-color--grey-700">Primary Colors</h5>
		</div>
		<div class="ui-color-card-col"  *ngFor="let color of primaries">
			<ui-color-card group="primary" [color]="color" [hex]="hex(color)" [rgb]="rgb(color)"></ui-color-card>
		</div>
	</div>
	<br />
	<div class="row">
		<div class="col-24">
			<h5 class="text--underline full-width-block font-color--grey-700">Secondary Colors</h5>
		</div>
		<div class="ui-color-card-col"  *ngFor="let color of secondaries">
			<ui-color-card group="secondary" [color]="color" [hex]="hex(color)" [rgb]="rgb(color)"></ui-color-card>
		</div>
	</div>
	<br />
	<div class="row">
		<div class="col-24">
			<h5 class="text--underline full-width-block font-color--grey-700">Greyscale Colors</h5>
		</div>
		<div class="ui-color-card-col"  *ngFor="let color of greyscales">
			<ui-color-card group="greyscale" [color]="color" [hex]="hex(color)" [rgb]="rgb(color)"></ui-color-card>
		</div>
	</div>
	<br />
	<div class="row">
		<div class="col-24">
			<h5 class="text--underline full-width-block font-color--grey-700">Semantic Colors</h5>
		</div>
		<div class="ui-color-card-col"  *ngFor="let color of semantics">
			<ui-color-card group="semantic" [color]="color" [hex]="hex(color)" [rgb]="rgb(color)"></ui-color-card>
		</div>
	</div>
</div>