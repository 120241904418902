import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiIconWidgetComponent } from './ui-icon-widget/ui-icon-widget.component';

@NgModule({
  declarations: [UiIconWidgetComponent],
  imports: [
    CommonModule,
  ],
  exports: [
  	UiIconWidgetComponent
  ]
})
export class UiIconModule { }
