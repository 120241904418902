import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModelFactoryConfig, ModelService } from '@getrearview/model-builder';
import { ConfigService } from './../../shared/services/config.service';
import { MemoryMediaService } from './memory-media.service';
import { MemoryCommentService } from './memory-comment.service';

@Injectable({
  providedIn: 'root'
})
export class MemoryService extends ModelService {

  constructor (injector: Injector, httpClient: HttpClient) 
  {
  	let ConfigSrvc: ConfigService = injector.get<any>(ConfigService);
		let MFC: ModelFactoryConfig = (new ModelFactoryConfig()).setAll({
  				// Model Name
  				name: "Memory",
  				// Model Endpoint
					endpoint: ConfigSrvc.get("api.api_url")+"/memories/memories",
					// Model Fields
					fields: ['id', 'milestone_id', 'author_id', 'token', 'body', 'type', 'deleted_at', 'created_at', 'updated_at'],
					// Model `Permitted` Fields
					permitted: ['milestone_id', 'author_id', 'token', 'body', 'type'],
					// Model `Required` Fields
					required: [],
					// Model Field Labels
					labels: {},
					// Model Field Validation Patterns
					patterns: {},
					// Parent ID Field Name
					parent_id_field: "milestone_id",
					// Model Children Types
					children_types: {
						media: injector.get<any>(MemoryMediaService),
						comment: injector.get<any>(MemoryCommentService),
					},
					// Model Association Types
					association_types: {},
					// Storage Type (local, session, cookies)
					storage_type: "local"
				});

  	super(MFC, httpClient);
  }
}