import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModelFactoryConfig, ModelInstance, ModelService } from '@getrearview/model-builder';
import { ConfigService } from './../../shared/services/config.service';
import { MemoryChestTagService } from './memory-chest-tag.service';
import { MemberMediaService } from './member-media.service';

@Injectable({
  providedIn: 'root'
})
export class MemberService extends ModelService {

  constructor (injector: Injector, httpClient: HttpClient) 
  {
  	let ConfigSrvc: ConfigService = injector.get<any>(ConfigService);
		let MFC: ModelFactoryConfig = (new ModelFactoryConfig()).setAll({
  				// Model Name
  				name: "Memory Chest Member",
  				// Model Endpoint
					endpoint: ConfigSrvc.get("api.api_url")+"/memories/members",
					// Model Fields
					fields: ["chest_id", "user_id", "nickname", "member_type", "expose_at", "exposed_at", "invited_at", "joined_at"],
					// Model `Permitted` Fields
					permitted: ["chest_id", "user_id", "nickname", "member_type", "expose_at", "exposed_at", "invited_at", "joined_at"],
					// Model `Required` Fields
					required: [],
					// Model Field Labels
					labels: {},
					// Model Field Validation Patterns
					patterns: {},
					// Parent ID Field Name
					parent_id_field: "chest_id",
					// Model Children Types
					children_types: {
						tag: injector.get<any>(MemoryChestTagService),
						media: injector.get<any>(MemberMediaService),
					},
					// Model Association Types
					association_types: {"chest": null},
					// Storage Type (local, session, cookies)
					storage_type: "local",
				});

  	super(MFC, httpClient);
  }
}