<div class="chest-preview-component">
  <div *ngIf="MemoryChest?.isNew()===false">
		<div class="memory-chest-title">
			<div [routerLink]="['/memory-chests',MemoryChest.id()]"><h6 class="font-color--purple">{{MemoryChest?.get('nickname')}}</h6></div>
			<div rvDropdownContainer class="context-wrapper">
				<rv-button (click)="isContextOpen = !isContextOpen" open sm iconLeft="user-interface.menu"></rv-button>
				<rv-dropdown-widget [isOpen]="isContextOpen" position="right">
					<ul class="context-menu">
						<li><rv-button (click)="editMemoryChest(memoryChestEditor)" open sm dk-gray iconLeft="user-interface.pencil" label="Edit MemoryChest" /></li>
						<!-- <li><rv-button (click)="deleteMemoryChest()" open sm red iconLeft="user-interface.trash" label="Delete" /></li> -->
						<li><rv-button (click)="goToMemoryChest()" open sm iconLeft="user-interface.logout-1" label="Visit MemoryChest" /></li>
					</ul>
				</rv-dropdown-widget>
			</div>
		</div>
    <div [ngClass]="['preview-imgs', mediaRows.II.length>0?'':'tall-preview']" *ngIf="mediaRows.I.length">
      <div class="preview-img" *ngFor="let media of mediaRows.I; let idx = index;">
				<rv-img [cdn]="media" cover />
      </div>
    </div>
    <div class="preview-imgs img-row-two" *ngIf="mediaRows.II.length">
      <div class="preview-img" *ngFor="let media of mediaRows.II; let idx = index;">
				<rv-img [cdn]="media" cover />
      </div>
    </div>
    <div class="memory-chest-timestamp font-color--grey-400">Created on {{MemoryChest.get('created_at')|date:birthDateFormat}}</div>
  </div>
</div>

<ng-template #memoryChestEditor>
	<rv-memory-chest-editor-widget [memoryChestId]="memoryChestId" (onCancel)="onCancel($event)" (onSuccess)="onSuccess($event)"></rv-memory-chest-editor-widget>
</ng-template>