import { Injectable } from '@angular/core';
import { SessionUser } from './../../../core/session/session-user.model';
import { AuthFormStates } from './AuthFormStates.enum';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { StorageService } from './../../../shared/services/storage.service';

type GenericObject = { [key: string]: any };
type authStateConfig = {state: AuthFormStates, form_fields: GenericObject};

@Injectable({
  providedIn: 'root'
})
export class AuthFormService {

	private _FormState: BehaviorSubject<AuthFormStates> = new BehaviorSubject(AuthFormStates.LOGIN);
	private _formStateConfig: authStateConfig = {state:0, form_fields:{}};
	private _SuccessSubject: BehaviorSubject<string> = new BehaviorSubject("");
	private _ErrorSubject: BehaviorSubject<string> = new BehaviorSubject("");

	get onAuthChange (): Observable<boolean>
	{
		return this.SessionUser.onAuthChange;
	}

  get isAuth (): boolean
  {
  	return !!this.SessionUser.isAuth;
  }

	get	onAuthFormStateChange (): Observable<AuthFormStates>
	{
		return this._FormState;
	}

	get onSuccess (): Observable<string>
	{
		return this._SuccessSubject.asObservable().pipe(distinctUntilChanged())
	}

	get onError (): Observable<string>
	{
		return this._ErrorSubject.asObservable().pipe(distinctUntilChanged())
	}

	set notify (msg: string)
	{
		this._SuccessSubject.next(msg);
		setTimeout(() => this._SuccessSubject.next(""), 5000);
	}

	set fail (msg: string)
	{
		this._ErrorSubject.next(msg);
		setTimeout(() => this._ErrorSubject.next(""), 5000);
	}

	get formStateConfig (): {[key: string]: any}
	{
		let fsc = JSON.parse(JSON.stringify(this._formStateConfig))
		if (fsc && fsc?.form_fields?.password)
			fsc.form_fields.password = "*******";
		return fsc;
	}

	async fillAuthStateConfig ()
	{
  	this._formStateConfig = 		await this.fromStorage();
  	if (AuthFormStates[this._formStateConfig.state])
  		this.state = this._formStateConfig.state;
	}

	set state (state: AuthFormStates)
	{
		this._formStateConfig.state = state;
		this._FormState.next(this._formStateConfig.state);
		this.toStorage();
	}

	get state (): AuthFormStates
	{
		return this._formStateConfig?.state;
	}

	set emailOrPhone (emailOrPhone: string)
	{
		this._formStateConfig.form_fields.emailOrPhone = emailOrPhone;
		this.toStorage();
	}

	get emailOrPhone (): string
	{
		return this._formStateConfig?.form_fields?.emailOrPhone;
	}

	set password (password: string)
	{
		this._formStateConfig.form_fields.password = password;
		this.toStorage();
	}

	get password (): string
	{
		return this._formStateConfig?.form_fields?.password;
	}

	set code (code: string)
	{
		this._formStateConfig.form_fields.code = code;
		this.toStorage();
	}

	get code (): string
	{
		return this._formStateConfig?.form_fields?.code;
	}

	async provider (provider?: string): Promise<string>
	{
		if (provider && (provider = `${provider}`.trim().toUpperCase()) && ['COGNITO','FACEBOOK','GOOGLE'].includes(provider))
			this.StorageSrvc.store('auth.provider', provider);
		if (provider)
			return Promise.resolve(provider);

		return await this.StorageSrvc.retrieve('auth.provider');
	}

	private createNewStateConfig (): authStateConfig 
	{
		this._formStateConfig = {
			state: 					+AuthFormStates.LOGIN,
			form_fields: 		{}
		}

		return this._formStateConfig;
	}

	private async fromStorage (): Promise<authStateConfig>
	{
		let data;
		try {
			data = await this.StorageSrvc.retrieve('pages.auth.state','object');
			if (data && typeof data === 'object' && Object.values(data).length > 0)
				return data as authStateConfig;
		}
		catch (ex) {
			console.error(`Failed to retrieve authStateConfig.  Setup as new visitor. ${ex}`);
		}

		return Promise.resolve(this.createNewStateConfig());
	}

	private toStorage (): void
	{
		let formStateConfig = Object.assign(this._formStateConfig)
		formStateConfig.form_fields.password = null;

		this.StorageSrvc.store('pages.auth.state', formStateConfig);
	}

	clearStorage = () => 
		this.StorageSrvc.store('pages.auth.state', this.createNewStateConfig());

  constructor (private StorageSrvc: StorageService, private SessionUser: SessionUser) 
  {}
}
