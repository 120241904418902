<div id="modal-content" [@openClose]="isEnabled">
	<div class="modal-header row">
		<div class="col-22 font-color--purple">
			<h6>{{modalService.title|async}}</h6>
		</div>
		<div class="col-2">
			<rv-modal-close-btn icon='user-interface.close-2'></rv-modal-close-btn>
		</div>
	</div>
	<div class="modal-body">
		<ng-container #dynamicTemplate></ng-container>
	</div>
</div>
 