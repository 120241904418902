<div id="memory-creator-widget" class="container-fluid">
	<div class="row">
		<div class="col">
			<rv-textarea [formGroup]="memoryForm" controlName='caption' placeholder='Details ...' />
		</div>
	</div>
	<div class="row mt-2">
		<div class="col">
			<!-- <rv-hashtag-selector [formGroup]="memoryForm"  [control]="memoryForm.get('tag_ids')" controlName='tag_ids' [hideIfEmpty]="true" /> -->
		</div>
	</div>
	<div class="row mt-2">
		<div class="col">
			<!-- <rv-member-selector [chestId]="Milestone?.get('chest_id')" [formGroup]="memoryForm"  [control]="memoryForm.get('member_ids')" controlName='member_ids' [hideIfEmpty]="true" /> -->
		</div>
	</div>
	<div class="row mt-4">
		<div class="col-12">
			<rv-button outline purple md block label="Cancel" (click)="close()"/>
		</div>
		<div class="col-12">
			<rv-button [purple]="memoryForm.valid" [gray]="!memoryForm.valid" md block label="Create" (click)="memoryForm.valid && saveMemory()" />
		</div>
	</div>
</div>