import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { SwitchboardGuard } from './switchboard.guard';
import { RouteData } from 'src/app/core/values/route-data.value';
import { SwitchboardPageComponent } from './switchboard-page/switchboard-page.component';


const routes: Routes = [
	{ 
		path:                       '',
		component:                  SwitchboardPageComponent,
		canActivate:                [AuthGuard, SwitchboardGuard],
		data: 											new RouteData({animation:'switchboard'})
	}
];

@NgModule({
  imports:                      [RouterModule.forChild(routes)],
  exports:                      [RouterModule]
})
export class SwitchboardRoutingModule { }