import { Component, Input } from '@angular/core';
import { ChestService } from './../shared/chest.service';

@Component({
  selector: 'rv-no-milestones',
  templateUrl: './no-milestones.component.html',
  styleUrls: ['./no-milestones.component.scss']
})
export class NoMilestonesComponent {

	@Input('chestId')
	chestId: string;

	get showMe (): boolean
	{
		return !this.chestSrvc.doMilestonesExist();
	}

	constructor (public chestSrvc: ChestService)
	{}
}
