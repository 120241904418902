import { Component, Input, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormControl } from '@angular/forms';
import { UiInputWidgetComponent } from 'src/app/shared/interface/ui-input/ui-input-widget/ui-input-widget.component';
import { GoogleMap } from '@angular/google-maps';
import { ModelInstance } from '@getrearview/model-builder';

// const COMPONENT_TEMPLATE: string = 
// 	`<div class="location-selector-component">`
// +			`<ng-container *ngIf="mode === 'autocomplete'">`
// +				`<rv-input #rvInputSelector placeholder="Where at?"  [controlName]="controlName" [formGroup]="formGroup" [control]="control" iconLeft="location-and-map.add-location" />`
// +			`</ng-container>`
// +			`<ng-container *ngIf="mode === 'search'">`
// +				`<rv-input #rvInputSelector placeholder="Where at?"  [controlName]="controlName" [formGroup]="formGroup" [control]="control" iconLeft="location-and-map.add-location" />`
// // +				`<!-- <input #mapSearchField class="my-map-searchfield" type="text" /> -->`
// +				`<google-map [width]='mapWidth' [center]="initialCoordinates" [options]="mapConfigurations"></google-map>`
// +			`</ng-container>`
// +		`</div>`

const COMPONENT_TEMPLATE: string = 
	`<div class="location-selector-component">`
+			`<ng-container *ngIf="mode === 'autocomplete'">`
+				`<rv-input #rvInputSelector placeholder="Where at?"  controlName="locationName" [formGroup]="locationForm" [control]="locationForm.get('locationName')" iconLeft="location-and-map.add-location" />`
+			`</ng-container>`
+			`<ng-container *ngIf="mode === 'search'">`
+				`<rv-input #rvInputSelector placeholder="Where at?"  controlName="locationName" [formGroup]="locationForm" [control]="locationForm.get('locationName')" iconLeft="location-and-map.add-location" />`
// +				`<!-- <input #mapSearchField class="my-map-searchfield" type="text" /> -->`
+				`<google-map [width]='mapWidth' [center]="initialCoordinates" [options]="mapConfigurations"></google-map>`
+			`</ng-container>`
+		`</div>`



/*
https://timdeschryver.dev/blog/google-maps-as-an-angular-component

<google-maps (boundsChanged)="handler($event)"	bounds_changed	This event is fired when the viewport bounds have changed
							(centerChanged)="handler($event)"	center_changed	This event is fired when the map center property changes
							(mapClick)="handler($event)"	click	This event is fired when the user clicks on the map
							(mapDblclick)="handler($event)"	dblclick	This event is fired when the user double-clicks on the map. Note that the click event will also fire, right before this one
							(mapDrag)="handler($event)"	drag	This event is repeatedly fired while the user drags the map
							(mapDragend)="handler($event)"	dragend	This event is fired when the user stops dragging the map
							(mapDragstart)="handler($event)"	dragstart	This event is fired when the user starts dragging the map
							(headingChanged)="handler($event)"	heading_changed	This event is fired when the map heading property changes
							(idle)="handler($event)"	idle	This event is fired when the map becomes idle after panning or zooming
							(maptypeidChanged)="handler($event)"	maptypeid_changed	This event is fired when the mapTypeId property changes
							(mapMousemove)="handler($event)"	mousemove	This event is fired whenever the user's mouse moves over the map container
							(mapMouseout)="handler($event)"	mouseout	This event is fired when the user's mouse exits the map container
							(mapMouseover)="handler($event)"	mouseover	This event is fired when the user's mouse enters the map container
							(projectionChanged)="handler($event)"	projection_changed	This event is fired when the projection has changed
							(mapRightclick)="handler($event)"	rightclick	This event is fired when the DOM contextmenu event is fired on the map container
							(tilesloaded)="handler($event)"	tilesloaded	This event is fired when the visible tiles have finished loading
							(tiltChanged)="handler($event)"	tilt_changed	This event is fired when the map tilt property changes
							(zoomChanged)="handler($event)"	zoom_changed	This event is fired when the map zoom property changes
		/>

this.map.fitBounds()	Sets the viewport to contain the given bounds
this.map.panBy()	Changes the center of the map by the given distance in pixels
this.map.panTo()	Changes the center of the map to the given LatLng
this.map.panToBounds()	Pans the map by the minimum amount necessary to contain the given LatLngBounds
this.map.getBounds()	Returns the lat/lng bounds of the current viewport
this.map.getCenter()	Returns the position displayed at the center of the map
this.map.getClickableIcons()	Returns the clickability of the map icons
this.map.getHeading()	Returns the compass heading of aerial imagery
this.map.getMapTypeId()	Returns the map type id
this.map.getProjection()	Returns the current Projection
this.map.getStreetView()	Returns the default StreetViewPanorama bound to the map, which may be a default panorama embedded within the map
this.map.getTilt()	Returns the current angle of incidence of the map, in degrees from the viewport plane to the map plane
this.map.getZoom()	Returns the current zoom
this.map.controls()	Additional controls to attach to the map
this.map.data()	An instance of Data, bound to the map
this.map.mapTypes()	A registry of MapType instances by string ID
this.map.overlayMapTypes()	Additional map types to overlay

*/



@Component({
  selector: 'rv-location-selector',
  styleUrls: ['./location-selector.component.scss'],
  template: COMPONENT_TEMPLATE
})
export class LocationSelectorComponent {

	@ViewChild(UiInputWidgetComponent, {static: false}) rvInputSelector: UiInputWidgetComponent;
	@ViewChild('mapSearchField') mapSearchField: ElementRef;
	@ViewChild(GoogleMap) map: GoogleMap;

	@Input('formGroup') formGroup: FormGroup;
	@Input('control') control: FormControl;
	@Input('controlName') controlName: string;
	@Input('Location') Location: ModelInstance;

	@Input('mode') mode: string = 'autocomplete'

	locationForm: FormGroup = new FormGroup({locationName: new FormControl('')});

	autocompleteSelection;
	mapWidth = '100%';

	initialCoordinates = {
		lat: 46.533408,
		lng: 8.352592
	}

	mapConfigurations = {
		disableDefaultUI: true,
		fullscreenControl: true,
		zoomControl: true
	}

	get inputRef (): HTMLInputElement
	{
		return this.rvInputSelector.uiInput.nativeElement;
	}

	private setLocation (place): void
	{
		if (this.control)
			this.control.setValue((place||{})?.place_id)
	}

	initAutocomplete () 
	{
		if (this.control?.value && this.Location && this.Location?.attribs?.nickname)
			this.locationForm.controls.locationName.setValue(this.Location.attribs.nickname, {emitEvent: false})

		const options = {
			// bounds: defaultBounds,
			// limit fields reduces the overhead / api cost 
			// strictBounds: false,
			// value: this.inputRef.value,
			componentRestrictions: { country: "us" },
			// fields: ["address_components", "geometry", "place_id", "name"],
			fields: ["place_id", "name"],
		}, autocomplete = new google.maps.places.Autocomplete(this.inputRef, options);


		// https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult
		// const place = autocomplete.getPlace() // PlaceResult object
		autocomplete.addListener('place_changed', () => this.setLocation(autocomplete?.getPlace()));
	}

	initSearchbox () 
	{
		// bind a SearchBox wrapper class to the Input nativeElement.
		const searchBox = new google.maps.places.SearchBox(this.inputRef);

		// bind map controls to the SearchBox input.
		this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(this.inputRef);

		// Add custom event handler to listen selection change 
		// in the input field.  That will upload the map location.
		searchBox.addListener('places_changed', (e) => {
			const places = searchBox.getPlaces();
			if (places.length === 0)
				return;

			const bounds = new google.maps.LatLngBounds();
			places.forEach(place => {
				if (!place.geometry || !place.geometry.location)
					return;
				if (place.geometry.viewport) {
					bounds.union(place.geometry.viewport)
				}
				else {
					bounds.extend(place.geometry.location)
				}
			});
			this.map.fitBounds(bounds);
		})
	}

	constructor (private http: HttpClient) 
  {}

	ngAfterViewInit (): void
	{
		if (this.mode === 'autocomplete')
			this.initAutocomplete();
		if (this.mode === 'search')
			this.initSearchbox();
	}
}