<div id="signup-form-component" class="container-fluid auth-form-component">
	<div class="row">
		<div class="col-24">
			<div class="font-color--gray text-center rv-heading-sm">Sign in to Rearview</div>

			<form [formGroup]="registerForm" class="rv-form rv-auth-form rv-form-bg-blue" name="registerForm" layout layout-align="center" layout-padding novalidate (ngSubmit)="(AuthFormSrvc.state === 5 ? handleSignup() : handleSignupConfirm())" #registerFormRef [ngClass]="{'is-submitting': isSubmitting}">

				<div class="inputs mb-2">
					<div class="form-group">
						<input class="form-control" type="text" formControlName="name" required placeholder="Name" />
					</div>
					<hr />
					<div class="form-group" [ngClass]="{'show-manual-error': hasManualError()}">
						<input class="form-control" type="text" formControlName="email_or_phone" required placeholder="Email" />
						<span *ngIf="isManualError('USERNAME_EXISTS')" class="manual-error">Account already exists</span>
					</div>
					<hr />
					<div *ngIf="AuthFormSrvc.state === 5" class="form-group" [ngClass]="{'show-manual-error': hasManualError()}">
						<input class="form-control" type="password" formControlName="password" required placeholder="Password" />
						<span *ngIf="isManualError('UNKNOWN_ERROR')" class="manual-error">Unable to reach server</span>
						<span *ngIf="isManualError('INVALID_PASS')" class="manual-error">Invalid Password</span>
						<span *ngIf="isManualError('INSECURE_PASS')" class="manual-error">Password Too Simple</span>
					</div>
					<div *ngIf="AuthFormSrvc.state === 6" class="form-group" [ngClass]="{'show-manual-error': hasManualError()}">
						<input class="form-control" type="text" formControlName="code" placeholder="Verification Code" />
						<span *ngIf="isManualError('INVALID_CODE')" class="manual-error">Invalid Code</span>
					</div>
				</div>

				<div class="buttons mb-5">
				  <rv-button sm [disabled]="isSubmitting" class="btn-block" #submitBtnRef type='submit' aria-label="">
				  	<div class="btn-content" style='padding-left: 2rem;padding-right: 2rem;'>
				  		<div class="btn-label" [innerHTML]="AuthFormSrvc.state === 5 ? (isSubmitting ? 'Creating ' : 'Create Account') : (isSubmitting ? 'Verifying ' : 'Verify Account')"></div>
				  		<!-- <div class="btn-spinner"> (spin)</div> -->
				  	</div>
					</rv-button>
				</div>

				<rv-link sm (click)="goToLogin()" iconLeft='arrows.left-arrow-1' label='Back to social login' off-white icon-off-white />
			</form>

			<rv-auth-notifications></rv-auth-notifications>
		</div>
	</div>
</div>