<div id="account-page-component" class="container-fluid page-container-fluid">
	<div class="row">
		<div class="col-24 text-center">
			<h6>Account Page</h6>
			<hr />
			<p style="text-align: center;">
				<button disabled routerLink='/account'>Account</button>
				<button routerLink='/blank'>Blank</button>
				<button routerLink=''>Feed</button>
				<button routerLink='/profile'>Profile</button>
				<button routerLink='/ui' >UI Components</button>
			</p>
		</div>
		<div class="col-24 text-center">
			<hr />
			<rv-payment-method-manager></rv-payment-method-manager>
			<hr />
			<rv-user-account-list></rv-user-account-list>
		</div>
	</div>
</div>