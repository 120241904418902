import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ModelInstance } from '@getrearview/model-builder';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { SessionUser } from './../../../core/session/session-user.model';
import { PolicyService } from './../../../models/system/policy.service';

const compareSemanticVersions = (key?: string) => (a: any, b: any) => 
{
  // 1. Split the strings into their parts.
  let a1;
  let b1;
 
 if (key) {
      a1 = a[ key ].split('.');
      b1 = b[ key ].split('.');
  } else {
      a1 = a.split('.');
      b1 = b.split('.');
  }
  // 2. Contingency in case there's a 4th or 5th version
  const len = Math.min(a1.length, b1.length);
  // 3. Look through each version number and compare.
  for (let i = 0; i < len; i++) {
      const a2 = +a1[ i ] || 0;
      const b2 = +b1[ i ] || 0;
      
      if (a2 !== b2) {
          return a2 > b2 ? 1 : -1;        
      }
  }
  
  // 4. We hit this if the all checked versions so far are equal
  return b1.length - a1.length;
};

@Component({
  selector: 'rv-settings-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

	environment

	@Output('onClose')
	onClose: EventEmitter<void> = new EventEmitter();

	Policy: ModelInstance;
	policyForm = this.fb.group({
		policy: [null, [Validators.required]]
	})

	canSave: boolean = false;
	isReadonly: boolean = false;
	isLoading: boolean = false;

	private _subscriptions$ = new Subscription();

	reload = () => this.load()

	private async fetchPolicy (type: string): Promise<ModelInstance>
	{
		let Policies 				= 	await this.PolicySrvc.search({type: 'privacy-policy'}),
				policyId 				=		Policies.map(Policy => ({id: Policy.id(), version: Policy.get('version')})).sort(compareSemanticVersions('version')).shift();

		if (policyId && policyId?.id)
			this.Policy 			= 	Policies.filter(Policy => Policy.id() === policyId.id).shift();

		return Promise.resolve(this.Policy);
	}

	private async onFormChanges (policy)
	{
		if (this.Policy && policy?.policy)
			this.Policy.set('policy', policy.policy);
	}

	save (): void
	{
		// Disabled. 
		// Uncomment to use.
		// 
		// if (this.Policy)
		// 	this.Policy.save();
	}

	private async load (): Promise<void> 
	{
		this.isLoading = true;

		await this.fetchPolicy('privacy-policy');

		this.policyForm.controls.policy.setValue((this.Policy && this.Policy.get('policy')) || '');
		this._subscriptions$.add(this.policyForm.valueChanges.pipe(distinctUntilChanged()).subscribe(changes => this.onFormChanges(changes)));

		this.isLoading = false;
	}

	constructor (private fb: FormBuilder, private PolicySrvc: PolicyService) 
	{}

	ngOnInit () 
	{
		this.load();
	}

	ngOnDestroy () 
	{
		this._subscriptions$.unsubscribe();
	}
}
