import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ModelInstance } from '@getrearview/model-builder';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rv-date-location',
  templateUrl: './date-location.component.html',
  styleUrls: ['./date-location.component.scss']
})
export class DateLocationComponent implements OnInit {

	@Output('previous')
	previous: EventEmitter<void> = new EventEmitter();

	@Output('next')
	next: EventEmitter<void> = new EventEmitter();

	@Input('Location')
	Location: ModelInstance;
	start_at: string;
	nickname: string;

	showPrevious: boolean;
	showNext: boolean;

	@Input('disabled')
	isDisabled: boolean;

	private setMilestoneLocation ()
	{
		if (this.Location) {
			this.nickname = this.Location?.attribs?.nickname;
			this.start_at = this.Location?.attribs?.start_at;

			this.showPrevious = this.showNext = false;
			if (this.Location?.attribs?.is_initial)
				this.showPrevious = this.showNext = this.Location.attribs.is_initial === true || parseInt(this.Location.attribs.is_initial) === 1;
		}
	}

  constructor ()
  {}

  ngOnInit (): void 
  {}

  ngOnDestroy (): void 
  {}

 	ngOnChanges (Changes: SimpleChanges): void
 	{
 		if (Changes?.Location)
 			this.setMilestoneLocation()
 	}
}
