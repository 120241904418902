<header id="ui-header-widget-component" *ngIf="isWidgetEnabled" class="base" [ngClass]="{'is-auth': requiresAuth?(SessionUser.onAuthChange|async):true, 'is-off-page': !isDown, 'center-logo': headerLayout === headerLayouts.SOLO_LOGO}" [@openClose]="isDown?'closed':'open'" [ngStyle]="headerStyle">
	<ul>
		<li class="opt-back-btn" *ngIf="showBackBtn"><rv-back-btn /></li>
		<li class="opt-nav-btn" *ngIf="showNavBtn"><rv-nav-btn /></li>
		<li class="opt-logo" *ngIf="showLogo"><rv-home-btn /></li>
		<li class="opt-alerts" *ngIf="showAlerts"><rv-button sm open iconLeft="user-interface.bell"></rv-button></li>
		<li class="opt-user-menu" *ngIf="showUserMenu"><rv-user-menu></rv-user-menu></li>
	</ul>
	<!--
		<div class="menu-col">
			<div class="icon-wrapper"></div>
		</div>
		<div class="logo-col">
			<div class="info-card" [ngSwitch]="headerLayout">
			  <h2 *ngSwitchCase="headerLayouts.STANDARD" class="info-card__regular-message">STANDARD</h2>
			  <h2 *ngSwitchCase="headerLayouts.NO_USER_MENU" class="info-card__regular-message">NO_USER_MENU</h2>
			</div>
		</div>
	</div>
-- 	<div class="header-row">
		<div class="menu-col">
			<div class="icon-wrapper">
				<rv-button sm open iconLeft="content.bars"></rv-button>
			</div>
		</div>
		<div class="logo-col">
			<rv-logo-widget routerLink='' layout="horizontal" symbol="purple" name="blue"></rv-logo-widget>
			<div class="info-card" [ngSwitch]="headerLayout">
			  <h2 *ngSwitchCase="headerLayouts.STANDARD" class="info-card__regular-message">STANDARD</h2>
			  <h2 *ngSwitchCase="headerLayouts.NO_USER_MENU" class="info-card__regular-message">NO_USER_MENU</h2>
			</div>
		</div>
		<div class="btns-col">
			<rv-button sm open iconLeft="user-interface.bell"></rv-button>
			<rv-user-menu *ngIf="showUserMenu" ></rv-user-menu>
		</div>
	</div> -->
</header>