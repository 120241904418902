import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HashtagManagerWidgetComponent } from './hashtag-manager-widget/hashtag-manager-widget.component';
import { HashtagComponent } from './hashtag/hashtag.component';
import { HashtagSelectorComponent } from './hashtag-selector/hashtag-selector.component';
import { HashtagListComponent } from './hashtag-list/hashtag-list.component';
import { TaggedPipe } from './hashtag/tagged.pipe';
import { UiMultiSelectModule } from 'src/app/shared/interface/ui-multi-select/ui-multi-select.module';

@NgModule({
  declarations: [
    HashtagManagerWidgetComponent,
    HashtagComponent,
    HashtagSelectorComponent,
    HashtagListComponent,
    TaggedPipe
  ],
  imports: [
    CommonModule,
    UiMultiSelectModule
  ],
  exports: [
  	HashtagManagerWidgetComponent,
  	HashtagComponent,
  	HashtagSelectorComponent
  ]
})
export class HashtagManagerModule { }
