<div id="memory-chest-page-component" class="container-fluid page-container-fluid" [ngClass]="{loaded: loaded}" rvHeaderTrigger>
	<div class="row" *ngIf="enableFilter && chestSrvc.enableSelector; else noFilter">
		<div class="col-20">
			<rv-memory-chest-selector-widget [chestId]="MemoryChest?.id()" (onSelect)="onChestSelected($event)"></rv-memory-chest-selector-widget>
		</div>
		<div class="col-4 filter-btn" [style.visibility]="chestSrvc.doMilestonesExist()? 'visible': 'hidden'">
			<rv-button open sm icon="user-interface.filters" (click)="openFilter(milestoneFilter)" />
		</div>
	</div>
	<ng-template #noFilter>
		<div *ngIf="chestSrvc.enableSelector" class="row">
			<div class="col-24">
				<rv-memory-chest-selector-widget [chestId]="MemoryChest?.id()" (onSelect)="onChestSelected($event)"></rv-memory-chest-selector-widget>
			</div>
		</div>
	</ng-template>
	<div class="row">
		<!-- 
		<div class="col-24 mt-1 mb-3">
			<rv-memory-chest-search />
		</div> 
		-->
		<div class="col-24 pb-3">
			<rv-memory-chest-members />
		</div>
	</div>
	<div class="row">
		<div class="col-24" waIntersectionObserver waIntersectionThreshold="0.05">
			<ng-container *ngFor="let M of chestSrvc.Milestones$|async; let idx = index">
				<div *ngIf="chestSrvc.monthYears[M.id()]" class="feed-date-indicator">
					<milestone-date-indicator [date]="chestSrvc.monthYears[M.id()]"></milestone-date-indicator>
				</div>
				<div class="feed-milestone">
					<milestone [chestId]="MemoryChest?.id()" [Milestone]="M" [fetchPreview]="idx < 4" (waIntersectionObservee)="onIntersection(M, $event)" />
				</div>
			</ng-container>
			<rv-no-milestones />
			<!-- <rv-no-memories [chestId]="MemoryChest?.id()" /> -->
		</div>

		<!--
		<div class="col-24 mt-1 mb-3" *ngIf="chestId">
			<rv-feed-chest-search />
		</div>
		-->

		<!--

		-->

		<!--
		<div class="col-24" waIntersectionObserver waIntersectionThreshold="0.05">
			<ng-container *ngFor="let M of feedSrvc.Milestones$|async|activeMemoryChest:chestId; let idx = index">
				<div *ngIf="feedSrvc.monthYears[M.id()]" class="feed-date-indicator">
					<feed-date-indicator [date]="feedSrvc.monthYears[M.id()]"></feed-date-indicator>
				</div>
				<div class="feed-milestone">
					<feed-milestone [chestId]="chestId" [FeedMilestone]="M" [fetchPreview]="idx < 4" (waIntersectionObservee)="onIntersection(M, $event)"></feed-milestone>
				</div>
			</ng-container>
			<rv-no-milestones [chestId]="chestId" />
			<rv-no-memories [chestId]="chestId" />
		</div>
		-->
	</div>
</div>

<ng-template #milestoneFilter>
	<form 
		[formGroup]="milestoneFilterForm" 
		class="milestoneFilterForm rv-form rv-new-account-form rv-form-bg-blue" 
		name="milestoneFilterForm" 
		layout 
		layout-align="center" 
		layout-padding 
		novalidate 
		(ngSubmit)="handleSubmit()" 
		#newAcctFormRef
	>
		<rv-input 
			class="mb-3" 
			iconLeft="education.open-book" 
			placeholder="Milestone" 
			[formGroup]="milestoneFilterForm" 
			name="milestone" 
			controlName="milestone"
		></rv-input>
		<rv-input 
			class="mb-3" 
			iconLeft="users.account" 
			placeholder="Select Person" 
			[formGroup]="milestoneFilterForm" 
			name="selectPerson" 
			controlName="selectPerson"
		></rv-input>
		<rv-location-selector
			[formGroup]="milestoneFilterForm" 
			[controlName]="'location'"
			[control]="milestoneFilterForm.get('location')"
		></rv-location-selector>
		<rv-input 
			class="my-3" 
			iconLeft="user-interface.date" 
			placeholder="Initial Date" 
			[formGroup]="milestoneFilterForm" 
			name="initialDate" 
			controlName="initialDate"
		></rv-input>
		<rv-input 
			class="mb-3 position-relative"
			iconLeft="monochrome.house-user" 
			placeholder="Hashtags" 
			[formGroup]="milestoneFilterForm" 
			name="hashtags" 
			controlName="hashtags"
		>
			<div class="hash-icon position-absolute">#</div>
		</rv-input>

		<div class="mt-4 d-flex justify-content-between align-items-center">
			<button type="button" class="cancel-button" (click)="closeFilter()">Cancel</button>
			<button type="submit" class="submit-button bg-color--purple-500">Submit</button>
		</div>
	</form>
</ng-template>