<div id="ui-page-uploader-component" class="container-fluid">
	<div class="row">
		<div class="col-24 text-center mt-4 mb-4">
			<h4>UI Uploader</h4>
		</div>
		<div class="col-12">
			<!-- <button (click)="takePhoto()">Take Photo</button> -->
		</div>
		<div class="col-12">
			<!-- <button (click)="uploadPhoto()">Upload Photo</button> -->
		</div>
	</div>
	<hr />
	<section>
		<h3 *ngIf="images.length == 0" class="ion-padding ion-text-center">Please Select Image!</h3>
		<ul>
			<li *ngFor="let file of images; let i = index">
				<img [src]="file.data" />
				<label class="ion-text-wrap">{{ file.name }}</label>
				<button (click)="startUpload(file)">
					upload
				</button>
				<button (click)="deleteImage(file)">
					trash
				</button>
			</li>
		</ul>
	</section>
	<hr />
	<section>
		<button (click)="selectImage()">
			<rv-icon group='multimedia' icon='photo' md purple></rv-icon>
		</button>
	</section>
</div>