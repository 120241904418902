<div id="ui-page-icons-component" class="container-fluid">
	<div class="row">
		<div class="col-24 text-center mt-4 mb-4">
			<h4>UI Icons</h4>
		</div>
		<div class="icon-selector mb-4">
			<div><span ngxCopyPaste #cp="copy" class="text-align">{{iconSelectorString||'Select icon to see directive selector.'}}</span></div>
			<div><rv-button *ngIf="iconSelectorString" (click)="cp.copy()" open xs iconLeft='file-and-folder.document-2'></rv-button></div>
		</div>
		<div class="col-24">
			<div class="card" *ngFor="let group of groups; index as gdx;">
				<div class="card-header">{{group|strReplace:'-':' '|titlecase}}</div>
				<div class="card-body">
					<div class="row">
						<div *ngFor="let icon of icons[group]; index as idx;" class='ri-wrapper'>
							<rv-icon (click)="copySelector({group,icon}) && cpBtn.click()" group='{{group}}' icon='{{icon}}'></rv-icon>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>