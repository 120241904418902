<div class="settings-widget-component">
	<div class="container-fluid" (click)="onActivate.emit(setting)">
		<div class="row">
			<div class="col-24">
				<table>
					<tbody>
						<tr>
							<td rowspan="2" class="font-color--purple-500">
								<rv-icon md purple [group]="setting?.icon?.split('.').shift()" [icon]="setting?.icon?.split('.').pop()" />
							</td>
							<td class="font-color--grey-500">{{setting?.label}}</td>
							<td rowspan="2" class="font-color--grey-800">
								<rv-icon md group="arrows" icon="right-arrow" />
							</td>
						</tr>
						<tr>
							<td class="font-color--grey-800">{{setting?.descrip}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>