interface RouteData_Widgets {
	[widgetSelector: string]: {
		enable: Boolean;
		options: {[key: string]: any};
	}
}

export interface RouteData {
	animation: string;
	widgets: RouteData_Widgets;
}

export class RouteData {
	animation!: string;
	widgets!: RouteData_Widgets;

	get (key:string): any 
	{
		let keys: Array<string> = (key && `${key}`.split(".")) || [];

		if (keys.length === 1) {
			try 				{ return this[key]; 																																						}
			catch(ex) 	{ console.error("ERROR: The "+key+" key does not exist within route data."); return undefined; 	}
		}

		key 							= 		keys.shift();
		var configVal 		= 		this.get(key);

		if(configVal) {
			do {
				try { configVal = configVal[keys.shift()]; }
				catch (ex) { configVal = undefined; }
			} while (keys.length > 0);
		}

		return 									configVal;
	}

	set (data): void
	{
		Object.keys(data).forEach(key => this[key] = data[key]);
	}

	constructor (data?: {[key: string]: any}) {
		if (data && typeof data === 'object')
			this.set(data);
	}
}