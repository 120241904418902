import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { SessionUser } from './../../../core/session/session-user.model';
import { AuthFormService } from './../shared/auth-form.service';
import { ConfigService } from './../../../shared/services/config.service';
import { AuthFormComponent } from './../shared/auth-form.component';
import { TrackJsService } from './../../../shared/services/trackjs.service';
import { TokenService } from './../../../core/services/token.service';
import { AuthService, SigninRequestResponse } from './../../../core/services/auth.service';
import { ApiService } from './../../../core/services/api.service';
import { Platform } from '@ionic/angular';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { environment } from '../../../../environments/environment';

declare const google: any;

import {
  SocialAuthService,
  SocialUser,
} from '@abacritt/angularx-social-login';

@Component({
  selector: 'rv-social-login',
  templateUrl: './social-login.component.html',
  styleUrls: ['./../shared/auth-form.component.scss']
})
export class SocialLoginComponent extends AuthFormComponent implements OnInit 
{
  private _isLoggedin?: boolean = undefined;
  showForm: boolean = false;

  onSuccess (response: SigninRequestResponse): void
  {
  	this.AuthFormSrvc.notify = `${response?.translate()||''}`;
  	this.goToLogin();
  }

  onError (response: SigninRequestResponse): void
  {
  	this.AuthFormSrvc.fail = `${response?.translate()||''}`;
  }

  signInWithGoogle (): void 
  {
  	this.auth.login('GOOGLE').then(response => this.onSuccess(response), response => this.onError(response));
  }

  signInWithFB (): void 
  {
  	this.auth.login('FACEBOOK').then(response => this.onSuccess(response), response => this.onError(response));
  }

  signInWithCognito (): void
  {
  	this.goToLoginForm();
  }

  constructor (
    public platform: Platform,
  	private socialAuthService: SocialAuthService, 
  	SessionUser: SessionUser,  // not used here.
  	AuthFormSrvc: AuthFormService,  // not used here.
  	ConfigSrvc: ConfigService,  // not used here.
  	fb: FormBuilder,  // not used here.
  	TrackJsSrvc: TrackJsService,  // not used here.
  	private token: TokenService,  // not used here.
  	private auth: AuthService, 
  	AuthApi: ApiService // not used here.
  ) 
  {
  	super(SessionUser, AuthApi, AuthFormSrvc, ConfigSrvc, fb, TrackJsSrvc)
  }

  ngOnInit () 
  {
  	this._subscriptions$.add(this.socialAuthService.authState.subscribe((User: SocialUser) => {
      if (User && !this._isLoggedin) {
        this._isLoggedin = 	User != null;
        // Social user has authenticated w/ Social Provider.
        // We will now request an access token from the RV API.
        this.auth.requestAccessToken(User).then().catch((err: SigninRequestResponse) => {
        	console.error("auth err occurred: ",err);
        });
      }
    }));
  }

  ngOnDestroy (): void
  {
  	this._subscriptions$.unsubscribe();
  }

  signOut (): void 
  {
    this.socialAuthService.signOut();
  }

}