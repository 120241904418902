import { ComponentFactoryResolver, Inject, Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';

export declare interface modalParams {
	size?: string;
	title?: string
}

@Injectable()
export class ModalService {

	#modalSize: BehaviorSubject<string> = new BehaviorSubject('');
	#modalTitle: BehaviorSubject<string> = new BehaviorSubject('');
	#modalVisibility: BehaviorSubject<boolean> = new BehaviorSubject(false);
	#modalTemplate: Subject<TemplateRef<any>> = new Subject();

  private modalNotifier?: Subject<string>;

  get isOpen (): Observable<boolean>
  {
  	return this.#modalVisibility.asObservable();
  }

  get size(): Observable<string>
  {
  	return this.#modalSize.asObservable();
  }

  get title(): Observable<string>
  {
  	return this.#modalTitle.asObservable();
  }

  get template(): Observable<TemplateRef<any>>
  {
  	return this.#modalTemplate.asObservable();
  }

  setSize (size: string): void
  {
  	const s: string = `${this.#modalSize.getValue()}`;
  	if (size === '' && s || s === '' && size)
  		this.#modalSize.next(size);
  }

  setTitle (title: string): void
  {
  	const v: string = `${this.#modalTitle.getValue()}`;
  	if (title === '' && v || v === '' && title)
  		this.#modalTitle.next(title);
  }

  setTemplate (template: TemplateRef<any>): void
  {
  	this.#modalTemplate.next(template||undefined);
  }

  open (template?: TemplateRef<any>, options?: modalParams) 
  {
  	// if (template)
  	this.setTemplate(template);

  	const {size,title} = (options ? options : {size: null, title: null});


  	if (typeof size === 'string')
  		this.setSize(size);

  	if (typeof title === 'string')
  		this.setTitle(title);

  	this.#modalVisibility.next(true);

    this.modalNotifier = new Subject();
    return this.modalNotifier?.asObservable();
  }

  close (): void
  {
  	this.closeModal();
  }

  closeModal () 
  {
  	this.#modalVisibility.next(false);
    this.modalNotifier?.complete();
    this.setTitle('');
  }

  submitModal () 
  {
    this.modalNotifier?.next('confirm');
    this.closeModal();
  }
}
