import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RouteData } from 'src/app/core/values/route-data.value';
import { SettingsPageComponent } from './settings-page/settings-page.component';
import { LoggedInService } from './../../core/services/logged-in.service';
import { SessionUserResolver } from './../../core/resolvers/session-user.resolver'
// import { HeaderModule } from 'src/app/widgets/header/header.module';
// import { UserNotificationsResolver } from './../../core/resolvers/user-notifications.resolver'
import { ProfileFormComponent } from './profile-form/profile-form.component';
// import { MemoryChestListComponent } from './settings-page'
import { NotificationsSelectorComponent } from './notifications-selector/notifications-selector.component';



const routes: Routes = [
	{ 
		path:                       'users/settings',
		component:                  SettingsPageComponent,
		resolve: 										{SessionUser: SessionUserResolver},
		canActivate: 								[LoggedInService],
		data: 											new RouteData({animation:'settingsPage',widgets:{'rv-floating-controls-widget':{enable:false},'rv-header-widget':{enable:true}}})
	},
	// { 
	// 	path:                       'users/settings/profile',
	// 	component:                  ProfileFormComponent,
	// 	resolve: 										{SessionUser: SessionUserResolver},
	// 	canActivate: 								[LoggedInService],
	// 	data: 											{animation:'fadeInAnimation'},
	// },
	// { 
	// 	path:                       'users/settings/memory-chests',
	// 	component:                  ProfileFormComponent,
	// 	resolve: 										{SessionUser: SessionUserResolver},
	// 	canActivate: 								[LoggedInService],
	// 	data: 											{animation:'fadeInAnimation'},
	// },
	// { 
	// 	path:                       'users/settings/notifications',
	// 	component:                  NotificationsSelectorComponent,
	// 	resolve: 										{UserNotifications: UserNotificationsResolver},
	// 	canActivate: 								[LoggedInService],
	// 	data: 											{animation:'fadeInAnimation'},
	// },
	{ 
		path:                       'memory-chests/settings',
		component:                  SettingsPageComponent,
		resolve: 										{SessionUser: SessionUserResolver},
		canActivate: 								[LoggedInService],
		data: 											new RouteData({animation:'settingsPage',widgets:{'rv-floating-controls-widget':{enable:false},'rv-header-widget':{enable:true}}})
	},
];


@NgModule({
  imports:                      [RouterModule.forChild(routes)],
  exports:                      [RouterModule]
})
export class SettingsRoutingModule { }