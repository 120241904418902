import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MemoryChestTagService } from 'src/app/models/memories/memory-chest-tag.service';
import { ModelInstance } from '@getrearview/model-builder';

@Component({
  selector: 'rv-ui-multi-select',
  templateUrl: './ui-multi-select.component.html',
  styleUrls: ['./ui-multi-select.component.scss']
})
export class UiMultiSelectComponent implements OnInit {

	demoForm: FormGroup = this.fb.group({
		members: 					[''],
		hashtags: 				[''],
	});
	controlName1: string = 'members';
	controlName2: string = 'hashtags';

	chestId: string = '';

	options: Array<string> = [];

	opt: string ='';

	isOpen: boolean = false;
	isLoading: boolean = false;
	Hashtags: Array<ModelInstance> = [];

	isSelected (tag: string): boolean
	{
		return false; 
	}

	onFocus ($evt) 
	{
		this.isOpen = true;
	}

	onBlur ($evt) 
	{}

	setHashtags (Hashtags: Array<ModelInstance>) {
		this.Hashtags = Hashtags;
	}

  constructor (private fb: FormBuilder, private MemoryChestTagSrvc: MemoryChestTagService) 
  {}

  ngOnInit (): void 
  {}
}