import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthModule } from './auth/auth.module';
import { AccountModule } from './account/account.module';
import { UiModule } from './ui/ui.module';
import { BlankModule } from './blank/blank.module';
import { FeedModule } from './feed/feed.module';
import { MilestoneModule } from './milestone/milestone.module';
import { SettingsModule } from './settings/settings.module';
import { SwitchboardModule } from './switchboard/switchboard.module';
import { WelcomeModule } from './welcome/welcome.module';
import { ProfileModule } from './profile/profile.module';
import { MemoryChestsModule } from './memory-chests/memory-chests.module';
import { MemoryChestModule } from './memory-chest/memory-chest.module';
import { InviteTokensModule } from './invite-tokens/invite-tokens.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AuthModule,
    AccountModule,
    UiModule,
    BlankModule,
    FeedModule,
    ProfileModule,
    MilestoneModule,
    SettingsModule,
    SwitchboardModule,
    WelcomeModule,
    MemoryChestsModule,
    MemoryChestModule,
    InviteTokensModule
  ]
})
export class PagesModule { }
