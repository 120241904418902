<form class="container-fluid mb-4" id="header-widget-component" [ngStyle]="headerStyle" (submit)="onSubmit($event)">
	<div class="row">
		<div class="col-20 col-xs-21 col-lg-22">
			<rv-input white lg open outline_disable class="fw-bold" shadowed="isEditing" [formGroup]="nicknameForm" name="nickname" controlName="nickname" (focus)="onFocus()" (blur)="onBlur()"></rv-input>
		</div>
		<div class="col-4 col-sm-3 col-lg-2">
			<rv-button *ngIf="isEditing" open sm shadowed (click)="cancel()">
				<rv-icon white sm group="user-interface" icon="close" />
			</rv-button>
		</div>
	</div>
	<div id="milestone-header-image-uploader" [hidden]="!Milestone">
		<rv-image-uploader [Milestone]="Milestone" [Media]="HeaderMedia" (onMediaUpdate)="onMediaUpdate($event)" />
	</div>
</form>
<div class="casting bg-color--grey-500 pb-2 pt-4 rounded-bottom-4 d-flex justify-content-center" *ngIf="isCastable">
	<button class="d-flex justify-content-center align-items-center bg-transparent border border-0 text-light fw-bold" (click)="cast()">
		Play
		<rv-icon class="ms-2" sm white group='multimedia' icon='play'></rv-icon>
	</button>
</div>