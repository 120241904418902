import { Component, Input } from '@angular/core';

@Component({
  selector: 'rv-logo-widget',
  templateUrl: './logo-widget.component.html',
  styleUrls: ['./logo-widget.component.scss']
})
export class LogoWidgetComponent {

	@Input('layout')
	set layout (layout: string) { this._layout = layout; }
	get layout (): string { return this._layout; }

	@Input('symbol')
	set symbolColor (color: string) {  this._symbolColor = this.getColor(color); }
	get symbolColor (): string { return this._symbolColor; }

	@Input('name')
	set nameColor (color: string) {  this._nameColor = this.getColor(color); }
	get nameColor (): string { return this._nameColor; }



	_layout: string						=			"horizontal";
	_symbolColor: string 			= 		"#5e36dd";
	_nameColor: string 				= 		"#5e36dd";




	private getColor (color: string): string
	{
		switch (color) {
			case "white": 	return "#ffffff";		break;
			case "purple": 	return "#5e36dd"; 	break;
			case "blue": 		return "#071956"; 	break;
				// 1D2C63
				// 071956
				// 7,26,86

			default: 				return "gray"; 			break;
		}
	}

  constructor () 
  {}
}
