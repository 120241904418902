<div id="profile-form-component">
	<rv-component-loader-widget [is-loading]="isLoading">
		<div class="container-fluid">
			<div class="row">
				<div class="col-24">
					<div class="font-color--blue text-left rv-heading-xs">Your Settings</div>

					<form [formGroup]="profileForm" class="rv-form rv-settings-form rv-form-bg-blue" name="profileForm" layout layout-align="center" layout-padding novalidate #profileFormRef>

						<div class="inputs">
							<div class="form-group">
								<input class="form-control" type="text" formControlName="name" required placeholder="Your Name" />
							</div>
							<div class="form-group" [ngClass]="{'show-manual-error': hasManualError()}">
								<input class="form-control" type="text" formControlName="email" required placeholder="Password" />
							</div>
						</div>

<!-- 					  <button [disabled]="isSaving" class="btn-block btn-spinner" [class.spin]="isSaving" #submitBtnRef type='submit' mat-button matSuffix mat-flat-button aria-label="">
					  	<div class="btn-content">
					  		<div class="btn-label" [innerHTML]="'Save'"></div>
					  	</div>
						</button> -->
					</form>
				</div>
			</div>
		</div>
		<rv-button (click)="save()" sm label='Save Profile' iconRight='arrows.right-arrow'></rv-button>
		<rv-button (click)="onClose.emit()" outline sm label='Cancel' iconRight='arrows.right-arrow'></rv-button>
	</rv-component-loader-widget>
</div>