import { Component, Input } from '@angular/core';

@Component({
  selector: 'rv-facebook-button',
  templateUrl: './facebook-button.component.html',
  styleUrls: ['./facebook-button.component.scss']
})
export class FacebookButtonComponent {

	@Input()
	label: string = 'Continue with Facebook';
}
