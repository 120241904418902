import { Component, Input } from '@angular/core';

@Component({
  selector: 'rv-component-loader-widget',
  templateUrl: './component-loader-widget.component.html',
  styleUrls: ['./component-loader-widget.component.scss']
})
export class ComponentLoaderWidgetComponent {

	@Input('is-loading')
	isLoading: boolean;
}
