<div id="floating-controls-widget-component" *ngIf="isWidgetEnabled" [ngClass]="{'is-modal-open': isModalOpen, 'disable-controls': isControlsDisabled}">

	<div #floatingControlStage id="floatingControlStage"></div>

	<ul>
		<!-- -->
		<li class="action-btn"><div>
			<button type="button">
				<rv-icon md dk-gray group='user-interface' icon='house'></rv-icon>
			</button></div>
		</li>
		<!-- -->
		<li class="action-btn"><div>
			<button type="button">
				<rv-icon md dk-gray open group='education' icon='open-book'></rv-icon>
			</button></div>
		</li>
		<!-- -->
		<li class="multi-func-btn" [hidden]="!btnVisibility.multi"><div>
			<button #multiFuncBtn type="button" (click)="onBtnClick('multi', {milestonePage, memoryEditor, milestoneCreator, milestoneEditor, memoryChestEditor})">
				<rv-icon md white group='user-interface' icon='plus'></rv-icon>
			</button></div>
		</li>
		<!-- -->
		<li class="action-btn"><div>
			<button type="button">
				<rv-icon md dk-gray open group='shopping' icon='cart'></rv-icon>
			</button></div>
		</li>
		<!-- -->
		<li class="action-btn"><div>
			<button type="button">
				<rv-icon md dk-gray open group='user-interface' icon='save'></rv-icon>
			</button></div>
		</li>
	</ul>

	<!-- Interaction Buttons -->
	<div class="pop-btn pop-btn-1" [hidden]="!btnVisibility.library" (click)="canUpload && onBtnClick('library', {milestonePage, memoryCreator, memoryEditor, milestoneCreator, milestoneEditor, memoryChestEditor, milestoneLocationCreator})" [style.visibility]="canUpload?'visible':'hidden'">
		<rv-icon lg white group='multimedia' icon='multiple-image' />
	</div>
	<div class="pop-dot pop-dot-1" [hidden]="!btnVisibility.library" [style.visibility]="canUpload?'visible':'hidden'"></div>

	<div class="pop-btn pop-btn-2" [hidden]="!btnVisibility.photo" (click)="canUpload && onBtnClick('photo', {milestonePage, memoryCreator, memoryEditor, milestoneCreator, milestoneEditor, memoryChestEditor, milestoneLocationCreator})" [style.visibility]="canUpload?'visible':'hidden'">
		<rv-icon lg white group="multimedia" icon="photo" />
	</div>
	<div class="pop-dot pop-dot-2" [hidden]="!btnVisibility.photo" [style.visibility]="canUpload?'visible':'hidden'"></div>

	<div class="pop-btn pop-btn-3" [hidden]="!btnVisibility.comment" (click)="onBtnClick('comment', {milestonePage, memoryCreator, memoryEditor, milestoneCreator, milestoneEditor, memoryChestEditor, milestoneLocationCreator})" [style.visibility]="canUpload?'visible':'hidden'">
		<rv-icon lg white group='file-and-folder' icon='clipboard-2' />
	</div>
	<div class="pop-dot pop-dot-3" [hidden]="!btnVisibility.comment" [style.visibility]="canUpload?'visible':'hidden'"></div>

	<div class="pop-btn pop-btn-4" [hidden]="!btnVisibility.video" (click)="canUpload && onBtnClick('video', {milestonePage, memoryCreator, memoryEditor, milestoneCreator, milestoneEditor, memoryChestEditor})" [style.visibility]="canUpload?'visible':'hidden'">
		<rv-icon lg white group='multimedia' icon='video-camera-438839-24526' />
	</div>
	<div class="pop-dot pop-dot-4" [hidden]="!btnVisibility.video" [style.visibility]="canUpload?'visible':'hidden'"></div>
	<!-- /Interaction Buttons -->

</div>

<ng-template #milestonePage>
	<rv-memory-editor-widget [Milestone]="controlSrvc.Milestone" (onCancel)="close()" [Memory]="controlSrvc.Memory"></rv-memory-editor-widget>
</ng-template>

<ng-template #memoryEditor>
	<rv-memory-editor-widget [Milestone]="controlSrvc.Milestone" (onCancel)="close()" [Memory]="controlSrvc.Memory"></rv-memory-editor-widget>
</ng-template>

<ng-template #memoryCreator>
	<rv-memory-creator-widget [Milestone]="controlSrvc.Milestone" [Me]="controlSrvc.Me" (onCancel)="close()" (onSuccess)="onMemoryCreatorSuccess($event)" ></rv-memory-creator-widget>
</ng-template>

<ng-template #milestoneEditor>
	<rv-milestone-editor-widget [Milestone]="controlSrvc.Milestone" (onCancel)="close()" [Memory]="controlSrvc.Memory"></rv-milestone-editor-widget>
</ng-template>

<ng-template #milestoneCreator>
	<rv-milestone-creator-widget [MemoryChest]="controlSrvc.MemoryChest" (onCancel)="close()" [Me]="controlSrvc.Me" (onSuccess)="onMilestoneCreatorSuccess($event)"></rv-milestone-creator-widget>
</ng-template>

<ng-template #memoryChestEditor>
	<rv-memory-chest-editor-widget [memoryChestId]="controlSrvc.memoryChestId" (onCancel)="close()" (onSuccess)="onMemoryChestEditorSuccess($event)" ></rv-memory-chest-editor-widget>
</ng-template>

<ng-template #memoryContentCreator>
	<rv-memory-content-creator-widget [Milestone]="controlSrvc.Milestone" [Me]="controlSrvc.Me" (onCancel)="close()" (onSuccess)="onMemoryMessageCreatorSuccess($event)" />
</ng-template>

<ng-template #milestoneLocationCreator>
	<rv-milestone-location-creator-widget [Milestone]="controlSrvc.Milestone" [Me]="controlSrvc.Me" (onCancel)="close()" (onSuccess)="onMilestoneLocationCreatorSuccess($event)" />
</ng-template>