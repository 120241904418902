<ng-container *ngFor="let MemoryChest of Selectables; let idx = index;">
	<ng-template #templates>
		<div class="memory-chest-option">
			{{MemoryChest.attribs.nickname}}
		</div>
	</ng-template>
</ng-container>

<div class="filter-member-chest-selector-component">
	<rv-multi-select iconLeft='education.book' [templates]="templates" [models]="Selectables"  [controlName]="controlName" [formGroup]="formGroup" [control]="control" />
</div>

