import { Component } from '@angular/core';

@Component({
  selector: 'rv-nav-btn',
  templateUrl: './nav-btn.component.html',
  styleUrls: ['./nav-btn.component.scss']
})
export class NavBtnComponent {

}
