import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CameraInterface } from 'src/app/shared/native/camera-interface.service';
import { CameraResultType } from '@capacitor/camera';
import { MemoryMediaService } from 'src/app/models/memories/memory-media.service';
import { MemoryService } from 'src/app/models/memories/memory.service';
import { ModelInstance } from '@getrearview/model-builder';
import { Photo } from '@capacitor/camera'

@Component({
  selector: 'rv-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent {

	@Input('Milestone')
	Milestone: ModelInstance;

	@Input('Media')
	Media: ModelInstance;

	@Output('onMediaUpdate')
	onMediaUpdate: EventEmitter<ModelInstance> = new EventEmitter();

	async uploadPhoto (Photo: Photo): Promise<void>
	{
		const {dataUrl,exif,format} 	= 	(Photo||{}),
					filemime 								= 	dataUrl ? dataUrl.substring(dataUrl.indexOf(":")+1, dataUrl.indexOf(";")) : (format ? `image/${format}` : null),
					base64Data 							= 	dataUrl ? dataUrl.substr(dataUrl.indexOf(',') + 1) : '';

		return new Promise((resolve, reject) => {
			this.Milestone.addChild('media',{}).then((Media: ModelInstance) => {
				const include_attribs = {...Media.attribs, media_type: 'IMG', _filemime: filemime, _fileexif: exif||{}};
				Media.upload(base64Data, {include_attribs})
						.subscribe(
								evt => {
									if (evt?.event === 'success')
										this.onMediaUpdate.next(evt?.asset)
								}, 
								err => Promise.reject(`${err}`)
							);
			});
		});

		return Promise.resolve();
	}

	async selectImage () 
	{
		const CameraRequest = await this.cameraInf.getPhotoRequest({resultType: CameraResultType.DataUrl});

		this.uploadPhoto(CameraRequest.Photo);
	}

	constructor (public cameraInf: CameraInterface, private MemoryMediaSrvc: MemoryMediaService, private MemorySrvc: MemoryService)
	{}

	ngOnInit (): void
	{}
}