import { Component, OnInit } from '@angular/core';
import { AuthFormService } from './../auth-form.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rv-auth-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

	public msg: string = "";
	public className: string = '';

	private _isAuth: boolean = false;
	private _subscriptions$ = new Subscription();

	private setAuth (isAuth: boolean): void
	{
		// Uncomment these lines to have the message 
		// cleared on logout.  This is the wrong solution,
		// but effective.  should be using pipes & distinct
		// for change to simplify, with a config/param for 
		// state control.
		// 
		// if (!isAuth)
		// 	this.msg = '';

		this._isAuth = isAuth;
	}

  constructor (private AuthFormSrvc: AuthFormService) 
  {}

  ngOnInit (): void 
  {
  	this._subscriptions$.add(this.AuthFormSrvc.onSuccess.subscribe(msg => {
  		if (this.msg === msg) return;
  		if (msg) {
  			this.className = 'valid';
  			this.msg = msg;
  		}
  		else {
	  		if (this.className && this.className !== 'valid' && !msg) return;
	  		this.className = 'valid';
	  		this.msg = msg;
  		}
  	}));

  	this._subscriptions$.add(this.AuthFormSrvc.onError.subscribe(msg => {
  		if (this.msg === msg) return;
  		if (msg) {
  			this.className = 'invalid';
  			this.msg = msg;
  		}
  		else {
	  		if (this.className && this.className !== 'invalid' && !msg) return;
	  		this.className = 'invalid';
	  		this.msg = msg;
  		}
  	}));

  	this._subscriptions$.add(this.AuthFormSrvc.onAuthChange.subscribe(isAuth => {
  		if (this._isAuth !== isAuth)
  			this.setAuth(isAuth);
  	}));
  }

  ngOnDestroy (): void
  {
  	this._subscriptions$.unsubscribe();
  }
}
