import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { UiInputCheckboxWidgetComponent } from './ui-input-checkbox-widget/ui-input-checkbox-widget.component';

@NgModule({
  declarations: [UiInputCheckboxWidgetComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  exports: [
  	UiInputCheckboxWidgetComponent
  ]
})
export class UiInputCheckboxModule { }