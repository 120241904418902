import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'any'
})
export class MediaRetrievalService
{
	base64toBlob (base64Data)
	{
  	var byteCharacters 	= 		atob(base64Data),
  			byteArrays 			= 		[],
  			sliceSize 			=			512;

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) 
    {
    	var slice 				= 		byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers 	= 		new Array(slice.length);

      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] 	= 		slice.charCodeAt(i);
      }

    	var byteArray 		= 		new Uint8Array(byteNumbers);
    	byteArrays.push(byteArray);
  	}

  	return 										new Blob(byteArrays, {type: "application/pdf"});
	}

	fetchById (mediaId: number)
	{

	}

	fullUrl (filename: string)
	{
		return 											this.ConfigSrvc.get("api.media_url")+"/"+filename;
	}

	buildUrl (chestId: string, milestoneId: string, filename: string): string
	{
		return `${this.ConfigSrvc.get("api.media_url")}/chests/${chestId}/${milestoneId}/${filename}`;
	}

	fetchByFilename (chestId: string, milestoneId: string, filename: string): Observable<any>
	{
		const url: 						string 	= 	this.buildUrl(chestId, milestoneId, filename),
  				requestOptions: Object 	= 	{responseType: 'blob'};

		return 														this.http.get<any>(url, requestOptions);
	}

	constructor (private http: HttpClient, private ConfigSrvc: ConfigService) 
	{}
}
