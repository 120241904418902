import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { SessionUser } from './../session/session-user.model';
import { TheFutureAuthProvider } from './../session/the-future-auth.provider';

@Injectable({ 
  providedIn: 'root' 
})
export class AuthGuard implements CanActivate {

	#isAuth = async (): Promise<boolean> => await this.authProvider.isTokenValid();

	#isLogout = (state: RouterStateSnapshot): boolean => 
	{
    let isLogout: boolean 			=     false;

    try                         { isLogout = String(state.url).toLowerCase().includes("logout"); 	}
    catch (ex)                  { isLogout = false;                                             	}

    return !!isLogout;
	}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean|UrlTree>
  {
  	if (this.#isLogout(state)) {
  		await this.SessionUser.logout();
  		return this.router.createUrlTree(['/login'])
  	}

  	if (await this.#isAuth())
  		return true;

  	return this.router.createUrlTree(['/login']);
  }

  constructor(private router: Router, private SessionUser: SessionUser, private authProvider: TheFutureAuthProvider) 
  {}
}