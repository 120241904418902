import { Directive, SimpleChanges, Renderer2, Input, HostBinding, HostListener, ElementRef, Output, EventEmitter } from '@angular/core';
import { AUTO_STYLE, animate, style, AnimationPlayer, AnimationBuilder } from "@angular/animations";
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

const COLLAPSED_STYLE_STATE = {
						// border: 'thick solid red',
        		margin: '0',
        		padding: '0',
            overflow: 'hidden',
            height: '0', 
            visibility: 'hidden', 
            // display: 'none'
            zIndex: 999
			},
			EXPANDED_STYLE_STATE = {
						// border: 'thick solid blue',
		      	margin: AUTO_STYLE, 
		      	padding: AUTO_STYLE,
          	// display: AUTO_STYLE, 
          	overflow: AUTO_STYLE, 
            height: AUTO_STYLE, 
            visibility: AUTO_STYLE,
            zIndex: 999
          }

@Directive({
  selector: '[rvDropdownContent]'
})
export class DropdownContentDirective {

  private collapseAnimation: AnimationPlayer;
  private expandAnimation: AnimationPlayer;

	@Input() rvDropdownContent;
	@Input() name;
	@Input() position;

	@Output('collapse.start')
	onCollapseStart: EventEmitter<void> = new EventEmitter();
	@Output('collapse.done')
	onCollapseDone: EventEmitter<void> = new EventEmitter();
	@Output('collapse.destroy')
	onCollapseDestroy: EventEmitter<void> = new EventEmitter();
	@Output('expand.start')
	onExpandStart: EventEmitter<void> = new EventEmitter();
	@Output('expand.done')
	onExpandDone: EventEmitter<void> = new EventEmitter();
	@Output('expand.destory')
	onExpandDestroy: EventEmitter<void> = new EventEmitter();

	isExpanded: boolean = false;
	#isExpanded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	private sub: Subscription = new Subscription();

  open (): void
  {
    this.collapseAnimation.reset();
    this.expandAnimation.restart();
    this.isExpanded = true;
  }

  close (): void
  {
    this.expandAnimation.reset();
    this.collapseAnimation.restart();
    this.isExpanded = false;
  }

	toggle (): void
	{
		this.#isExpanded$.next(!!this.rvDropdownContent);
	}

  constructor(private builder: AnimationBuilder, private el: ElementRef, private renderer: Renderer2) 
  {}

  ngOnInit (): void
  {}

  ngOnChanges (Changes: SimpleChanges): void
  {
  	if (Changes.rvDropdownContent)
  		this.toggle();
  }

  ngAfterViewInit (): void
  {
		if (typeof this.position === 'object') {
			let style: string = '';
			Object.keys(this.position).forEach(key => style += `${key}: ${this.position[key]};`);
  		this.renderer.setAttribute(this.el.nativeElement, 'style', style);
  	}
  	else if (this.position === 'absolute')
  		this.renderer.setAttribute(this.el.nativeElement, 'style', 'position: absolute;');

    this.collapseAnimation = this.builder
      .build([
        style(EXPANDED_STYLE_STATE),
        animate('250ms ease-in-out', style(COLLAPSED_STYLE_STATE)),
      ])
      .create(this.el.nativeElement);

    this.collapseAnimation.onDone(() => {
    	this.onCollapseDone.next();
    	this.el.nativeElement.style.display = 'none';
    });
    this.collapseAnimation.onStart(() => this.onCollapseStart.next());
    this.collapseAnimation.onDestroy(() => this.onCollapseDestroy.next());

    this.expandAnimation = this.builder
      .build([
        style(COLLAPSED_STYLE_STATE),
        animate('250ms ease-in', style(EXPANDED_STYLE_STATE)),
      ])
      .create(this.el.nativeElement);

    this.expandAnimation.onDone(() => this.onExpandDone.next());
    this.expandAnimation.onStart(() => {
    	this.el.nativeElement.style.display = 'block';
    	this.onExpandStart.next()
    });
		this.expandAnimation.onDestroy(() => this.onExpandDestroy.next());

		this.sub.add(this.#isExpanded$.pipe(distinctUntilChanged()).subscribe(expand => expand ? this.open() : this.close()));
  }

  ngOnDestroy (): void
  {
  	this.sub.unsubscribe();
  }
}
