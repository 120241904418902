import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModelFactoryConfig, ModelService } from '@getrearview/model-builder';
import { ConfigService } from './../../shared/services/config.service';
import { MemoryService } from './memory.service';
import { MilestoneMediaService } from './milestone-media.service';
import { MemoryChestTagService } from './memory-chest-tag.service';
import { MilestoneLocationService } from './milestone-location.service';
import { MilestoneEventMapService } from './milestone-event-map.service';

@Injectable({
  providedIn: 'root'
})
export class MilestoneService extends ModelService {

  constructor (injector: Injector, httpClient: HttpClient) 
  {
  	let ConfigSrvc: ConfigService = injector.get<any>(ConfigService);
		let MFC: ModelFactoryConfig = (new ModelFactoryConfig()).setAll({
  				// Model Name
  				name: "Milestone",
  				// Model Endpoint
					endpoint: ConfigSrvc.get("api.api_url")+"/memories/milestones",
					// Model Fields
					fields: ["chest_id", "author_id", "start_at", "end_at", "nickname", "description", "cover_ext"],
					// Model `Permitted` Fields
					permitted: [],
					// Model `Required` Fields
					required: [],
					// Model Field Labels
					labels: {},
					// Model Field Validation Patterns
					patterns: {},
					// Parent ID Field Name
					parent_id_field: "chest_id",
					// Model Children Types
					children_types: {
						memory: injector.get<any>(MemoryService),
						media: injector.get<any>(MilestoneMediaService),
						tag: injector.get<any>(MemoryChestTagService),
						location: injector.get<any>(MilestoneLocationService),
						// eventMap: injector.get<any>(MilestoneEventMapService),
						event_map: injector.get<any>(MilestoneEventMapService),
					},
					// Model Association Types
					association_types: {},
					// Storage Type (local, session, cookies)
					storage_type: "local"
				});

  	super(MFC, httpClient);
  }
}