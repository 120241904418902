import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenService } from './../services/token.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedInService implements CanActivate{

  constructor(
  	private router: Router,
  	private TokenSrvc: TokenService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		return new Promise((resolve, reject) => this.TokenSrvc.isValid().then(res => resolve(res?res:this.router.createUrlTree(['/login']))));
  }
}
