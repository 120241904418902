import { Component, OnInit, Input, ElementRef } from '@angular/core';

const isActive = (val: any) => (typeof val === 'boolean' ? val : typeof val === 'string' && val === '')

@Component({
  template: '<ng-content></ng-content>'
})
export class UiCoreWidgetComponent implements OnInit {

	private _classes: Array<string> = ['rv-ui'];

	// sizes
	@Input('xs')
	private _xs: boolean;
	@Input('sm')
	private _sm: boolean;
	@Input('md')
	private _md: boolean;
	@Input('lg')
	private _lg: boolean;

	get size (): string
	{
		if (this.isActive(this._xs))	
			return 'xs';
		else if (this.isActive(this._sm))	
			return 'sm';
		else if (this.isActive(this._md))	
			return 'md';
		else if (this.isActive(this._lg))	
			return 'lg';
		return 'sm';
	}

	get sizeClass (): string 
	{
		return `rv-ui-${this.size}`;
	}

	get coreClasses (): Array<string>
	{
		return ['rv-ui',this.sizeClass];
	}

	isActive (value: any): boolean
	{
		return !!isActive(value);
	}

  constructor () 
  {}

  ngOnInit (): void 
  {}
}