import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { ModelInstance, ModelChildrenCollection } from '@getrearview/model-builder';
import { of } from 'rxjs';
import { take, delay } from 'rxjs/operators';

const HOLD_DELETE_DELAY = 2000;

@Component({
  selector: 'rv-memory-media-router',
  templateUrl: './memory-media-router.component.html',
  styleUrls: ['./memory-media-router.component.scss']
})
export class MemoryMediaRouterComponent implements OnInit {

	@Input('Memory') Memory: ModelInstance;
	@Input('IsMultiImage') IsMultiImage: boolean;
	@Input('MediaIds') MediaIds: Array<string|number>
	@Input('Media') Media: ModelInstance;
	@Input('Medias') Medias: ModelChildrenCollection;
	@Input('quantity') quantity: number;

	@Output('onDelete')
	onDelete: EventEmitter<{Media: ModelInstance, $element: any}> = new EventEmitter();

	private _promptTimer: string = '';
	private deleteSub$;

	// kids (): number
	// {
	// 	if (this?.Memory?.getChildren('media'))
	// 		return Object.values(this.Memory.getChildren('media')).length;
	// 	return 0;
	// }

	// get quantity (): number
	// {
	// 	return this.MediaIds.length;
	// }

	get media_ids (): Array<string>
	{
		return Object.keys(this.Medias);
	}

	onMouseDown ($event, Media: ModelInstance): void
	{
		try {
	  	if (this.deleteSub$)
	  		this.deleteSub$.unsubscribe();
		}
		catch (ex) {
			console.error('ex ',ex);
		}

		const M: ModelInstance = Media,
					$e = $event;
		this.deleteSub$ = of(this._promptTimer = `${M.id()}`).pipe(delay(HOLD_DELETE_DELAY),take(1))
				.subscribe(mediaId => {
					let $element = $e.target;
					if ($element)
						$element = !$element.classList.contains('.memory-media-component') ? $element.closest('.memory-media-component') : $element;

					if (this._promptTimer) {
						this._promptTimer = '';
						this.onDelete.emit({Media: M, $element});
					}
				});
	}

	onMouseUp (): void
	{
		this._promptTimer = '';
	}

  constructor () 
  {}

  ngOnInit (): void 
  {}

  ngOnDestroy (): void
  {
  	if (this.deleteSub$)
  		this.deleteSub$.unsubscribe();
  }
}