<div id="ui-widget-selector-component" class="container-fluid">
	<div class="row">
		<div class="col-24 text-center">
			<ul class="bg-color--grey-100">
				<li class="bg-color--grey-200" routerLinkActive="active-link" routerLink="icons">Icons</li>
				<li class="bg-color--grey-200" routerLinkActive="active-link" routerLink="fonts">Fonts</li>
				<li class="bg-color--grey-200" routerLinkActive="active-link" routerLink="buttons">Buttons</li>
				<li class="bg-color--grey-200" routerLinkActive="active-link" routerLink="colors">Colors</li>
				<li class="bg-color--grey-200" routerLinkActive="active-link" routerLink="inputs">Inputs</li>
				<li class="bg-color--grey-200" routerLinkActive="active-link" routerLink="multi-select">Multi-Select</li>
				<li class="bg-color--grey-200" routerLinkActive="active-link" routerLink="locations">Locations</li>
				<li class="bg-color--grey-200" routerLinkActive="active-link" routerLink="pagination">Pagination</li>
				<li class="bg-color--grey-200" routerLinkActive="active-link" routerLink="uploader">Uploader</li>
			</ul>
		</div>
	</div>
</div>
