import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { SessionUser } from './../../../core/session/session-user.model';
import { AuthFormService } from './../shared/auth-form.service';
import { ConfigService } from './../../../shared/services/config.service';
import { AuthFormComponent } from './../shared/auth-form.component';
import { AuthFormStates } from './../shared/AuthFormStates.enum';
import { TrackJsService } from './../../../shared/services/trackjs.service';
import { ApiService } from './../../../core/services/api.service';

@Component({
  selector: 'rv-forgot-password-confirm-form',
  templateUrl: './forgot-password-confirm-form.component.html',
  styleUrls: ['./../shared/auth-form.component.scss']
})
export class ForgotPasswordConfirmFormComponent extends AuthFormComponent implements OnInit {

	showEmail: boolean = false;

  constructor (
  	SessionUser: SessionUser,  // not used here.
  	AuthApi: ApiService,  // not used here.
  	AuthFormSrvc: AuthFormService, 
  	ConfigSrvc: ConfigService, 
  	fb: FormBuilder,  // not used here.
  	TrackJsSrvc: TrackJsService // not used here.
  ) 
  {
  	super(SessionUser, AuthApi, AuthFormSrvc, ConfigSrvc, fb, TrackJsSrvc)
  }

  ngOnInit (): void 
  {
  	if (!this.AuthFormSrvc.emailOrPhone)
  		this.showEmail = true;

  	if (this.showEmail) {
  		// const emailOrPhone: string = this.AuthFormSrvc.emailOrPhone || this.ConfigSrvc.get('defaults.pages.auth.forgot_password_confirm.email_or_phone') || '';
      // this.resetForm = this.fb.group({...this.resetForm.controls, email_or_phone: [emailOrPhone]});
  		// this.resetForm.addControl('email_or_phone', this.fb.control(emailOrPhone, [Validators.required]));
  		// this.resetForm.addControl('email_or_phone', new FormControl(emailOrPhone, [Validators.required]));
  	}

		// this.resetForm.addControl('email_or_phone', new FormControl(this.AuthFormSrvc.emailOrPhone || this.ConfigSrvc.get('defaults.pages.auth.forgot_password_confirm.email_or_phone') || null, [Validators.required]));

		this._subscriptions$.add(this.resetForm.valueChanges.subscribe(() => this.reset()));
  }

  ngOnDestroy (): void
  {
  	this._subscriptions$.unsubscribe();
  }
}