<button type='button' class="btn btn-primary btn-google google-button-component">
	<span class='social-icon'>
		<!-- <rv-icon group='brands' icon='google' /> -->
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			 viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
			<style type="text/css">
				.st0{clip-path:url(#SVGID_00000013876474821099335340000010236831057985547695_);}
				.st1{fill:#4285F4;}
				.st2{fill:#34A853;}
				.st3{fill:#FBBC04;}
				.st4{fill:#E94235;}
			</style>
			<g>
				<defs>
					<rect id="SVGID_1_" x="0.2" y="0" width="20" height="20"/>
				</defs>
				<clipPath id="SVGID_00000155127761352597814510000008783059150839925670_">
					<use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
				</clipPath>
				<g style="clip-path:url(#SVGID_00000155127761352597814510000008783059150839925670_);">
					<path class="st1" d="M19.8,10.2c0-0.7-0.1-1.4-0.2-2h-9.4v3.9h5.4c-0.2,1.2-0.9,2.3-2,3v2.5h3.2C18.7,15.8,19.8,13.3,19.8,10.2z"
						/>
					<path class="st2" d="M10.2,20c2.7,0,5-0.9,6.6-2.4l-3.2-2.5c-0.9,0.6-2,1-3.4,1c-2.6,0-4.8-1.8-5.6-4.1H1.3v2.6
						C2.9,17.8,6.3,20,10.2,20z"/>
					<path class="st3" d="M4.6,11.9c-0.2-0.6-0.3-1.2-0.3-1.9s0.1-1.3,0.3-1.9V5.5H1.3C0.6,6.9,0.2,8.4,0.2,10s0.4,3.1,1.1,4.5
						L4.6,11.9z"/>
					<path class="st4" d="M10.2,4C11.7,4,13,4.5,14,5.5l2.9-2.9C15.2,1,12.9,0,10.2,0C6.3,0,2.9,2.2,1.3,5.5l3.3,2.6
						C5.4,5.7,7.6,4,10.2,4z"/>
				</g>
			</g>
		</svg>
	</span>
	&nbsp;
	<span class='social-label'>
		{{label}}
	</span>
</button>