import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef, SimpleChanges } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { UiInputWidgetComponent } from './../../ui-input/ui-input-widget/ui-input-widget.component'
import { FormGroup, FormControl, ControlContainer } from '@angular/forms';
import { DatetimePicker } from '@capawesome-team/capacitor-datetime-picker';

const COMPONENT_TEMPLATE = 
	`<div [ngClass]="classes">`
+ 	`<label>{{label}}</label>`
+ 	`<span class="input-icon input-left-icon" *ngIf="!!iconLeftGroup&&!!iconLeftName">`
+ 		`<rv-icon [group]="iconLeftGroup" [icon]="iconLeftName"></rv-icon>`
+ 	`</span>`
+ 	`<div class="input-wrapper" [formGroup]="formGroup">`
+ 		`<ng-container *ngIf="isReactive;else standard">`
+ 			`<input #uiDatepicker [type]="type" showMaskTyped="{{mask?.showMaskTyped}}" [specialCharacters]="mask?.specialCharacters" [mask]="mask?.mask" shownMaskExpression="{{mask?.shownMaskExpression}}" [formControl]="control" [placeholder]="placeholder" (focus)="onFocus()" (blur)="onBlur()" />`
+			`</ng-container>`
+ 		`<ng-template #standard>`
+ 			`<input #uiDatepicker [type]="type" [form]="formRef" class="form-control" [formControlName]="controlName" [placeholder]="placeholder" (focus)="onFocus()" (blur)="onBlur()" />`
+			`</ng-template>`
+ 		`<ng-content class="input-btn-group"></ng-content>`
+ 	`</div>`
+ 	`<span class="msg error-msg" *ngIf="showError">{{error}}</span>`
+ `</div>`;

const isActive = (val: any) => (typeof val === 'boolean' ? val : typeof val === 'string' && val === '')

@Component({
  selector: 'rv-datepicker',
  styleUrls: ['./ui-datepicker-widget.component.scss'],
  template: COMPONENT_TEMPLATE
})
export class UiDatepickerWidgetComponent implements OnInit 
{
	@ViewChild('uiDatepicker') uiDatepicker: ElementRef;

	isFocused: boolean = false;
	iconLeftGroup: string;
	iconLeftName: string;
	iconRightGroup: string;
	iconRightName: string;
	private _classes: Array<string> = ['rv-ui','ui-input','ui-datepicker'];
	protected isNative: boolean = true;

	// input labels
	@Input('label')
	private _label: string;
	@Input('placeholder')
	private _placeholder: string;
	@Input('name')
	private _name: string;
	@Input('type')
	type: string = 'datetime';

	// sizes
	@Input('xs')
	private _xs: boolean;
	@Input('sm')
	private _sm: boolean;
	@Input('md')
	private _md: boolean;
	@Input('lg')
	private _lg: boolean;
	@Input('xl')
	private _xl: boolean;

	// interactive
	@Input('disabled')
	private _disabled: boolean;
	@Input('readonly')
	private _readonly: boolean;
	@Input('valid')
	private _valid: boolean;
	@Input('invalid')
	private _invalid: string;
	@Input('reactive')
	private _reactive: boolean;

	// colors
	@Input('purple')
	private _purple: boolean;
	@Input('blue')
	private _blue: boolean;
	@Input('gray')
	private _gray: boolean;
	@Input('dk-gray')
	private _dkGray: boolean;	
	@Input('white')
	private _white: boolean;

	// style
	@Input('open')
	private _open: boolean;
	@Input('shadowed')
	private _shadowed: boolean;
	@Input('outline_disable')
	private _outline_disable: boolean;

	// icons
	@Input('iconLeft')
	private _iconLeft: string;
	@Input('iconRight')
	private _iconRight: string;

	// data 
	@Input('mask')
	_mask: string|{[key: string]: any}
	// mask: {[key: string]: any} = {
		// Detailed Example:
		// showMaskTyped: false,
		// specialCharacters: ['e', 'x', 't', ' ', '(', ')', '-', '.'],
		// shownMaskExpression: "(___) ___-____ ext. ______",
		// mask: "(000) 000-0000 ext. 000000"
	// }

	// Form Access & Controls.
	public form: FormGroup;
	@Input('formRef')
	public formRef;
	@Input('formGroup')
	private _formGroup: FormGroup;
	@Input('controlName')
	private _controlName: string;
	@Input('control')
	private _control: FormControl;
	private _emptyControl: FormControl = new FormControl();

	@Output('focus')
	private _focus:EventEmitter<void> = new EventEmitter();
	@Output('blur')
	private _blur:EventEmitter<void> = new EventEmitter();
	@Output('change')
	private _change:EventEmitter<void> = new EventEmitter();

	get mask (): {[key: string]: any} 
	{
		if (typeof this._mask === 'string')
			return {mask: this._mask}
		return this._mask;
	}

	get control (): FormControl
	{
		return this._control ? this._control : this._emptyControl
	}

	get controlName (): string
	{
		return `${this._controlName||''}`;
	}

	get error (): string 
	{
		return `${this._invalid||'Invalid'}`
	}

	get classes (): string
	{
		return this._classes.concat([
				this.sizeClass,
				this.labelClass,
				this.colorClass,
				this.styleClass,
				this.modifierClass,
				this.outlineClass,
				(!!this._iconLeft?'has-left-icon':''),
				(!!this._iconRight?'has-right-icon':''),
				(this.isFocused?'is-focused':''),
				(this._invalid?'is-invalid':isActive(this._valid)?'is-valid':'')
			]).join(' ');
	}

	get sizeClass (): string
	{
		if (isActive(this._xs)) return 'rv-ui-xs';
		if (isActive(this._sm)) return 'rv-ui-sm';
		if (isActive(this._md)) return 'rv-ui-md';
		if (isActive(this._lg)) return 'rv-ui-lg';
		if (isActive(this._xl)) return 'rv-ui-xl';
		return 'rv-ui-sm';
	}

	get colorClass (): string
	{
		if (isActive(this._purple)) 	return 'rv-input-purple';
		if (isActive(this._blue)) 		return 'rv-input-blue';
		if (isActive(this._gray)) 		return 'rv-input-gray';
		if (isActive(this._dkGray)) 	return 'rv-input-dk-gray';
		if (isActive(this._white)) 		return 'rv-input-white';
		return 'rv-input-dk-gray';
	}

	get labelClass (): string
	{
		if (isActive(this._label))
			return 'has-label';
		return '';
	}

	get styleClass (): string
	{
		if (isActive(this._open)) 		return 'rv-input-open';
	}

	get modifierClass (): string
	{
		if (isActive(this._shadowed)) return 'rv-input-shadowed';
	}

	get outlineClass (): string
	{
		if (isActive(this._outline_disable)) return 'rv-input-outline-disable'
	}

	get label (): string
	{
		return `${this._label||''}`
	}

	get placeholder (): string
	{
		return `${this._placeholder||''}`
	}

	get disabled (): boolean
	{
		return !!this._disabled;
	}

	get readonly (): boolean
	{
		return !!this._readonly;
	}

	get isValid (): boolean
	{
		return !!this.control.valid;
	}

	get showError (): boolean
	{
		if (this.control)
			return this.control.dirty ? this.isInvalid : false;

		return this.isInvalid;
	}

	get isInvalid (): boolean
	{
		return !!this.control.invalid;
	}

	get isReactive (): boolean
	{
		return isActive(this._reactive)
	}

	get formGroup (): FormGroup
	{
		return this._formGroup;
	}

	onFocus (): void 
	{
		if (Capacitor.isNative) {
			this.openPicker(new Date()).then((dateObj: Date) => {
				try {
					if (this._control) 
						this.control.setValue(dateObj)
					else if (this._formGroup && this._controlName && this._formGroup.controls[this._controlName])
						this._formGroup.controls[this._controlName].setValue(dateObj)
				}
				catch (ex) {
					console.error(`Attempted to set model value ${ex}`)
				}

				let now: Date = new Date(),
						dateStr: string = (dateObj ? `${(dateObj.getHours()>12?dateObj.getHours()-12:dateObj.getHours())}:${(dateObj.getMinutes()>9?dateObj.getMinutes():('0'+dateObj.getMinutes()))} ${(dateObj.getHours()>=12?'PM':'AM')}` : '');

				if (dateStr) {
					dateStr += ', ';
					switch (dateObj.getMonth())
					{
						case 0: dateStr += `Jan ${dateObj.getDate()}`; break;
						case 1: dateStr += `Feb ${dateObj.getDate()}`; break;
						case 2: dateStr += `Mar ${dateObj.getDate()}`; break;
						case 3: dateStr += `Apr ${dateObj.getDate()}`; break;
						case 4: dateStr += `May ${dateObj.getDate()}`; break;
						case 5: dateStr += `Jun ${dateObj.getDate()}`; break;
						case 6: dateStr += `Jul ${dateObj.getDate()}`; break;
						case 7: dateStr += `Aug ${dateObj.getDate()}`; break;
						case 8: dateStr += `Sept ${dateObj.getDate()}`; break;
						case 9: dateStr += `Oct ${dateObj.getDate()}`; break;
						case 10: dateStr += `Nov ${dateObj.getDate()}`; break;
						case 11: dateStr += `Dec ${dateObj.getDate()}`; break;
					}
					if (now.getFullYear() !== dateObj.getFullYear())
						dateStr += ` ${dateObj.getFullYear()}`;
				}

				if (this.uiDatepicker) 
					this.uiDatepicker.nativeElement.value = dateStr;
			});
		}

		this.isFocused = true;
		this._focus.emit();
	}

	onBlur (): void 
	{
		this.isFocused = false;
		this._blur.emit();
	}

	private openPicker = async (dateVal: Date): Promise<Date> => 
	{
	  const {value} = await DatetimePicker.present({
	    cancelButtonText: 'Cancel',
	    doneButtonText: 'Ok',
	    mode: 'datetime',
	    value: dateVal.toISOString(),
	    theme: 'light',
	    locale: 'en-US',
	    format: 'yyyy-MM-dd\'T\'HH:mm:ss.sss'
	  });

	  if (value)
	  	return new Date(value);
	};

	private setIcon (icon: string): void
	{
		if (this[`_${icon}`] && typeof this[`_${icon}`] === 'string' && /([a-z\-]+)\.([a-z\-]+)/i.test(this[`_${icon}`])) {
			let [g,i] = this[`_${icon}`].split('.');
			this[`${icon}Group`] = g;
			this[`${icon}Name`] = i;
		}
		else if (this[`_${icon}`] && Array.isArray(this[`_${icon}`]) && /([a-z\-]+)\.([a-z\-]+)/i.test(this[`_${icon}`].slice(0,2).join('.'))) {
			let [g,i] = this[`_${icon}`];
			this[`${icon}Group`] = g;
			this[`${icon}Name`] = i;
		}
		else {
			this[`${icon}Group`] = '';
			this[`${icon}Name`] = '';
		}
	}

	constructor (elementRef: ElementRef<HTMLElement>, private controlContainer: ControlContainer) 
	{}

  ngOnInit () 
  {
    // Set our form property to the parent control
    // (i.e. FormGroup) that was passed to us, so that our
    // view can data bind to it
    this.form = <FormGroup>this.controlContainer.control;

    if (!(this.isNative = Capacitor.isNative))
    	this.type = this.type === "datetime" ? "datetime-local" : this.type;
  }

  ngOnChanges (Changes: SimpleChanges): void
  {
  	if (Changes.hasOwnProperty('_iconLeft'))  this.setIcon('iconLeft');
  	if (Changes.hasOwnProperty('_iconRight')) this.setIcon('iconRight');
  }
}