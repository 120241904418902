<div class="ui-logo-widget-component">
	<svg width="100%" height="100%" viewBox="0 0 1920 250" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">



			<!-- symbol -->
			<g>
				<!-- left paren -->
		    <g transform="matrix(4.367,0,0,4.367,-349.958,-1201.13)">
		        <path d="M107.136,287.919C109.873,287.919 112.09,285.702 112.09,282.965C112.09,280.229 109.873,278.011 107.136,278.011C92.975,278.011 81.455,289.531 81.455,303.691C81.455,317.852 92.975,329.372 107.136,329.372C109.873,329.372 112.09,327.154 112.09,324.418C112.09,321.682 109.873,319.464 107.136,319.464C98.439,319.464 91.363,312.388 91.363,303.691C91.363,294.995 98.439,287.919 107.136,287.919Z" style="fill:{{symbolColor}};fill-rule:nonzero;"/>
		    </g>
		    <!-- top colon -->
		    <g transform="matrix(4.367,0,0,4.367,-349.958,-1201.13)">
		        <path d="M128.826,275.899C124.924,275.899 121.76,279.063 121.76,282.965C121.76,286.868 124.924,290.031 128.826,290.031C132.729,290.031 135.892,286.868 135.892,282.965C135.892,279.063 132.729,275.899 128.826,275.899Z" style="fill:{{symbolColor}};fill-rule:nonzero;"/>
		    </g>
		    <!-- bottom colon -->
		    <g transform="matrix(4.367,0,0,4.367,-349.958,-1201.13)">
		        <path d="M128.826,317.353C124.924,317.353 121.76,320.516 121.76,324.418C121.76,328.321 124.924,331.484 128.826,331.484C132.729,331.484 135.892,328.321 135.892,324.418C135.892,320.516 132.729,317.353 128.826,317.353Z" style="fill:{{symbolColor}};fill-rule:nonzero;"/>
		    </g>
		    <!-- right paren -->
		    <g transform="matrix(4.367,0,0,4.367,-349.958,-1201.13)">
		        <path d="M150.516,278.011C147.779,278.011 145.562,280.229 145.562,282.965C145.562,285.702 147.779,287.919 150.516,287.919C159.212,287.919 166.288,294.995 166.288,303.691C166.288,312.388 159.212,319.464 150.516,319.464C147.779,319.464 145.562,321.682 145.562,324.418C145.562,327.154 147.779,329.372 150.516,329.372C164.676,329.372 176.196,317.852 176.196,303.691C176.196,289.531 164.676,278.011 150.516,278.011Z" style="fill:{{symbolColor}};fill-rule:nonzero;"/>
		    </g>
		  </g>



			<!-- name -->
			<g>
		    <!-- r -->
		    <g transform="matrix(4.367,0,0,4.367,-349.958,-1201.13)">
		        <path d="M216.583,284.159C212.746,285.64 209.56,287.519 206.985,289.591L206.985,287.215C206.985,284.889 205.1,283.004 202.774,283.004C200.448,283.004 198.563,284.889 198.563,287.215L198.563,319.83C198.563,322.155 200.448,324.041 202.774,324.041C205.1,324.041 206.985,322.155 206.985,319.83L206.985,304.102C206.985,301.843 210.166,295.664 219.616,292.016C221.786,291.178 222.866,288.741 222.028,286.571C221.191,284.402 218.755,283.322 216.583,284.159Z" style="fill:{{nameColor}};fill-rule:nonzero;"/>
		    </g>
		    <!-- e -->
		    <g transform="matrix(4.367,0,0,4.367,-349.958,-1201.13)">
		        <path d="M232.245,299.311C233.957,294.742 238.368,291.48 243.527,291.48C248.687,291.48 253.098,294.742 254.81,299.311L232.245,299.311ZM243.527,283.059C232.244,283.059 223.063,292.238 223.063,303.522C223.063,314.806 232.244,323.986 243.527,323.986C248.988,323.986 253.275,323.099 259.032,318.563C260.858,317.123 261.172,314.475 259.733,312.648C258.293,310.822 255.645,310.507 253.819,311.947C249.867,315.062 247.549,315.564 243.527,315.564C238.368,315.564 233.956,312.303 232.245,307.733L259.78,307.733C262.105,307.733 263.991,305.848 263.991,303.522C263.991,292.238 254.811,283.059 243.527,283.059Z" style="fill:{{nameColor}};fill-rule:nonzero;"/>
		    </g>
		    <!-- a -->
		    <g transform="matrix(4.367,0,0,4.367,-349.958,-1201.13)">
		        <path d="M288.718,315.564C282.078,315.564 276.676,310.162 276.676,303.522C276.676,296.882 282.078,291.48 288.718,291.48C292.526,291.48 298.553,293.987 299.762,300.846L299.762,307.292C298.291,313.303 292.255,315.564 288.718,315.564ZM303.973,283.004C301.881,283.004 300.158,284.534 299.831,286.534C296.449,284.24 292.484,283.059 288.718,283.059C277.434,283.059 268.254,292.238 268.254,303.522C268.254,314.806 277.434,323.986 288.718,323.986C292.447,323.986 296.427,322.838 299.842,320.619C300.212,322.566 301.918,324.041 303.973,324.041C306.299,324.041 308.184,322.155 308.184,319.83L308.184,287.215C308.184,284.889 306.299,283.004 303.973,283.004Z" style="fill:{{nameColor}};fill-rule:nonzero;"/>
		    </g>
		    <!-- r -->
		    <g transform="matrix(4.367,0,0,4.367,-349.958,-1201.13)">
		        <path d="M333.345,284.159C329.508,285.64 326.321,287.519 323.746,289.591L323.746,287.215C323.746,284.889 321.861,283.004 319.535,283.004C317.209,283.004 315.324,284.889 315.324,287.215L315.324,319.83C315.324,322.155 317.209,324.041 319.535,324.041C321.861,324.041 323.746,322.155 323.746,319.83L323.746,304.102C323.746,301.843 326.927,295.664 336.378,292.016C338.547,291.179 339.627,288.741 338.79,286.571C337.953,284.402 335.516,283.322 333.345,284.159Z" style="fill:{{nameColor}};fill-rule:nonzero;"/>
		    </g>
		    <!-- v -->
		    <g transform="matrix(4.367,0,0,4.367,-349.958,-1201.13)">
		        <path d="M381.038,283.388C378.827,282.656 376.447,283.852 375.714,286.058L367.737,310.093L367.665,310.295C365.808,315.449 365.494,315.449 364.171,315.449C362.844,315.449 362.529,315.449 360.668,310.269L352.628,286.058C351.896,283.852 349.516,282.656 347.305,283.388C345.098,284.121 343.902,286.504 344.635,288.711L352.629,312.797L352.742,313.115C354.553,318.157 356.605,323.871 364.171,323.871C371.725,323.871 373.777,318.175 375.588,313.15L383.708,288.711C384.44,286.504 383.245,284.121 381.038,283.388Z" style="fill:{{nameColor}};fill-rule:nonzero;"/>
		    </g>
			  <!-- i -->
		    <g transform="matrix(4.367,0,0,4.367,-349.958,-1201.13)">
		        <path d="M394.448,283.004C392.123,283.004 390.237,284.889 390.237,287.215L390.237,319.83C390.237,322.155 392.123,324.041 394.448,324.041C396.774,324.041 398.659,322.155 398.659,319.83L398.659,287.215C398.659,284.889 396.774,283.004 394.448,283.004Z" style="fill:{{nameColor}};fill-rule:nonzero;"/>
		    </g>
		    <g transform="matrix(4.367,0,0,4.367,-349.958,-1201.13)">
		        <path d="M394.448,268.516C391.838,268.516 389.722,270.632 389.722,273.242C389.722,275.853 391.838,277.968 394.448,277.968C397.059,277.968 399.175,275.853 399.175,273.242C399.175,270.632 397.059,268.516 394.448,268.516Z" style="fill:{{nameColor}};fill-rule:nonzero;"/>
		    </g>
		    <!-- e -->
		    <g transform="matrix(4.367,0,0,4.367,-349.958,-1201.13)">
		        <path d="M415.036,299.311C416.748,294.742 421.159,291.48 426.318,291.48C431.477,291.48 435.889,294.742 437.6,299.311L415.036,299.311ZM426.318,283.059C415.034,283.059 405.854,292.238 405.854,303.522C405.854,314.806 415.034,323.986 426.318,323.986C432.232,323.986 436.648,322.964 443.035,317.572C444.813,316.071 445.037,313.415 443.537,311.638C442.036,309.859 439.378,309.636 437.603,311.136C432.875,315.127 430.423,315.564 426.318,315.564C421.159,315.564 416.747,312.303 415.036,307.733L442.571,307.733C444.896,307.733 446.782,305.848 446.782,303.522C446.782,292.238 437.602,283.059 426.318,283.059Z" style="fill:{{nameColor}};fill-rule:nonzero;"/>
		    </g>
		    <!-- w -->
		    <g transform="matrix(4.367,0,0,4.367,-349.958,-1201.13)">
		        <path d="M515.659,283.388C513.448,282.656 511.067,283.851 510.335,286.058L502.358,310.093L502.286,310.293C500.429,315.449 500.115,315.449 498.792,315.449C497.465,315.449 497.15,315.449 495.29,310.269L487.249,286.058C486.639,284.218 484.879,283.099 483.031,283.197C481.181,283.097 479.42,284.216 478.809,286.058L470.832,310.093L470.759,310.293C468.902,315.449 468.588,315.449 467.266,315.449C465.939,315.449 465.624,315.449 463.763,310.271L455.723,286.058C454.99,283.852 452.61,282.656 450.399,283.388C448.192,284.121 446.997,286.504 447.729,288.711L455.723,312.797L455.837,313.118C457.648,318.158 459.7,323.871 467.266,323.871C474.82,323.871 476.873,318.174 478.683,313.147L483.027,300.074L487.25,312.797L487.363,313.115C489.174,318.157 491.226,323.871 498.792,323.871C506.347,323.871 508.399,318.174 510.209,313.147L518.328,288.711C519.062,286.504 517.866,284.121 515.659,283.388Z" style="fill:{{nameColor}};fill-rule:nonzero;"/>
		    </g>
		  </g>
	</svg>
</div>