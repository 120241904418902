import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { UiTextareaWidgetComponent } from './ui-textarea-widget/ui-textarea-widget.component';
import { UiIconModule } from './../ui-icon/ui-icon.module';

@NgModule({
  declarations: [UiTextareaWidgetComponent], 
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UiIconModule
  ],
  exports: [
  	UiTextareaWidgetComponent
  ]
})
export class UiTextareaModule { }