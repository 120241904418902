<div id="settings-page-component" class="container-fluid full-page-container-fluid" [ngClass]="{'activate-child':activeChild}">
	<rv-component-loader-widget [is-loading]="isLoading">
		<div class="full-page-inner-container">
			<div class="container-fluid page-title-bar">
				<div class="row">
					<div class="col">
						<h5 class="font-color--purple rv-heading-sm">Settings</h5>
					</div>
				</div>
			</div>
		</div>			
		<div class="full-page-inner-container expands pt-4 pb-4">
			<div *ngIf="!activeChild" class="container-fluid settings-selector mt-1 pt-3">
				<div class="row">
					<div class="col-xs-24">
						<ul>
							<li *ngFor="let setting of config.options" [hidden]="!setting.enabled">
								<rv-settings-widget [setting]="setting" (onActivate)="activateChild($event)"></rv-settings-widget>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="container-fluid settings-stage">
				<div class="row">
					<div class="col-24 settings-forms" [ngSwitch]="activeChild">
						<rv-settings-profile-form *ngSwitchCase="'users/settings/profile'" (onClose)="activateChild({})"></rv-settings-profile-form>
						<rv-settings-memory-chest-list *ngSwitchCase="'users/settings/memory-chests'" (onClose)="activateChild({})"></rv-settings-memory-chest-list>
						<rv-settings-notifications-selector *ngSwitchCase="'users/settings/notifications'" (onClose)="activateChild({})"></rv-settings-notifications-selector>
						<rv-settings-privacy-policy *ngSwitchCase="'users/settings/privacy-policy'" (onClose)="activateChild({})"></rv-settings-privacy-policy>
						<rv-settings-terms-conditions *ngSwitchCase="'users/settings/terms-conditions'" (onClose)="activateChild({})"></rv-settings-terms-conditions>
					</div>
				</div>
			</div>
		</div>
	</rv-component-loader-widget>
</div>