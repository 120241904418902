import { InjectionToken } from '@angular/core';

export interface UserNotificationsConfig {
	groups: Array<{
		group: string;
		children: Array<{
			key: string;
			opts?: Array<string>;
			is_multi_opts?: boolean;
			is_required?: boolean;
			label: string;
			descrip: string;
		}>
	}>
}

export const UserNotificationsConfig: UserNotificationsConfig = {
	groups: [
		{
			group: 'Comments & Mentions',
			children: [
				{
					key: 'comments.mentions',
					opts: ['push','sms','email'],
					is_multi_opts: true,
					is_required: false,
					label: "New Mention",
					descrip: "Someone wants to talk to you!  We'll send you a notification with the Memory Chest & comment details."
				},
			]
		},
		{
			group: 'Members & Invites',
			children: [
				{
					key: 'members.invites',
					opts: ['push','sms','email'],
					is_multi_opts: true,
					is_required: false,
					label: "New Member Invited",
					descrip: "We'll send you a notification anytime a new member is invited to a Memory Chest that you're a part of."
				},
			]
		}
	]
}

export const USER_NOTIFICATIONS_CONFIG = new InjectionToken<string>('pages.user-notifications.config');