import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { ProfileService } from './../../models/users/profile.service'
import { ModelInstance } from '@getrearview/model-builder';

@Injectable({
  providedIn: 'root'
})
export class AccountPageResolver implements Resolve<boolean> {

	private run (): Promise<boolean>
	{
		return new Promise(async (resolve, reject) => {
			let isResolved: boolean = false;
			if (!this.ProfileSrvc.retrieve()) {
				const Profile: Array<ModelInstance> = await this.ProfileSrvc.search({})
				this.ProfileSrvc.set(Profile.shift());
				this.ProfileSrvc.store();
				isResolved = true;
			}
			resolve(isResolved);
		});
	}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return Observable.create(observer => {
				this.run().then(resp => {
					observer.next(resp);
					observer.complete();
				},rej => {
					console.error("rejected!", rej);
					observer.error(new Error("Oh no!  An error occurred while attempting to load your account page.  We'll get this checked out right away!"));
					observer.complete();
				});
			});

  }

  constructor (private ProfileSrvc: ProfileService)
  {

  }
}
