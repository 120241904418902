import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InterfaceDirectivesModule } from './directives/interface-directives.module';
import { DropdownContentDirective } from './directives/dropdown-content.directive';
import { DropdownContainerDirective } from './directives/dropdown-container.directive';

@NgModule({
  declarations: [],
  imports: [
    InterfaceDirectivesModule
  ],
  exports: [
  	DropdownContentDirective,
  	DropdownContainerDirective
  ]
})
export class InterfaceCoreModule { }
