import { Component, QueryList, OnInit, Input, TemplateRef, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MemoryChestTagService } from 'src/app/models/memories/memory-chest-tag.service';
import { ModelInstance } from '@getrearview/model-builder';

// interface MultiSelectOption {
// 	model: ModelInstance,
// 	template: TemplateRef<any>
// };

@Component({
  selector: 'rv-hashtag-selector',
  templateUrl: './hashtag-selector.component.html',
  styleUrls: ['./hashtag-selector.component.scss']
})
export class HashtagSelectorComponent implements OnInit {

	@ViewChildren('templates') templates;
	@ViewChildren('selectables') selectables;

	@Input('formGroup') formGroup: FormGroup;
	@Input('control') control: FormControl;
	@Input('controlName') controlName: string;
	@Input('hideIfEmpty') hideIfEmpty: boolean;
	
	@Input('chestId')
	chestId: string;
	_chestId: string;

	Hashtags: Array<ModelInstance> = [];
	isHidden: boolean = false;
	viewHasInit: boolean = false;

	async fetch () 
	{
		if (this.chestId && this._chestId!==this.chestId) {
			this._chestId = this.chestId;
			this.MemoryChestTagSrvc
							.search({chest_id: this.chestId},{do_not_store: true})
							.then(Response => {
								this.Hashtags = Response?.models||[]
								this.isHidden = this.hideIfEmpty === true ? this.Hashtags.length < 2 : false;
								this.cd.detectChanges();
							});
		}
	}

	constructor (private cd: ChangeDetectorRef, private MemoryChestTagSrvc: MemoryChestTagService) 
	{}

	ngOnInit (): void
	{
		this.isHidden = this.hideIfEmpty === true;
	}

	ngOnChanges (): void
	{}

	ngAfterViewInit (): void
	{}

	ngAfterContentInit (): void
	{
		this.fetch();
		this.cd.detectChanges();
	}
}
