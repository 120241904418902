import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ModelInstance } from '@getrearview/model-builder';

@Component({
  selector: 'rv-milestone-deleter-widget',
  templateUrl: './milestone-deleter-widget.component.html',
  styleUrls: ['./milestone-deleter-widget.component.scss']
})
export class MilestoneDeleterWidgetComponent implements OnInit {

	@Input('MemoryChest')
	MemoryChest: ModelInstance;

	@Input('Me')
	Me: ModelInstance;

	@Input('Milestone')
	Milestone: ModelInstance;

	@Output('onSuccess')
	onSuccess: EventEmitter<ModelInstance> = new EventEmitter<ModelInstance>();

	@Output('onCancel')
	onCancel: EventEmitter<void> = new EventEmitter<void>();

	delete (): void
	{
		try { this.Milestone.delete(); }
		catch (ex) { console.error(`Failed to delete milestone. ${ex}`); }

		this.onSuccess.next(this.Milestone);
	}

	constructor ()
	{}

	ngOnInit (): void
	{}
}
