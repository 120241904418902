import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private baseUrl: string = environment.socialProviders.authServerUrl;

  constructor (private http: HttpClient, private token: TokenService) 
  {}

  signup (data: any) {
  	console.log("Use of deprecated method ApiService.signup().");
    return this.http.post<any>(`${this.baseUrl}/register`, data)
  }

  register (data: any) {
    return this.http.post<any>(`${this.baseUrl}/register`, data)
  }

  signin (data: any) {
  	console.log("Use of deprecated method ApiService.signin().");
    return this.http.post(`${this.baseUrl}/login`, data)
  }

  login (data: any) {
    return this.http.post(`${this.baseUrl}/login`, data)
  }

  profile () {
    return this.http.get(`${this.baseUrl}/profile`)
  }

  validateInviteToken (InviteToken) {
  	return this.http.post<any>(`${this.baseUrl}/invite-tokens/validate`, InviteToken.payload());
  }

  consumeInviteToken (InviteToken) {
  	return this.http.post<any>(`${this.baseUrl}/invite-tokens/consume`, InviteToken.payload());
  }
}
