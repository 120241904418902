import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModelFactoryConfig, ModelService } from '@getrearview/model-builder';
import { ConfigService } from './../../shared/services/config.service';
import { MemoryService } from './memory.service';
import { MemberService } from './member.service';
import { MilestoneService } from './milestone.service';
import { MemoryChestTagService } from './memory-chest-tag.service';
import { InviteTokenService } from './invite-token.service';

@Injectable({
  providedIn: 'root'
})
export class MemoryChestService extends ModelService {

  constructor (injector: Injector, httpClient: HttpClient) 
  {
  	let ConfigSrvc: ConfigService = injector.get<any>(ConfigService);
		let MFC: ModelFactoryConfig = (new ModelFactoryConfig()).setAll({
  				// Model Name
  				name: "Memory Chest",
  				// Model Endpoint
					endpoint: ConfigSrvc.get("api.api_url")+"/memories/memory-chests",
					// Model Fields
					fields: ["label"],
					// Model `Permitted` Fields
					permitted: ["label"],
					// Model `Required` Fields
					required: [],
					// Model Field Labels
					labels: {},
					// Model Field Validation Patterns
					patterns: {},
					// Parent ID Field Name
					parent_id_field: null,
					// Model Children Types
					children_types: {
						memory: injector.get<any>(MemoryService),
						milestone: injector.get<any>(MilestoneService),
						tag: injector.get<any>(MemoryChestTagService),
						invite: injector.get<any>(InviteTokenService),
						member: injector.get<any>(MemberService),
					},
					// Model Association Types
					association_types: {"user": null},
					// Storage Type (local, session, cookies)
					storage_type: "local"
				});

  	super(MFC, httpClient);
  }
}