import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './../../core/guards/auth.guard';
import { UiComponent } from './ui/ui.component';
import { UiWidgetSelectorComponent } from './ui-widget-selector/ui-widget-selector.component';
import { UiButtonsComponent } from './ui-buttons/ui-buttons.component';
import { UiColorsComponent } from './ui-colors/ui-colors.component';
import { UiInputsComponent } from './ui-inputs/ui-inputs.component';
import { UiFontsComponent } from './ui-fonts/ui-fonts.component';
import { UiIconsComponent } from './ui-icons/ui-icons.component';
import { UiUploaderComponent } from './ui-uploader/ui-uploader.component';
import { UiMultiSelectComponent } from './ui-multi-select/ui-multi-select.component';
import { UiPaginationComponent } from './ui-pagination/ui-pagination.component';
import { UiLocationsComponent } from './ui-locations/ui-locations.component'

const routes: Routes = [
	{ 
		path:                       'ui',
		component:                  UiComponent,
		canActivate:                [AuthGuard],
		data: 											{animation:'uiPage'},
    children: 									[
    															{path: '', redirectTo: 'icons', pathMatch: 'full'},
													        { path: 'icons', component: UiIconsComponent, data: {animation:'uiPageIcons'} },
													        { path: 'fonts', component: UiFontsComponent, data: {animation:'uiPageFonts'} },
													        { path: 'buttons', component: UiButtonsComponent, data: {animation:'uiPageButtons'} },
													        { path: 'colors', component: UiColorsComponent, data: {animation:'uiPageColors'} },
													        { path: 'inputs', component: UiInputsComponent, data: {animation:'uiPageInputs'} },
													        { path: 'pagination', component: UiPaginationComponent, data: {animation:'uiPagePagination'} },
													        { path: 'uploader', component: UiUploaderComponent, data: {animation:'uiPageUploader'} },
													        { path: 'multi-select', component: UiMultiSelectComponent, data: {animation:'uiPageMultiSelect'} },
													        { path: 'locations', component: UiLocationsComponent, data: {animation:'uiLocations'} },
													      ]
	}
];

@NgModule({
  imports:                      [RouterModule.forChild(routes)],
  exports:                      [RouterModule]
})
export class UiRoutingModule { }