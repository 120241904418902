import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionUser } from './session/session-user.model';
import { AuthService } from './services/auth.service';
import { TokenService } from './services/token.service';
import { ApiService } from './services/api.service';
import { TheFutureAuthProvider } from './session/the-future-auth.provider';
import { SessionUserResolver } from './resolvers/session-user.resolver';
import { MessageBusService } from './services/message-bus.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
  	SessionUser, AuthService, TokenService, ApiService, TheFutureAuthProvider, SessionUserResolver, MessageBusService
  ]
})
export class CoreModule { }
