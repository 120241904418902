import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AuthService, SigninRequestResponse } from 'src/app/core/services/auth.service';
import { Platform } from '@ionic/angular';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { environment } from 'src/environments/environment';
import { filter } from 'rxjs';

@Component({
  selector: 'rv-google-signin-button',
  templateUrl: './google-signin-button.component.html',
  styleUrls: ['./google-signin-button.component.scss']
})
export class GoogleSigninButtonComponent {

	@Input('InviteToken')
	InviteToken;

	@Output('onSuccess')
	onSuccess: EventEmitter<SigninRequestResponse> = new EventEmitter();

	@Output('onError')
	onError: EventEmitter<SigninRequestResponse> = new EventEmitter();

	signIn ()
	{
		this.auth.login('GOOGLE', {InviteToken: this.InviteToken}).then(res => this.onSuccess.next(res), err => this.onError.next(err));
	}

	constructor (public platform: Platform, private auth: AuthService)
	{}

	ngOnInit (): void
	{
		
	}
}