import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ChestService } from './../shared/chest.service';

const NUM_MEMBERS = 5

@Component({
  selector: 'milestone-members',
  templateUrl: './milestone-members.component.html',
  styleUrls: ['./milestone-members.component.scss']
})
export class MilestoneMembersComponent implements OnInit {

	@Input('members')
	_members: Array<string> = [];

	host: string = '';

	get extra (): number
	{
		return this.total - this.members.length;
	}

	get total (): number
	{
		return this._members.length;
	}

	get members (): Array<string>
	{
		return (this._members||[]).slice(0,NUM_MEMBERS);
	}

  constructor (private ChestSrvc: ChestService) 
  {}

  ngOnInit (): void 
  {
  	this.host = environment.api.media_url;
  }
}
