import { Component, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { CognitoUserPool, CognitoUserAttribute, CognitoUser, AuthenticationDetails, CognitoUserSession, CodeDeliveryDetails } from 'amazon-cognito-identity-js';
import { environment } from './../../../../environments/environment'
import { FormBuilder, Validators } from '@angular/forms';
import { SessionUser } from './../../../core/session/session-user.model';
import { ApiService } from './../../../core/services/api.service';
import { AuthFormStates } from './AuthFormStates.enum';
import { AuthFormService } from './../shared/auth-form.service';
import { ConfigService } from './../../../shared/services/config.service';
import { TrackJsService } from './../../../shared/services/trackjs.service';
import { EMAIL as EMAIL_PATTERN } from './../../../shared/patterns'
import { Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


interface formDataInterface {
  "email": string;
  [key: string]: string;
};

interface cognitoUserAuthStore {
  "session"?: CognitoUserSession;
  "user"?: CognitoUser;
};

interface cognitoSignupRequest {
  "email": string; 
  "password": string; 
  "attributeList": Array<CognitoUserAttribute>
};

interface cognitoConfirmRequest {
  "email": string; 
  "code": string;
};

interface cognitoLoginRequest {
  "email": string; 
  "password": string;
};

interface cognitoResponseInterface {
  "status": boolean;
  "error": string;
};

interface cognitoAuthResponseInterface {
  "status": boolean;
  "error": string;
  "session"?: {[key: string]: any};
  "user"?: {[key: string]: any};
};

function getErrorCodeFromCognitoError (error: string): string
{
	// move these to central location.
	const ERROR_CODES = {
		INVALID_PASS: 			'incorrect username or password.',
		INSECURE_PASS: 			'password did not conform with policy',
		SHORT_PASS: 				'password did not conform with policy: password not long enough',
		LONG_PASS: 					'1 validation error detected: value at \'password\' failed to satisfy constraint: member must have length less than or equal to 256',
		USERNAME_EXISTS: 		'an account with the given email already exists.',
		TOO_MANY_ATTEMPTS: 	'password attempts exceeded',
		// LIMIT_EXCEEDED_EXCEPTION
	}

	error = `${error || ''}`.trim().toLowerCase();
	let errors = Object.keys(ERROR_CODES).filter(errCode => ERROR_CODES[errCode] === `${error}` || `${error}`.includes(ERROR_CODES[errCode]))
	error = (error = errors.shift()) ? error : "UNKNOWN_ERROR";

	return error;
}

async function cognitoUserSignup (request: cognitoSignupRequest): Promise<cognitoResponseInterface>
{
  const userPool 			= new CognitoUserPool({
									    			UserPoolId: environment.socialProviders.cognitoUserPoolId,
									    			ClientId: 	environment.socialProviders.cognitoAppClientId
									  			})

	return new Promise((resolve, reject) => {
		const {email,password,attributeList} = request;
    userPool.signUp(email, password, attributeList, [], (error, data) => {
    	let response: cognitoResponseInterface = {status: false, error: ''};

      if (error) {
      	response.error = (error.message || JSON.stringify(error));
      	return resolve(response);
      }

      response.status = true;
      resolve(response);
    });
	})
};

async function cognitoUserConfirm (request: cognitoConfirmRequest): Promise<cognitoResponseInterface>
{
  const userPool 			= new CognitoUserPool({
									    			UserPoolId: environment.socialProviders.cognitoUserPoolId,
									    			ClientId: 	environment.socialProviders.cognitoAppClientId
									  			}),
  			cognitoUser 	= new CognitoUser({
  													Username: request.email, 
  													Pool: userPool
  												});

	return new Promise((resolve, reject) => {
  	cognitoUser.confirmRegistration(request.code, true, (error, data) => {
    	let response: cognitoResponseInterface = {status: false, error: ''};

      if (error) {
      	response.error = (error.message || JSON.stringify(error));
      	return resolve(response);
      }

      response.status = true;
      resolve(response);
		});
  });
};

async function cognitoLogin (request: cognitoLoginRequest): Promise<cognitoAuthResponseInterface>
{
	return new Promise((resolve, reject) => {
		let authenticationDetails = new AuthenticationDetails({
		  Username: request.email,
		  Password: request.password,
		});

		let poolData = {
		  UserPoolId: environment.socialProviders.cognitoUserPoolId,
		  ClientId: environment.socialProviders.cognitoAppClientId
		};

		let userPool = new CognitoUserPool(poolData);
		let userData = { Username: request.email, Pool: userPool };
		var cognitoUser = new CognitoUser(userData);

		cognitoUser.authenticateUser(authenticationDetails, {
		  onSuccess: function (session: CognitoUserSession) {
	    	const response: cognitoAuthResponseInterface = {status: true, error: '', session, user: cognitoUser};
	      return resolve(response);

		  },
		  onFailure: function (error) {
	    	error = (error && typeof error === 'object' && error?.error) ? error?.error : error;
	    	let response: cognitoAuthResponseInterface = {status: false, error, user: undefined, session: undefined};
	      return reject(response);
		  },
		});
	});
};


@Component({
  selector: 'rv-auth-form',
  template: '<p>Rearview Authentication Form</p>',
})
export class AuthFormComponent {

	// Boolean flag indicating if the login/forgot
	// pass/reset/sign up forms are being submitted.
	isSubmitting: boolean = false;

	// Boolean flag indicating if the 'email_or_phone'
	// field should be displayed when verifying code.
	showEmail: boolean = false;

	private errors: Array<string> = [];

	_subscriptions$ = new Subscription();

	authForm = this.fb.group({
		email_or_phone: 		['', [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
		password: 					['', [Validators.required]]
	});

	resetForm = this.fb.group({
		code: 							['', [Validators.required]],
		password: 					['', [Validators.required]]
	});

	confirmForm = this.fb.group({
		email_or_phone: 		['', [Validators.required]],
		code: 							['', [Validators.required]],
		password: 					['', [Validators.required]]
	});

	registerForm = this.fb.group({
		name: 							['', [Validators.required]],
		email_or_phone: 		['', [Validators.required]],
		password: 					['', [Validators.required]],
		code: 							['', [Validators.required]]
	});

	@Output('onStateChange') 
	onStateChange = new EventEmitter();

	goToLogin = () => 
		this.AuthFormSrvc.state = AuthFormStates.LOGIN;

	goToLoginForm = () => 
		this.AuthFormSrvc.state = AuthFormStates.LOGIN_FORM;

	goToSignup = () =>
		this.AuthFormSrvc.state = AuthFormStates.SIGNUP;

	goToSignupForm = () =>
		this.AuthFormSrvc.state = AuthFormStates.SIGNUP_FORM;

	goToForgotPassword = () =>
		this.AuthFormSrvc.state = AuthFormStates.FORGOT_PASSWORD;

	goToForgotPasswordConfirm = () =>
	{
		this.AuthFormSrvc.state = AuthFormStates.FORGOT_PASSWORD_CONFIRM;
	}

	isAuthState = state =>
		state && AuthFormStates[this.AuthFormSrvc.state] === `${state}`.trim().toUpperCase();

	hasManualError = (): boolean => 
		this.errors.length > 0;

	isManualError = (err): boolean => 
		this.errors.indexOf(`${err}`.trim().toUpperCase()) > -1;

	allErrors = () =>
		this.errors

	reset = (inclForms?: boolean) => 
	{
		this.errors = [];

		if (inclForms === true) {

        // this.yourForm.form.markAsPristine();
        // this.yourForm.form.markAsUntouched();
        // this.yourForm.form.updateValueAndValidity();
			this.authForm.markAsPristine();
			this.authForm.markAsUntouched();
			this.authForm.updateValueAndValidity();
			this.resetForm.markAsPristine();
			this.resetForm.markAsUntouched();
			this.resetForm.updateValueAndValidity();
			this.registerForm.markAsPristine();
			this.registerForm.markAsUntouched();
			this.registerForm.updateValueAndValidity();
		}
	}

	private _handleSuccess = (response?: any, next?: AuthFormStates): void =>
	{
		const cognito: cognitoUserAuthStore = response && typeof response === 'object' ? response : {};

		switch (this.AuthFormSrvc.state)
		{
			case 1:
			case AuthFormStates.LOGIN:
			case AuthFormStates.LOGIN_FORM:
				this.AuthFormSrvc.notify = "Great!  We'll send you to your favorite memory chest momentarily.";
				this.reset(true);
				break;
			case 3:
			case AuthFormStates.FORGOT_PASSWORD:
				this.AuthFormSrvc.notify = "We've sent an email or text message with a code to reset your password.";
				this.AuthFormSrvc.state = AuthFormStates.FORGOT_PASSWORD_CONFIRM;
				break;
			case 4:
			case AuthFormStates.FORGOT_PASSWORD_CONFIRM:
				this.AuthFormSrvc.notify = "Great!  Your password has been reset.  You will be redirected to login momentarily.";
				break;
			case 5:
			case AuthFormStates.SIGNUP:
				this.AuthFormSrvc.notify = "We've sent an email or text message with a code to complete your registration.";
				this.AuthFormSrvc.state = AuthFormStates.SIGNUP_CONFIRM;
				break;
			case 6:
			case AuthFormStates.SIGNUP_CONFIRM:
				this.AuthFormSrvc.notify = "Great!  Your account has been created.  We'll send you to your account momentarily.";
				break;
		}
	}

	private _handleFailure = (response: any): boolean =>
	{
		this.isSubmitting = false;
		return (this.errors = [response]) && false;
	}

	private _handleResponse = (response: any, next?: AuthFormStates): void|boolean =>
	{
		this.isSubmitting = false;

		// Successes - - - - - - - - - - - - - - - - - - -
		// Forgot Pass Confirmation: Success
		// Registration Confirmation: Success <ISignUpResult> ?? 
		if (response === 'SUCCESS')
			return this._handleSuccess();

		if (response && typeof response === 'object') {

			// Forgot Password: response object.
			if (response?.CodeDeliveryDetails && this.AuthFormSrvc.state === AuthFormStates.FORGOT_PASSWORD)
				return this._handleSuccess();

			// Login: response object.
			if (response instanceof CognitoUser && this.AuthFormSrvc.state === AuthFormStates.LOGIN) {
				return this._handleSuccess();
			}

			// Registration: response object.
			if (response?.userSub && this.AuthFormSrvc.state === AuthFormStates.SIGNUP) {
				return this._handleSuccess();
			}

			// Cognito Login: response object
			if (response?.session && response?.user && this.AuthFormSrvc.state === AuthFormStates.LOGIN_FORM) {
				return this._handleSuccess(response);
			}
		}



		// Errors - - - - - - - - - - - - - - - - - - - - 
		// Too many tries to update password.
		if (response === "TOO_MANY_TRIES")
			return this._handleFailure(response);

		// Invalid Password on login attempt
		if (response === "INVALID_PASS" || (response?.error === "INVALID_PASS"))
			return this._handleFailure("INVALID_PASS");

		if (response === "TOO_MANY_ATTEMPTS" || (response?.error === "TOO_MANY_ATTEMPTS"))
			return this._handleFailure("TOO_MANY_ATTEMPTS");

		// Invalid verification code when confirming reset password.
		if (response === "INVALID_CODE")
			return this._handleFailure(response);

		// Registration: Username already in use.
		if (response === "USERNAME_EXISTS" || (response?.error === "USERNAME_EXISTS"))
			return this._handleFailure("USERNAME_EXISTS");

		// 

		this.TrackJsSrvc.track("Unknown error occurred during AWS authentication request. ",{response, formStateConfig: this.AuthFormSrvc.formStateConfig});
		return this._handleFailure("UNKNOWN_ERROR");
	}
	
	handleLogin = async () =>
	{
		if (!this.authForm.valid) return;
		this.isSubmitting = true;

		const pass = async (cognitoLoginResponse: cognitoAuthResponseInterface): Promise<any> => 
		{
			return new Promise(async (resolve, reject) => {
				await this.AuthApi
											.login({idToken: cognitoLoginResponse?.session?.idToken?.jwtToken, provider: 'COGNITO'})
											.subscribe(
											  (data: any) => {
											  	this.SessionUser.token(data.access_token, 'COGNITO').finally(() => {
											  		resolve(true);
											  		this._handleResponse(cognitoLoginResponse)
											  	})
											  },
											  (error) => {
											  	reject(error);
											  }
											);
			});
		}

		try {
			await pass(await cognitoLogin({email: this.authForm.get('email_or_phone').value, password: this.authForm.get('password').value}));
		}
		catch (ex) {
			return this._handleFailure(getErrorCodeFromCognitoError(((ex && typeof ex === 'object' && ex?.error) || `${ex}`))); // || 'UNKNOWN_ERROR'
		}
	}

	handleRequestResetPass = async () =>
	{
		if (!this.authForm.get('email_or_phone').valid) return;
		const email: string = this.authForm.get('email_or_phone').value;
		this.isSubmitting = true;

    // setup cognitoUser first
		const poolData = {
					  UserPoolId: environment.socialProviders.cognitoUserPoolId,
					  ClientId: 	environment.socialProviders.cognitoAppClientId
					},
					userPool = new CognitoUserPool(poolData),
					userData = { Username: email, Pool: userPool },
    			cognitoUser = new CognitoUser(userData);

    // call forgotPassword on cognitoUser
    let delivery;
		try {
		  delivery = await (new Promise((resolve,reject) => {
				cognitoUser.forgotPassword({
				  onSuccess: function (delivery: CodeDeliveryDetails) {
				  	resolve(delivery)
				  },
				  onFailure: function (error) {
				  	reject(error)
				  },
				});
		  }))
		}
		catch (ex) {
    	let err = (ex && typeof ex === 'object' && ex?.name) ? ex?.name : ex;
    	// let response: cognitoAuthResponseInterface = {status: false, error, user: undefined, session: undefined};
    	return this._handleFailure(getErrorCodeFromCognitoError(`${(err ? `${err}`.replace(/(?:^|\.?)([A-Z])/g, function (x,y){return "_" + y.toLowerCase()}).replace(/^_/, "").toUpperCase() : 'UNKNOWN_ERROR')}`));			
		}

		return this._handleSuccess();
	}

	handleVerifyResetPass = async () => 
	{
		if (!this.confirmForm.valid) return;
		this.isSubmitting 				= true;

		// 655313
		const email: string 				= 	this.confirmForm.get('email_or_phone').value, 
					code: string 					= 	this.confirmForm.get('code').value, 
					password: string 			= 	this.confirmForm.get('password').value;

    // setup cognitoUser first
		const poolData = {
					  UserPoolId: environment.socialProviders.cognitoUserPoolId,
					  ClientId: 	environment.socialProviders.cognitoAppClientId
					},
					userPool = new CognitoUserPool(poolData),
					userData = { Username: email, Pool: userPool },
    			cognitoUser = new CognitoUser(userData);

    // call confirmPassword on cognitoUser
    let confirmed;
		try {
		  await (new Promise((resolve,reject) => {
				cognitoUser.confirmPassword(code, password, {
				  onSuccess: function () {
				  	resolve(true)
				  },
				  onFailure: function (error) {
				  	reject(error)
				  },
				});
		  }))
		}
		catch (ex) {
			console.error(`${ex}`);
    	let err = (ex && typeof ex === 'object' && ex?.name) ? ex?.name : ex;
    	// let response: cognitoAuthResponseInterface = {status: false, error, user: undefined, session: undefined};
    	return this._handleFailure(getErrorCodeFromCognitoError(`${(err ? `${err}`.replace(/(?:^|\.?)([A-Z])/g, function (x,y){return "_" + y.toLowerCase()}).replace(/^_/, "").toUpperCase() : 'UNKNOWN_ERROR')}`));			
		}

		return this._handleSuccess();

		// this._handleResponse(await this.SessionUser.forgotPasswordSubmit((this.resetForm.get('email_or_phone') && this.resetForm.get('email_or_phone').value || this.AuthFormSrvc.emailOrPhone), this.resetForm.get('code').value, this.resetForm.get('password').value));
	}

	handleSignup = async () => 
	{
// 		this.myFormControl.setValidators([
//   Validators.required,
//   Validators.minLength(5)
// ]);
// this.myFormControl.updateValueAndValidity();

		const addValidation = () => this.registerForm.get('code').setValidators([Validators.required]),
					removeValidation = () => this.registerForm.get('code').setValidators(null),
					validateSignupMode = () =>
					{
						this.registerForm.get('name').setValidators([Validators.required]);
						this.registerForm.get('email_or_phone').setValidators([Validators.required]);
						this.registerForm.get('password').setValidators([Validators.required]);
						this.registerForm.get('code').setValidators(null);
					},
					validateConfirmMode = () => 
					{
						this.registerForm.get('name').setValidators(null);
						this.registerForm.get('email_or_phone').setValidators([Validators.required]);
						this.registerForm.get('password').setValidators(null);
						this.registerForm.get('code').setValidators([Validators.required]);
					},
					updateValidation = add => { add ? addValidation() : removeValidation(); this.registerForm.get('code').updateValueAndValidity(); };

		updateValidation(this.AuthFormSrvc.state !== 5);

		if (!this.registerForm.valid) {
			return;
		}
		this.isSubmitting 			= true;

    const attributeList: Array<CognitoUserAttribute> 	= 	[],
    			formData: formDataInterface 								= 	{"email": this.registerForm.get('email_or_phone').value};
    attributeList.push(new CognitoUserAttribute({Name: "email", Value: this.registerForm.get('email_or_phone').value}));
    attributeList.push(new CognitoUserAttribute({Name: "name", Value: this.registerForm.get('name').value}));

    const signupResponse: cognitoResponseInterface = await cognitoUserSignup({"email": this.registerForm.get('email_or_phone').value, "password": this.registerForm.get('password').value, attributeList})

    this.isSubmitting 			= false;

    if (!signupResponse.status)
    	return this._handleFailure(getErrorCodeFromCognitoError(signupResponse?.error));

		this._handleSuccess();
	}

	handleSignupConfirm = async () => 
	{
		if (!this.registerForm.valid) return;
		this.isSubmitting 			= true;

    const confirmResponse: cognitoResponseInterface = await cognitoUserConfirm({"email": this.registerForm.get('email_or_phone').value, "code": this.registerForm.get('code').value})

    this.isSubmitting 			= false;

    if (!confirmResponse.status)
    	return this._handleFailure(getErrorCodeFromCognitoError(confirmResponse?.error));

		this._handleSuccess();

		const authResponse = await cognitoLogin({
															email: 		this.registerForm.get('email_or_phone').value, 
															password: this.registerForm.get('password').value
														});

		if (authResponse.status && authResponse?.session) {
	    const signupApi = await this.AuthApi
	    			.register({idToken: authResponse?.session.idToken.jwtToken, provider: 'COGNITO'})
	    			.subscribe((data: any) => {
						    if (data.success === true && data?.access_token)
						    	this.SessionUser.token(`${data.access_token}`, 'COGNITO');
	    				},(error) => {
	    					console.error(error); 
	    				});
		}

		this.goToLogin();
	}

  constructor (protected SessionUser: SessionUser, protected AuthApi: ApiService, protected AuthFormSrvc: AuthFormService, protected ConfigSrvc: ConfigService, protected fb: FormBuilder, protected TrackJsSrvc: TrackJsService) 
  {}
}