<form id="milestone-creator-widget-component" class="container-fluid" [formGroup]="milestoneForm">
	<!--
		share with people
			- access your contacts.
			- invite people that arent currently members.
	-->
	<div class="row">
		<div class="col-24">
			<rv-input sm [disabled]="isEditing||isFetching" [formGroup]="milestoneForm" placeholder="Milestone Name" name="nickname" controlName="nickname" iconLeft="education.open-book"></rv-input>
			<!-- white lg open outline_disable class="fw-bold" -->
		</div>
	</div>

	<div class="row">
		<div class="col-24">
			<!-- <rv-location-selector [formGroup]="milestoneForm" [control]="milestoneForm.get('place_id')" controlName="Where?" /><br /> -->
			<!-- <rv-input sm [disabled]="isEditing||isFetching" [formGroup]="milestoneForm" placeholder="When's this take place?" name="start_at" controlName="start_at" (focus)="onFocus()" (blur)="onBlur()" iconLeft="education.open-book"></rv-input> -->
			<!-- <rv-input sm type="datetime-local" [disabled]="isEditing||isFetching" [formGroup]="milestoneForm" placeholder="When's this take place?" name="start_at" controlName="start_at" (focus)="onFocus()" iconLeft="education.open-book" /> -->
			<rv-datepicker [disabled]="isEditing||isFetching" [formGroup]="milestoneForm" placeholder="When's this take place?" name="start_at" controlName="start_at" iconLeft="business.calendar" />
			<!-- white lg open outline_disable class="fw-bold" -->
		</div>
	</div>

	<div class="row">
		<div class="col-24">
			<rv-location-selector [formGroup]="milestoneForm" [control]="milestoneForm.get('place_id')" controlName="place_id" /><br />
			<!-- white lg open outline_disable class="fw-bold" -->
			<!-- <rv-icon group='location-and-map' icon='add-location'></rv-icon> -->
		</div>
	</div>

	<div class="row mt-4">
		<div class="col-12">
			<rv-button outline purple md block label="Cancel" (click)="onCancel.emit()"/>
		</div>
		<div class="col-12">
			<rv-button [purple]="milestoneForm.valid" [gray]="!milestoneForm.valid" md block label="Create" (click)="milestoneForm.valid && saveMilestone()" />
		</div>
	</div>
</form>
