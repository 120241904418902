<div id="profile-page-component" class="container-fluid full-page-container-fluid">
	<rv-component-loader-widget [is-loading]="isLoading">
		<div class="full-page-inner-container">
			<div class="container-fluid page-title-bar">
				<div class="row">
					<div class="col"> 
						<h5 class="font-color--purple rv-heading-sm">My Profile</h5>
					</div>
				</div>
			</div>
		</div>			
		<div class="full-page-inner-container expands bg-color--white">
			<div class="container-fluid mt-1 pt-3 pb-3">
				<div class="row">
					<div class="col-6 text-center">
						<rv-image-uploader [Profile]="Profile" (onMediaUpdate)="onMediaUpdate($event)">
							<div class="profile-img">
								<img *ngIf="profileUrl" src="{{profileUrl}}" />
							</div>
						</rv-image-uploader>
					</div>
					<div class="col-18">
						<h6 class="font-color--dk-gray-5">{{Profile.attribs.name}}</h6>
						<span class="font-color--dk-gray-2 rv-copy rv-copy-14">Started making memories on</span><br />
						<span class="font-color--dk-gray-2 rv-copy rv-copy-14">{{Profile.attribs.created_at|date:birthDateFormat}}</span>
					</div>
				</div>
			</div>
			<div class="container-fluid mt-1 pt-3">
				<div class="row">
					<div class="col-24">
						<rv-profile-form [Profile]="Profile" (onSuccess)="onSuccess($event)"></rv-profile-form>
					</div>
				</div>
			</div>
		</div>
	</rv-component-loader-widget>
</div>