import { Component, Output, Input, EventEmitter } from '@angular/core';
import { StoryService } from './../shared/story.service';

@Component({
  selector: 'rv-next-btn',
  templateUrl: './next-btn.component.html',
  styleUrls: ['./next-btn.component.scss']
})
export class NextBtnComponent {

	@Output('onClick')
	onClick: EventEmitter<void> = new EventEmitter();

	get isDisabled (): boolean
	{
		return !this.storySrvc.Next;
	}

	constructor (public storySrvc: StoryService) 
	{}
}