<div id="ui-page-component" class="container-fluid page-container-fluid">
	<div class="row">
		<div class="col-24 text-center">
			<h6>Rearview UI Components</h6>
			<p style="text-align: center;">
				<button routerLink='/account'>Account</button>
				<button routerLink='/blank'>Blank</button>
				<button routerLink=''>Feed</button>
				<button routerLink='/profile'>Profile</button>
				<button disabled routerLink='/ui' >UI Components</button>
			</p>
		</div>
		<div class="col-24">
			<ui-widget-selector></ui-widget-selector>
		</div>
		<div class="col-24">
			<router-outlet></router-outlet>
		</div>
	</div>
</div>
