<div id="privacy-policy-component">
	<rv-link (click)="onClose.emit()" label='Back to Settings' iconLeft='arrows.left-arrow' class='mb-4' />
	<rv-component-loader-widget [is-loading]="isLoading">
		<div class="container-fluid">
			<div *ngIf="canSave; else viewOnly" class="row">
				<div class="col-24">
					<div class="font-color--blue text-left rv-heading-xs">Rearview Privacy Policy</div>
				</div>
				<form class="col-24" [formGroup]="policyForm">
					<editor disabled="true" apiKey="lkgybjhrjg3njlcnixt23jiws1q0r5hwelhnegzvv6khhr94" formControlName="policy" [init]="{ branding: false, plugins: 'lists link image table code help wordcount' }"></editor>
				</form>
				<rv-button (click)="save()" sm label='Save Policy' iconRight='arrows.right-arrow'></rv-button>
				<rv-button (click)="onClose.emit()" outline sm label='Cancel' iconRight='arrows.right-arrow'></rv-button>
			</div>
			<ng-template #viewOnly>
				<div class="row">
					<div class="col-24">
						<div class="font-color--blue text-left rv-heading-xs">Rearview Privacy Policy</div>
					</div>
					<div class="col-24" [formGroup]="policyForm">
						<div [innerHTML]="policyForm.get('policy').value"></div>
					</div>
				</div>
			</ng-template>
		</div>
	</rv-component-loader-widget>
</div>