<div class="container-fluid memory-widget-component bg-color--grey-100">
	<div class="row" [ngClass]="{'is-flipped':isFlipped, 'mem-1c': isFullWidth, 'mem-2c': !isFullWidth}">
		<div class="col">
			{{Memory.get('body')}}
		</div>
		<div class="col">
			<rv-memory-media-router 
				[Memory]="Memory" 
				[Medias]="Memory&&Memory.getChildren('media')"
				[IsMultiImage]="isMultiImage" 
				[MediaIds]="mediaIds"
				*ngIf="!isMultiImage" 
				(onDelete)="onDelete($event)"
				[quantity]="numOfMedia"
			/>
		</div>
	</div>
	<div class="row multiImageContainer">
		<rv-memory-media-router 
			[Memory]="Memory" 
			[Medias]="Memory&&Memory.getChildren('media')"
			[IsMultiImage]="isMultiImage"
			[MediaIds]="mediaIds"
			*ngIf="isMultiImage" 
			(onDelete)="onDelete($event)"
			[quantity]="numOfMedia"
		/>
	</div>
	<div class="row">
		<rv-comments-indicator
			[Memory]="Memory" 
			[showComments]="showComments" 
			(onShowComments)="onShowComments($event)" 
		/>
		<rv-comments 
			[Memory]="Memory" 
			[showComments]="showComments" 
		/>
	</div>
</div>