import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MembersListComponent } from './members-list/members-list.component';
import { MemberPaneComponent } from './member-pane/member-pane.component';


@NgModule({
  declarations: [MembersListComponent, MemberPaneComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  exports: [
  	MembersListComponent,
  	MemberPaneComponent
  ]
})
export class MemoryChestMembersWidgetModule { }