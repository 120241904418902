import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionUser } from './../session/session-user.model';
import { ProfileService } from './../../models/users/profile.service';

@Injectable()
export class SessionUserResolver implements Resolve<Observable<void>> {
	private async profile (): Promise<void>
	{
		if (this.SessionUser.instance)
			return Promise.resolve();

		try { await this.SessionUser.getInstance(); }
		catch (ex) { return Promise.reject(); }

		if (this.SessionUser.instance)
			return Promise.resolve();		

		return Promise.reject();
	}

	protected run (): Promise<void|string>
	{
		return new Promise((resolve, reject) => resolve());
	}

	resolve (route: ActivatedRouteSnapshot): Observable<void>
	{
		return Observable.create(observer => {
				this.profile().then(() => {
					observer.next();
					observer.complete();
				},rej => {
					observer.error(new Error("Oh no!  An error occurred while attempting to load the page.  We'll get this checked out right away!"));
					observer.complete();
				});
			});
	}

  constructor(
  		public ProfileSrvc: ProfileService, 
  		public SessionUser: SessionUser, 
  		public router: Router
  	) 
  {}
}