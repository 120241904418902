import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileFormComponent } from './profile-form/profile-form.component';
import { ProfilePageComponent } from './profile-page/profile-page.component';
import { LoadersModule } from './../../widgets/loaders/loaders.module';
import { MemberModule } from 'src/app/widgets/member/member.module';
import { RearviewInterfaceModule } from 'src/app/shared/interface/rearview-interface.module';
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';

@NgModule({
  declarations: [ProfileFormComponent, ProfilePageComponent, ImageUploaderComponent],
  imports: [
    CommonModule,
    ProfileRoutingModule,
    ReactiveFormsModule,
    LoadersModule,
    MemberModule,
    RearviewInterfaceModule
  ]
})
export class ProfileModule { }
