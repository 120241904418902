import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { environment } from './../../../environments/environment'
import { AuthService } from './../services/auth.service';
import { TokenService } from './../services/token.service';
import { SocialAuthService, FacebookLoginProvider } from '@abacritt/angularx-social-login';

function getCognitoUser (): CognitoUser | null
{
	return (new CognitoUserPool({
		UserPoolId: environment.socialProviders.cognitoUserPoolId,
		ClientId: 	environment.socialProviders.cognitoAppClientId
	}))?.getCurrentUser();
}

function getFacebookUser (): Promise<any | null>
{
	// return (new CognitoUserPool({
	// 	UserPoolId: environment.socialProviders.cognitoUserPoolId,
	// 	ClientId: 	environment.socialProviders.cognitoAppClientId
	// }))?.getCurrentUser();
	return Promise.resolve();
}

@Injectable()
export class TheFutureAuthProvider {

	private _provider;
	private _providerName!: string;

	get provider (): string
	{
		return this._providerName;
	}

	private async setProvider (provider: string): Promise<void>
	{
		if (['COGNITO','FACEBOOK','GOOGLE'].includes(`${provider||''}`.trim().toUpperCase())) {
			this._providerName = `${provider||''}`.trim().toUpperCase();
		}
		else {
			this._providerName = '';
			// throw new Error(`Invalid provider supplied. [PROVIDER: ${provider}]`);
		}

		return Promise.resolve();
	}

	private async loadProvider (): Promise<void>
	{
		let provider!: string;

		try {
			provider = await this.TokenSrvc.provider();
		}
		catch (ex) {
			console.error(`Failed to load provider. ${ex}`);
			provider = '';
			return Promise.resolve();
		}

		this.setProvider(provider);
	}

	async isTokenValid (): Promise<boolean>
	{
		return await this.TokenSrvc.isValid()
	}

	async token (token?: string, provider?: string): Promise<string>
	{
		if (token) {
			await this.TokenSrvc.set(token);
			token = await this.TokenSrvc.get();
      this.auth.changeAuthStatus(!!token);
			return token;
		}

		return await this.TokenSrvc.get();
	}

	async init (): Promise<void>
	{
		let isReady: boolean = false;
		try {
			isReady = await lastValueFrom(this.socialAuthService.initState)
		}
		catch (ex) {
			console.error(`${ex}`);
			return Promise.reject();
		}

		if (!isReady)
			throw new Error('Unable to initialize.');

		return Promise.resolve();
	}

	async validateToken (): Promise<boolean>
	{
		if (await this.isTokenValid()) {

			if (!this.provider)
				await this.loadProvider();

			if (!this.provider)
				return Promise.resolve(false);


			switch (this.provider)
			{
				case 'COGNITO': 	return Promise.resolve(!!getCognitoUser());
				case 'FACEBOOK': 	return Promise.resolve(true);
				case 'GOOGLE': 		return Promise.resolve(true);
			}

		}

		return Promise.resolve(false);
	}

	async logout (): Promise<void>
	{
		if (!this.provider)
			await this.loadProvider();

		switch (this.provider)
		{
			case 'COGNITO':
				const CUser = getCognitoUser();
				if (CUser) CUser.signOut();
				break;
			case 'FACBOOK':
				try { await this.socialAuthService.signOut(); }
				catch (ex) { console.error(`Logout error: ${ex}`); }
				break;
			case 'GOOGLE':
				try { await this.socialAuthService.signOut(); }
				catch (ex) { console.error(`Logout error: ${ex}`); }
				break;
			default:
				// try {
				// 	// clear everything in storage.
				// }
				// catch (ex) {}
				try {
					const CUser = getCognitoUser();
					if (CUser) CUser.signOut();
				}
				catch (ex) { 
					if (`${ex}` !== 'Not logged in')
						console.error(`Logout error with unknown authentication provider: ${ex}`); 
				}
				try { 
					await this.socialAuthService.signOut(); 
				}
				catch (ex) { console.error(`Logout error with unknown social provider: ${ex}`); }
				break;
		}

    await this.TokenSrvc.remove();
    this.auth.changeAuthStatus(false);

		return Promise.resolve();
	}

	constructor (private TokenSrvc: TokenService, private socialAuthService: SocialAuthService, private auth: AuthService) 
	{}
}
