import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ChestService } from './../shared/chest.service';
import { ModelInstance } from '@getrearview/model-builder';
import { Subscription } from 'rxjs';
import { FloatingControlService } from './../../../widgets/floating-controls/floating-control.service';
import { ModalService, modalParams } from 'src/app/widgets/modal/shared/modal.service';
import { SessionUser } from 'src/app/core/session/session-user.model';
import { FormBuilder, Validators } from '@angular/forms';


@Component({
  selector: 'rv-memory-chest-page',
  templateUrl: './memory-chest-page.component.html',
  styleUrls: ['./memory-chest-page.component.scss']
})
export class MemoryChestPageComponent implements OnInit {

	loaded: boolean = true;

	isModalOpen: boolean = false;

	#subscriptions$ = new Subscription();

	enableFilter: boolean = false;
	previewing = [];

	private _MC: ModelInstance;
	chest_id: string = '';
	get MemoryChest (): ModelInstance { return this._MC; }
	set MemoryChest (MC: ModelInstance) { this._MC = MC; }

	milestoneFilterForm = this.formBuilder.group({
		milestone: [null, [Validators.required]],
		selectPerson: [null, [Validators.required]],
		location: [null, [Validators.required]],
		initialDate: [null, [Validators.required]],
		hashtags: [null, [Validators.required]],
	});


	onIntersection (Milestone: ModelInstance, $evt)  // IntersectionObserverEvent
	{
		if ($evt.shift()?.isIntersecting && !this.previewing.includes(Milestone.id())) 
			this.chestSrvc.preview(Milestone);
	}

	onChestSelected (MemoryChest: ModelInstance): void
	{
		if (MemoryChest && `${MemoryChest?.id()}` !== `${this.MemoryChest?.id()}`)
			this.router.navigate([`/memory-chests`, MemoryChest?.id()]);
	}

	openFilter (milestoneFilterRef: TemplateRef<any>) 
	{
		if (this.isModalOpen) {
			this.modalService.close()
			return;
		}

		const opts:modalParams = {title: 'Filter'};

		this.modalService.open(milestoneFilterRef, opts);
	}

	closeFilter(): void {
		this.modalService.close();
	}

	handleSubmit(): void {
		const searchParams = {
			milestone_id: this.milestoneFilterForm.value.milestone,
			member_id: this.milestoneFilterForm.value.selectPerson,
			place_id: this.milestoneFilterForm.value.location,
			event_date: this.milestoneFilterForm.value.initialDate,
			tag_id: this.milestoneFilterForm.value.hashtags
		}

		this.chestSrvc.fetch(this.chest_id, searchParams).finally(() => {
			this.loaded = true;
			this.floatingControl.setParams({memoryChestId: this.chest_id, MemoryChest: this.chestSrvc.Chest, Me: this.chestSrvc.Me});
		});
	}

  constructor (
	private route: ActivatedRoute, 
	private router: Router, 
	public chestSrvc: ChestService, 
	private modalService: ModalService, 
	private SessionUser: SessionUser, 
	private floatingControl: FloatingControlService, 
	private formBuilder: FormBuilder,
  ) 
  {}

  ngOnInit (): void 
  {
  	this.#subscriptions$.add(this.modalService.isOpen.subscribe((isOpen: boolean) => this.isModalOpen = isOpen));
  	this.SessionUser.getInstance().then((Profile:ModelInstance) => {
		  this.#subscriptions$.add(this.route.params.subscribe(params => {
		  	this.chestSrvc.fetch(this.chest_id = params?.id).finally(() => {
		  		this.loaded = true;
		  		this.floatingControl.setParams({memoryChestId: params?.id, MemoryChest: this.chestSrvc.Chest, Me: this.chestSrvc.Me});
		  	});
		  }));
  	});
  	this.#subscriptions$.add(this.chestSrvc.Chest$.subscribe((Chest: ModelInstance) => this.MemoryChest = Chest));
  }

  ngOnDestroy (): void 
  {
    this.#subscriptions$.unsubscribe();
		this.floatingControl.reset();
  }
}