import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[formControlErrorContainer]'
})
export class FormControlErrorContainerDirective {

  constructor (public vcr: ViewContainerRef) 
  {}
}
