import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'rv-ui-page-inputs',
  templateUrl: './ui-inputs.component.html',
  styleUrls: ['./ui-inputs.component.scss']
})
export class UiInputsComponent implements OnInit {

	demoForm: FormGroup = this.fb.group({
		phone: 					['8005551212', [Validators.required]],
	});

  constructor (private fb: FormBuilder) 
  {}

  ngOnInit (): void 
  {}
}
