import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownMenuWidgetComponent } from './dropdown-menu-widget/dropdown-menu-widget.component';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { RearviewInterfaceModule } from 'src/app/shared/interface/rearview-interface.module';

@NgModule({
  declarations: [
    DropdownMenuWidgetComponent
  ],
  imports: [
    CommonModule,
    DirectivesModule,
    RearviewInterfaceModule
  ],
  exports: [
  	DropdownMenuWidgetComponent
  ]
})
export class DropdownMenuModule { }
