<div class="memory-media-component">

	<ng-container *ngIf="quantity === 1;else multiMedia">
		<ng-container [ngSwitch]="Medias[media_ids[0]].attribs.media_type">
			<rv-memory-media-image *ngSwitchCase="'IMG'" [Media]="Medias[media_ids[0]]" (mousedown)="onMouseDown($event, Medias[media_ids[0]])" (mouseup)="onMouseUp()" />
			<rv-memory-media-url *ngSwitchCase="'URL'" [Media]="Medias[media_ids[0]]" (mousedown)="onMouseDown($event, Medias[media_ids[0]])" (mouseup)="onMouseUp()" />
			<rv-memory-media-video *ngSwitchCase="'VIDEO'" [Media]="Medias[media_ids[0]]" (mousedown)="onMouseDown($event, Medias[media_ids[0]])" (mouseup)="onMouseUp()" />
			<rv-memory-media-audio *ngSwitchCase="'AUDIO'" [Media]="Medias[media_ids[0]]" (mousedown)="onMouseDown($event, Medias[media_ids[0]])" (mouseup)="onMouseUp()" />
		</ng-container>
	</ng-container>
	<ng-template #multiMedia>
		<ng-container *ngIf="quantity >= 2;else noMedia">
			<ul *ngIf="!IsMultiImage" class="multi-media-wrapper">
				<li *ngFor="let M of Medias|keyvalue">
					<ng-container [ngSwitch]="M.value.attribs.media_type">
						<rv-memory-media-image *ngSwitchCase="'IMG'" [Media]="M.value" (mousedown)="onMouseDown($event, M.value)" (mouseup)="onMouseUp()" />
						<rv-memory-media-url *ngSwitchCase="'URL'" [Media]="M.value" (mousedown)="onMouseDown($event, M.value)" (mouseup)="onMouseUp()" />
						<rv-memory-media-video *ngSwitchCase="'VIDEO'" [Media]="M.value" (mousedown)="onMouseDown($event, M.value)" (mouseup)="onMouseUp()" />
						<rv-memory-media-audio *ngSwitchCase="'AUDIO'" [Media]="M.value" (mousedown)="onMouseDown($event, M.value)" (mouseup)="onMouseUp()" />
					</ng-container>
				</li>
			</ul>
			<rv-memory-media-multi-image *ngIf="IsMultiImage" [Memory]="Memory" [MediaIds]="MediaIds" [Medias]="Medias" />
		</ng-container>
	</ng-template>


<!--
	<ng-container *ngIf="Media;else memoryMedia">		
		<ng-container [ngSwitch]="Media.attribs.media_type">
			<rv-memory-media-image *ngSwitchCase="'IMG'" [Media]="Media" (mousedown)="onMouseDown($event, Media)" (mouseup)="onMouseUp()" />
			<rv-memory-media-url *ngSwitchCase="'URL'" [Media]="Media" (mousedown)="onMouseDown($event, Media)" (mouseup)="onMouseUp()" />
			<rv-memory-media-video *ngSwitchCase="'VIDEO'" [Media]="Media" (mousedown)="onMouseDown($event, Media)" (mouseup)="onMouseUp()" />
			<rv-memory-media-audio *ngSwitchCase="'AUDIO'" [Media]="Media" (mousedown)="onMouseDown($event, Media)" (mouseup)="onMouseUp()" />
		</ng-container>
	</ng-container>
	<ng-template #memoryMedia>
		<ng-container *ngIf="quantity === 1;else multiMedia">
			<ng-container [ngSwitch]="Memory.getChild('media', MediaIds[0]).attribs.media_type">
				<rv-memory-media-image *ngSwitchCase="'IMG'" [Media]="Memory.getChild('media', MediaIds[0])" (mousedown)="onMouseDown($event, Memory.getChild('media', MediaIds[0]))" (mouseup)="onMouseUp()" />
				<rv-memory-media-url *ngSwitchCase="'URL'" [Media]="Memory.getChild('media', MediaIds[0])" (mousedown)="onMouseDown($event, Memory.getChild('media', MediaIds[0]))" (mouseup)="onMouseUp()" />
				<rv-memory-media-video *ngSwitchCase="'VIDEO'" [Media]="Memory.getChild('media', MediaIds[0])" (mousedown)="onMouseDown($event, Memory.getChild('media', MediaIds[0]))" (mouseup)="onMouseUp()" />
				<rv-memory-media-audio *ngSwitchCase="'AUDIO'" [Media]="Memory.getChild('media', MediaIds[0])" (mousedown)="onMouseDown($event, Memory.getChild('media', MediaIds[0]))" (mouseup)="onMouseUp()" />
			</ng-container>
		</ng-container>
		<ng-template #multiMedia>
			<ng-container *ngIf="quantity >= 2;else noMedia">
				<ul *ngIf="!IsMultiImage" class="multi-media-wrapper">
					<li *ngFor="let id of MediaIds">
						<ng-container [ngSwitch]="Memory.getChild('media', id).attribs.media_type">
							<rv-memory-media-image *ngSwitchCase="'IMG'" [Media]="Memory.getChild('media', id)" (mousedown)="onMouseDown($event, Memory.getChild('media', id))" (mouseup)="onMouseUp()" />
							<rv-memory-media-url *ngSwitchCase="'URL'" [Media]="Memory.getChild('media', id)" (mousedown)="onMouseDown($event, Memory.getChild('media', id))" (mouseup)="onMouseUp()" />
							<rv-memory-media-video *ngSwitchCase="'VIDEO'" [Media]="Memory.getChild('media', id)" (mousedown)="onMouseDown($event, Memory.getChild('media', id))" (mouseup)="onMouseUp()" />
							<rv-memory-media-audio *ngSwitchCase="'AUDIO'" [Media]="Memory.getChild('media', id)" (mousedown)="onMouseDown($event, Memory.getChild('media', id))" (mouseup)="onMouseUp()" />
						</ng-container>
					</li>
				</ul>
				<rv-memory-media-multi-image *ngIf="IsMultiImage" [Memory]="Memory" [MediaIds]="MediaIds" />
			</ng-container>
		</ng-template>
	</ng-template>
-->

	<ng-template #noMedia>
	</ng-template>
<!-- 	<ng-container *ngIf="mediaType == 'image'">
		<div class="row media-container media-container-image"> -->
			<!-- <hope-memory-media-image [media]="Media"></hope-memory-media-image> -->
<!-- 		</div>
	</ng-container>
	<ng-container *ngIf="mediaType == 'audio'">
		<div class="row media-container media-container-audio"> -->
			<!-- <hope-memory-media-audio [media]="Media"></hope-memory-media-audio> -->
<!-- 		</div>
	</ng-container>
	<ng-container *ngIf="mediaType == 'video'">
		<div class="row media-container media-container-video">
			<hope-memory-media-video [media]="Media"></hope-memory-media-video>
		</div>
	</ng-container> -->
</div>