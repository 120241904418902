<div id="milestone-page-component" class="container-fluid page-container-fluid" [ngClass]="{'is-empty': storySrvc.getIsMilestoneEmpty}">
	<rv-milestone-header [Milestone]="storySrvc.Milestone"></rv-milestone-header>
	<div class="row">
		<div class="col-24">
			<no-memories />
			<ul cdkDropList class="milestone-events-list" (cdkDropListDropped)="drop($event)">
				<ng-container *ngFor="let M of storySrvc.MilestoneEvents|stripEmptyMemories; let idx = index">
					<li class="example-custom-placeholder" *cdkDragPlaceholder>Placeholder</li>
					<li class='mm-wrapper' cdkDrag *ngIf="M.getName() === 'Memory'"><rv-memory [Memory]='M' [numOfMedia]="getNumOfMedia(M)" [index]='idx' (onDeleteMedia)="onDeleteMedia($event, deleteMediaRef)" /></li>
					<li class='mm-wrapper' cdkDrag *ngIf="M.getName() === 'Milestone Location'"><rv-date-location [Location]='M' [index]='idx' (previous)="previous()" (next)="next()" [disabled]="isDisabled" /></li>
				</ng-container>
			</ul>
			<rv-milestone-footer [Milestone]="storySrvc.Milestone" [NextMilestone]="storySrvc.Next" [PreviousMilestone]="storySrvc.Previous" [isMilestoneEmpty]="storySrvc.getIsMilestoneEmpty" class='footer-wrapper'></rv-milestone-footer>
		</div>
	</div>
</div>

<ng-template #milestoneIntro>
	<milestone-intro [MemoryChest]="storySrvc.MemoryChest" [Milestone]="storySrvc.Milestone" />
</ng-template>

<ng-template #memoryCreator>
	<rv-memory-creator-widget [MemoryChest]="storySrvc.MemoryChest" [Milestone]="storySrvc.Milestone" [Me]="storySrvc.Me" (onSuccess)="onMemoryCreatorSuccess($event)" />
</ng-template>

<ng-template #deleteMediaRef>
	<delete-media-confirm [Media]="DeleteMedia" [preview]="deletePreviewElement" (onConfirm)="onConfirmDelete($event)" (onCancel)="onConfirmDelete(false)" />
</ng-template>