import { InjectionToken } from '@angular/core';

export const defaultErrors = {
  required: (error) 															=> 	`Required`,
  minlength: ({ requiredLength, actualLength }) 	=> 	`Expect ${requiredLength} but got ${actualLength}`,
  pattern: (error) 																=> 	`Invalid Format`,
  email: (error) 																	=>	`Invalid Email Format`
}

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => defaultErrors
});