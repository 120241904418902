import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { UiInputWidgetComponent } from './ui-input-widget/ui-input-widget.component';
import { UiIconModule } from './../ui-icon/ui-icon.module';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask'
import { DirectivesModule } from 'src/app/shared/directives/directives.module';

@NgModule({
  declarations: [UiInputWidgetComponent], // InputMaskDirective
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    UiIconModule,
    DirectivesModule
  ],
  exports: [
  	UiInputWidgetComponent
  ]
})
export class UiInputModule { }