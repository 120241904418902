import { Injectable } from '@angular/core';
// import { HttpErrorResponse } from '@angular/common/http';
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js'
import { BehaviorSubject, Observable, of, firstValueFrom} from 'rxjs';
import { TokenService } from './token.service';
import { ApiService } from './api.service';
import { ConfigService } from 'src/app/shared/services/config.service';
import { TheFutureAuthProvider } from './../session/the-future-auth.provider';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import {
	FacebookLogin,
	FacebookLoginResponse,
  } from '@capacitor-community/facebook-login';
import {
  SocialAuthService,
  FacebookLoginProvider,
  SocialUser,
} from '@abacritt/angularx-social-login';

export class SigninRequestResponse {

	private ERROR_CODES = {
		PREVIOUSLY_REGISTERED: 	'It looks like you\'ve already created an account.',
		NOT_REGISTERED: 				'It looks like you don\'t have an account yet.',
		INVALID_CLIENT: 				'Invalid client',
		INVALID_DOMAIN: 				'Invalid domain',
		EXPIRED_TOKEN: 					'Expired token',
		INVALID_KEY: 						'Invalid key',
		INVALID_PUBLIC: 				'Invalid public',
		BAD_REQUEST: 						'Bad request',
	}

	translate () {
		return this.error_code && this.ERROR_CODES.hasOwnProperty(this.error_code) ? this.ERROR_CODES[this.error_code] : (this.message||this.error_code);
	}

	success = false;
	access_token = '';
	token_type = '';
	message = '';
	error_code = '';
}

declare const google: any;

const FACEBOOK_PERMISSIONS = ['email','user_birthday','user_photos']

@Injectable({
  providedIn: 'root'
})
export class AuthService {

	private _isAuth!: boolean;
  private _loggedIn = new BehaviorSubject<boolean>(!!this._isAuth);

  private _isAuthenticating: boolean = false;
  private _isRegistering: boolean = false;

  authStatus = this._loggedIn.asObservable();

  get isAuth (): boolean
  {
  	return this._isAuth;
  }

  get isAuthenticating (): boolean
  {
  	return this._isAuthenticating;
  }

  get isRegistering (): boolean
  {
  	return this._isRegistering;
  }

  get isLoading (): boolean
  {
  	return this._isAuthenticating || this._isRegistering;
  }

  changeAuthStatus (value: boolean) 
  {
		this._isAuth = value;
	    this._loggedIn.next(value);
  }

  async determineAuthStatus (value?: boolean) 
  {
  	const token = await this.token.get();
  	if (this.isAuth !== !!token)
  		this._loggedIn.next(this._isAuth = !!token);
  }

  private async getAccessToken (User: any, opts?: {[key: string]: any}, register?: boolean): Promise<SigninRequestResponse>
  {
		const response: SigninRequestResponse = new SigninRequestResponse(),
					{InviteToken} = (opts||{});

  	try {
  		const {success, message, access_token, token_type} = (await firstValueFrom(this.api[register === true?'register':'login']({...User, inviteToken: InviteToken?.payload()})) as SigninRequestResponse);
  		response.success 				= 	success && !!access_token
  		response.access_token 	= 	access_token;
  		response.token_type 		= 	token_type;
  		response.message 				= 	message;
  	}
  	catch (ex) {
  		if (ex?.error && ex.error?.error_code)
  			response.error_code = ex.error?.error_code;
  		if (ex?.error && ex.error?.message)
  			response.message = ex.error?.message;
  	}

  	return Promise.resolve(response);
  }

  private onAccessTokenSuccess (provider: string, Response: SigninRequestResponse): SigninRequestResponse
  {
		this.token.handle(Response.access_token, (provider?`${provider}`.toUpperCase():''));
		this.changeAuthStatus(true);
		delete Response.access_token;
		return Response;
  }

  async registerAccessToken (User: any, opts?: {[key: string]: any}): Promise<SigninRequestResponse>
  {
  	const Response: SigninRequestResponse = await this.getAccessToken(User, opts, true);

  	if (Response && Response.success)
  		return this.onAccessTokenSuccess(`${(User && User?.provider)||''}`, Response)

  	return Promise.reject(Response);
  }

  async requestAccessToken (User: any, opts?: {[key: string]: any}): Promise<SigninRequestResponse>
  {
  	const Response: SigninRequestResponse = await this.getAccessToken(User, opts);

  	if (Response && Response.success)
  		return this.onAccessTokenSuccess(`${(User && User?.provider)||''}`, Response)

  	return Promise.reject(Response);
  }

  async register (provider: string): Promise<SigninRequestResponse>
  {
		const response: SigninRequestResponse = new SigninRequestResponse();

  	switch (provider)
  	{
  		case 'FACEBOOK':
		  	try {
					const result = await FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });
					return await this.registerAccessToken({provider: "FACEBOOK", authToken: result.accessToken.token})
		  	}
		  	catch (ex) {
		  		return Promise.reject(ex);
		  	}

  		case 'GOOGLE':
		  	try {
		  		const result = await GoogleAuth.signIn();
		  		return await this.registerAccessToken({provider: "GOOGLE", idToken: result && result?.authentication?.idToken})
		  	}
		  	catch (ex) {
		  		return Promise.reject(ex);
		  	}

  		case 'COGNITO':
  			break;
  		default:
  			break;
  	}

  	return Promise.reject();
  }

  async login (provider: string, opts?: {[key: string]: any}): Promise<SigninRequestResponse>
  {
		const response: SigninRequestResponse = new SigninRequestResponse(),
					{InviteToken} = (opts||{});

  	switch (provider)
  	{
  		case 'FACEBOOK':
		  	try {
					const result = await FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS })
					return await this.requestAccessToken({provider: "FACEBOOK", authToken: result.accessToken.token}, {InviteToken})
		  	}
		  	catch (ex) {
		  		return Promise.reject(ex);
		  	}

  		case 'GOOGLE':
		  	try {
					const result = await GoogleAuth.signIn();
					return await this.requestAccessToken({provider: "GOOGLE", idToken: result.authentication.idToken}, {InviteToken})
		  	}
		  	catch (ex) {
		  		return Promise.reject(ex);
		  	}

  		case 'COGNITO':
  			break;
  		default:
  			break;
  	}

  	return Promise.reject();
  }

  constructor (private token: TokenService, private ConfigSrvc: ConfigService, private socialAuthService: SocialAuthService, private api: ApiService) 
  {}
} 