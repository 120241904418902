<div id="social-signup-component" class="container-fluid auth-form-component">
	<div class="row">
		<div class="col-24">
			<div class="font-color--gray text-center rv-heading-sm">Sign up to Rearview</div>
			<form class="rv-form rv-auth-form rv-form-bg-blue" name="registerForm" layout layout-align="center" layout-padding [ngClass]="{'is-submitting': isSubmitting}">
				<div class="btn-group-vertical btn-group-vertical-spaced mb-5" style='text-align: center;'>
					<rv-facebook-button (click)="registerWithFB()" label='Register with Facebook' class="mb-1" />
					<rv-google-button (click)="registerWithGoogle()" label='Register with Google' class="mb-3" />
					<rv-button type='button' block sm purple class="btn-cognito" (click)="registerWithCognito()" label='Register with Email' />
					<!-- <rv-link label='Back to Login' (click)="goToLogin()" style='margin:auto;' off-white /> -->
				</div>

				<rv-link sm (click)="goToLogin()" iconLeft='arrows.left-arrow-1' label='Back to social login' off-white icon-off-white />
			</form>
			<rv-auth-notifications></rv-auth-notifications>
		</div>
	</div>
</div>