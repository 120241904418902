import { Component, Input } from '@angular/core';
import { ModelInstance } from '@getrearview/model-builder'

@Component({
  selector: 'rv-memory-editor-widget',
  templateUrl: './memory-editor-widget.component.html',
  styleUrls: ['./memory-editor-widget.component.scss']
})
export class MemoryEditorWidgetComponent {

	@Input()
	Milestone: ModelInstance;

	@Input()
	Memory: ModelInstance;

}