import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AccountService } from './../../../models/accounts/account.service'
import { SessionUser } from './../../../core/session/session-user.model'

@Component({
  selector: 'rv-account-creator',
  templateUrl: './account-creator.component.html',
  styleUrls: ['./account-creator.component.scss']
})
export class AccountCreatorComponent {

	isSaving: boolean = false;

	newAccountForm = this.fb.group({
		name: 				[null, [Validators.required]]
	});

	handleSubmit () 
	{
		if (this.newAccountForm.valid) {
			this.AccountSrvc.get().set('name', this.newAccountForm.get('name').value);
			this.AccountSrvc.get().save().then(res => {
				this.AccountSrvc.all().push(this.AccountSrvc.get());
				this.AccountSrvc.set(this.AccountSrvc.factory().create());
			});
		}
	}

	constructor (private fb: FormBuilder, private SessionUser: SessionUser,public AccountSrvc: AccountService)
	{}

	ngOnInit (): void 
	{}
}
