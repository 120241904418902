<div class="container-fluid date-location-widget-component">
	<div class="row">
		<div class="col p-link">
			<rv-previous-btn *ngIf="showPrevious" />
		</div>
		<div class="col memory-indicator">
			<div class="m-date" *ngIf="start_at">{{start_at | date: 'MM/dd/yyyy'}}</div>
			<div class="m-location" *ngIf="nickname">
				<rv-icon group='location-and-map' icon='location' purple />
				&nbsp;{{nickname}}
			</div>
		</div>
		<div class="col n-link">
			<rv-next-btn *ngIf="showNext" />
		</div>
	</div>
</div>