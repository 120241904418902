import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { SessionUser } from './../../../core/session/session-user.model';
import { AuthFormService } from './../shared/auth-form.service';
import { ConfigService } from './../../../shared/services/config.service';
import { AuthFormComponent } from './../shared/auth-form.component';
import { TrackJsService } from './../../../shared/services/trackjs.service';
import { ApiService } from './../../../core/services/api.service';

@Component({
  selector: 'rv-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./../shared/auth-form.component.scss']
})
export class ForgotPasswordFormComponent extends AuthFormComponent implements OnInit {

  constructor (SessionUser: SessionUser, AuthApi: ApiService, AuthFormSrvc: AuthFormService, ConfigSrvc: ConfigService, fb: FormBuilder, TrackJsSrvc: TrackJsService) 
  {
  	super(SessionUser, AuthApi, AuthFormSrvc, ConfigSrvc, fb, TrackJsSrvc)
  }

  ngOnInit (): void 
  {
		this.authForm.get('email_or_phone').setValue(this.AuthFormSrvc.emailOrPhone || this.ConfigSrvc.get('defaults.pages.auth.login.email_or_phone') || null);

		this._subscriptions$.add(this.authForm.valueChanges.subscribe(() => this.reset()));
  }

  ngOnDestroy (): void
  {
  	this._subscriptions$.unsubscribe();
  }
}