import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService, modalParams } from 'src/app/widgets/modal/shared/modal.service';
import { Subscription } from 'rxjs';
import { ModelInstance } from '@getrearview/model-builder';
import { ChestsService } from './../shared/chests.service';

@Component({
  selector: 'chest-preview',
  templateUrl: './chest-preview.component.html',
  styleUrls: ['./chest-preview.component.scss']
})
export class ChestPreviewComponent implements OnInit {

  @Input()
	MemoryChest: ModelInstance;
	memoryChestId: string = '';

  birthDateFormat: string = 'mediumDate';

	isContextOpen: boolean = false;
	isModalOpen: boolean = false;

	#subscriptions$ = new Subscription();

  mediaRows: {[key: string]: Array<string>} = {
  	"I": [],
  	"II": []
  };

  onSuccess = (MemoryChest: ModelInstance) => 
  {
  	this.chestsSrvc.replace(MemoryChest);
		if (this.isModalOpen)
			this.modalService.close();
  }

  editMemoryChest = (memoryChestEditor: TemplateRef<any>): void => 
  {
		if (this.isModalOpen)
			this.modalService.close();

		this.modalService.open(memoryChestEditor, {title: 'Update A Memory Chest'} as modalParams);
  }

  deleteMemoryChest = () => 
  {
  	this.chestsSrvc.remove(this.MemoryChest);
  	this.MemoryChest.delete();
		if (this.isModalOpen)
			this.modalService.close();
  }

  goToMemoryChest = () => 
  {
  	this.router.navigate([`/memory-chests`, this.MemoryChest.id()]);
  }

  private distribute (): void
  {
    const medias = (this.MemoryChest?.get('medias')||[]),
    			rows = (1 + (medias.length > 3 ? 1 : 0)),
    			perRow = medias.length && Math.floor(medias.length/rows);

    this.mediaRows.I = medias.slice(0, perRow);
    this.mediaRows.II = medias.slice(perRow, perRow*2);
  }

  constructor (public router: Router, public modalService: ModalService, private chestsSrvc: ChestsService) 
  {}

  ngOnChanges (): void 
  {}

  ngOnInit (): void
  {
  	this.#subscriptions$.add(this.modalService.isOpen.subscribe((isOpen: boolean) => this.isModalOpen = isOpen));
  	this.distribute();
  	this.memoryChestId = (this.MemoryChest?.id()&&`${this.MemoryChest.id()}`)||'';
  }

  ngOnDestroy(): void 
  {
  	this.#subscriptions$.unsubscribe();
  }
}