import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SessionUser } from './../../../core/session/session-user.model';
import { ConfigService } from './../../../shared/services/config.service';
import { AuthFormComponent } from './../shared/auth-form.component';
import { AuthFormService } from './../shared/auth-form.service';
import { ApiService } from './../../../core/services/api.service';
import { TrackJsService } from './../../../shared/services/trackjs.service';
import { AuthFormStates } from './../shared/AuthFormStates.enum';
import { TokenService } from './../../../core/services/token.service';
import { AuthService } from './../../../core/services/auth.service';

import {
  SocialAuthService,
  FacebookLoginProvider,
  SocialUser,
} from '@abacritt/angularx-social-login';

@Component({
  selector: 'rv-signup-form',
  templateUrl: './signup-form.component.html',
  styleUrls: ['./../shared/auth-form.component.scss']
})
export class SignUpFormComponent extends AuthFormComponent implements OnInit {

	socialUser!: SocialUser;
	isLoggedin?: boolean = undefined;

  handleResponse(data: any) {
    if(data.success === true && data.access_token){
      this.token.handle(data.access_token);
      this.auth.changeAuthStatus(true);
      // this.router.navigateByUrl('profile');
    }
    else {
    	throw new Error(`Failed to complete new registration. ${data}`);
    }
  }

  FBLogin() {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  constructor (private socialAuthService: SocialAuthService, private auth: AuthService, private token: TokenService, SessionUser: SessionUser, AuthApi: ApiService, AuthFormSrvc: AuthFormService, ConfigSrvc: ConfigService, fb: FormBuilder, TrackJsSrvc: TrackJsService) 
  {
  	super(SessionUser, AuthApi, AuthFormSrvc, ConfigSrvc, fb, TrackJsSrvc)
  }

  ngOnInit (): void 
  {
    this.socialAuthService.authState.subscribe((user) => {
      this.socialUser = user;
      if(user && !this.isLoggedin){
        this.isLoggedin = user != null;
        this.AuthApi.signup(user).subscribe(
          data => this.handleResponse(data),
          error => console.error(error)
        );
      }
    });

  	if (AuthFormStates[this.AuthFormSrvc.state] === 'SIGNUP_CONFIRM') {
  		if (!this.AuthFormSrvc.emailOrPhone || !this.AuthFormSrvc.password)
  			this.AuthFormSrvc.state = AuthFormStates.SIGNUP;
  	}

		this.registerForm.get('email_or_phone').setValue(this.AuthFormSrvc.emailOrPhone || this.ConfigSrvc.get('defaults.pages.auth.login.email_or_phone') || null);
		this.registerForm.get('password').setValue(this.AuthFormSrvc.password || this.ConfigSrvc.get('defaults.pages.auth.login.password') || null);

		this._subscriptions$.add(this.registerForm.valueChanges.subscribe(() => {this.reset()}));
  }

  ngOnDestroy (): void
  {
  	this._subscriptions$.unsubscribe();
  }
}