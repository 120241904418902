<div class="memory-media-image py-2 overflow-x-hidden">
	<div class="row">
		<div class="col-12 firstImage">
			<div class="image-wrapper">
				<img [src]="imgUrls[0]" class="rounded-3" />
			</div>
		</div>
        <div class="col-12 multiImageField">
            <div class="topRow">
                <img [src]="url" class="rounded-3 p-1" *ngFor="let url of topUrls" />
            </div>
            <div class="bottomRow">
                <img [src]="url" class="rounded-3 p-1" *ngFor="let url of bottomUrls" />
            </div>
        </div>
	</div>
</div>