import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ModelInstance } from '@getrearview/model-builder';
import { MilestoneService } from 'src/app/models/memories/milestone.service';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { MessageBusService, Channels, Message } from 'src/app/core/services/message-bus.service';

import { DatetimePicker } from '@capawesome-team/capacitor-datetime-picker';
// import { DatePicker, DatePickerOptions } from '@capacitor-community/date-picker';
// import type { DatePickerTheme } from '@capacitor-community/date-picker/src';
// const selectedTheme: DatePickerTheme = 'light';

// Intl.DateTimeFormat().resolvedOptions().timeZone
// https://en.wikipedia.org/wiki/List_of_tz_database_time_zones


@Component({
  selector: 'rv-milestone-creator-widget',
  templateUrl: './milestone-creator-widget.component.html',
  styleUrls: ['./milestone-creator-widget.component.scss']
})
export class MilestoneCreatorWidgetComponent {

	@Output('onCancel')
	onCancel: EventEmitter<void> = new EventEmitter<void>();

	@Output('onSuccess')
	onSuccess: EventEmitter<ModelInstance> = new EventEmitter<ModelInstance>();

	@Input('MemoryChest')
	MemoryChest: ModelInstance;

	@Input('Me')
	Me: ModelInstance;

	// @Input('milestoneId')
	// milestoneId!: string;

	isSaving: boolean = false;
	isFetching: boolean = false;
	isEditing: boolean = false;

	Milestone!: ModelInstance;
	milestoneForm: FormGroup = new FormGroup({
																	nickname: new FormControl('',Validators.required),
																	start_at: new FormControl(''),
																	place_id: new FormControl(''),
																});

	_subscriptions$:Subscription = new Subscription();

	async saveMilestone (): Promise<void>
	{

		this.isSaving = true;
		const formValues = Object.keys(this.milestoneForm.controls).map(key => ({[key]: key === 'start_at' ? new Date(this.milestoneForm.get(key).value) : this.milestoneForm.get(key).value})).reduce((acc, field) => ({...acc, ...field}), {author_id: this.Me?.id()});

		try {
			this.Milestone = await this.MemoryChest.createChild('milestone', formValues) as ModelInstance;
			if (this.Milestone) {
				this.onSuccess.next(this.Milestone);
				this.bus.emit(Channels.Model, new Message('milestone.created',{Milestone:this.Milestone}));
			}
		}
		catch (ex) {
			console.error(`Failed to save new Milestone. ${ex}`);
		}

		this.isSaving = false;
	}

	#updateModel (changes: {[key: string]: any}): void
	{
		if (this.Milestone)
			Object.keys(changes).forEach(key => this.Milestone.set(key, changes[key]));
  }

	updateForm (Milestone: ModelInstance): void
	{
		this.milestoneForm.get('nickname').setValue((Milestone?.get('nickname')||''));
	}

	updateFormSilent (Milestone: ModelInstance): void
	{
		this.milestoneForm.get('nickname').setValue((Milestone?.get('nickname')||''), {emitEvent: false});
	}

  constructor (private fb: FormBuilder, private MilestoneSrvc: MilestoneService, private bus: MessageBusService)
  {}

  ngOnInit (): void
  {
  	this._subscriptions$.add(this.milestoneForm.valueChanges.pipe(distinctUntilChanged(), debounceTime(200)).subscribe(changes => this.#updateModel(changes)));
  }

  ngOnDestroy (): void
  {
  	this._subscriptions$.unsubscribe();
  }
}