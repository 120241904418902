import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export enum Channels { 
	Route, 
	Scroll,
	Modal,
	Model,
	Widget
}

export class Message 
{
	private _name!: string;
	private _payload!: {[key: string]: any};

	constructor (name: string, payload?: {[key: string]: any})
	{
		this._name = name;
		this._payload = payload || {};
	}

	get name (): string
	{
		return this._name;
	}

  get payload (): {[key: string]: any}
  {
  	return this._payload;
  }

  get native (): Event
  {
  	return this.payload?.native
  }
}

@Injectable()
export class MessageBusService 
{
  private _subjects$: {[key: string]: Subject<any>} = {}

  subscribe (channel: Channels, callback: any): Subscription
  {
  	if (!this._subjects$.hasOwnProperty(channel))
  		this._subjects$[channel] = new Subject<any>();

    return this._subjects$[channel].subscribe(callback);
  }

  emit (channel: Channels, msg: Message) 
  {
  	if (this._subjects$[channel])
    	this._subjects$[channel].next(msg);
  }

  constructor () 
  {}
}

export class EmitEvent {
  constructor(public name: any, public value?: any) {}
}