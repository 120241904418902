import { Injectable } from '@angular/core';
import { ModelInstance } from '@getrearview/model-builder';
import { Subject, Observable } from 'rxjs';

import type { FloatingControlParams } from './floating-control-params.d';

const DEFAULT_PARAMS = {
	Me: null,
	memoryId: '',
	Memory: null,
	milestoneId: '',
	Milestone: null,
	memoryChestId: '',
	MemoryChest: null,
	Members: []
};

@Injectable({
  providedIn: 'any'
})
export class FloatingControlService {

	private _popBtns$: Subject<any> = new Subject();

	private params: FloatingControlParams = {
		Me: null,
		memoryId: '',
		Memory: null,
		milestoneId: '',
		Milestone: null,
		memoryChestId: '',
		MemoryChest: null,
		Members: []
	}

	get Me (): ModelInstance
	{
		if (this.params.Me)
			return this.params.Me;
	}

	get memoryId (): string
	{
		if (this.params.memoryId)
			return this.params.memoryId;
	}

	get Memory (): ModelInstance
	{
		if (this.params.Memory)
			return this.params.Memory;
	}

	get milestoneId (): string
	{
		if (this.params.milestoneId)
			return this.params.milestoneId;
	}

	get Milestone (): ModelInstance
	{
		if (this.params.Milestone)
			return this.params.Milestone;
	}

	get memoryChestId (): string
	{
		if (this.params.memoryChestId)
			return this.params.memoryChestId;
	}

	get MemoryChest (): ModelInstance
	{
		if (this.params.MemoryChest)
			return this.params.MemoryChest;
	}

	get Members (): Array<ModelInstance>
	{
		if (this.params.Members)
			return this.params.Members;
		return [];
	}

	get popBtns$ (): Observable<any>
	{
		return this._popBtns$.asObservable();
	}

	set popBtnEvent (btnEvt: {[key: string]: any})
	{
		this._popBtns$.next(btnEvt);
	}

	setParams (params): void {
		Object.keys(params = params && typeof params === 'object' ? params : {}).filter(k => Object.keys(this.params).includes(k)).forEach(k => this.params[k] = params[k]);
	}

	reset (): void {
		Object.keys(this.params).forEach(key => this.params[key] = /Id$/.test(`${key}`) ? '' : null)
	}

  constructor () 
  {}
}