import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// import { NgStyle } from '@angular/common';
import { UiCoreWidgetComponent } from './../../shared/ui-core-widget.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'rv-img',
  styleUrls: ['./ui-img-widget.component.scss'],
  template: '<img *ngIf="!!imgSrc" [hidden]="isInvalid" [ngClass]="classes" [src]="imgSrc" (error)="imageHasBeenLoaded($event)" [ngStyle]="style" (load)="onImageLoad($event, false)" (error)="onImageLoad($event, true)" />'
})
export class UiImgWidgetComponent extends UiCoreWidgetComponent implements OnInit 
{
	@Input('src')
	src: string;

	@Input('cdn')
	cdn: string;

	@Input('height')
	private _height: string;

	@Input('round')
	private _round: boolean;

	@Input('cover')
	private _cover: boolean;

	@Output('onLoad')
	private _onLoad: EventEmitter<any> = new EventEmitter<any>();

	@Output('onError')
	private _onError: EventEmitter<any> = new EventEmitter<any>();

	isInvalid: boolean = false;

	onImageLoad ($event, isError)
	{
		if (isError) { this._onError.next($event); }
		else { this._onLoad.next($event); }
	}

	get imgSrc (): string
	{
		if (this.src)
			return this.src;
		if (this.cdn)
			return `${environment.api.media_url}${this.cdn.replace(/^\/public/,'')}`
	}

	get classes (): string
	{
		return this.coreClasses.concat(['ui-img',this.round?'ui-img-round':'',this.cover?'ui-img-cover':'']).join(' ');
	}

	get round (): boolean
	{
		return this.isActive(this._round);
	}

	get cover (): boolean
	{
		return this.isActive(this._cover);
	}

	get style ()
	{
		const style: {
			height?: string
		} = {};
		
		if (this._height)
			style.height = `${this._height}`;

		return style;
	}

	// get cdn (): string
	// {
	// 	// /public/chests/a2b38659-9d64-4a8f-a1cf-0286ab0f0b3b/ab785864-30cc-41bb-b745-d9b3f745d5e5
	// 	// MEMBER_IMG_URL = `https://cdn.rearview.com/users/{id}/profile.jpg`,
	// 	return '';
	// }

	imageHasBeenLoaded (evt)
	{
		if (evt && evt?.type === 'error') {
			this.isInvalid = true;
			console.error(`Failed to load ui-img (${this.src}).`);
		}
	}

  constructor () 
  {
  	super();
  }

  ngOnInit (): void 
  {}
}
