import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiImgWidgetComponent } from './ui-img-widget/ui-img-widget.component';

@NgModule({
  declarations: [UiImgWidgetComponent],
  imports: [
    CommonModule,
  ],
  exports: [
  	UiImgWidgetComponent
  ]
})
export class UiImgModule { }
