import { Component, OnInit } from '@angular/core';

/*
*
* This is a work-around for now.
* Angular throughs exception (after view did update) when 
* updating the digest cycle based on rendered content (bg color)
*
* An alternative to collect vars from scss may be viable.
* Needs Investigating:
* https://stackoverflow.com/questions/40418804/access-sass-values-colors-from-variables-scss-in-typescript-angular2-ionic2
*
* As well, behavior of window.getComputedStyle needs to be researched for usage with Capacitor
*
*/
const COLORS = {
	'color-rv-purple': '#5E36DD',
	'color-rv-purple-lt-1': '#A0A0B4',
	'color-rv-blue': '#071956',
	'color-rv-blue-lt-5': '#394677',
	'color-rv-blue-dk-1': '#454559',
	'color-rv-gray': '#EDEFF7',
	'color-rv-gray-dk-1': '#CBCED7',
	'color-rv-gray-dk-2': '#A4A6AE',
	'color-rv-gray-dk-3': '#898B93',
	'color-rv-gray-dk-4': '#6E7077',
	'color-rv-gray-dk-5': '#414247',
	'color-validation-success': '#4CAF50',
	'color-validation-success-bg': '#BDFFBF',
	'color-validation-error': '#F44336',
	'color-validation-error-bg': '#FFC8C4',
	'color-brand-purple': '#5E36DD',
	'color-brand-blue': '#071956',
	// 'color-purple-gradient': '#5E36DD',
	// background: linear-gradient(166.64deg, #7C00DE -14.24%, rgba(124, 0, 222, 0) 185.18%);
	// 'color-blue-gradient': '#071956',
	// background: linear-gradient(166.64deg, #0904b3 -14.24%, rgba(9, 4, 179, 0) 185.18%);
	'color-purple-900': '#170B3F',
	'color-purple-800': '#251166',
	'color-purple-700': '#32168A',
	'color-purple-600': '#431FB8',
	'color-purple-500': '$color-rv-PURPLE',
	'color-purple-400': '#7755E1',
	'color-purple-300': '#9D89DC',
	'color-purple-200': '#BFB5DC',
	'color-purple-100': '#D9D7DF',
	'color-blue-900': '#131D43',
	'color-blue-800': '#1D2C63',
	'color-blue-700': '#343F68',
	'color-blue-600': '#3E486F',
	'color-grey-900': '#3F3F3F',
	'color-grey-800': '#565656',
	'color-grey-700': '#6C6C6C',
	'color-grey-600': '#868585',
	'color-grey-500': '#A1A1A1',
	'color-grey-400': '#C3C3C3',
	'color-grey-300': '#DADADA',
	'color-grey-200': '#E2E2E2',
	'color-grey-100': '#FAFAFA',
	'color-success': '#179C0B',
	'color-warning': '#E58F0C',
	'color-error': '#C03A4A'
},
RV_COLORS = {
	primary: 		{
		'brand-purple': {hex: '', rgb: ''},
		'brand-blue': {hex: '', rgb: ''},
		'purple-gradient': {hex: '', rgb: ''},
		'blue-gradient': {hex: '', rgb: ''},
	}, 
	secondary: 	{
		'purple-900': {hex: '', rgb: ''},
		'purple-800': {hex: '', rgb: ''},
		'purple-700': {hex: '', rgb: ''},
		'purple-600': {hex: '', rgb: ''},
		'purple-500': {hex: '', rgb: ''},
		'purple-400': {hex: '', rgb: ''},
		'purple-300': {hex: '', rgb: ''},
		'purple-200': {hex: '', rgb: ''},
		'purple-100': {hex: '', rgb: ''},
		'blue-900': {hex: '', rgb: ''},
		'blue-800': {hex: '', rgb: ''},
		'blue-700': {hex: '', rgb: ''},
		'blue-600': {hex: '', rgb: ''},
	}, 
	greyscale: 	{
		'grey-900': {hex: '', rgb: ''},
		'grey-800': {hex: '', rgb: ''},
		'grey-700': {hex: '', rgb: ''},
		'grey-600': {hex: '', rgb: ''},
		'grey-500': {hex: '', rgb: ''},
		'grey-400': {hex: '', rgb: ''},
		'grey-300': {hex: '', rgb: ''},
		'grey-200': {hex: '', rgb: ''},
		'grey-100': {hex: '', rgb: ''},
	}, 
	semantic: 	{
		'success': {hex: '', rgb: ''},
		'warning': {hex: '', rgb: ''},
		'error': {hex: '', rgb: ''},
	}
}, HEX_REGEX = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

function hexToRgb (hex): Array<string> 
{
	var result;
	if (hex && (result = HEX_REGEX.exec(hex))) {
	  try {
	  	return [`${parseInt(result[1], 16)}`,`${parseInt(result[2], 16)}`,`${parseInt(result[3], 16)}`];
	  }
	  catch (ex) {
	  	console.error(`Failed to convert hexToRgb(${hex})`,result)
	  }
	}

	return [];
}

function componentToHex (c): string 
{
  var hex = c.toString(16);
  return `${hex.length == 1 ? "0" + hex : hex}`;
}

function rgbToHex (r, g, b): string 
{
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

const getBgColor = (elem): string =>
{
	let color: string;
	try {
		color = elem && window.getComputedStyle(elem).backgroundColor
	}
	catch (ex) {
		console.error(`Failed to get background color of ui-color-card.  ${ex}`);
		return;
	}

	if (`${color}`.indexOf('rgb') > -1) {
		if (color.split(',').length === 3) {
			let colors: Array<number> = color.split(',').map(c => parseInt(c.match(/\d+/).shift()));
			color = rgbToHex(colors.shift(),colors.shift(),colors.shift());
		}
		else if (color.split(',').length === 4) {
			// includes alpha channel.
			color = '';
		}
	}

	return color && `${color}`.toUpperCase();
}

@Component({
  selector: 'rv-ui-page-colors',
  templateUrl: './ui-colors.component.html',
  styleUrls: ['./ui-colors.component.scss']
})
export class UiColorsComponent implements OnInit {

  primaries: Array<string> 		=  	Object.keys(RV_COLORS.primary);
  secondaries: Array<string> 	=  	Object.keys(RV_COLORS.secondary);
  greyscales: Array<string> 	=  	Object.keys(RV_COLORS.greyscale);
  semantics: Array<string> 		=  	Object.keys(RV_COLORS.semantic);

  groups = (): Array<string> => Object.keys(RV_COLORS);
	hex = (color: string): string => COLORS[`color-${color}`];
	rgb = (color: string): string => hexToRgb(this.hex(color)).join(', ');

  constructor () 
  {}

  ngOnInit (): void 
  {}
}
