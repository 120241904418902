import { Component, Input } from '@angular/core';

@Component({
  selector: 'rv-google-button',
  templateUrl: './google-button.component.html',
  styleUrls: ['./google-button.component.scss']
})
export class GoogleButtonComponent {

	@Input()
	label: string = 'Continue with Google';
}
