import { Component, OnInit, Input } from '@angular/core';
import { ModelInstance } from '@getrearview/model-builder';
import { ChestService } from './../shared/chest.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'milestone-preview',
  templateUrl: './milestone-preview.component.html',
  styleUrls: ['./milestone-preview.component.scss']
})
export class MilestonePreviewComponent implements OnInit {

	@Input('Milestone')
	public Milestone: ModelInstance;

	private _preview$: Subscription;
	public Preview: ModelInstance;

	public images: Array<string> = [];
	public members: Array<string> = [];
	public mediaTypes: Array<string> = [];
	public hashtags: Array<string> = [];

	get classes (): string
	{
		let m = {
			AUD: 'has-audio',
			VID: 'has-video',
			TEXT: 'has-copy'
		}

		return Object.keys(m).filter(k => this.mediaTypes.includes(k)).map(k => m[k]).join(' ');
	}

	onPreviewLoaded (Preview: ModelInstance)
	{
		let images: Array<string> = Preview?.attribs?.images.map(m => m.replace(/memory-medias\//,''))||[];
		if ((images||[]).length !== this.images.length)
			this.images = images;

		let members: Array<string> = Preview?.attribs?.members||[];
		if ((members||[]).length !== this.members.length)
			this.members = members;

		let mediaTypes: Array<string> = Preview?.attribs?.media_types||[];
		if ((mediaTypes||[]).length !== this.mediaTypes.length)
			this.mediaTypes = mediaTypes;

		let hashtags: Array<string> = Preview?.attribs?.hashtags||[];
		if ((hashtags||[]).length !== this.hashtags.length)
			this.hashtags = hashtags;
	}

  constructor (public chestSrvc: ChestService) 
  {}

  ngOnInit (): void 
  {
  	this._preview$ = this.chestSrvc.onPreview(`${this.Milestone.id()}`).subscribe(P => this.onPreviewLoaded(P))
  }

  ngOnDestroy (): void
  {
  	if (this._preview$)
  		this._preview$.unsubscribe();
  }
}