<div id="ui-page-buttons-component" class="container-fluid">
	<div class="row">
		<div class="col-24 text-center mt-4 mb-4">
			<h4>UI Buttons</h4>
		</div>
		<div class="col-24 text-center">
			<table>
				<tbody>
					<tr>
						<td colspan="3"></td>
						<td colspan="3" style='border-bottom: 1px solid #5E36DD;'>Label: True</td>
						<td colspan="3" style='border-bottom: 1px solid #5E36DD;'>Label: False</td>
					</tr>

					<tr>
						<td colspan="3"></td>
						<td>Large</td>
						<td>Medium</td>
						<td>Small</td>
						<td>Large</td>
						<td>Medium</td>
						<td>Small</td>
					</tr>

					<tr>
						<td rowspan="6">Right Icon: False</td>
						<td rowspan="3">Left Icon: False</td>
						<td>Default</td>
						<td><rv-button lg label='Button'></rv-button></td>
						<td><rv-button md label='Button'></rv-button></td>
						<td><rv-button sm label='Button'></rv-button></td>
						<td><rv-button lg></rv-button></td>
						<td><rv-button md></rv-button></td>
						<td><rv-button sm></rv-button></td>
					</tr>

					<tr>
						<td>Disabled</td>
						<td><rv-button [disabled]="true" lg label='Button'></rv-button></td>
						<td><rv-button [disabled]="true" md label='Button'></rv-button></td>
						<td><rv-button [disabled]="true" sm label='Button'></rv-button></td>
						<td><rv-button [disabled]="true" lg></rv-button></td>
						<td><rv-button [disabled]="true" md></rv-button></td>
						<td><rv-button [disabled]="true" sm></rv-button></td>
					</tr>

					<tr>
						<td>Pressed</td>
						<td><rv-button [active]="true" active lg label='Button'></rv-button></td>
						<td><rv-button [active]="true" focus md label='Button'></rv-button></td>
						<td><rv-button [active]="true" focused sm label='Button'></rv-button></td>
						<td><rv-button [active]="true" lg></rv-button></td>
						<td><rv-button [active]="true" md></rv-button></td>
						<td><rv-button [active]="true" sm></rv-button></td>
					</tr>

					<tr>
						<td rowspan="3">Right Icon: False</td>
						<td>Default</td>
						<td><rv-button lg iconLeft='arrows.left-arrow' label='Button'></rv-button></td>
						<td><rv-button md iconLeft='arrows.left-arrow' label='Button'></rv-button></td>
						<td><rv-button sm iconLeft='arrows.left-arrow' label='Button'></rv-button></td>
						<td><rv-button lg iconLeft='arrows.left-arrow'></rv-button></td>
						<td><rv-button md iconLeft='arrows.left-arrow'></rv-button></td>
						<td><rv-button sm iconLeft='arrows.left-arrow'></rv-button></td>
					</tr>

					<tr>
						<td>Disabled</td>
						<td><rv-button [disabled]="true" lg iconLeft='arrows.left-arrow' label='Button'></rv-button></td>
						<td><rv-button [disabled]="true" md iconLeft='arrows.left-arrow' label='Button'></rv-button></td>
						<td><rv-button [disabled]="true" sm iconLeft='arrows.left-arrow' label='Button'></rv-button></td>
						<td><rv-button [disabled]="true" lg iconLeft='arrows.left-arrow'></rv-button></td>
						<td><rv-button [disabled]="true" md iconLeft='arrows.left-arrow'></rv-button></td>
						<td><rv-button [disabled]="true" sm iconLeft='arrows.left-arrow'></rv-button></td>
					</tr>

					<tr>
						<td>Pressed</td>
						<td><rv-button [active]="true" lg iconLeft='arrows.left-arrow' label='Button'></rv-button></td>
						<td><rv-button [active]="true" md iconLeft='arrows.left-arrow' label='Button'></rv-button></td>
						<td><rv-button [active]="true" sm iconLeft='arrows.left-arrow' label='Button'></rv-button></td>
						<td><rv-button [active]="true" lg iconLeft='arrows.left-arrow'></rv-button></td>
						<td><rv-button [active]="true" md iconLeft='arrows.left-arrow'></rv-button></td>
						<td><rv-button [active]="true" sm iconLeft='arrows.left-arrow'></rv-button></td>
					</tr>

					<tr>
						<td rowspan="6">Right Icon: True</td>
						<td rowspan="3">Left Icon: False</td>
						<td>Default</td>
						<td><rv-button lg label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button md label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button sm label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button lg iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button md iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button sm iconRight='arrows.right-arrow'></rv-button></td>
					</tr>

					<tr>
						<td>Disabled</td>
						<td><rv-button [disabled]="true" lg label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button [disabled]="true" md label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button [disabled]="true" sm label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button [disabled]="true" lg iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button [disabled]="true" md iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button [disabled]="true" sm iconRight='arrows.right-arrow'></rv-button></td>
					</tr>

					<tr>
						<td>Pressed</td>
						<td><rv-button [active]="true" lg label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button [active]="true" md label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button [active]="true" sm label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button [active]="true" lg iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button [active]="true" md iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button [active]="true" sm iconRight='arrows.right-arrow'></rv-button></td>
					</tr>

					<tr>
						<td rowspan="3">Right Icon: False</td>
						<td>Default</td>
						<td><rv-button lg iconLeft='arrows.left-arrow' label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button md iconLeft='arrows.left-arrow' label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button sm iconLeft='arrows.left-arrow' label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button lg iconLeft='arrows.left-arrow' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button md iconLeft='arrows.left-arrow' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button sm iconLeft='arrows.left-arrow' iconRight='arrows.right-arrow'></rv-button></td>
					</tr>

					<tr>
						<td>Disabled</td>
						<td><rv-button [disabled]="true" lg iconLeft='arrows.left-arrow' label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button [disabled]="true" md iconLeft='arrows.left-arrow' label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button [disabled]="true" sm iconLeft='arrows.left-arrow' label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button [disabled]="true" lg iconLeft='arrows.left-arrow' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button [disabled]="true" md iconLeft='arrows.left-arrow' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button [disabled]="true" sm iconLeft='arrows.left-arrow' iconRight='arrows.right-arrow'></rv-button></td>
					</tr>

					<tr>
						<td>Pressed</td>
						<td><rv-button [active]="true" lg iconLeft='arrows.left-arrow' label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button [active]="true" md iconLeft='arrows.left-arrow' label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button [active]="true" sm iconLeft='arrows.left-arrow' label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button [active]="true" lg iconLeft='arrows.left-arrow' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button [active]="true" md iconLeft='arrows.left-arrow' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button [active]="true" sm iconLeft='arrows.left-arrow' iconRight='arrows.right-arrow'></rv-button></td>
					</tr>
				</tbody>
			</table>
			<hr />
			<table>
				<tbody>
					<tr>
						<td colspan="3"></td>
						<td colspan="3" style='border-bottom: 1px solid #5E36DD;'>Label: True</td>
						<td colspan="3" style='border-bottom: 1px solid #5E36DD;'>Label: False</td>
					</tr>

					<tr>
						<td colspan="3"></td>
						<td>Large</td>
						<td>Medium</td>
						<td>Small</td>
						<td>Large</td>
						<td>Medium</td>
						<td>Small</td>
					</tr>

					<tr>
						<td rowspan="6">Right Icon: False</td>
						<td rowspan="3">Left Icon: False</td>
						<td>Default</td>
						<td><rv-button outline lg label='Button'></rv-button></td>
						<td><rv-button outline md label='Button'></rv-button></td>
						<td><rv-button outline sm label='Button'></rv-button></td>
						<td><rv-button outline lg></rv-button></td>
						<td><rv-button outline md></rv-button></td>
						<td><rv-button outline sm></rv-button></td>
					</tr>

					<tr>
						<td>Disabled</td>
						<td><rv-button outline [disabled]="true" lg label='Button'></rv-button></td>
						<td><rv-button outline [disabled]="true" md label='Button'></rv-button></td>
						<td><rv-button outline [disabled]="true" sm label='Button'></rv-button></td>
						<td><rv-button outline [disabled]="true" lg></rv-button></td>
						<td><rv-button outline [disabled]="true" md></rv-button></td>
						<td><rv-button outline [disabled]="true" sm></rv-button></td>
					</tr>

					<tr>
						<td>Pressed</td>
						<td><rv-button outline [active]="true" active lg label='Button'></rv-button></td>
						<td><rv-button outline [active]="true" focus md label='Button'></rv-button></td>
						<td><rv-button outline [active]="true" focused sm label='Button'></rv-button></td>
						<td><rv-button outline [active]="true" lg></rv-button></td>
						<td><rv-button outline [active]="true" md></rv-button></td>
						<td><rv-button outline [active]="true" sm></rv-button></td>
					</tr>

					<tr>
						<td rowspan="3">Right Icon: False</td>
						<td>Default</td>
						<td><rv-button outline lg iconLeft='arrows.left-arrow' label='Button'></rv-button></td>
						<td><rv-button outline md iconLeft='arrows.left-arrow' label='Button'></rv-button></td>
						<td><rv-button outline sm iconLeft='arrows.left-arrow' label='Button'></rv-button></td>
						<td><rv-button outline lg iconLeft='arrows.left-arrow'></rv-button></td>
						<td><rv-button outline md iconLeft='arrows.left-arrow'></rv-button></td>
						<td><rv-button outline sm iconLeft='arrows.left-arrow'></rv-button></td>
					</tr>

					<tr>
						<td>Disabled</td>
						<td><rv-button outline [disabled]="true" lg iconLeft='arrows.left-arrow' label='Button'></rv-button></td>
						<td><rv-button outline [disabled]="true" md iconLeft='arrows.left-arrow' label='Button'></rv-button></td>
						<td><rv-button outline [disabled]="true" sm iconLeft='arrows.left-arrow' label='Button'></rv-button></td>
						<td><rv-button outline [disabled]="true" lg iconLeft='arrows.left-arrow'></rv-button></td>
						<td><rv-button outline [disabled]="true" md iconLeft='arrows.left-arrow'></rv-button></td>
						<td><rv-button outline [disabled]="true" sm iconLeft='arrows.left-arrow'></rv-button></td>
					</tr>

					<tr>
						<td>Pressed</td>
						<td><rv-button outline [active]="true" lg iconLeft='arrows.left-arrow' label='Button'></rv-button></td>
						<td><rv-button outline [active]="true" md iconLeft='arrows.left-arrow' label='Button'></rv-button></td>
						<td><rv-button outline [active]="true" sm iconLeft='arrows.left-arrow' label='Button'></rv-button></td>
						<td><rv-button outline [active]="true" lg iconLeft='arrows.left-arrow'></rv-button></td>
						<td><rv-button outline [active]="true" md iconLeft='arrows.left-arrow'></rv-button></td>
						<td><rv-button outline [active]="true" sm iconLeft='arrows.left-arrow'></rv-button></td>
					</tr>

					<tr>
						<td rowspan="6">Right Icon: True</td>
						<td rowspan="3">Left Icon: False</td>
						<td>Default</td>
						<td><rv-button outline lg label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button outline md label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button outline sm label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button outline lg iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button outline md iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button outline sm iconRight='arrows.right-arrow'></rv-button></td>
					</tr>

					<tr>
						<td>Disabled</td>
						<td><rv-button outline [disabled]="true" lg label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button outline [disabled]="true" md label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button outline [disabled]="true" sm label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button outline [disabled]="true" lg iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button outline [disabled]="true" md iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button outline [disabled]="true" sm iconRight='arrows.right-arrow'></rv-button></td>
					</tr>

					<tr>
						<td>Pressed</td>
						<td><rv-button outline [active]="true" lg label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button outline [active]="true" md label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button outline [active]="true" sm label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button outline [active]="true" lg iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button outline [active]="true" md iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button outline [active]="true" sm iconRight='arrows.right-arrow'></rv-button></td>
					</tr>

					<tr>
						<td rowspan="3">Right Icon: False</td>
						<td>Default</td>
						<td><rv-button outline lg iconLeft='arrows.left-arrow' label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button outline md iconLeft='arrows.left-arrow' label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button outline sm iconLeft='arrows.left-arrow' label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button outline lg iconLeft='arrows.left-arrow' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button outline md iconLeft='arrows.left-arrow' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button outline sm iconLeft='arrows.left-arrow' iconRight='arrows.right-arrow'></rv-button></td>
					</tr>

					<tr>
						<td>Disabled</td>
						<td><rv-button outline [disabled]="true" lg iconLeft='arrows.left-arrow' label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button outline [disabled]="true" md iconLeft='arrows.left-arrow' label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button outline [disabled]="true" sm iconLeft='arrows.left-arrow' label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button outline [disabled]="true" lg iconLeft='arrows.left-arrow' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button outline [disabled]="true" md iconLeft='arrows.left-arrow' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button outline [disabled]="true" sm iconLeft='arrows.left-arrow' iconRight='arrows.right-arrow'></rv-button></td>
					</tr>

					<tr>
						<td>Pressed</td>
						<td><rv-button outline [active]="true" lg iconLeft='arrows.left-arrow' label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button outline [active]="true" md iconLeft='arrows.left-arrow' label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button outline [active]="true" sm iconLeft='arrows.left-arrow' label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button outline [active]="true" lg iconLeft='arrows.left-arrow' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button outline [active]="true" md iconLeft='arrows.left-arrow' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button outline [active]="true" sm iconLeft='arrows.left-arrow' iconRight='arrows.right-arrow'></rv-button></td>
					</tr>
				</tbody>
			</table>
			<hr />
			<table>
				<tbody>
					<tr>
						<td colspan="3"></td>
						<td colspan="3" style='border-bottom: 1px solid #5E36DD;'>Label: True</td>
						<td colspan="3" style='border-bottom: 1px solid #5E36DD;'>Label: False</td>
					</tr>

					<tr>
						<td colspan="3"></td>
						<td>Large</td>
						<td>Medium</td>
						<td>Small</td>
						<td>Large</td>
						<td>Medium</td>
						<td>Small</td>
					</tr>

					<tr>
						<td rowspan="6">Right Icon: False</td>
						<td rowspan="3">Left Icon: False</td>
						<td>Default</td>
						<td><rv-button open lg label='Button'></rv-button></td>
						<td><rv-button open md label='Button'></rv-button></td>
						<td><rv-button open sm label='Button'></rv-button></td>
						<td><rv-button open lg></rv-button></td>
						<td><rv-button open md></rv-button></td>
						<td><rv-button open sm></rv-button></td>
					</tr>

					<tr>
						<td>Disabled</td>
						<td><rv-button open [disabled]="true" lg label='Button'></rv-button></td>
						<td><rv-button open [disabled]="true" md label='Button'></rv-button></td>
						<td><rv-button open [disabled]="true" sm label='Button'></rv-button></td>
						<td><rv-button open [disabled]="true" lg></rv-button></td>
						<td><rv-button open [disabled]="true" md></rv-button></td>
						<td><rv-button open [disabled]="true" sm></rv-button></td>
					</tr>

					<tr>
						<td>Pressed</td>
						<td><rv-button open [active]="true" active lg label='Button'></rv-button></td>
						<td><rv-button open [active]="true" focus md label='Button'></rv-button></td>
						<td><rv-button open [active]="true" focused sm label='Button'></rv-button></td>
						<td><rv-button open [active]="true" lg></rv-button></td>
						<td><rv-button open [active]="true" md></rv-button></td>
						<td><rv-button open [active]="true" sm></rv-button></td>
					</tr>

					<tr>
						<td rowspan="3">Right Icon: False</td>
						<td>Default</td>
						<td><rv-button open lg iconLeft='arrows.left-arrow' label='Button'></rv-button></td>
						<td><rv-button open md iconLeft='arrows.left-arrow' label='Button'></rv-button></td>
						<td><rv-button open sm iconLeft='arrows.left-arrow' label='Button'></rv-button></td>
						<td><rv-button open lg iconLeft='arrows.left-arrow'></rv-button></td>
						<td><rv-button open md iconLeft='arrows.left-arrow'></rv-button></td>
						<td><rv-button open sm iconLeft='arrows.left-arrow'></rv-button></td>
					</tr>

					<tr>
						<td>Disabled</td>
						<td><rv-button open [disabled]="true" lg iconLeft='arrows.left-arrow' label='Button'></rv-button></td>
						<td><rv-button open [disabled]="true" md iconLeft='arrows.left-arrow' label='Button'></rv-button></td>
						<td><rv-button open [disabled]="true" sm iconLeft='arrows.left-arrow' label='Button'></rv-button></td>
						<td><rv-button open [disabled]="true" lg iconLeft='arrows.left-arrow'></rv-button></td>
						<td><rv-button open [disabled]="true" md iconLeft='arrows.left-arrow'></rv-button></td>
						<td><rv-button open [disabled]="true" sm iconLeft='arrows.left-arrow'></rv-button></td>
					</tr>

					<tr>
						<td>Pressed</td>
						<td><rv-button open [active]="true" lg iconLeft='arrows.left-arrow' label='Button'></rv-button></td>
						<td><rv-button open [active]="true" md iconLeft='arrows.left-arrow' label='Button'></rv-button></td>
						<td><rv-button open [active]="true" sm iconLeft='arrows.left-arrow' label='Button'></rv-button></td>
						<td><rv-button open [active]="true" lg iconLeft='arrows.left-arrow'></rv-button></td>
						<td><rv-button open [active]="true" md iconLeft='arrows.left-arrow'></rv-button></td>
						<td><rv-button open [active]="true" sm iconLeft='arrows.left-arrow'></rv-button></td>
					</tr>

					<tr>
						<td rowspan="6">Right Icon: True</td>
						<td rowspan="3">Left Icon: False</td>
						<td>Default</td>
						<td><rv-button open lg label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button open md label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button open sm label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button open lg iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button open md iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button open sm iconRight='arrows.right-arrow'></rv-button></td>
					</tr>

					<tr>
						<td>Disabled</td>
						<td><rv-button open [disabled]="true" lg label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button open [disabled]="true" md label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button open [disabled]="true" sm label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button open [disabled]="true" lg iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button open [disabled]="true" md iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button open [disabled]="true" sm iconRight='arrows.right-arrow'></rv-button></td>
					</tr>

					<tr>
						<td>Pressed</td>
						<td><rv-button open [active]="true" lg label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button open [active]="true" md label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button open [active]="true" sm label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button open [active]="true" lg iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button open [active]="true" md iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button open [active]="true" sm iconRight='arrows.right-arrow'></rv-button></td>
					</tr>

					<tr>
						<td rowspan="3">Right Icon: False</td>
						<td>Default</td>
						<td><rv-button open lg iconLeft='arrows.left-arrow' label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button open md iconLeft='arrows.left-arrow' label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button open sm iconLeft='arrows.left-arrow' label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button open lg iconLeft='arrows.left-arrow' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button open md iconLeft='arrows.left-arrow' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button open sm iconLeft='arrows.left-arrow' iconRight='arrows.right-arrow'></rv-button></td>
					</tr>

					<tr>
						<td>Disabled</td>
						<td><rv-button open [disabled]="true" lg iconLeft='arrows.left-arrow' label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button open [disabled]="true" md iconLeft='arrows.left-arrow' label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button open [disabled]="true" sm iconLeft='arrows.left-arrow' label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button open [disabled]="true" lg iconLeft='arrows.left-arrow' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button open [disabled]="true" md iconLeft='arrows.left-arrow' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button open [disabled]="true" sm iconLeft='arrows.left-arrow' iconRight='arrows.right-arrow'></rv-button></td>
					</tr>

					<tr>
						<td>Pressed</td>
						<td><rv-button open [active]="true" lg iconLeft='arrows.left-arrow' label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button open [active]="true" md iconLeft='arrows.left-arrow' label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button open [active]="true" sm iconLeft='arrows.left-arrow' label='Button' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button open [active]="true" lg iconLeft='arrows.left-arrow' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button open [active]="true" md iconLeft='arrows.left-arrow' iconRight='arrows.right-arrow'></rv-button></td>
						<td><rv-button open [active]="true" sm iconLeft='arrows.left-arrow' iconRight='arrows.right-arrow'></rv-button></td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
