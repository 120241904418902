import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'rv-dropdown-widget',
  templateUrl: './dropdown-menu-widget.component.html',
  styleUrls: ['./dropdown-menu-widget.component.scss']
})
export class DropdownMenuWidgetComponent {

	@Input() isOpen: boolean = false;

	@Input('position') _position!: string;
	get position (): string
	{
		return this._position?`p-${this._position}`:'';
	}

	get classes (): Array<string>
	{
		return [this.position].filter(n => n);
	}
}
