import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownContentDirective } from './dropdown-content.directive';
import { DropdownContainerDirective } from './dropdown-container.directive';

@NgModule({
  declarations: [
    DropdownContentDirective,
    DropdownContainerDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
  	DropdownContentDirective,
  	DropdownContainerDirective
  ]
})
export class InterfaceDirectivesModule { }
