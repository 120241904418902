import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoWidgetComponent } from './logo-widget/logo-widget.component';
import { SymbolComponent } from './symbol/symbol.component';


@NgModule({
  declarations: [LogoWidgetComponent, SymbolComponent],
  imports: [
    CommonModule
  ],
  exports: [
  	LogoWidgetComponent,
  	SymbolComponent
  ]
})
export class LogoModule { }