import { Component, OnInit } from '@angular/core';
import { SessionUser } from './../../../core/session/session-user.model'
import { FormBuilder, Validators } from '@angular/forms';
import { ModelInstance } from '@getrearview/model-builder'
import { AccountService } from './../../../models/accounts/account.service'
import { UserService } from './../../../models/users/user.service'

@Component({
  selector: 'rv-user-account-list',
  templateUrl: './user-account-list.component.html',
  styleUrls: ['./user-account-list.component.scss']
})
export class UserAccountListComponent implements OnInit {

	isSaving: boolean = false;
	EditAccount!: ModelInstance;
	
	editAccount (Account: ModelInstance): void
	{
		this.EditAccount = Account;
	}

	private fetchAccounts = async (): Promise<void> =>
	{
		const userId: string = `${this.SessionUser.instance.id()}`;
		this.AccountSrvc.set(this.AccountSrvc.factory().create())

		try {
			this.AccountSrvc.set(await this.AccountSrvc.search({_relationships:{user:{id:userId}, phone: true}}));
		}
		catch (ex) {
			console.error(`Failed to fetch user accounts in user-account-list. ${ex}`);
		}
	}

	constructor (private fb: FormBuilder, private SessionUser: SessionUser,public AccountSrvc: AccountService, private UserSrvc: UserService)
	{}

	ngOnInit (): void 
	{
		this.fetchAccounts()
	}
}
