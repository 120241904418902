<div class="memory-media-url">
	<div class="row">
		<div class="col-24">
			<div class="image-wrapper" [ngClass]="{'is-clickable': isClickable}">
				<a href="{{href}}" target="_BLANK" rel="noopener noreferrer nofollow" (click)="onImageClick($event)" (load)="onImageLoad($event)">
					<div class="clickable-icon">
						<rv-icon group='user-interface' icon='external-link'></rv-icon>
					</div>
					<img #imgContainer />
				</a>
			</div>
		</div>
	</div>
</div>