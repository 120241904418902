<div id="social-login-component" class="container-fluid auth-form-component">
	<div class="row">
		<div class="col-24">
			<div class="font-color--gray text-center rv-heading-sm">Sign in to Rearview</div>
			<form class="rv-form rv-auth-form rv-form-bg-blue" name="authForm" layout layout-align="center" layout-padding [ngClass]="{'is-submitting': isSubmitting}">
				<div class="btn-group-vertical btn-group-vertical-spaced" style='text-align: center;'>
					<rv-facebook-button (click)="signInWithFB()" class="mb-1" />
					<rv-google-button (click)="signInWithGoogle()" class="mb-3" />
					<rv-link label='Continue with Email' (click)="signInWithCognito()" style='margin:auto;' off-white />
				</div>
			</form>
			<rv-auth-notifications></rv-auth-notifications>
		</div>
	</div>
</div>