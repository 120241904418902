<form id="milestone-location-creator-widget-component" class="container-fluid" [formGroup]="milestoneForm">

	<div class="row">
		<div class="col-24">
			<rv-datepicker [disabled]="isEditing||isFetching" [formGroup]="milestoneForm" placeholder="When's this take place?" name="start_at" controlName="start_at" iconLeft="business.calendar" />
		</div>
	</div>

	<div class="row">
		<div class="col-24">
			<rv-location-selector [formGroup]="milestoneForm" [control]="milestoneForm.get('place_id')" controlName="place_id" />
		</div>
	</div>

	<div class="row mt-4">
		<div class="col-12">
			<rv-button outline purple md block label="Cancel" (click)="onCancel.emit()"/>
		</div>
		<div class="col-12">
			<rv-button [purple]="milestoneForm.valid && milestoneForm.dirty" [gray]="!milestoneForm.valid || milestoneForm.pristine" [disabled]="!milestoneForm.valid || milestoneForm.pristine" md block label="Add" (click)="milestoneForm.valid && saveLocation()" />
		</div>
	</div>
</form>
