<div class="milestone-component">
	<div class="milestone-title">
		<div [routerLink]="['/milestones',Milestone.id()]"><h6 class="font-color--purple">{{Milestone.get('nickname')}}</h6></div>
		<div rvDropdownContainer class="context-wrapper"> 
			<rv-button (click)="isOpen = !isOpen" open sm iconLeft="user-interface.menu"></rv-button>
			<div [rvDropdownContent]="!!isOpen" [position]="contextMenuPosition">
				<div>
					<ul class="context-menu">
					<li><rv-button (click)="shareMilestone()" open sm dk-gray iconLeft="user-interface.share" label="Share Event" /></li>
					<li><rv-button (click)="editMilestone()" open sm dk-gray iconLeft="user-interface.pencil" label="Edit" /></li>
					<li><rv-button (click)="deleteMilestone()" open sm red iconLeft="user-interface.trash" label="Delete" /></li>
					</ul>
				</div>
			</div>
			<!--
			<rv-dropdown-widget [isOpen]="isOpen" position="right">
				<ul class="context-menu">
					<li><rv-button (click)="shareMilestone()" open sm dk-gray iconLeft="user-interface.share" label="Share Event" /></li>
					<li><rv-button (click)="editMilestone()" open sm dk-gray iconLeft="user-interface.pencil" label="Edit" /></li>
					<li><rv-button (click)="deleteMilestone()" open sm red iconLeft="user-interface.trash" label="Delete" /></li>
				</ul>
			</rv-dropdown-widget>
			-->
			<div [rvDropdownContent]="!!isOpen" [position]="contextMenuPosition">
				<div>
					<ul class="context-menu">
						<li><rv-button *ngIf="can_share" (click)="shareMilestone()" open sm dk-gray iconLeft="user-interface.share" label="Share Event" /></li>
						<li><rv-button *ngIf="can_edit" (click)="editMilestone()" open sm dk-gray iconLeft="user-interface.pencil" label="Edit" /></li>
						<li><rv-button *ngIf="can_delete" (click)="deleteMilestone()" open sm red iconLeft="user-interface.trash" label="Delete" /></li>
					</ul>
				</div>
			</div>
		</div>
	</div>
	<div class="milestone-description">{{Milestone.get('description')}}</div>
	<div class="milestone-preview">
		<milestone-preview [Milestone]="Milestone"></milestone-preview>
	</div>
	<div class="milestone-timestamp font-color--grey-400">Story beings on {{Milestone.get('start_at')|date:birthDateFormat}}</div>
</div>


<ng-template #milestoneInviter>
	<rv-memory-chest-inviter [MemoryChest]="MemoryChest" [Me]="Me" [Milestone]="Milestone" (onSuccess)="onSharedSuccess($event)" (onCancel)="modalService.close()" />
</ng-template>

<ng-template #milestoneEditor>
	<rv-milestone-editor-widget [MemoryChest]="MemoryChest" [Me]="Me" [Milestone]="Milestone"  (onSuccess)="onEditSuccess($event)" (onCancel)="modalService.close()" />
</ng-template>

<ng-template #milestoneDeleter>
	<rv-milestone-deleter-widget [MemoryChest]="MemoryChest" [Me]="Me" [Milestone]="Milestone" (onSuccess)="onDeletedSuccess($event)" (onCancel)="modalService.close()" />
</ng-template>
