import { Capacitor } from '@capacitor/core';

// ------------------------------------------------------------------------
// Request Conversion Utilities:
// const convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
//     const reader = new FileReader;
//     reader.onerror = reject;
//     reader.onload = () => resolve(reader.result);
//     reader.readAsDataURL(blob);
// });

type NativeInterfaceRequestOptions = {
	skipPermissions: boolean
}

export class NativeInterfaceRequest 
{
	#payload = null;
	#requestWasValid = null;
	#error: string = '';
	#requestOptions: NativeInterfaceRequestOptions = {skipPermissions: false}

	async checkPermissions (): Promise<boolean> {
		return (!Capacitor.isNative);
	}

	async execute (promiseObj: Promise<any>): Promise<any>
	{
		if (this.shouldCheckPermissions)
			this.checkPermissions()

		this.#payload = null;

		try { 
			this.#payload = await promiseObj; 
			this.#requestWasValid = true; 
		}
		catch (ex) { 
			this.#requestWasValid = false;
			this.#error = `${ex}`;
			return Promise.reject(`${ex}`); 
		}	

		return Promise.resolve(this);
	}

	get shouldCheckPermissions (): boolean
	{ 
		return this.getRequestOption('skipPermissions') !== true; 
	}

	getPayload = (): any => this.#payload;

	getRequestOption = opt => this.#requestOptions?.[opt]

	setRequestOptions = (opts: {[key: string]: any}): void =>
	{
		this.#requestOptions = {...this.#requestOptions, ...(opts ? opts : {})};
	}

	constructor (opts?: {[key: string]: any})
	{
		this.setRequestOptions(opts)
	}
}

/*
	// Create a new file from a capture image
	async saveImage(photo: Photo) 
	{
	  const base64Data = await this.readAsBase64(photo);

	  const fileName = new Date().getTime() + '.jpeg';
	  const savedFile = await Filesystem.writeFile({
	      path: `${IMAGE_DIR}/${fileName}`,
	      data: base64Data,
	      directory: Directory.Data
	  });

	  // Reload the file list
	  // Improve by only loading for the new image and unshifting array!
	  console.log({savedFile});
	  this.loadFiles();
	}

	// async startUpload(file: LocalFile) 
	async startUpload(file) 
	{
    const response = await fetch(file.data);
    const blob = await response.blob();
    const formData = new FormData();
    formData.append('file', blob, file.name);
    this.uploadData(formData);
	}

	// async deleteImage(file: LocalFile) 
	async deleteImage(file) 
	{
	  await Filesystem.deleteFile({directory: Directory.Data, path: file.path});
	  this.loadFiles();
	  // this.presentToast('File removed.');
	  console.log('File removed.');
	}

	async uploadData(formData: FormData) 
	{
	  // const loading = await this.loadingCtrl.create({message: 'Uploading image...',});
	  // await loading.present();

		// console.log("Upload Data: ",formData.getAll());
		return Promise.resolve();

	  // Use your own API!
	  // const url = 'http://localhost:8888/images/upload.php';
	  // this.http.post(url, formData)
	  //     .pipe(
	  //         finalize(() => {
	  //             loading.dismiss();
	  //         })
	  //     )
	  //     .subscribe(res => {
	  //         if (res['success']) {
	  //             this.presentToast('File upload complete.')
	  //         } else {
	  //             this.presentToast('File upload failed.')
	  //         }
	  //     });
	}




	// Get the actual base64 data of an image
	// base on the name of the file
	async loadFileData (fileNames: string[]) 
	{
		for (let f of fileNames) 
		{
			const filePath 	= 	`${IMAGE_DIR}/${f}`,
						readFile 	= 	await Filesystem.readFile({path: filePath, directory: Directory.Data});

			this.images.push({
				name: 		f,
				path: 		filePath,
				data: 		`data:image/jpeg;base64,${readFile.data}`
			});
		}
		
		console.log({loadFileData: this.images});
	}

	async loadFiles () 
	{
		this.images = [];

		// const loading = await this.loadingCtrl.create({
		// 	message: 'Loading data...'
		// });
		// await loading.present();

		Filesystem.readdir({
			path: IMAGE_DIR,
			directory: Directory.Data
		})
			.then(
				result => this.loadFileData(result.files.map((x) => x.name)),

				async (err) => {
					// Folder does not yet exists!
					console.log("Err:  ",err);
					await Filesystem.mkdir({path: IMAGE_DIR, directory: Directory.Data});
				}
			);
			// .then((_) => {
			// 	loading.dismiss();
			// });
	}

  // https://ionicframework.com/docs/angular/your-first-app/3-saving-photos
  private async readAsBase64(photo: Photo) 
  {
  	if (Capacitor.isNativePlatform()) {
      const file = await Filesystem.readFile({
          path: photo.path
      });

      return file.data;
  	}
  	else {
      // Fetch the photo, read as a blob, then convert to base64 format
      const response = await fetch(photo.webPath);
      const blob = await response.blob();

      return await convertBlobToBase64(blob) as string;
  	}

	  // if (this.plt.is('hybrid')) {
	  //     const file = await Filesystem.readFile({
	  //         path: photo.path
	  //     });

	  //     return file.data;
	  // }
	  // else {
	  //     // Fetch the photo, read as a blob, then convert to base64 format
	  //     const response = await fetch(photo.webPath);
	  //     const blob = await response.blob();

	  //     return await this.convertBlobToBase64(blob) as string;
	  // }
	}




	

// const takePicture = async () => {
//   const image = await Camera.getPhoto({
//     quality: 90,
//     allowEditing: true,
//     resultType: CameraResultType.Uri
//   });

//   // image.webPath will contain a path that can be set as an image src.
//   // You can access the original file using image.path, which can be
//   // passed to the Filesystem API to read the raw data of the image,
//   // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
//   var imageUrl = image.webPath;

//   // Can be set to the src of an image now
//   imageElement.src = imageUrl;
// };


*/