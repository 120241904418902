<div id="no-memories-component" *ngIf="storySrvc.getIsMilestoneEmpty">
	<div class="no-memories">
		<div class="photo-album mt-5">
			<svg width="238" height="195" viewBox="0 0 238 195" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M0.0297852 41.4272V186.398C0.0297852 189.018 2.07353 191.129 4.56136 191.076L111.428 188.904C111.428 188.904 113.544 194.101 119.183 194.473C124.822 194.844 129.053 188.904 129.053 188.904L229.712 191.667C233.862 191.781 237.281 188.27 237.281 183.906V44.5142C237.281 40.2267 233.977 36.749 229.903 36.749L135.5 39L119.183 41.4272L4.47541 36.749C2.021 36.749 0.0297852 38.8451 0.0297852 41.4272Z" fill="#E2E2E2"/>
				<path d="M120.243 184.797H228.308V46.1434L118.701 40.6364L120.243 184.797Z" fill="#E0E0E0"/>
				<path d="M175.754 165.437C162.761 164.317 149.524 164.508 136.875 165.704V120.789C149.166 118.674 164.164 118.083 175.644 119.265L175.749 165.437H175.754Z" fill="#F5F5F5"/>
				<path d="M228.208 184.625C228.125 184.583 228.006 184.524 227.851 184.45C227.518 184.289 227.021 184.057 226.367 183.77C225.057 183.196 223.115 182.402 220.581 181.519C215.514 179.754 208.083 177.637 198.624 176.226C179.73 173.407 152.744 173.406 120.342 184.657L118.806 40.7191L118.825 40.7155C118.892 40.7029 118.992 40.6842 119.123 40.6598C119.385 40.611 119.772 40.5394 120.271 40.4483C121.27 40.2662 122.719 40.0059 124.52 39.6935C128.121 39.0688 133.129 38.2358 138.756 37.4029C150.011 35.7368 163.736 34.0716 173.629 34.0716C185.47 34.0716 199.134 35.7345 209.84 37.398C215.193 38.2297 219.805 39.0614 223.079 39.6852C224.716 39.997 226.018 40.2569 226.911 40.4388C227.358 40.5298 227.702 40.6012 227.934 40.6499C228.051 40.6743 228.139 40.693 228.198 40.7055L228.208 40.7077V184.625Z" fill="white" stroke="#D6D5D5" stroke-width="0.2"/>
				<path d="M218.553 112.143C190.323 110.009 163.018 109.837 136.88 112.143V53.7752C165.11 51.0741 192.414 50.8597 218.553 53.7752V112.143Z" fill="#E0E0E0"/>
				<path d="M160.492 108.515L174.17 74.3172L183.729 98.8728L191.547 87.3775L202.036 109.57L160.492 108.515Z" fill="#C8C8C8"/>
				<path d="M190.098 63.8366C190.098 66.2077 188.171 68.1241 185.8 68.1241C183.424 68.1241 181.503 66.2017 181.503 63.8366C181.503 61.4654 183.43 59.549 185.8 59.549C188.177 59.549 190.098 61.4714 190.098 63.8366Z" fill="#C8C8C8"/>
				<path d="M183.77 118.55L182.595 164.559L219.466 165.497L220.641 119.488L183.77 118.55Z" fill="#FEFEFE"/>
				<path d="M186.054 120.258L184.961 163.033L217.187 163.853L218.28 121.077L186.054 120.258Z" fill="#EBEBEB"/>
				<path d="M184.955 163.026L195.566 136.482L202.981 155.542L209.046 146.619L217.182 163.846L184.955 163.026Z" fill="#E0E0E0"/>
				<path d="M207.823 128.621C207.823 130.484 206.31 131.989 204.447 131.989C202.58 131.989 201.071 130.479 201.071 128.621C201.071 126.759 202.585 125.253 204.447 125.253C206.314 125.253 207.823 126.763 207.823 128.621Z" fill="#E0E0E0"/>
				<path d="M173.085 117.788L136.206 118.353L136.915 164.372L173.793 163.807L173.085 117.788Z" fill="#FEFEFE"/>
				<path d="M170.79 119.472L138.557 119.966L139.216 162.75L171.448 162.256L170.79 119.472Z" fill="#E0E0E0"/>
				<path d="M161.505 127.668C161.505 129.531 159.991 131.037 158.129 131.037C156.262 131.037 154.753 129.526 154.753 127.668C154.753 125.806 156.266 124.3 158.129 124.3C159.996 124.3 161.505 125.811 161.505 127.668Z" fill="#D6D6D6"/>
				<path d="M138.944 160.438L148.705 133.571L156.723 152.387L162.5 143.277L171.181 160.236L138.944 160.438Z" fill="#D9D9D9"/>
				<path d="M118.191 40.7127L120.141 184.661C85.2854 173.771 48.8266 172.034 10.5342 180.775L9.89523 36.4778C44.3893 27.195 78.7057 32.3015 118.191 40.7127Z" fill="#EBEBEB" stroke="#D6D5D5" stroke-width="0.2"/>
				<path d="M120.141 184.586C120.079 184.536 119.999 184.473 119.902 184.398C119.633 184.189 119.23 183.885 118.693 183.501C117.619 182.734 116.01 181.649 113.869 180.379C109.587 177.84 103.175 174.558 94.6527 171.589C77.6261 165.657 52.1766 160.973 18.4566 165.935V22.3671C18.4832 22.3632 18.516 22.3584 18.5549 22.3528C18.6762 22.3352 18.8571 22.3095 19.0946 22.2772C19.5696 22.2126 20.2713 22.1214 21.1775 22.0154C22.9898 21.8033 25.6202 21.5316 28.8911 21.2934C35.4332 20.817 44.5369 20.4747 54.7837 21.0106C75.2666 22.082 100.3 26.6624 118.573 40.6813L120.141 184.586Z" fill="#F5F5F5" stroke="#D6D5D5" stroke-width="0.2"/>
				<path d="M95.7373 94.7354L104.896 149.039L32.8348 159.839L23.6761 105.535L95.7373 94.7354Z" fill="#EBEBEB"/>
				<path d="M34.7449 156.047L101.74 146.005L93.7365 98.5418L26.737 108.579L34.7449 156.047Z" fill="#868585"/>
				<g opacity="0.3">
				<path d="M34.7449 156.047L53.1672 123.038L70.7778 139.321L78.9433 127.983L101.74 146.005L34.7449 156.047Z" fill="black"/>
				</g>
				<g opacity="0.3">
				<path d="M73.2083 116.325C73.6715 119.074 71.6851 121.652 68.7722 122.09C65.8594 122.528 63.1233 120.656 62.6601 117.907C62.1969 115.163 64.1834 112.581 67.0962 112.143C70.009 111.704 72.7451 113.577 73.2083 116.325Z" fill="black"/>
				</g>
				<path d="M92.9915 37.111L91.2964 85.6316L27.1428 82.2682L28.838 33.7477L92.9915 37.111Z" fill="#EBEBEB"/>
				<path d="M29.4587 79.3289L89.1094 82.454L90.5896 40.0456L30.9438 36.9205L29.4587 79.3289Z" fill="#767676"/>
				<g opacity="0.3">
				<path d="M29.4587 79.3289L51.2141 54.0611L63.5434 71.2302L72.6066 62.8696L89.1094 82.454L29.4587 79.3289Z" fill="black"/>
				</g>
				<g opacity="0.3">
				<path d="M69.7129 51.7934C69.6269 54.2469 67.4543 56.1239 64.8614 55.9905C62.2685 55.8523 60.2343 53.7562 60.3203 51.3028C60.4062 48.8493 62.5789 46.9724 65.1718 47.1058C67.7646 47.2439 69.7988 49.34 69.7129 51.7934Z" fill="black"/>
				</g>
				<path d="M118.601 40.6827L120.135 184.502C120.109 184.468 120.079 184.432 120.047 184.391C119.866 184.167 119.593 183.838 119.227 183.419C118.495 182.583 117.388 181.389 115.888 179.96C112.888 177.101 108.315 173.298 102.021 169.521C89.4492 161.974 70.0131 154.526 42.5375 154.893V10.2096C42.542 10.2092 42.5467 10.2088 42.5515 10.2083C42.6207 10.2022 42.7242 10.1935 42.8612 10.1837C43.1351 10.164 43.5425 10.1396 44.0755 10.1207C45.1416 10.0829 46.7102 10.0672 48.718 10.1555C52.7337 10.3321 58.5061 10.925 65.5295 12.5908C79.5678 15.9203 98.605 23.5362 118.601 40.6827Z" fill="#FEFEFE" stroke="#D6D5D5" stroke-width="0.2"/>
				<path d="M109.824 32.3281L108.339 80.8438L52.279 77.4805L53.764 28.9648L109.824 32.3281Z" fill="#FEFEFE"/>
				<path d="M54.3084 74.5412L106.429 77.671L107.723 35.2579L55.6025 32.1328L54.3084 74.5412Z" fill="#EBEBEB"/>
				<path d="M54.3084 74.5412L73.3181 49.2733L84.0907 66.4425L92.0079 58.0866L106.429 77.6711L54.3084 74.5412Z" fill="#E0E0E0"/>
				<path d="M89.4818 47.0057C89.4054 49.4591 87.5097 51.3361 85.2415 51.2027C82.9734 51.0646 81.197 48.9685 81.2734 46.515C81.3498 44.0616 83.2456 42.1846 85.5137 42.318C87.7819 42.4514 89.5582 44.5523 89.4818 47.0057Z" fill="#E0E0E0"/>
				<path d="M114.074 102.777L109.198 151.074L53.511 143.818L58.3816 95.5215L114.074 102.777Z" fill="#FEFEFE"/>
				<path d="M55.7362 141.026L107.513 147.772L111.772 105.559L59.9956 98.8133L55.7362 141.026Z" fill="#EBEBEB"/>
				<path d="M55.7362 141.026L76.4697 117.145L86.0152 135.019L94.5005 127.235L107.513 147.772L55.7362 141.026Z" fill="#E0E0E0"/>
				<path d="M92.7528 116.006C92.5045 118.45 90.4798 120.189 88.2308 119.898C85.9769 119.603 84.3534 117.388 84.6017 114.944C84.85 112.5 86.8746 110.761 89.1237 111.052C91.3776 111.347 93.0011 113.562 92.7528 116.006Z" fill="#E0E0E0"/>
				<path d="M77.7157 144.288V0.3452L92.1723 6.83705C104.599 12.4181 114.024 23.1566 117.654 36.2612L117.654 36.2612C118.028 37.6089 118.266 38.7066 118.411 39.467C118.483 39.8472 118.532 40.143 118.563 40.3436C118.578 40.4439 118.589 40.5203 118.596 40.5715L118.604 40.6293L118.606 40.6431L118.606 40.6438L120.13 183.537C117.067 166.398 106.652 156.609 96.9027 151.094C91.9117 148.27 87.0944 146.566 83.5248 145.568C81.74 145.069 80.2667 144.746 79.2393 144.548C78.7256 144.449 78.3233 144.381 78.0492 144.338C77.9121 144.316 77.8071 144.301 77.7363 144.291C77.7291 144.29 77.7222 144.289 77.7157 144.288Z" fill="white" stroke="#D6D5D5" stroke-width="0.2"/>
				<path d="M88.5412 133.895L88.2928 89.0663C88.2928 89.0663 105.397 94.7354 114.074 110.656L114.389 150.569C114.389 150.569 105.154 137.935 88.5412 133.895Z" fill="#F5F5F5"/>
				<path d="M88.5411 133.895L100.407 120.575L105.149 130.136L109.977 123.247L114.389 150.569C114.389 150.569 107.135 138.954 88.5411 133.895Z" fill="#EBEBEB"/>
				<path d="M105.397 114.134C105.397 115.568 104.7 116.735 103.845 116.735C102.991 116.735 102.293 115.573 102.293 114.134C102.293 112.695 102.991 111.533 103.845 111.533C104.7 111.533 105.397 112.695 105.397 114.134Z" fill="#EBEBEB"/>
				<path d="M88.2928 71.2302L88.5889 25.8825C88.5889 25.8825 105.402 31.4181 114.608 48.3062L114.456 88.1564C114.456 88.1564 104.905 75.27 88.2976 71.2255L88.2928 71.2302Z" fill="#F5F5F5"/>
				<path d="M88.2928 71.2302L100.407 57.7769L105.149 67.3381L109.977 60.4495L114.451 88.1612C114.451 88.1612 106.472 75.4606 88.2928 71.2302Z" fill="#EBEBEB"/>
				<path d="M105.397 51.3361C105.397 52.77 104.7 53.9372 103.845 53.9372C102.991 53.9372 102.293 52.7748 102.293 51.3361C102.293 49.8974 102.991 48.735 103.845 48.735C104.7 48.735 105.397 49.8974 105.397 51.3361Z" fill="#EBEBEB"/>
			</svg>
		</div>
		<h6 class="font-color--dk-gray mt-4 mb-1">No Memories</h6><br />
		<div class="font-color--dk-gray" style='display:flex;justify-content:center;'><span>Click </span>&nbsp;<rv-icon purple group="user-interface" icon="plus" />&nbsp;<span> below to make your first Memory.</span></div>
	</div>
</div>