import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

const isActive = (val: any) => (typeof val === 'boolean' ? val : typeof val === 'string' && val === '')

@Component({
  selector: 'rv-form',
  template: '<form [formGroup]="formGroup" [name]="name" [class]="class" layout layout-align="center" layout-padding novalidate (ngSubmit)="handleSubmit()"><ng-content></ng-content></form>',
  styleUrls: ['./ui-form-widget.component.scss']
})
export class UiFormWidgetComponent {

	@Input('formGroup')
	formGroup!: FormGroup;

	@Input('reactive')
	private _reactive: boolean;
	get reactive (): boolean { return this._reactive; }
	set reactive (reactive: boolean) { this._reactive = isActive(reactive); }

	@Input('name')
	private _name: string;
	get name (): string { return `${this._name||''}`; }

	@Input('class')
	private _class: Array<string>|string;
	get class (): string { return `rv-form ${(Array.isArray(this._class)?this._class:[this._class]).join(' ')}`; }

	@Output('onSubmit')
	onSubmit: EventEmitter<void> = new EventEmitter();

	handleSubmit ()
	{
		try { this.onSubmit.emit() }
		catch (ex) { console.error(ex) }
	}
}
