import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModelInstance } from '@getrearview/model-builder';
import { BehaviorSubject } from 'rxjs';
import { MemberService } from 'src/app/models/memories/member.service';

@Component({
  selector: 'chests-list',
  templateUrl: './chests-list.component.html',
  styleUrls: ['./chests-list.component.scss']
})
export class ChestsListComponent {

	@Input('chests')
	chests: Array<ModelInstance> = []

	@Input('isOpen')
	isOpen: BehaviorSubject<boolean>;

	@Output('onSelect')
	onSelect: EventEmitter<string> = new EventEmitter()

	toggle () {
		this.isOpen.next(!this.isOpen.getValue());
	}

  constructor (public memberSrvc: MemberService)
  {}
}