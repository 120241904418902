import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ModelInstance } from '@getrearview/model-builder';

@Component({
  selector: 'fe-preview-img',
  templateUrl: './preview-img.component.html',
  styleUrls: ['./preview-img.component.scss']
})
export class PreviewImgComponent implements OnInit {

	@Input('Milestone')
	Milestone: ModelInstance;

	@Input('Preview')
	Preview: ModelInstance;

	@Input('filename')
	filename: string;

	@Input('primary')
	primary: boolean;

	url: string;

  constructor () 
  {}

  ngOnInit (): void 
	{
		this.url = `${environment.api.media_url}/chests/${[this.Milestone.get('c_id'),this.Milestone.id(),this.filename].join('/')}`;
	}

	ngOnChanges (): void
	{}
}
