import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';

function getFromEnvFile (key: string): any {
	let keys: Array<string> = key.split(".");

	if(keys.length === 1) {
		try 				{ return environment[key]; 																																				}
		catch(ex) 	{ console.error("ERROR: The "+key+" key does not exist as a config setting."); return undefined; 	}
	}

	key 							= 		keys.shift();
	var configVal 		= 		getFromEnvFile(key);

	if(configVal) {
		do {
			try { configVal =		configVal[keys.shift()]; }
			catch(ex) { configVal = undefined; }
		} while(keys.length > 0);
	}

	return 									configVal;
}

function getFromProcessEnv (key): any {
	switch (key) {
		case 'production': 																	return process.env['PRODUCTION'];
		case 'hmr': 																				return process.env['HMR'];
		case 'socialProviders.facebookAppKey': 							return process.env['FACEBOOK_SOCIAL_APP_KEY'];
		case 'socialProviders.googleClientId': 							return process.env['GOOGLE_SOCIAL_CLIENT_ID'];
		case 'socialProviders.cognitoUserPoolId': 					return process.env['COGNITO_SOCIAL_USER_POOL_ID'];
		case 'socialProviders.cognitoAppClientId': 					return process.env['COGNITO_SOCIAL_APP_CLIENT_ID'];
		case 'socialProviders.authServerUrl': 							return process.env['RV_AUTH_API_URL'];

		case 'api.api_url': 																return process.env['RV_API_URL'];
		case 'api.media_url': 															return process.env['RV_MEDIA_URL'];

		case 'integrations.stripe.key': 										return process.env['STRIPE_KEY'];
		case 'integrations.google.locations.api_key': 			return process.env['GOOGLE_LOCATION_API_KEY'];

		case 'app.auth.can_register': 											return process.env['APP_AUTH_CAN_REGISTER'];
		default: 
			return;
	}
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

	get (key: string): any
	{
		// local & dev envs
		// return getFromProcessEnv(key);
		// beta & prod envs
		return getFromEnvFile(key);
	}

  constructor () 
  {}
}