<div id="login-form-component" class="container-fluid auth-form-component">
	<div class="row">
		<div class="col-24">
			<div class="font-color--gray text-center rv-heading-sm">Sign in to Rearview</div>

			<form [formGroup]="authForm" class="rv-form rv-auth-form rv-form-bg-blue" name="authForm" layout layout-align="center" layout-padding novalidate (ngSubmit)="handleLogin()" #authFormRef [ngClass]="{'is-submitting': isSubmitting}">

				<div class="inputs mb-2">
					<div class="form-group">
						<input class="form-control" type="text" formControlName="email_or_phone" required placeholder="Email or Phone" />
					</div>
					<hr />
					<div class="form-group" [ngClass]="{'show-manual-error': hasManualError()}">
						<input class="form-control" type="password" formControlName="password" required placeholder="Password" />
						<!-- <ng-container *ngIf="foo === 1;else second"></ng-container>
						<ng-template #second>
						    <ng-container *ngIf="foo === 2;else third"></ng-container>
						</ng-template>
						<ng-template #third></ng-template> -->
						<span *ngIf="isManualError('UNKNOWN_ERROR')" class="manual-error">Unable to reach server</span>
						<span *ngIf="isManualError('INVALID_PASS')" class="manual-error">Invalid Password</span>
						<span *ngIf="isManualError('TOO_MANY_ATTEMPTS')" class="manual-error">Too Many Attempts.  Try again later.</span>
					</div>
				</div>

				<div class="buttons mb-5">
					<rv-button sm [disabled]="isSubmitting" class="btn-block" #submitBtnRef type='submit' aria-label="">
				  	<div class="btn-content" style='padding-left: 2rem;padding-right: 2rem;'>
				  		<div class="btn-label" [innerHTML]="isSubmitting ? 'Logging In ' : 'Login'"></div>
				  		<!-- <div class="btn-spinner">(spin)</div> -->
				  	</div>
					</rv-button>
				</div>

				<rv-link sm (click)="goToForgotPassword()" label='Forgot Password' off-white class='mb-3'/>
				<br />
				<rv-link sm (click)="goToLogin()" iconLeft='arrows.left-arrow-1' label='Back to social login' off-white icon-off-white />

			</form>

			<rv-auth-notifications></rv-auth-notifications>
		</div>
	</div>
</div>