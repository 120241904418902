import { Component, OnInit } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { ModelInstance } from '@getrearview/model-builder';
import { filter } from 'rxjs/operators';
import { NavigationStart } from '@angular/router'
import { SessionUser } from './../../../core/session/session-user.model';
import { ConfigService } from 'src/app/shared/services/config.service';
import { environment } from 'src/environments/environment';

const PROFILE_URL = `${environment.api.media_url}/users/{id}/{filename}`;

@Component({
  selector: 'rv-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {

	initial: string = '';
	usePhoto: boolean = false;
	showMenu: boolean = false;
	showExtendedMenu: boolean = false;

	onPhotoError () {
		this.usePhoto = false;
	}

	private Media: ModelInstance;

	private _subscriptions$: Subscription = new Subscription();
	url$: BehaviorSubject<string> = new BehaviorSubject('');

	private setUrl = (url: string): boolean => 
	{
		url = url?url:'';
		this.url$.next(`${url}`);
		return !!url;
	}

	private getMedia (Profile: ModelInstance): ModelInstance 
	{
		if (Profile && Profile?.id() && Profile.getChildren('media')) {
			return Object.values(Profile.getChildren('media')).filter((M: ModelInstance) => !!M.get('filename')).sort((A: ModelInstance, B: ModelInstance) => {
				return `${new Date(A.get('created_at')).getTime()}`.localeCompare(`${new Date(B.get('created_at')).getTime()}`, undefined, { numeric: true });
			}).pop() as ModelInstance;
		}
	}

	private setInitial (): void
	{
		this.initial = this.SessionUser?.instance && `${this.SessionUser.instance.get('name')}`.charAt(0);
	}

	private setProfileUrl = (Profile: ModelInstance): void => 
	{
		this.setInitial();

		let filename: string = '';
		if ((this.Media = this.getMedia(Profile)) && this.Media?.get('filename'))
			filename = `${this.Media.get('filename')}`.trim();

		if (filename) {
			this.setUrl(PROFILE_URL.replace('{id}',`${Profile.id()}`).replace('{filename}', filename));
			this.usePhoto = true;
		}
		else {
			this.usePhoto = false;
		}

		return;
	}

  constructor (public SessionUser: SessionUser, private ConfigSrvc: ConfigService)
  {}

  ngOnInit(): void 
  {
  	this.showExtendedMenu = (this.ConfigSrvc.get('production') === false && this.ConfigSrvc.get('hmr') === true);
  	this._subscriptions$.add(this.SessionUser.onProfileChange.subscribe(Profile => this.setProfileUrl(Profile)));
  	if (this.SessionUser.instance) this.setProfileUrl(this.SessionUser.instance);
  }

  ngOnDestroy (): void 
  {
  	this._subscriptions$.unsubscribe();
  }
}