import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { RouteData } from 'src/app/core/values/route-data.value';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';

const routes: Routes = [
	{ 
		path:                       'welcome',
		component:                  WelcomePageComponent,
		canActivate:                [AuthGuard],
		data: 											new RouteData({animation:'welcomePage',widgets:{'rv-header-widget':{enable:false}}})
	}
];

@NgModule({
  imports:                      [RouterModule.forChild(routes)],
  exports:                      [RouterModule]
})
export class WelcomeRoutingModule { }