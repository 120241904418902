<form 
	[formGroup]="feedFilterForm" 
	id="memory-chest-filter-component"
	class="rv-form rv-new-account-form rv-form-bg-blue" 
	name="feedFilterForm" 
	layout 
	layout-align="center" 
	layout-padding 
	novalidate 
	(ngSubmit)="handleSubmit()" 
	#newAcctFormRef
>
	<filter-memory-chest-selector [formGroup]="feedFilterForm" name="memoryChest" controlName="memoryChest" />
	<filter-member-selector [formGroup]="feedFilterForm" name="selectPerson" controlName="selectPerson" />
	<!--
	<rv-location-selector
		[formGroup]="feedFilterForm" 
		[controlName]="'location'"
		[control]="feedFilterForm.get('location')"
	></rv-location-selector>
	-->
	<rv-input 
		class="my-3" 
		iconLeft="user-interface.date" 
		placeholder="Initial Date" 
		[formGroup]="feedFilterForm" 
		name="initialDate" 
		controlName="initialDate"
		mask="00/00/0000"
		reactive
	></rv-input>
	<rv-input 
		class="mb-3 position-relative"
		iconLeft="monochrome.house-user" 
		placeholder="Hashtags" 
		[formGroup]="feedFilterForm" 
		name="hashtags" 
		controlName="hashtags"
	>
		<div class="hash-icon position-absolute">#</div>
	</rv-input>

	<div class="mt-4 d-flex justify-content-between align-items-center">
		<button type="button" class="cancel-button" (click)="closeFilter()">Cancel</button>
		<button type="submit" class="submit-button bg-color--purple-500">Submit</button>
	</div>
</form>