import { Component, OnInit, Input, SimpleChanges, SimpleChange } from '@angular/core';
import { ModelInstance } from '@getrearview/model-builder';
import { StoryService } from './../shared/story.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

const COVER_IMAGE_URL: string = `${environment.api.media_url}chests/{chestId}/{milestoneId}/header.jpg`;

@Component({
  selector: 'rv-milestone-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit  {

	@Input('Milestone')
	Milestone: ModelInstance;

	@Input('NextMilestone')
	NextMilestone: ModelInstance;

	@Input('PreviousMilestone')
	PreviousMilestone: ModelInstance;

	@Input('isMilestoneEmpty')
	isMilestoneEmpty: boolean;

  prevImgUrl: string = '';
  nextImgUrl: string = '';
  hashTags = [];

  private setPrevUrl ()
  {
  	if (this.PreviousMilestone)
  		this.prevImgUrl = (COVER_IMAGE_URL.replace('{chestId}',this.PreviousMilestone?.attribs?.chest_id).replace('{milestoneId}',`${this.PreviousMilestone?.id()}`));
  }

  private setNextUrl ()
  {
  	if (this.NextMilestone)
			this.nextImgUrl = (COVER_IMAGE_URL.replace('{chestId}',this.NextMilestone?.attribs?.chest_id).replace('{milestoneId}',`${this.NextMilestone?.id()}`));
  }

  constructor (public storySrvc: StoryService)
  {}

  ngOnInit (): void 
  {}

  ngOnDestroy (): void 
  {}

  ngOnChanges (changes: SimpleChanges): void 
  {
    if (changes?.NextMilestone)
    	this.setNextUrl()

    if (changes?.PreviousMilestone)
    	this.setPrevUrl()
  }
}
