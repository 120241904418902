import { Component, QueryList, OnInit, Input, TemplateRef, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MemberService } from 'src/app/models/memories/member.service';
import { ModelInstance } from '@getrearview/model-builder';

@Component({
  selector: 'rv-member-selector',
  templateUrl: './member-selector.component.html',
  styleUrls: ['./member-selector.component.scss']
})
export class MemberSelectorComponent {

	@ViewChildren('templates') templates;
	@ViewChildren('selectables') selectables;

	@Input('formGroup') formGroup: FormGroup;
	@Input('control') control: FormControl;
	@Input('controlName') controlName: string;
	@Input('hideIfEmpty') hideIfEmpty: boolean;

	@Input('chestId')
	chestId: string;
	_chestId: string;

	Members: Array<ModelInstance> = [];
	isHidden: boolean = false;

	async fetch () 
	{
		if (this.chestId && this._chestId!==this.chestId) {
			this._chestId = this.chestId;
			this.MemberSrvc
							.search({chest_id: this.chestId},{do_not_store: true})
							.then(Response => {
								this.Members = Response?.models||[]
								this.isHidden = this.hideIfEmpty === true ? this.Members.length < 2 : false;
								this.cd.detectChanges();
							});
		}
	}

	constructor (private cd: ChangeDetectorRef, private MemberSrvc: MemberService) 
	{}

	ngOnInit (): void
	{
		this.isHidden = this.hideIfEmpty === true;
	}

	ngOnChanges (): void
	{}

	ngAfterViewInit (): void
	{}

	ngAfterContentInit (): void
	{
		this.fetch();
		this.cd.detectChanges();
	}
}