import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MemoryChestMemberWidgetComponent } from './memory-chest-member-widget/memory-chest-member-widget.component';
import { RearviewInterfaceModule } from './../../shared/interface/rearview-interface.module';

@NgModule({
  declarations: [
    MemoryChestMemberWidgetComponent
  ],
  imports: [
    CommonModule,
    RearviewInterfaceModule
  ],
  exports: [
  	MemoryChestMemberWidgetComponent
  ]
})
export class MemoryChestMemberModule { }
