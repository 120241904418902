import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModelInstance, ModelChildrenCollection } from '@getrearview/model-builder';
import { StoryService } from './../shared/story.service';
import { environment } from 'src/environments/environment';

const PROFILE_URL: string = `${environment.api.media_url}/users/{id}/profile.jpg`;

@Component({
  selector: 'rv-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent {

	@Input('Memory')
	Memory: ModelInstance;

	@Input('Comment')
	Comment: ModelInstance;

	@Input('ReplyTo')
	ReplyTo: ModelInstance;

	@Output('onReplyTo')
	onReplyTo: EventEmitter<ModelInstance> = new EventEmitter();

	get Member (): ModelInstance {
		return this.StorySrvc.Members.filter(M => M.id() === this.Comment?.get('member_id')).shift();
	}

	get url (): string
	{
		return `${PROFILE_URL}`.replace('{id}', `${this.Member?.get('user_id')||''}`)
	}

	_onReplyTo (ReplyTo: ModelInstance): void
	{
		this.onReplyTo.next(ReplyTo);
	}

	constructor (private StorySrvc: StoryService)
	{}

	ngOnInit (): void
	{}
}