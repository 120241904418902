import { Component, OnInit, Input, SimpleChanges, SimpleChange } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ModelInstance } from '@getrearview/model-builder';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

const COVER_IMAGE_URL: string = `${environment.api.media_url}/chests/{chestId}/{milestoneId}/{filename}`;

@Component({
  selector: 'rv-milestone-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

	@Input('Milestone')
	Milestone: ModelInstance;

	HeaderMedia: ModelInstance;

	isCastable: boolean = false;
	private _shadow: string = '';
	private _imgUrl: string = '';
	isEditing: boolean = false;
	private _subscriptions$ = new Subscription;
	nicknameForm: FormGroup = new FormGroup({nickname: new FormControl('',Validators.required)});

	headerStyle: { background?: String; } = {};

	cancel () {
		// Isn't there a revert method on an instance so we dont need a shadow?
		if (this.Milestone?.attribs && this.Milestone?.attribs.hasOwnProperty('nickname')) 
			this.Milestone.attribs.nickname = this._shadow;
	}

	onSubmit ($event) {
		//
		// This should be a form directive option 
		// on rv-form (or standard html form tag)
		$event.preventDefault();
		return false;
	}

	cast (): void
	{}

	onMediaUpdate (event: ModelInstance) {
		if (event) {
			Object.keys(this.Milestone.getChildren('media')).forEach(mediaId => this.Milestone.removeChild('media', mediaId));
			this.Milestone.setChild('media', event);
			this.setHeaderMedia();
		}
	}

	get imgUrl (): string 
	{
		return this._imgUrl;
	}

	set imgUrl (imgUrl: string)
	{
		this._imgUrl = imgUrl||'';
	}

	private setHeaderMedia (): boolean
	{
		let headerId = Object.keys(this.Milestone.getChildren('media')).shift(),
				HeaderMedia: ModelInstance;

		if (headerId)
			HeaderMedia = this.Milestone.getChild('media', headerId) as ModelInstance;

		this.HeaderMedia = HeaderMedia;
		if (this.HeaderMedia)
			this.imgUrl = (COVER_IMAGE_URL.replace('{chestId}',this.Milestone.attribs.chest_id).replace('{milestoneId}',`${this.Milestone.id()}`).replace('{filename}',`${this.HeaderMedia.get('filename')}`));

		this.headerStyle = { background: `linear-gradient(182deg, rgba(0, 0, 0, 0.00) 56.69%, rgba(0, 0, 0, 0.50) 112.97%), linear-gradient(0deg, rgba(254, 232, 232, 0.40) 0%, rgba(254, 232, 232, 0.40) 100%), url(${this.imgUrl}) center center / cover no-repeat, lightgray` };
		return !!this.imgUrl;
	}

	private onMilestoneChanged (): void 
	{
		this.nicknameForm.get('nickname').setValue(this.Milestone?.attribs?.nickname||'');
		this.setHeaderMedia();
	}

	private hasMilestoneChanged (ChangedMilestone: SimpleChange): boolean
	{
		if (!!ChangedMilestone?.previousValue !== !!ChangedMilestone?.currentValue)
			return true;
		return (ChangedMilestone?.previousValue?.id() !== ChangedMilestone?.currentValue?.id());
	}

	onFocus (): void
	{
		this._shadow = this.Milestone?.attribs?.nickname||'';
		this.isEditing = true;
	}

	onBlur (): void
	{
		if (this.Milestone.attribs.nickname)
			this.Milestone.save();

		this.isEditing = false;
	}

  constructor (private fb: FormBuilder)
  {}

  ngOnInit (): void 
  {
  	this._subscriptions$.add(this.nicknameForm.valueChanges.subscribe(changes => this.Milestone.attribs.nickname = changes.nickname));
  }

  ngOnDestroy (): void 
  {
  	this._subscriptions$.unsubscribe();
  }

  ngOnChanges (changes: SimpleChanges): void 
  {
  	if(this.hasMilestoneChanged(changes?.Milestone))
  		this.onMilestoneChanged();
  }
}
