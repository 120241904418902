import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModelInstance } from '@getrearview/model-builder';

@Component({
  selector: 'rv-comments-indicator',
  templateUrl: './comments-indicator.component.html',
  styleUrls: ['./comments-indicator.component.scss']
})
export class CommentsIndicatorComponent implements OnInit 
{
	@Input('Memory')
	Memory: ModelInstance;

	@Input('showComments')
	showComments: boolean = false;

	@Output('onShowComments')
	onShowComments: EventEmitter<boolean> = new EventEmitter(this.showComments);

	get count(): string
	{
		let length: number = this.Memory.getChildren('comment') ? Object.values(this.Memory.getChildren('comment')).length : 0
		if (length>1)
			return `${length} Comments`;
		if (length)
			return `${length} Comment`;
		return `No Comments`;
	}

	toggle () {
		this.onShowComments.next(!this.showComments);
	}

	constructor ()
	{}

	ngOnInit (): void
	{}
}
