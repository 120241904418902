<div id="blank-page-component" class="container-fluid page-container-fluid">
	<div class="row">
		<div class="col-24 text-center">
			<h6>Blank Page</h6>
			<hr />
			<p style="text-align: center;">
				<button routerLink='/account'>Account</button>
				<button disabled routerLink='/blank'>Blank</button>
				<button routerLink=''>Feed</button>
				<button routerLink='/profile'>Profile</button>
				<button routerLink='/ui' >UI Components</button>
			</p>
		</div>
	</div>
</div>
