import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rv-ui-page-fonts',
  templateUrl: './ui-fonts.component.html',
  styleUrls: ['./ui-fonts.component.scss']
})
export class UiFontsComponent implements OnInit {

  constructor () 
  {}

  ngOnInit (): void 
  {}
}
