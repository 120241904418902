import { Directive, Inject, Host, Optional, ViewContainerRef, ComponentRef, ComponentFactoryResolver, Input } from '@angular/core';
import { shareReplay, tap } from 'rxjs/operators';
import { NgControl } from '@angular/forms';
import { FORM_ERRORS } from './form-errors.provider';
import { fromEvent, merge, Observable, EMPTY, Subscription } from 'rxjs';
import { FormSubmitDirective } from './form-submit.directive';
import { FormControlErrorComponent } from './form-control-error/form-control-error.component';
import { FormControlErrorContainerDirective } from './form-control-error-container.directive';

@Directive({
  selector: '[formControlName]'
})
export class FormControlErrorDirective {

	ref: ComponentRef<FormControlErrorComponent>;
	submit$: Observable<Event>;
	container: ViewContainerRef;
	subscription$ = new Subscription();

	@Input('form')
	_form;

	setError (text: string) 
	{
		if (!this.ref) {
			const factory = this.resolver.resolveComponentFactory(FormControlErrorComponent);
			this.ref = this.vcr.createComponent(factory);
		}

		this.ref.instance.text = text;
	}

  constructor (	
  	private vcr: ViewContainerRef, 
  	private resolver: ComponentFactoryResolver, 
  	private cntrlDir: NgControl, 
  	@Optional() controlErrorContainer: FormControlErrorContainerDirective,
  	@Optional() @Host() private form: FormSubmitDirective, 
  	@Inject(FORM_ERRORS) private errors) {
  		this.container 	= 	controlErrorContainer ? controlErrorContainer.vcr : vcr;
  	}

	ngOnInit () 
	{
		if (this.form)
			this.submit$ = this.form.submit$;

		else if (this._form)
			this.submit$ = fromEvent<SubmitEvent>(this._form, 'submit').pipe(tap(() => {
				 if (this._form.classList.contains('submitted') === false)
				   this._form.classList.add('submitted');
				}), shareReplay(1));

		else 
			this.submit$ = EMPTY;

		const _sub$: Subscription = new Subscription();

		// From here, the input subscribes to the form 'submit' event.
		_sub$.add(this.submit$.subscribe((onSubmit: SubmitEvent) => {
			_sub$.unsubscribe();

			this.subscription$.add(
				merge(
					this.submit$,
					this.cntrlDir.valueChanges
				)
				.subscribe(() => {
			     const controlErrors = this.cntrlDir.errors;
			     if (controlErrors) {
			       const firstKey = Object.keys(controlErrors)[0];
			       const getError = this.errors[firstKey];
			       const text = getError(controlErrors[firstKey]);
			       this.setError(text);
			     }
			     else {
			     	this.setError(null);
			     }
				})
			)
		}));
	}

	ngOnDestroy () 
	{
		this.subscription$.unsubscribe();
	}
}