<div id="notifications-selector-component">
	<rv-link (click)="onClose.emit()" label='Back to Settings' iconLeft='arrows.left-arrow' class='mb-4' />
	<rv-component-loader-widget [is-loading]="isLoading">
		<div class="container-fluid">
			<div class="row">
				<div class="col-24">
					<div class="font-color--blue text-left rv-heading-xs">Your Notification Settings</div>
				</div>
				<div class="col-24">
					<form [formGroup]="notifForm">
						<div class="container-fluid" *ngFor="let group of config.groups">
							<div class="row">
								<div class="col-24">
									<h6>{{group.group}}</h6>
								</div>
								<div class="col-24" *ngFor="let child of group.children">
									<table>
										<thead>
											<tr>
												<td colspan="2">{{group.group}}</td>
											</tr>
										</thead>
										<tbody>
											<ng-container *ngFor="let child of group.children">
											<tr>
												<td>{{child.label}}</td>
												<td rowspan="2">
													<rv-input-checkbox *ngIf="notifForm.controls[child.key]" [control]="notifForm.controls[child.key]"></rv-input-checkbox>
												</td>
											</tr>
											<tr>
												<td>{{child.descrip}}</td>
											</tr>
											</ng-container>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		<button (click)="reload()">Reload</button>
		<button (click)="onClose.emit()">onClose</button>
	</rv-component-loader-widget>
</div>