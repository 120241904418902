import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderWidgetComponent } from './header-widget/header-widget.component';
import { HeaderTriggerDirective } from './header-trigger/header-trigger.directive';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { LogoModule } from './../logo/logo.module';
import { SocialIconsModule } from './../social-icons/social-icons.module';
import { ViewSelectorModule } from './../view-selector/view-selector.module';
import { RearviewInterfaceModule } from './../../shared/interface/rearview-interface.module';
import { BackBtnComponent } from './back-btn/back-btn.component';
import { NavBtnComponent } from './nav-btn/nav-btn.component';
import { AlertsIndicatorComponent } from './alerts-indicator/alerts-indicator.component';
import { HomeBtnComponent } from './home-btn/home-btn.component';
import { NavigationService } from './shared/navigation.service';

@NgModule({
  declarations: [HeaderWidgetComponent, UserMenuComponent, HeaderTriggerDirective, BackBtnComponent, NavBtnComponent, AlertsIndicatorComponent, HomeBtnComponent],
  imports: [
    CommonModule,
    LogoModule,
    RouterModule,
    SocialIconsModule,
    ViewSelectorModule,
    RearviewInterfaceModule
  ],
  exports: [
  	HeaderWidgetComponent,
  	HeaderTriggerDirective
  ],
  providers: [
  	NavigationService
  ]
})
export class HeaderModule { }