import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './../../core/guards/auth.guard';
import { BlankComponent } from './blank/blank.component';

const routes: Routes = [
	{ 
		path:                       'blank',
		component:                  BlankComponent,
		canActivate:                [AuthGuard],
		data: 											{animation:'blankPage'}
	}
];

@NgModule({
  imports:                      [RouterModule.forChild(routes)],
  exports:                      [RouterModule]
})
export class BlankRoutingModule { }