import { Component, Input } from '@angular/core';
import { FeedService } from './../shared/feed.service';

@Component({
  selector: 'rv-no-milestones',
  templateUrl: './no-milestones.component.html',
  styleUrls: ['./no-milestones.component.scss']
})
export class NoMilestonesComponent {

	@Input('chestId')
	chestId: string;

	get showMe (): boolean
	{
		return !this.feedSrvc.doMilestonesExist() && !this.feedSrvc.getIsMemoryChestsEmpty();
	}

	constructor (public feedSrvc: FeedService)
	{}
}
