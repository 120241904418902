import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MemoryContentCreatorWidgetComponent } from './memory-content-creator-widget/memory-content-creator-widget.component';
import { RearviewInterfaceModule } from './../../shared/interface/rearview-interface.module';

@NgModule({
  declarations: [
    MemoryContentCreatorWidgetComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RearviewInterfaceModule
  ],
  exports: [
  	MemoryContentCreatorWidgetComponent
  ]
})
export class MemoryContentCreatorModule { }
