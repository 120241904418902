import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UiIconModule } from './../ui-icon/ui-icon.module';
import { UiLinkWidgetComponent } from './ui-link-widget/ui-link-widget.component';

@NgModule({
  declarations: [UiLinkWidgetComponent],
  imports: [
    CommonModule,
    UiIconModule,
    RouterModule
  ],
  exports: [
  	UiLinkWidgetComponent
  ]
})
export class UiLinkModule { }
