import { Component, OnInit, Input } from '@angular/core';
import { SessionUser } from 'src/app/core/session/session-user.model';
import { RouteData } from 'src/app/core/values/route-data.value';
import { Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { Router, Scroll, ActivatedRoute, RouterOutlet, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { MessageBusService, Channels, Message } from 'src/app/core/services/message-bus.service';
import { trigger, state, style, animate, transition } from '@angular/animations';

const COMPONENT_SELECTOR: string = 'rv-header-widget';

const ENABLE_AUTO_HIDE: boolean = false;

export enum HeaderLayout {
	STANDARD,
	NO_USER_MENU,
	SOLO_LOGO
}

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './header-widget.component.html',
  styleUrls: ['./header-widget.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({top: '-3rem', opacity: 0})),
      state('closed', style({top: 0, opacity: 1})),
      transition('open => closed', [animate('0.4s')]),
      transition('closed => open', [animate('0.3s')]),
    ]),
  ]
})
export class HeaderWidgetComponent implements OnInit {

	requiresAuth: boolean = true;
	isWidgetEnabled: boolean = false;
	isDown: boolean = true;

	headerLayouts: {[key: string]: HeaderLayout} = {
		STANDARD: HeaderLayout.STANDARD,
		NO_USER_MENU: HeaderLayout.NO_USER_MENU,
		SOLO_LOGO: HeaderLayout.SOLO_LOGO,
	};
	#headerLayout: HeaderLayout = HeaderLayout.STANDARD;
	headerStyle: {[key: string]: String|Number} = {};

	#subscriptions$: Subscription = new Subscription();

	showBackBtn: boolean = false;
	showNavBtn: boolean = false;
	showLogo: boolean = true;
	showAlerts: boolean = false;
	showUserMenu: boolean = true;

	get headerLayout (): HeaderLayout
	{
		return this.#headerLayout;
	}

	set headerLayout (headerLayout: HeaderLayout)
	{
		this.#headerLayout = headerLayout;

		this.showBackBtn = [HeaderLayout.STANDARD, HeaderLayout.NO_USER_MENU].includes(headerLayout);
		this.showNavBtn = [].includes(headerLayout);
		this.showLogo = [HeaderLayout.STANDARD, HeaderLayout.NO_USER_MENU, HeaderLayout.SOLO_LOGO].includes(headerLayout);
		this.showAlerts = [].includes(headerLayout);
		this.showUserMenu = [HeaderLayout.STANDARD].includes(headerLayout);
	}

	/*
	get showBackBtn (): boolean
	{
		// return this.headerLayout !== this.headerLayouts.NO_USER_MENU;
		return true;
	}

	get showNavBtn (): boolean
	{
		// return this.headerLayout !== this.headerLayouts.NO_USER_MENU;
		return false;
	}

	get showLogo (): boolean
	{
		// return this.headerLayout !== this.headerLayouts.NO_USER_MENU;
		return true;
	}

	get showAlerts (): boolean
	{
		// return this.headerLayout !== this.headerLayouts.NO_USER_MENU;
		return false;
	}

	get showUserMenu (): boolean
	{
		return true;//this.headerLayout !== this.headerLayouts.NO_USER_MENU;
	}
	*/

	private setLayout (layout?: HeaderLayout)
	{
		switch (layout) {
			case HeaderLayout.STANDARD:
				this.headerLayout = HeaderLayout.STANDARD;
				break;
			case HeaderLayout.NO_USER_MENU:
				this.headerLayout = HeaderLayout.NO_USER_MENU;
				break;
			case HeaderLayout.SOLO_LOGO:
				this.headerLayout = HeaderLayout.SOLO_LOGO;
				break;
			default:
				this.headerLayout = HeaderLayout.STANDARD;
		}
	}

  private onScrollEvent (Msg: Message): void
  {
  	if (ENABLE_AUTO_HIDE)
  		this.isDown = Msg.name === 'header.show';
  }

  constructor (private router: Router, public SessionUser: SessionUser, private bus: MessageBusService, private activatedRoute: ActivatedRoute)
  {}

  ngOnInit(): void 
  {
  	this.#subscriptions$.add(this.bus.subscribe(Channels.Scroll, (Msg) => this.onScrollEvent(Msg)));
    this.#subscriptions$.add(
    	this.router.events
				.pipe(
					filter((event) => (event instanceof NavigationEnd || (event instanceof Scroll && event.routerEvent instanceof NavigationEnd))),
					map(() => this.activatedRoute),
					map(route => route.firstChild || route),
					switchMap(route => route.data),
					map(obj => {
						const {enable,opts} 		= 	(new RouteData(obj)).get(`widgets.${COMPONENT_SELECTOR}`)||{},
									{layout, style, requiresAuth} 	= 	opts||{};

						this.setLayout(layout);

						if (style && typeof style === 'object')
							this.headerStyle = {...this.headerStyle, ...style};

						this.requiresAuth = requiresAuth !== false;
						return enable === true;
					}),
					filter(isEnabled => this.isWidgetEnabled !== isEnabled)
				)
				.subscribe(() => this.isWidgetEnabled = !this.isWidgetEnabled)
    	);
  }

  ngOnDestroy (): void 
  {
  	this.#subscriptions$.unsubscribe();
  }
}