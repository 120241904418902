import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { AuthGuard } from 'src/app/core/guards/auth.guard';
// import { NonAuthGuard } from 'src/app/core/guards/non-auth.guard';
import { InviteTokensGuard } from './shared/invite-tokens.guard';
import { InviteTokensPageComponent } from './invite-tokens-page/invite-tokens-page.component';
import { RouteData } from 'src/app/core/values/route-data.value';
import { LoggedOutService } from 'src/app/core/services/logged-out.service';

const routes: Routes = [
	{
		path:                       'it/:relation/:mId/:tId',
		component:                  InviteTokensPageComponent,
		canActivate:                [InviteTokensGuard],
		data: 											new RouteData({animation:'inviteTokensPage',widgets:{'rv-floating-controls-widget':{enable:false},'rv-header-widget':{enable:true, opts: {layout: 2, requiresAuth: false}}}})
	},
	{
		path:                       'it/:relation/:tId',
		component:                  InviteTokensPageComponent,
		canActivate:                [InviteTokensGuard],
		data: 											new RouteData({animation:'inviteTokensPage',widgets:{'rv-floating-controls-widget':{enable:false},'rv-header-widget':{enable:true, opts: {layout: 2, requiresAuth: false}}}})
	}
];

@NgModule({
  imports:                      [RouterModule.forChild(routes)],
  exports:                      [RouterModule]
})
export class InviteTokensRoutingModule { }
