import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModelInstance } from '@getrearview/model-builder';
import { StoryService } from './../shared/story.service';

@Component({
  selector: 'rv-commenter',
  templateUrl: './commenter.component.html',
  styleUrls: ['./commenter.component.scss']
})
export class CommenterComponent {

	@Input('Memory')
	Memory: ModelInstance;

	@Input('Comment')
	Comment: ModelInstance;

	@Output('onReplyTo')
	onReplyTo: EventEmitter<ModelInstance> = new EventEmitter()

	isSaving: boolean = false;

	commenterForm: FormGroup = this.fb.group({
		comment: 					['', Validators.required],
	});

	// if(this.businessFormGroup.get('businessType').value !== 'Other'){
	//     this.businessFormGroup.get('description').addValidators(Validators.required);               
	// } else {                
	//     this.businessFormGroup.get('description').clearValidators();               
	// }

	_onReplyTo (): void {
		this.onReplyTo.next(undefined);
	}

	async handleSubmit () 
	{
		this.isSaving = true;

		if (!this.Comment && this.commenterForm.get('comment').value)
			await this.Memory.createChild('comment', {member_id: this.StorySrvc.Me?.id(), comment: this.commenterForm.get('comment').value});
		else if (this.Comment && this.commenterForm.get('comment').value)
			await this.Memory.createChild('comment', {member_id: this.StorySrvc.Me?.id(), comment: this.commenterForm.get('comment').value, reply_to_id: this.Comment?.id()});
		else {
			this.isSaving = false;
			return Promise.resolve();
		}

		this.commenterForm.get('comment').setValue('');
		this.commenterForm.markAsPristine();
		this.isSaving = false;
	}

  constructor (private fb: FormBuilder, private StorySrvc: StoryService) 
  {}

  ngOnInit (): void 
  {}
}