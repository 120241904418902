import { Injectable, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, CanActivate, ActivatedRoute, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ApiService } from 'src/app/core/services/api.service';
import { of, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

export type intakeTokenPayload = {
	relation: string;
	token: string;
}

@Injectable({ 
  providedIn: 'root' 
})
export class InviteToken
{
	private intakeToken: intakeTokenPayload;
	private validatedToken: boolean = false;

	getFromUrl (): intakeTokenPayload
	{
		const payload: intakeTokenPayload = {relation: '', token: ''},
					{relation,mId,tId} = (this.route.snapshot.params||{});

		payload.relation 	= 	`${relation||''}`;
		payload.token 		= 	[mId?mId:'',tId].filter(n => n).join('/');

		return payload;
	}

	buildFromParams (params: {[key: string]: string}): intakeTokenPayload
	{
		const payload: intakeTokenPayload = {relation: '', token: ''},
					{relation,mId,tId} = (params||{});

		payload.relation 		= 	`${relation||''}`;
		payload.token 			= 	[mId?mId:'',tId].filter(n => n).join('/');

		return (this.intakeToken = payload);
	}

	payload (): intakeTokenPayload
	{
		return this.intakeToken;
	}

	async validate (): Promise<string|void>
	{
		return new Promise((resolve, reject) => {
			this.AuthApi
							.validateInviteToken(this)
							.pipe(catchError(err => {
								reject(err);
								return throwError(() => new Error(`${err}`));
							}))
		    			.subscribe((data: any) => resolve());
		});
	}

	async consume (): Promise<void>
	{
		// this.AuthApi.consumeInviteToken()
		return Promise.resolve();
	}

  constructor(private route: ActivatedRoute, private AuthApi: ApiService)
  {}
}
