<div id="auth-page" class="container-fluid">
	<div class="row">
		<div class="col-xs-24 vh-100 rv-blue-bg">
			<div class="logo-wrapper">
				<rv-logo-widget symbol='purple' name='white'></rv-logo-widget>
			</div>
			<div class="form-wrapper" [ngSwitch]="state">
				<rv-social-login *ngSwitchCase="'LOGIN'" (onStateChange)="onStateChange($event)"></rv-social-login>
				<rv-login-form *ngSwitchCase="'LOGIN_FORM'" (onStateChange)="onStateChange($event)"></rv-login-form>
				<rv-forgot-password-form *ngSwitchCase="'FORGOT_PASSWORD'" (onStateChange)="onStateChange($event)"></rv-forgot-password-form>
				<rv-forgot-password-confirm-form *ngSwitchCase="'FORGOT_PASSWORD_CONFIRM'" (onStateChange)="onStateChange($event)"></rv-forgot-password-confirm-form>
				<social-signup *ngSwitchCase="'SIGNUP'" (onStateChange)="onStateChange($event)"></social-signup>
				<rv-signup-form *ngSwitchCase="state === 'SIGNUP_FORM' || state === 'SIGNUP_CONFIRM' ? state : ''" (onStateChange)="onStateChange($event)"></rv-signup-form>
				<!-- <rv-signup-confirm-form *ngSwitchCase="'SIGNUP_CONFIRM'" (onStateChange)="onStateChange($event)"></rv-signup-confirm-form> -->
			</div>
			<div class="register-wrapper">
				<div *ngIf="canRegister(); else cantRegister" class="sign-up text-center font-color--white mb-5">
				  Need an account? <span (click)="goToSignup()" class="font-color--white">Join Rearview for free</span>
				</div>
				<ng-template #cantRegister>
					<div>
				  	Want an account? <span (click)="goToInvite()" class="font-color--white">Request Your Invite.</span>
				  </div>
				</ng-template>
			</div>
		</div>
		<!--
		<div class="col-sm-14 col-md-17 col-lg-17 vh-100" style="padding:  0;">
			<div class="animatable-wrapper">
				<rv-floating-clouds-widget key="a" left="13%" right="13%" top="7rem"></rv-floating-clouds-widget>
				<rv-floating-clouds-widget key="b" left="10%" right="11%" top="5rem"></rv-floating-clouds-widget>
			</div>

			<div class="animatable-wrapper">
				<rv-swaying-landscape-widget key="a" left="4%" bottom="0"></rv-swaying-landscape-widget>
				<rv-swaying-landscape-widget key="b" left="12%" bottom="0"></rv-swaying-landscape-widget>
				<rv-swaying-landscape-widget key="c" left="20%" bottom="0"></rv-swaying-landscape-widget>
				<rv-swaying-landscape-widget key="d" right="0" bottom="0"></rv-swaying-landscape-widget>
			</div>
		</div>
		-->
	</div>
</div>