import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModelInstance } from '@getrearview/model-builder';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { SessionUser } from './../../../core/session/session-user.model';
import { UserNotificationService } from './../../../models/users/user-notification.service';
import { USER_NOTIFICATIONS_CONFIG, UserNotificationsConfig } from './../user-notifications.config';

@Component({
  selector: 'rv-settings-notifications-selector',
  templateUrl: './notifications-selector.component.html',
  styleUrls: ['./notifications-selector.component.scss']
})
export class NotificationsSelectorComponent implements OnInit {

	@Output('onClose')
	onClose: EventEmitter<void> = new EventEmitter();

	isLoading: boolean = false;

	notifForm = this.fb.group({});

	private Notification: ModelInstance;
	private _subscriptions$ = new Subscription();

	reload = () => this.load()

	private async fetchNotificationSetting (): Promise<ModelInstance>
	{
		let Notifications 	= 	this.User.instance.getChildren('notification');

		if (!Notifications) {
			Notifications 		= 	await this.UserNotificationSrvc.search({_relationship: {user: {user_id: this.User.id}}})
			Notifications 		= 	Notifications && Array.isArray(Notifications) ? Notifications.shift() : Notifications;
			if (Notifications && Notifications instanceof ModelInstance) {
				this.User.instance.setChild('notification', Notifications as ModelInstance);
				return Promise.resolve(Notifications as ModelInstance);
			}
		}

		if (!Notifications)
			return Promise.resolve((await this.User.instance.createChild('notification', {config: {}})) as ModelInstance);

		if (Notifications && typeof Notifications === 'object' && Object.keys(Notifications).length > 0)
			if (Notifications[Object.keys(Notifications).shift()])
				return Promise.resolve(Notifications[Object.keys(Notifications).shift()]);
	}

	private async onFormChanges (config)
	{
		if (this.Notification) {
			this.Notification.set('config', config);
			this.Notification.save();
		}
	}

	private async load (): Promise<void> 
	{
		this.isLoading = true;

		this.config.groups.forEach(group => {
			(Array.isArray(group?.children) ? group.children : []).forEach(child => {
				if (child?.key && !this.notifForm.controls[child.key])
					this.notifForm = this.fb.group({...this.notifForm.controls, [child.key]: ['']});
			});
		});

		this.Notification = await this.fetchNotificationSetting();
		if (this.Notification && this.Notification?.get('config') && typeof this.Notification?.get('config') === 'object') {
			Object.keys(this.Notification.get('config')).forEach(key => 
				this.notifForm.controls[key].setValue(this.Notification.get('config')[key], {emitEvent: false})
			)
		}

		this._subscriptions$.add(this.notifForm.valueChanges.pipe(distinctUntilChanged()).subscribe(changes => this.onFormChanges(changes)));

		this.isLoading = false;
	}

	constructor (@Inject(USER_NOTIFICATIONS_CONFIG) public config: UserNotificationsConfig, private fb: FormBuilder, private User: SessionUser, private UserNotificationSrvc: UserNotificationService) 
	{}

	ngOnInit () 
	{
		this.load();
	}

	ngOnDestroy () 
	{
		this._subscriptions$.unsubscribe();
	}
}
