import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService, SigninRequestResponse } from 'src/app/core/services/auth.service';
import { Subscription } from 'rxjs';
import {
  SocialAuthService,
  SocialUser,
} from '@abacritt/angularx-social-login';

@Component({
  selector: 'rv-social-signin',
  templateUrl: './social-signin.component.html',
  styleUrls: ['./social-signin.component.scss']
})
export class SocialSigninComponent {

	@Input('InviteToken')
	InviteToken;

	@Output('onSuccess')
	onSuccess: EventEmitter<SigninRequestResponse> = new EventEmitter();

	@Output('onError')
	onError: EventEmitter<SigninRequestResponse> = new EventEmitter();

	private _isLoggedin?: boolean = undefined;
	private _subscriptions$ = new Subscription();

	constructor (private auth: AuthService, private socialAuthService: SocialAuthService)
	{}

	ngOnInit (): void
	{
		this._subscriptions$.add(this.socialAuthService.authState.subscribe((User: SocialUser) => {
		  if (User && !this._isLoggedin) {
		    this._isLoggedin = User != null;
		    // Social user has authenticated w/ Social Provider.
		    // We will now request an access token from the RV API.
		    this.auth.requestAccessToken(User, {InviteToken: this.InviteToken})
		    					.then((res: SigninRequestResponse) => this.onSuccess.next(res))
		    					.catch((err: SigninRequestResponse) => this.onError.next(err));
		  }
		}));
	}

	ngOnDestroy (): void
	{
		this._subscriptions$.unsubscribe();
	}
}