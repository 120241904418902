import { Injectable, Injector } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ModelFactoryConfig, ModelService } from '@getrearview/model-builder';
import { ConfigService } from './../../shared/services/config.service';
import { UserMetaService } from './user-meta.service';
import { UserMediaService } from './user-media.service';
import { UserSettingService } from './user-setting.service';
import { UserNotificationService } from './user-notification.service';
import { MemberService } from './../memories/member.service';

const PROFILE_URL = `${environment.api.media_url}/users/{id}/profile.jpg`;

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends ModelService {

	private _http: HttpClient;
	private _endpoint!: string;

	api (): {[name: string]: Function}
	{
		return {
			shouldWelcome: async (): Promise<boolean|void> => {
				return new Promise((resolve, reject) => {
					this._http.get<any>(`${this._endpoint}/welcome`, {})
							.subscribe(
									payload => resolve(payload?.payload?.enable !== false), 
									HttpResponseError => { reject(HttpResponseError); }
								);
				});
			},
			acknowledgeWelcome: async (): Promise<string|void> => {
				return new Promise((resolve, reject) => {
					this._http.post<any>(`${this._endpoint}/welcome`, {})
							.subscribe(
									payload => resolve(payload?.payload?.id), 
									HttpResponseError => { reject(HttpResponseError); }
								);
				});
			},
			createStripeCustomer: async (): Promise<string|void> => {
				return new Promise((resolve, reject) => {
					this._http.post<any>(`${this._endpoint}/customers`, {})
							.subscribe(
									payload => resolve(payload?.payload?.id), 
									HttpResponseError => { reject(HttpResponseError); }
								);
				});
			},
			setDefaultPaymentMethod: async (id: string): Promise<{"payload": {[key: string]: string|number|null|{[key: string]: string|number|null}}}> => {
				return new Promise((resolve, reject) => {
					this._http.put<any>(`${this._endpoint}/payment-methods/${id}`, {})
							.subscribe(
									payload => resolve(payload?.payload), 
									HttpResponseError => { reject(HttpResponseError); }
								);
				});
			},
			deletePaymentMethod: async (id: string): Promise<{"payload": {[key: string]: string|number|null|{[key: string]: string|number|null}}}> => {
				return new Promise((resolve, reject) => {
					this._http.delete<any>(`${this._endpoint}/payment-methods/${id}`)
							.subscribe(
									payload => resolve(payload?.payload), 
									HttpResponseError => { reject(HttpResponseError); }
								);
				});
			},
			getPaymentMethods: async (): Promise<{"payload": {[key: string]: string|number|null|{[key: string]: string|number|null}}}> => {
				return new Promise((resolve, reject) => {
					this._http.get<any>(`${this._endpoint}/payment-methods`)
							.subscribe(
									payload => resolve(payload?.payload), 
									HttpResponseError => { reject(HttpResponseError); }
								);
				});
			},
			addPaymentMethod: async (id: string): Promise<boolean> => {
				return new Promise((resolve, reject) => {
					this._http.post<any>(`${this._endpoint}/payment-methods`, {payment_method_id: id})
							.subscribe(
									payload => resolve(!!payload?.payload), 
									HttpResponseError => { reject(!!HttpResponseError); }
								);
				});
			}
		}
	}

  constructor (injector: Injector, httpClient: HttpClient) 
  {
  	const ConfigSrvc: ConfigService = injector.get<any>(ConfigService);
		let MFC: ModelFactoryConfig = (new ModelFactoryConfig()).setAll({
  				// Model Name
  				name: "User",
  				// Model Endpoint
					endpoint: ConfigSrvc.get("api.api_url")+"/users/profile",
					// Model Fields
					fields: ["name", "email", "password", "stripe_id"],
					// Model `Permitted` Fields
					permitted: ["name", "email", "password"],
					// Model `Required` Fields
					required: [],
					// Model Field Labels
					labels: {},
					// Model Field Validation Patterns
					patterns: {},
					// Model Children Types
					children_types: {
						media: injector.get<any>(UserMediaService),
						meta: injector.get<any>(UserMetaService),
						setting: injector.get<any>(UserSettingService),
						notification: injector.get<any>(UserNotificationService),
						member: injector.get<any>(MemberService),
					},
					// Model Association Types
					association_types: {},
					// Storage Type (local, session, cookies)
					storage_type: "local",

					instance_methods: {
						getProfileImageUrl: () => PROFILE_URL
					}
				});

  	super(MFC, httpClient);

  	this._http = httpClient;
  	this._endpoint = ConfigSrvc.get("api.api_url")+"/users/profile";
  }
}