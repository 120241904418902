import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InviteTokenFormStates } from './../shared/InviteTokenFormStates.enum';
import { InviteToken } from './../shared/invite-token.class';
import { ApiService } from 'src/app/core/services/api.service';
import { SigninRequestResponse } from 'src/app/core/services/auth.service';
import { BehaviorSubject, Subscription, Subject } from 'rxjs';

// InviteTokenFormStates:
// INITIALIZING
// INIT_ERROR
// INITIALIZED
// AUTHENTICATING
// USER_UNKNOWN
// USER_UNAUTHENTICATED
// USER_NOT_INVITED
// USER_SET_IDENTITY

@Component({
  selector: 'rv-invite-tokens-page',
  templateUrl: './invite-tokens-page.component.html',
  styleUrls: ['./invite-tokens-page.component.scss']
})
export class InviteTokensPageComponent implements OnInit {

	loaded: boolean = true;
	_tokenState$!: BehaviorSubject<any>;
	_subscriptions$ = new Subscription();
	tokenState: string = InviteTokenFormStates[InviteTokenFormStates.INITIALIZING];

	errorMsg!: string;
	errorMsg$: Subject<string> = new Subject();
	isFixed: boolean = false;
	isLoading: boolean = true;
	showingLegacy: boolean = false;
	showingSocial: boolean = false;

	onExpandDone (name) {}

	onCollapseDone (name) {}

	showLegacy () {
		this.showingLegacy = true;
		this.showingSocial = false;
	}

	showSocial () 
	{
		this.showingLegacy = false;
		this.showingSocial = true;
	}

	private handleValidateError (errorCode): void
	{
		switch (errorCode) 
		{
			case 'invite_tokens.nonexistent_token':
				this.errorMsg = `It seems you have a bad invite link.  Please check with the person that invited you.`;
				this.errorMsg$.next(this.errorMsg);
				this.isFixed = true;
				break;
			case 'invite_tokens.non_member':
				this.errorMsg = `It seems your email isn't listed on this invite.  Please check with the person that invited you.`;
				this.errorMsg$.next(this.errorMsg);
				break;
			case 'invite_tokens.previously_used':
				this.errorMsg = `It seems this invite link has already been used.  Please check with the person that invited you.`;
				this.errorMsg$.next(this.errorMsg);
				break;
			default:
				this.errorMsg = `An unknown error occurred while validating invite token. ${errorCode}`;
				break;
		}
	}

	private async validate (params: {[key: string]: string}): Promise<boolean>
	{
		this.InviteToken.buildFromParams(params);

		try {
			await this.InviteToken.validate();
			this.setTokenState(InviteTokenFormStates.INITIALIZED);
			return Promise.resolve(true);
		}
		catch (ex) {
			this.handleValidateError(ex)
			this.setTokenState(InviteTokenFormStates.INIT_ERROR);
			return Promise.resolve(false);
		}
	}

	private setTokenState (tokenState: InviteTokenFormStates): void
	{
		if (this.tokenState !== InviteTokenFormStates[tokenState]) {
			this.tokenState = InviteTokenFormStates[tokenState];

			switch (tokenState) 
			{
				case InviteTokenFormStates.INITIALIZING:
					break;
				case InviteTokenFormStates.INIT_ERROR:
					break;
				case InviteTokenFormStates.INITIALIZED:
					this.showingSocial = true;
					break;
				case InviteTokenFormStates.AUTHENTICATING:
					break;
				case InviteTokenFormStates.USER_UNKNOWN:
					break;
				case InviteTokenFormStates.USER_UNAUTHENTICATED:
					break;
				case InviteTokenFormStates.USER_NOT_INVITED:
					break;
				case InviteTokenFormStates.USER_SET_IDENTITY:
					break;
			}
		}
	}

	onSocialLoginError (err: SigninRequestResponse) {
		this.handleValidateError(err?.message);
	}

	onLegacyLoginError (err) {
		this.handleValidateError(`${err && err?.message?err.message:err}`);
	}

	constructor (private route: ActivatedRoute, public InviteToken: InviteToken)
	{}

	ngOnInit (): void
	{
		this._tokenState$ = new BehaviorSubject(InviteTokenFormStates.INITIALIZING);
		this._subscriptions$.add(this._tokenState$.subscribe(tokenState => this.setTokenState(tokenState)));
		this.validate(this.route.snapshot.params).finally(() => this.isLoading = false);
	}

	ngOnDestroy (): void
	{
		this._subscriptions$.unsubscribe();
	}
}
