import { Pipe, PipeTransform } from '@angular/core';
import { ModelInstance } from '@getrearview/model-builder';

@Pipe({
  name: 'stripEmptyMemories'
})
export class StripEmptyMemoriesPipe implements PipeTransform {
  transform(Memories: {[memoryId: string]: ModelInstance}, id?: string): Array<ModelInstance> {
		return Object.values(Memories||[]).filter((Memory: ModelInstance) => {

			// Dont hide items that are not memories.
			if (!Memory || Memory?.getName() !== 'Memory')
				return true;;

			// hide memories that are...
			//  - not a new & unsaved instance.
			//  - have empty content.
			//  - have no memory medias.
			if (Memory.isNew())
				return true;

			if (!Memory.get('author_id'))
				return false;

			if (Memory.get('type') === 'TEXT')
				return true;

			if (!Memory.get('content')) {
				if (!Memory.getChildren('media'))
					return false;
				return Object.keys(Memory.getChildren('media')).length > 0;
			}

			return false;
		});
  }
}