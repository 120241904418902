import { Component, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { FeedService } from '../shared/feed.service';
// import { Component, OnInit, Input, SimpleChanges, SimpleChange } from '@angular/core';
// import { ModelInstance } from '@getrearview/model-builder';
// import { Subscription } from 'rxjs';

@Component({
  selector: 'rv-feed-chest-search',
  templateUrl: './feed-chest-search.component.html',
  styleUrls: ['./feed-chest-search.component.scss']
})
export class FeedChestSearchComponent implements OnInit {

	isSearching: boolean = false;
	searchForm: FormGroup = new FormGroup({search: new FormControl('',Validators.required)});
	// private _subscriptions$ = new Subscription;

	async performSearch (): Promise<void>
	{
		this.isSearching = true;
		const searchParams = {
			global: this.searchForm.get('search').value,
		}

		this.feedSrvc.fetchFeed(null, searchParams).finally(() => this.isSearching = false);
		return Promise.resolve();
	}

	// private _shadow: string = '';
	// isEditing: boolean = false;

	// cancel () {
	// 	// Isn't there a revert method on an instance so we dont need a shadow?
	// 	if (this.Milestone?.attribs && this.Milestone?.attribs.hasOwnProperty('search')) 
	// 		this.Milestone.attribs.search = this._shadow;
	// }

	// onSubmit ($event) {
	// 	//
	// 	// This should be a form directive option 
	// 	// on rv-form (or standard html form tag)
	// 	$event.preventDefault();
	// 	return false;
	// }

	// onFocus (): void
	// {
	// 	this._shadow = this.Milestone?.attribs?.search||'';
	// 	this.isEditing = true;
	// }

	// onBlur (): void
	// {
	// 	if (this.Milestone.attribs.search)
	// 		this.Milestone.save();

	// 	this.isEditing = false;
	// }

  constructor (private fb: FormBuilder, public feedSrvc: FeedService)
  {}

  ngOnInit (): void 
  {
  	// this._subscriptions$.add(this.searchForm.valueChanges.subscribe(changes => {}));
  }

  ngOnDestroy (): void 
  {
  	// this._subscriptions$.unsubscribe();
  }

  ngOnChanges (changes: SimpleChanges): void 
  {}
}