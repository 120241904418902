import { InjectionToken } from '@angular/core';

export interface UserSettingsConfig {
	options: Array<{
		icon: string;
		label: string;
		descrip: string;
		route?: string;
		component?: string;
		enabled: boolean;
	}>
}

export const UserSettingsConfig: UserSettingsConfig = {
	options: [ 
			{
				icon: 'users.account',
				label: 'My Profile',
				descrip: 'Account and Subscriptions',
				component: 'users/settings/profile',
				enabled: true,
				route: '/profile'
			},
			{
				icon: 'education.open-book',
				label: 'My Memory Chests',
				descrip: 'Manage your chests',
				enabled: false
			},
			{
				icon: 'user-interface.bell',
				label: 'Notification Settings',
				descrip: 'Enable/Disable notifications',
				component: 'users/settings/notifications',
				enabled: false
			},
			{
				icon: 'file-and-folder.document',
				label: 'Terms and Conditions',
				descrip: 'View our terms and conditions',
				component: 'users/settings/terms-conditions',
				enabled: true
			},
			{
				icon: 'security.shield-check',
				label: 'Privacy Policy',
				descrip: 'View our privacy policy',
				component: 'users/settings/privacy-policy',
				enabled: true
			},
			{
				icon: 'user-interface.logout',
				label: 'Logout',
				descrip: 'Logout or switch your account',
				route: 'logout',
				enabled: true
			}
		]
}

export const USER_SETTINGS_CONFIG = new InjectionToken<string>('pages.user-settings.config');