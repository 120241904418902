import { Component, Input } from '@angular/core';
import { ModelInstance } from '@getrearview/model-builder';

@Component({
  selector: 'rv-hashtag',
  templateUrl: './hashtag.component.html',
  styleUrls: ['./hashtag.component.scss']
})
export class HashtagComponent {
	@Input('Hashtag')
	Hashtag: ModelInstance;
}
