import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService, SigninRequestResponse } from 'src/app/core/services/auth.service';
import {
  SocialAuthService,
  SocialUser,
} from '@abacritt/angularx-social-login';

@Component({
  selector: 'rv-facebook-signin-button',
  templateUrl: './facebook-signin-button.component.html',
  styleUrls: ['./facebook-signin-button.component.scss']
})
export class FacebookSigninButtonComponent implements OnInit {

	@Input('InviteToken')
	InviteToken;

	@Output('onSuccess')
	onSuccess: EventEmitter<SigninRequestResponse> = new EventEmitter();

	@Output('onError')
	onError: EventEmitter<SigninRequestResponse> = new EventEmitter();

	signIn ()
	{
		this.auth.login('FACEBOOK');
	}

	constructor (private auth: AuthService, private socialAuthService: SocialAuthService)
	{}

	ngOnInit (): void
	{}

	ngOnDestroy (): void
	{}
}