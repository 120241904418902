<div>
	<div>
		<h6>Member Selector</h6>
		<rv-member-selector [chestId]="chestId" [formGroup]="demoForm" [control]="demoForm.get('members')" [controlName]="controlName1" />
	</div>
	<br />
	<div>
		<h6>Hashtag Selector</h6>
		<rv-hashtag-selector [chestId]="chestId" [formGroup]="demoForm" [control]="demoForm.get('hashtags')" [controlName]="controlName2" />
	</div>
	<br />
</div>