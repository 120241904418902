import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiMultiSelectWidgetComponent } from './ui-multi-select-widget/ui-multi-select-widget.component';
import { UiIconModule } from './../ui-icon/ui-icon.module';
import { UiInputModule } from './../ui-input/ui-input.module';
import { SelectableComponent } from './selectable/selectable.component';
import { SelectedPipe } from './shared/selected.pipe';
import { UnselectedPipe } from './shared/unselected.pipe';
import { InterfaceCoreModule } from './../shared/interface-core.module';

@NgModule({
  declarations: [
    UiMultiSelectWidgetComponent,
    SelectableComponent,
    SelectedPipe,
    UnselectedPipe
  ],
  imports: [
    CommonModule,
    UiInputModule,
    UiIconModule,
    InterfaceCoreModule
  ],
  exports: [
  	UiMultiSelectWidgetComponent
  ]
})
export class UiMultiSelectModule { }
