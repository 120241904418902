import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rv-ui-page-pagination',
  templateUrl: './ui-pagination.component.html',
  styleUrls: ['./ui-pagination.component.scss']
})
export class UiPaginationComponent implements OnInit {

  constructor () 
  {}

  ngOnInit (): void 
  {}
}
