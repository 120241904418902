import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SessionUser } from './../../../core/session/session-user.model';
import { ProfileService } from './../../../models/users/profile.service';
import { ModelInstance } from '@getrearview/model-builder';

@Component({
  selector: 'rv-settings-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss']
})
export class ProfileFormComponent implements OnInit {

	@Output('onClose')
	onClose: EventEmitter<void> = new EventEmitter();

	isLoading: boolean = false;
	isSaving: boolean = false;

	profileForm = this.fb.group({
		name: 				[null, [Validators.required]],
		email: 				[null, [Validators.required]]
	});

	async save ()
	{
		if (!this.profileForm.valid) return;

		this.isSaving = true;

		this.ProfileSrvc.get().attribs.name = this.profileForm.get('name').value;
		this.ProfileSrvc.get().attribs.email = this.profileForm.get('email').value;
		await this.ProfileSrvc.get().save();

		this.ProfileSrvc.store();

		this.isSaving = false;
	}
	
	hasManualError ()
	{}
	
	isManualError (str:string)
	{}

  constructor (private fb: FormBuilder, private ProfileSrvc: ProfileService) 
  {}

  ngOnInit (): void 
  {
  	this.profileForm.get('name').setValue(this.ProfileSrvc.get().attribs.name);
  	this.profileForm.get('email').setValue(this.ProfileSrvc.get().attribs.email);
  }
}
