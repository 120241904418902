import { Component } from '@angular/core';
import { NavigationService } from './../shared/navigation.service';;
import { trigger, state, style, animate, transition, AUTO_STYLE } from '@angular/animations';

@Component({
  selector: 'rv-back-btn',
  templateUrl: './back-btn.component.html',
  styleUrls: ['./back-btn.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({width: AUTO_STYLE, opacity: 1})),
      state('closed', style({width: 0, opacity: 0})),
      transition('open => closed', [animate('0.25s')]),
      transition('closed => open', [animate('0.25s')]),
    ]),
  ]
})
export class BackBtnComponent {

	constructor (public navigationSrvc: NavigationService)
	{}

	ngOnDestroy (): void
	{
		this.navigationSrvc.cleanUp();
	}
}