import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ui-widget-selector',
  templateUrl: './ui-widget-selector.component.html',
  styleUrls: ['./ui-widget-selector.component.scss']
})
export class UiWidgetSelectorComponent implements OnInit 
{
	constructor () 
  {}

  ngOnInit (): void 
  {}
}
