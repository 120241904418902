import { Component, OnInit, Input, ElementRef, SimpleChanges } from '@angular/core';

const isActive = (val: any) => (typeof val === 'boolean' ? val : typeof val === 'string' && val === '')
const isValidClassName = str => new RegExp(/^(rv-ui-)?[a-z\-]+---[a-z0-9-]+$/).test(`${str}`);

@Component({
  selector: 'rv-icon',
  styleUrls: ['./ui-icon-widget.component.scss'],
  template: '<span [ngClass]="classes"><i *ngIf="icon" [ngClass]="icon"></i></span>'
})
export class UiIconWidgetComponent implements OnInit {

	@Input('group')
	_group: string;

	@Input('icon')
	_icon: string;

	@Input('spin')
	_spin: boolean;

	_classes: Array<string> = ['rv-ui','ui-icon'];

	// sizes
	@Input('xs')
	private _xs: boolean;
	@Input('sm')
	private _sm: boolean;
	@Input('md')
	private _md: boolean;
	@Input('lg')
	private _lg: boolean;
	@Input('xl')
	private _xl: boolean;

	// colors
	@Input('purple')
	private _purple: boolean;
	@Input('blue')
	private _blue: boolean;
	@Input('gray')
	private _gray: boolean;
	@Input('dk-gray')
	private _dkGray: boolean;	
	@Input('white')
	private _white: boolean;
	@Input('off-white')
	private _offWhite: boolean;

	private _iconClassName!: string;

	get classes (): string
	{
		return this._classes.concat([
				this.sizeClass,
				this.colorClass,
				(!!this.spin?'ui-icon-spin':'')
			]).join(' ');
	}

	get icon (): string
	{
		return this._iconClassName;
	}

	get spin (): boolean
	{
		return isActive(this._spin);
	}

	get sizeClass (): string
	{
		if (isActive(this._xs)) 		return 'rv-ui-xs';
		if (isActive(this._sm)) 		return 'rv-ui-sm';
		if (isActive(this._md)) 		return 'rv-ui-md';
		if (isActive(this._lg)) 		return 'rv-ui-lg';
		if (isActive(this._xl)) 		return 'rv-ui-xl';
	}

	get colorClass (): string
	{
		if (isActive(this._purple)) 	return 'rv-icon-purple';
		if (isActive(this._blue)) 		return 'rv-icon-blue';
		if (isActive(this._gray)) 		return 'rv-icon-gray';
		if (isActive(this._dkGray)) 	return 'rv-icon-dk-gray';
		if (isActive(this._white)) 		return 'rv-icon-white';
		if (isActive(this._offWhite)) return 'rv-icon-off-white';
		return 'rv-icon-dk-gray';
	}

	private makeIconClassName (): string
	{
		if (!this._icon)
			return '';

		let group:string = `${this._group||''}`,
				icon: string = '',
				opts: Array<string> = [];

		if (Array.isArray(this._icon)) {
			group 	= 	`${this._icon.shift()}`.trim().toLowerCase();
			icon 		= 	`${this._icon.shift()}`.trim().toLowerCase();
			if (this._icon.length > 0)
				opts.push(this._icon.shift());
		}
		else if (typeof this._icon === 'string') {
			icon 		= 	`${this._icon}`.trim().toLowerCase();
		}

		if (isValidClassName(`rv-ui-${group}---${icon}`))
			return `rv-ui-${group}---${icon}`;

		console.error(`Invalid Icon Selection Used. [Group: ${group} Icon: ${icon}]`);
		return '';
	}

  constructor (elementRef: ElementRef<HTMLElement>) 
  {}

  ngOnInit (): void 
  {
  	this._iconClassName = this.makeIconClassName()
  }

  ngOnChanges (Changes: SimpleChanges): void
  {
  	this._iconClassName = this.makeIconClassName()
  }
}
