import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalWidgetComponent } from './modal-widget/modal-widget.component';
import { ModalBackdropComponent } from './modal-backdrop/modal-backdrop.component';
import { ModalService } from './shared/modal.service';
import { ModalCloseBtnComponent } from './modal-close-btn/modal-close-btn.component';
import { RearviewInterfaceModule } from 'src/app/shared/interface/rearview-interface.module';
import { ModalContentComponent } from './modal-content/modal-content.component'

@NgModule({
  declarations: [
    ModalWidgetComponent,
    ModalBackdropComponent,
    ModalCloseBtnComponent,
    ModalContentComponent,
  ],
  imports: [
    CommonModule,
    RearviewInterfaceModule
  ],
  exports: [
  	ModalWidgetComponent,
  	ModalBackdropComponent,
  	ModalCloseBtnComponent
  ],
  providers: [
  	ModalService
  ]
})
export class ModalModule { }
