<ng-container *ngFor="let Member of Selectables; let idx = index;">
	<ng-template #templates>
		<rv-mini-member-img #selectables [Member]="Member" />&nbsp;{{Member.get('nickname')}}
	</ng-template>
</ng-container>

	<!-- <rv-input 
		class="mb-3" 
		iconLeft="users.account" 
		placeholder="Select Person" 
		[formGroup]="feedFilterForm" 
		name="selectPerson" 
		controlName="selectPerson"
	></rv-input> -->

<div class="filter-member-selector-component">
	<rv-multi-select iconLeft='users.users' [templates]="templates" [models]="Selectables"  [controlName]="controlName" [formGroup]="formGroup" [control]="control" />
</div>
