import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ModelInstance } from '@getrearview/model-builder';

@Component({
  selector: 'rv-comment-reply-button',
  templateUrl: './comment-reply-button.component.html',
  styleUrls: ['./comment-reply-button.component.scss']
})
export class CommentReplyButtonComponent {

	@Input('Comment')
	Comment: ModelInstance;

	@Output('onReplyTo')
	onReplyTo: EventEmitter<ModelInstance> = new EventEmitter();

	get isReplying (): boolean 
	{
		return false;
	}

	async toggle (): Promise<void>
	{
		this.onReplyTo.emit(this.Comment);
		return Promise.resolve();
	}
}
