import { Component, QueryList, OnInit, Input, TemplateRef, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MemberService } from 'src/app/models/memories/member.service';
import { ModelInstance } from '@getrearview/model-builder';
import { Subscription } from 'rxjs';
import { FeedService } from './../shared/feed.service';

@Component({
  selector: 'filter-member-selector',
  templateUrl: './filter-member-selector.component.html',
  styleUrls: ['./filter-member-selector.component.scss']
})
export class FilterMemberSelectorComponent {

	@ViewChildren('templates') templates;
	@ViewChildren('selectables') selectables;

	@Input('formGroup') formGroup: FormGroup;
	@Input('control') control: FormControl;
	@Input('controlName') controlName: string;

	Selectables: Array<ModelInstance> = [];

	private _subscriptions$ = new Subscription();

	// constructor (private cd: ChangeDetectorRef, private feedSrvc: FeedService) 
	// {}

	constructor (private feedSrvc: FeedService) 
	{}

	ngOnInit (): void
	{
		this._subscriptions$.add(this.feedSrvc.filterMembers$.subscribe(Selectables => this.Selectables = Selectables));
	}

	// ngAfterContentInit (): void
	// {
	// 	this.fetch();
	// 	this.cd.detectChanges();
	// }

	ngOnDestroy (): void
	{
		this._subscriptions$.unsubscribe();
	}
}