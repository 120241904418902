<form id="memory-content-creator-widget-component" class="container-fluid" [formGroup]="memoryMessageForm">
	<div class="row">
		<div class="col-24">
			<!-- <rv-input sm [disabled]="isEditing||isFetching" [formGroup]="memoryMessageForm" placeholder="Memory Chest Name" name="nickname" controlName="nickname" (focus)="onFocus()" (blur)="onBlur()" iconLeft="education.open-book"></rv-input> -->
			<rv-textarea sm [formGroup]="memoryMessageForm" placeholder="Tell me the story ..." name="body" controlName="body" iconLeft="education.open-book" />
			<!-- white lg open outline_disable class="fw-bold" -->
		</div>
	</div>
	<div class="row mt-4">
		<div class="col-12">
			<rv-button outline purple md block label="Cancel" (click)="onCancel.emit()"/>
		</div>
		<div class="col-12">
			<rv-button [purple]="memoryMessageForm.valid" [gray]="!memoryMessageForm.valid" md block [label]="MemoryMessage?.isNew() === false?'Update':'Create'" (click)="memoryMessageForm.valid && saveMemoryMessage()" />
		</div>
	</div>
</form>