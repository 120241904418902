import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { AuthFormStates } from './../shared/AuthFormStates.enum';
import { Subscription, BehaviorSubject } from 'rxjs';
import { AuthFormService } from './../shared/auth-form.service';
import { ConfigService } from './../../../shared/services/config.service';

type GenericObject = { [key: string]: any };
type authStateConfig = {state: AuthFormStates, form_fields: GenericObject};


@Component({
  selector: 'rv-auth-page',
  templateUrl: './auth-page.component.html',
  styleUrls: ['./auth-page.component.scss']
})
export class AuthPageComponent implements OnInit {

	private _FormState: BehaviorSubject<AuthFormStates> = new BehaviorSubject(AuthFormStates.LOGIN);
	private _formStateConfig: authStateConfig = {state:0, form_fields:{}};
	private _subscriptions$ = new Subscription();


	canRegister (): boolean
	{
		return [true,'true'].includes(this.ConfigSrvc.get("app.auth.can_register"));
	} 

	onStateChange (r)
	{
		if (+this._FormState.getValue() !== +AuthFormStates[r.toUpperCase()])
			this._FormState.next(+AuthFormStates[r.toUpperCase()]);
	}

	get state (): string
	{
		return AuthFormStates[this._formStateConfig.state];
	}

	goToSignup (): void 
	{
		this.AuthFormSrvc.state = AuthFormStates.SIGNUP;
	}

	goToInvite (): void
	{
		location.assign('https://getrearview.com');
	}

  constructor (private router: Router, private AuthFormSrvc: AuthFormService, private ConfigSrvc: ConfigService) 
  {}

  ngOnInit (): void 
  {
  	this._subscriptions$.add(this.AuthFormSrvc.onAuthFormStateChange.subscribe(state => this._formStateConfig.state = state));
  	this.AuthFormSrvc.fillAuthStateConfig();
  }

  ngOnDestroy (): void
  {
  	this._subscriptions$.unsubscribe();
  }
}