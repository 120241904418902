import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TrackJsService {

	track (...args): void
	{
		console.error(args);
	}

	// track (arg1?: any, arg2?: any, arg3?: any): any
	// {
	// 	console.error(arg1,arg2,arg3);
	// }

  constructor () 
  {}
}
