import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ModelInstance } from '@getrearview/model-builder';

@Component({
  selector: 'delete-media-confirm',
  templateUrl: './delete-media-confirm.component.html',
  styleUrls: ['./delete-media-confirm.component.scss']
})
export class DeleteMediaConfirmComponent {

	@Output('onCancel')
	onCancel: EventEmitter<void> = new EventEmitter();

	@Output('onConfirm')
	onConfirm: EventEmitter<ModelInstance> = new EventEmitter();

	@Input('Media')
	Media: ModelInstance;
}
