import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { RouteData } from 'src/app/core/values/route-data.value';
import { MemoryChestsPageComponent } from './memory-chests-page/memory-chests-page.component';

const routes: Routes = [
	{ 
		path:                       'memory-chests',
		component:                  MemoryChestsPageComponent,
		canActivate:                [AuthGuard],
		data: 											new RouteData({animation:'feedPage',widgets:{'rv-floating-controls-widget':{enable:false,options:{viewMode:'memoryChestEditor'}},'rv-header-widget':{enable:true}}})
	}
];

@NgModule({
  imports:                      [RouterModule.forChild(routes)],
  exports:                      [RouterModule]
})
export class MemoryChestsRoutingModule { }