import { trigger,transition,style,query,group,animate,animateChild } from '@angular/animations';

export const fadeInAnimation =
  trigger('fadeInAnimation', [
    transition('* <=> *', [
      style({ position: 'relative' }),
      query(':enter, :leave', [style({position: 'absolute', top: 0, left: 0, width: '100%'})], {optional: true}),
      query(':enter', [style({ visibility: 'hidden', opacity: 0, transform: 'translateY(-1rem)' })], {optional: true}),
      query(':leave', animateChild(), {optional: true}),
      group([
        query(':leave', [animate('300ms ease-out', style({ visibility: 'hidden', opacity: 0, transform: 'translateY(1rem)'}))], {optional: true}),
        query(':enter', [animate('300ms ease-out', style({ visibility: 'visible', opacity: 1, transform: 'translateY(0)'}))], {optional: true}),
      ]),
    ])
  ]);

export const slideInAnimation =
  trigger('slideInAnimation', [
    transition('* <=> *', [
      style({ position: 'relative' }),
      query(':enter, :leave', [style({position: 'absolute', top: 0, left: 0, width: '100%'})], {optional: true}),
      query(':enter', [style({ visibility: 'hidden', opacity: 0, transform: 'translateX(-5rem)' })], {optional: true}),
      query(':leave', animateChild(), {optional: true}),
      group([
        query(':leave', [animate('300ms ease-out', style({ visibility: 'hidden', opacity: 0, transform: 'translateY(1rem)'}))], {optional: true}),
        query(':enter', [animate('300ms ease-out', style({ visibility: 'visible', opacity: 1, transform: 'translateY(0)'}))], {optional: true}),
      ]),
    ])
  ]);