import { Injectable } from '@angular/core';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { CaptureImageOptions, CaptureVideoOptions, MediaCapture, MediaFile } from '@awesome-cordova-plugins/media-capture/ngx';
import { Photo } from '@capacitor/camera';
import { Filesystem } from "@capacitor/filesystem";

@Injectable({
  providedIn: 'root'
})
export class VideoInterfaceService {
  video: any;
  constructor(
    private mediaCapture: MediaCapture,
    private file: File
  ) { }

  async startRecording(): Promise<Photo> {
    try {
      let options: CaptureVideoOptions = { limit: 1 };
      const data = await this.mediaCapture.captureVideo(options);
      this.video = data[0];
      console.log('video', this.video);

      const dir = this.video.localURL.split('/');
      dir.pop();
      const fromDir = dir.join('/');
      console.log('from Dir', fromDir);
  
      const toDir = this.file.dataDirectory;
      // const response = await this.file.copyFile(fromDir, this.video.name, toDir, this.video.name);
      // console.log(response);
      const result = await Filesystem.readFile({ path: this.video.localURL });
      // const result = await this.file.readAsDataURL(this.file.dataDirectory, this.video.name);
      // console.log('result', result);
      // await this.saveVideoToPreferences(this.video.name);
      // this.loadVideoList();
      // return result;
      const photoData: Photo = { base64String: (result.data as string), path: toDir + this.video.name, format: this.video.type, saved: false };
      console.log('Photo Data', photoData);
      return photoData
    } catch (e) {
      console.log('ex', e);
    }
  }

  
}
