import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MiniMemberImgComponent } from './mini-member-img/mini-member-img.component';
import { MemberSelectorComponent } from './member-selector/member-selector.component';
import { UiMultiSelectModule } from 'src/app/shared/interface/ui-multi-select/ui-multi-select.module';
import { RearviewInterfaceModule } from './../../shared/interface/rearview-interface.module';
import { MemberInviterComponent } from './member-inviter/member-inviter.component';

@NgModule({
  declarations: [
    MiniMemberImgComponent,
    MemberSelectorComponent,
    MemberInviterComponent
  ],
  imports: [
    CommonModule,
    UiMultiSelectModule,
    RearviewInterfaceModule
  ],
  exports: [
  	MiniMemberImgComponent,
  	MemberSelectorComponent,
  	MemberInviterComponent
  ]
})
export class MemberModule { }
