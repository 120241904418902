import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MemoryChestInviterWidgetComponent } from './memory-chest-inviter-widget/memory-chest-inviter-widget.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RearviewInterfaceModule } from 'src/app/shared/interface/rearview-interface.module';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';

@NgModule({
  declarations: [
    MemoryChestInviterWidgetComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RearviewInterfaceModule,
    DirectivesModule
  ],
  exports: [
  	MemoryChestInviterWidgetComponent
  ]
})
export class MemoryChestInviterModule { }
