import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MemoryEditorWidgetComponent } from './memory-editor-widget/memory-editor-widget.component';
import { MemoryFormComponent } from './memory-form/memory-form.component';
import { ModalModule } from './../modal/modal.module'
import { RearviewInterfaceModule } from 'src/app/shared/interface/rearview-interface.module';

@NgModule({
  declarations: [
    MemoryEditorWidgetComponent,
    MemoryFormComponent
  ],
  imports: [
    CommonModule,
    ModalModule,
    RearviewInterfaceModule
  ],
  exports: [
  	MemoryEditorWidgetComponent
  ]
})
export class MemoryEditorModule { }
