import { Component, ViewChild, ViewContainerRef, TemplateRef } from '@angular/core';
import { trigger, state, style, animate, transition, AUTO_STYLE } from '@angular/animations';
import { ModalService } from './../shared/modal.service';
import { Subscription } from 'rxjs';

const COLLAPSED_STYLE_STATE = {
        		margin: '0',
        		padding: '0',
            overflow: 'hidden',
            height: '0', 
            visibility: 'hidden', 
            display: 'none'
			},
			EXPANDED_STYLE_STATE = {
		      	margin: AUTO_STYLE, 
		      	padding: AUTO_STYLE,
          	display: AUTO_STYLE, 
          	overflow: AUTO_STYLE, 
            height: AUTO_STYLE, 
            visibility: AUTO_STYLE
          };

@Component({
  selector: 'rv-modal-content',
  templateUrl: './modal-content.component.html',
  styleUrls: ['./modal-content.component.scss'],
  animations: [
		trigger('openClose', [
			state('true', style(EXPANDED_STYLE_STATE)),
			state('false', style(COLLAPSED_STYLE_STATE)),
			transition('false <=> true', animate(150))
		])
  ]
})
export class ModalContentComponent {

	@ViewChild('dynamicTemplate', {read: ViewContainerRef}) 
	dynamicTemplate: ViewContainerRef;
	isEnabled: boolean = false;

	#subscriptions$ = new Subscription();

	_isHiding: string = "close";
	get isHiding (): string { return this._isHiding; }

	setTemplate (template: TemplateRef<any>): void
	{
		if (template && this.dynamicTemplate) {
			this.dynamicTemplate.clear();
			this.dynamicTemplate.createEmbeddedView(template);
		}

		this._isHiding = !!template ? 'open' : 'close';
		this.isEnabled = !!template;
	}

  constructor (public modalService: ModalService)
  {}

  ngOnInit(): void 
  {
  	this.isEnabled = false;
  	this.#subscriptions$.add(this.modalService.template.subscribe((template: TemplateRef<any>) => this.setTemplate(template)));
  }

  ngOnDestroy (): void 
  {
  	this.#subscriptions$.unsubscribe();
  }
}