<div id="memory-chest-members-component" [ngClass]="{'can-invite': canInvite, 'has-loaded': hasLoaded}">
	<ul>
		<ng-container *ngFor="let M of chestSrvc.Members$|async; let idx = index">
			<li *ngIf="!!M?.get('user_id')" (click)="clickMember(M?.get('user_id'))">
				<rv-memory-chest-member-widget [Member]="M" [Selected]="selectedMemberIds?.includes(M?.get('user_id'))" />
			</li>
		</ng-container>
	</ul>
	<div *ngIf="canInvite" class="invite-button">
		<rv-button sm circle purple icon-off-white iconLeft="users.add-account" (click)="openMemberInviter()"></rv-button>
	</div>
</div>

<ng-template #memberInviter>
	<rv-memory-chest-inviter [MemoryChest]="chestSrvc.Chest" [Me]="Me" />
</ng-template>
