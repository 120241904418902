import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rv-ui',
  templateUrl: './ui.component.html',
  styleUrls: ['./ui.component.scss']
})
export class UiComponent implements OnInit {

	activeView:string;

	onViewChange (view: string): void
	{
		this.activeView = view;
	}

  constructor () 
  {}

  ngOnInit (): void 
  {}
}
