import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwayingLandscapeWidgetComponent } from './swaying-landscape-widget/swaying-landscape-widget.component';
import { SharedModule } from './../../shared/shared.module';


@NgModule({
  declarations: [SwayingLandscapeWidgetComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
  	SwayingLandscapeWidgetComponent
  ]
})
export class SwayingLandscapeModule { }
