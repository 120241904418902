import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SessionUser } from 'src/app/core/session/session-user.model';
import { ModelInstance } from '@getrearview/model-builder';
import { MemoryChestService } from 'src/app/models/memories/memory-chest.service';
import { MessageBusService, Channels, Message } from 'src/app/core/services/message-bus.service';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'rv-memory-chest-selector-widget',
  templateUrl: './memory-chest-selector-widget.component.html',
  styleUrls: ['./memory-chest-selector-widget.component.scss']
})
export class MemoryChestSelectorWidgetComponent implements OnInit {

	isFetching: boolean = false;
	isOpen: BehaviorSubject<boolean> = new BehaviorSubject(false);

	@Input('chestId')
	chestId: string;

	@Output('onSelect')
	onSelect: EventEmitter<ModelInstance> = new EventEmitter()

	MemoryChest: ModelInstance;
	chestsWithMembers: ModelInstance[];

	#subscriptions$: Subscription = new Subscription();

	get chests (): Array<ModelInstance>
	{
		return this.MemoryChestSrvc.all()
	}

	getChestsWithMembers = async () =>
	{
		const {models, pagination} = await this.MemoryChestSrvc.search({_relationships: ['member','member.media']},{do_not_store: true});
		this.chestsWithMembers = models;
	}

	onChestSelected = (chestId: string): void => 
	{
		this.onSelect.next(this.chests.filter(Chest => `${Chest.id()}` === `${chestId}`).shift());
	}

	private fetch = async () =>
	{
		if (!this.MemoryChestSrvc.all().length) {
			this.isFetching = true;
			await this.MemoryChestSrvc.search({})
			this.setChest();
			this.isFetching = false;
		}

		return Promise.resolve()		
	}
 
	private setChest ()
	{
		this.MemoryChest = (this.chests||[]).filter(Chest => `${Chest.id()}` === `${this.chestId}`).shift();
	}

	#addOrReplace (MemoryChest: ModelInstance): void
	{
		let MemoryChests: Array<ModelInstance> = this.MemoryChestSrvc.all();
		this.MemoryChestSrvc.set(this.chests.every(Chest => Chest.id() !== MemoryChest.id()) ? MemoryChests.concat([MemoryChest]) : MemoryChests.map(Chest => Chest.id() === MemoryChest?.id() ? MemoryChest : Chest));
	}

  constructor (private bus: MessageBusService, private User: SessionUser, private MemoryChestSrvc: MemoryChestService) 
  {}

  ngOnInit (): void 
  {
  	this.#subscriptions$.add(this.bus.subscribe(Channels.Model, (Msg) => {
  		if (Msg.name === 'memory-chest.updated' && Msg.payload?.MemoryChest)
  			this.#addOrReplace(Msg.payload.MemoryChest);
  	}));

  	this.fetch();
	this.getChestsWithMembers();
  }

  ngOnDestroy (): void
  {
  	this.#subscriptions$.unsubscribe();
  }

  ngOnChanges (): void
  {
  	this.setChest();
  }
}