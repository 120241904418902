import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { SessionUser } from './../session/session-user.model';
import { TokenService } from './../services/token.service';

@Injectable({
  providedIn: 'root'
})
export class NonAuthGuard implements CanActivate {

  async canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean|UrlTree>
  {
  	return new Promise((resolve, reject) => this.TokenSrvc.isValid().then(isAuth => resolve(isAuth ? this.router.createUrlTree(['']) : true)));
  }

  constructor (private router: Router, private SessionUser: SessionUser, private TokenSrvc: TokenService) 
  {}
}