import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'milestone-date-indicator',
  templateUrl: './milestone-date-indicator.component.html',
  styleUrls: ['./milestone-date-indicator.component.scss']
})
export class MilestoneDateIndicatorComponent implements OnInit {

	@Input('date')
	date!: string;

  constructor () 
  {}

  ngOnInit (): void 
  {}
}
