import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ModelInstance } from '@getrearview/model-builder';
// import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
// import { ModelInstance } from '@getrearview/model-builder';
// import { MilestoneService } from 'src/app/models/memories/milestone.service';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
// import { MessageBusService, Channels, Message } from 'src/app/core/services/message-bus.service';


@Component({
  selector: 'milestone-intro',
  templateUrl: './milestone-intro.component.html',
  styleUrls: ['./milestone-intro.component.scss']
})
export class MilestoneIntroComponent {

	@Output('onCancel')
	onCancel: EventEmitter<void> = new EventEmitter<void>();

	@Output('onSuccess')
	onSuccess: EventEmitter<ModelInstance> = new EventEmitter<ModelInstance>();

	@Input('MemoryChest')
	MemoryChest: ModelInstance;

	@Input('Milestone')
	Milestone: ModelInstance;

	isSaving: boolean = false;

	// Milestone!: ModelInstance;
	milestoneForm: FormGroup = new FormGroup({description: new FormControl(''), place_id: new FormControl('')});
// new FormGroup({nickname: new FormControl('',Validators.required)});
	// , start_at: [new FormControl(''), Validators.required]
	_subscriptions$:Subscription = new Subscription();

	async saveMilestone (): Promise<void>
	{
		this.isSaving = true;
		let success: boolean = false;

		if (this.Milestone) {
			try { 
				await this.Milestone.save();
				success = true;
			}
			catch (ex) {
				console.error(`Failed to save new Milestone. ${ex}`);
				/*TODO: complete error handling*/
				// this.onError.next(`${ex}`);
			}
		}

		if (success)
			this.onSuccess.next(this.Milestone);

		// this.bus.emit(Channels.Model,new Message('milestone.updated',{Milestone:this.Milestone}))

		this.isSaving = false;
	}

	#updateModel (changes: {[key: string]: any}): void
	{
		if (this.Milestone)
			this.Milestone.attribs = {...this.Milestone.attribs, ...changes};
  }

	updateForm (Milestone: ModelInstance): void
	{
		this.milestoneForm.get('nickname').setValue((Milestone?.get('nickname')||''));
	}

	updateFormSilent (Milestone: ModelInstance): void
	{
		this.milestoneForm.get('nickname').setValue((Milestone?.get('nickname')||''), { emitEvent: false });
	}

  constructor (private fb: FormBuilder)
  {}

  ngOnInit (): void
  {
  	this._subscriptions$.add(this.milestoneForm.valueChanges.pipe(distinctUntilChanged(), debounceTime(200)).subscribe(changes => this.#updateModel(changes)));

  	// this.#fetchMilestone()
  	// 				.finally(() => {
  	// 					if (this.Milestone)
  	// 						this.updateForm(this.Milestone)
  	// 					// this.isFetching = false;
  	// 				})
  	// 				.catch(() => {
  	// 					// this.isFetching = false;
  	// 				})
  }

  ngOnDestroy (): void
  {
  	this._subscriptions$.unsubscribe();
  }
}