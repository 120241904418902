import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
// import { ProductService } from './../../../models/billing/product.service';

// const stripe = Stripe("pk_test_0BxKkJD3VhACIIDUjdLJlvBB003ZngP49t");

@Component({
  selector: 'rv-account-page',
  templateUrl: './account-page.component.html',
  styleUrls: ['./account-page.component.scss']
})
export class AccountPageComponent implements OnInit {

	// private clientSecret: string = "";

	// @ViewChild('linkAuth') 
  // linkAuth: ElementRef;

	// @ViewChild('payment') 
  // payment: ElementRef;

  // isLoading:boolean = false;
  // errorMessage: string = "";

  // paymentForm: FormGroup;

  // private elements;

  /*
	private async fetchClientSecret(): Promise<void>
	{
	  const { client_secret } = await fetch("http://localhost/api/users/billing/intent", {
	    method: "POST",
	    headers: { "Content-Type": "application/json" },
	    body: JSON.stringify({"price_id": ["price_1MY1wdBqM04XrSktGEvjHPSX"]}),
	  }).then((r) => r.json());

	  this.elements = stripe.elements({clientSecret: client_secret});

	  // const linkAuthenticationElement = this.elements.create("linkAuthentication");
	  // linkAuthenticationElement.mount("#link-authentication-element");

	  const paymentElementOptions = {
	  	// email: 'accounts@stevenawaskey.com',
	    layout: "tabs",
	  };

	  const paymentElement = this.elements.create("payment", paymentElementOptions);
	  paymentElement.mount("#payment-element");

	  return Promise.resolve();
	}

	public async handleSubmit($event): Promise<void> 
	{
	  $event.preventDefault();
	  this.setLoading(true);
	  // this.isLoading = true;

	  const { error } = await stripe.confirmPayment({
	    elements: this.elements,
	    confirmParams: {
	      // Make sure to change this to your payment completion page
	      return_url: "http://localhost:4200/accounts?status=thank-you",
	      // receipt_email: 'swaskey@mac.com',
	    },
	  });

	  console.log(error);

	  // This point will only be reached if there is an immediate error when
	  // confirming the payment. Otherwise, your customer will be redirected to
	  // your `return_url`. For some payment methods like iDEAL, your customer will
	  // be redirected to an intermediate site first to authorize the payment, then
	  // redirected to the `return_url`.
	  if (error.type === "card_error" || error.type === "validation_error") {
	    this.showMessage(error.message);
	    // this.errorMessage = error.message;
	  } else {
	    this.showMessage("An unexpected error occurred.");
	    // this.errorMessage = "An unexpected error occurred.";
	  }

	  this.setLoading(false);
	  // this.isLoading = false;
	  return Promise.resolve();
	}

	public showMessage(messageText): void 
	{
	  const messageContainer = document.querySelector("#payment-message");

	  messageContainer.classList.remove("hidden");
	  messageContainer.textContent = messageText;

	  setTimeout(function () {
	    messageContainer.classList.add("hidden");
	    // messageText.textContent = "";
	  }, 4000);
	}

	public async checkStatus(): Promise<void>
	{
	  const clientSecret = new URLSearchParams(window.location.search).get(
	    "payment_intent_client_secret"
	  );

	  if (!clientSecret) {
	    return Promise.resolve();
	  }

	  const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

	  switch (paymentIntent.status) {
	    case "succeeded":
	      this.showMessage("Payment succeeded!");
	      break;
	    case "processing":
	      this.showMessage("Your payment is processing.");
	      break;
	    case "requires_payment_method":
	      this.showMessage("Your payment was not successful, please try again.");
	      break;
	    default:
	      this.showMessage("Something went wrong.");
	      break;
	  }

	  return Promise.resolve();
	}

	public setLoading(isLoading) 
	{
		const submit = document.querySelector("#submit") as any,
					spinner = document.querySelector("#spinner") as any,
					btnText = document.querySelector("#button-text") as any;

	  if (isLoading) {
	    submit.disabled = true;
	    spinner.classList.remove("hidden");
	    btnText.classList.add("hidden");
	  } else {
	    submit.disabled = false;
	    spinner.classList.add("hidden");
	    btnText.classList.remove("hidden");
	  }
	}
	*/

	// Stripe recommends handling the following events:
	// 	payment_intent.succeeded
	// 	payment_intent.processing
	// 	payment_intent.payment_failed

  constructor () 
  {}

  ngOnInit (): void 
  {
  	// this.paymentForm = new FormGroup({
  	// 	customer: new FormControl(null)
  	// });

  	// this.fetchClientSecret();
  	// this.checkStatus();
  	// this.ProductSrvc.search({},{do_not_store: true}).then(res => console.log('products: ',res))
  }
}
