import { Component, OnInit, Input } from '@angular/core';
import { FeedService } from './../shared/feed.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalService, modalParams } from 'src/app/widgets/modal/shared/modal.service';
import { ModelInstance } from '@getrearview/model-builder';
import { Subscription } from 'rxjs';

@Component({
  selector: 'feed-filter',
  templateUrl: './feed-filter.component.html',
  styleUrls: ['./feed-filter.component.scss']
})
export class FeedFilterComponent implements OnInit {

	@Input('chestId')
	chestId: string;

	MemoryChests;

	feedFilterForm = this.formBuilder.group({
		memoryChest: 		[null, [Validators.required]],
		selectPerson: 	[null, [Validators.required]],
		location: 			[null, [Validators.required]],
		initialDate: 		[null, [Validators.required]],
		hashtags: 			[null, [Validators.required]],
	});

	handleSubmit (): void 
	{
		const searchParams = {
			chest_id: 		this.feedFilterForm.value.memoryChest,
			member_id: 		this.feedFilterForm.value.selectPerson,
			place_id: 		this.feedFilterForm.value.location,
			event_date: 	this.feedFilterForm.value.initialDate,
			tag_id: 			this.feedFilterForm.value.hashtags
		}

		this.feedSrvc.fetchFeed(null, searchParams).finally(() => {
			this.modalService.close();
    });
	}

	closeFilter(): void {
		this.modalService.close();
	}

	constructor (private modalService: ModalService, private feedSrvc: FeedService, private formBuilder: FormBuilder) 
	{}

	ngOnInit (): void 
	{
		// this.feedFilterForm.valueChanges.subscribe(valueChanges => {});
	}
}
