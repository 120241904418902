import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject, pipe } from "rxjs";
import { takeUntil } from "rxjs/operators";
import * as moment from "moment";

@Injectable()
export class MediaAudioService 
{
  audioEvents: string[] = [
    "ended", "error",
    "play", "playing",
    "pause", "timeupdate",
    "canplay", "loadedmetadata",
    "loadstart"
  ];

  private stop$ = new Subject();
  private audioObj = new Audio();
  private state: AudioStreamState = {
  	loadstart: false,
    playing: false,
    readableCurrentTime: '',
    readableDuration: '',
    duration: undefined,
    currentTime: undefined,
    canplay: false,
    error: false,
  };

  private stateChange: BehaviorSubject<AudioStreamState> = new BehaviorSubject(this.state);

  prepareStream (url: string) 
  {
    return this.streamObservable(url).pipe(takeUntil(this.stop$));
  }

  play () 
  {
    this.audioObj.play();
  }

  pause () 
  {
    this.audioObj.pause();
  }

  stop () 
  {
    this.stop$.next(true);
  }

  seekTo (seconds: number) 
  {
    this.audioObj.currentTime = seconds;
  }

  formatTime (time: number, format: string = "m:ss") 
  {
    const momentTime 			= 		time * 1000;
    return moment.utc(momentTime).format(format);
  }

  getState (): Observable<AudioStreamState> 
  {
    return this.stateChange.asObservable();
  }

  private bindPlayerEvents (obj, events, handler) 
  {
    events.forEach(event => obj.addEventListener(event, handler));
  }

  private unbindPlayerEvents (obj, events, handler) 
  {
    events.forEach(event => obj.removeEventListener(event, handler));
  }

  private updateStateEvents (event: Event): void 
  {
    switch (event.type) {
    	case "loadstart":
				this.state.loadstart = true;
        this.state.canplay = false;
    		break;
    	case "loadedmetadata":
				this.state.loadstart = false;
    		break;
      case "canplay":
        this.state.duration = this.audioObj.duration;
        this.state.readableDuration = this.formatTime(this.state.duration);
        this.state.canplay = true;
        break;
      case "playing":
        this.state.playing = true;
        break;
      case "pause":
        this.state.playing = false;
        break;
      case "timeupdate":
        this.state.currentTime = this.audioObj.currentTime;
        this.state.readableCurrentTime = this.formatTime(
          this.state.currentTime
        );
        break;
      case "error":
        this.resetState();
        this.state.error = true;
        break;
    }
    this.stateChange.next(this.state);
  }

	private resetState () 
	{
	  this.state = {
	  	loadstart: false,
	    playing: false,
	    readableCurrentTime: '',
	    readableDuration: '',
	    duration: undefined,
	    currentTime: undefined,
	    canplay: false,
	    error: false
	  };
	}

	private streamObservable (url, play?: boolean) 
	{
	  return new Observable(observer => {

	    // Create Audio Object & Load URL
	    this.audioObj.src = url;
	    this.audioObj.load();

	    // If arg pass, commence play
	    if (!!play) this.audioObj.play();

	    // Define handler that will listen
	    // to user events on the audio player obj.
	    const handler = (event: Event) => {
	      this.updateStateEvents(event);
	      observer.next(event);
	    };

	    // bind to audio player events.
	    this.bindPlayerEvents(this.audioObj, this.audioEvents, handler);


	    return () => {
	      // Stop Playing
	      this.audioObj.pause();
	      this.audioObj.currentTime = 0;

	      // remove event listeners
	      this.unbindPlayerEvents(this.audioObj, this.audioEvents, handler);

	      // reset state
	      this.resetState();
	    };
	  });
	}
}

export interface AudioStreamState {
	loadstart: boolean;
  playing: boolean;
  readableCurrentTime: string;
  readableDuration: string;
  duration: number|undefined;
  currentTime: number|undefined;
  canplay: boolean;
  error: boolean;
}