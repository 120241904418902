import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject, lastValueFrom, firstValueFrom } from 'rxjs';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { ProfileService } from 'src/app/models/users/profile.service';
import { ModelInstance } from '@getrearview/model-builder';
import { AuthService } from 'src/app/core/services/auth.service';
import { TokenService } from 'src/app/core/services/token.service';
import { SocialAuthService, FacebookLoginProvider, SocialUser } from '@abacritt/angularx-social-login';
import { TheFutureAuthProvider } from './the-future-auth.provider';

const getChestIds = (User: ModelInstance): Array<string|number> =>
{
	if (User && User?.getChildren('member'))
		return Object.keys(User.getChildren('member')).map(memberId => (User.getChild('member', memberId) as ModelInstance).getAssociation('chest')?.ids()).filter(chestIds => Array.isArray(chestIds)).flat();
	return [];
}






interface cognitoUserAuthStore {
  "session"?: CognitoUserSession;
  "user"?: CognitoUser;
};

@Injectable()
export class SessionUser {

	private _isInitialized: boolean = false;
	private _socialUser!: SocialUser;
	private _cognitoSession!: CognitoUserSession;
	private _cognitoUser!: CognitoUser;
	private _initState = new BehaviorSubject<boolean>(false);
	private _onProfileChange = new Subject<ModelInstance>();

	get id (): string
	{
		return `${(this.instance && this.instance.id()) || ''}`
	}

	get instance (): ModelInstance
	{
		return this.ProfileSrvc.get();
	}

	get api (): {[name: string]: Function}
	{
		return this.ProfileSrvc.api();
	}

	get isAuth (): boolean 
	{
		return this.auth.isAuth;
	}

	get onProfileChange (): Observable<ModelInstance>
	{
		return this._onProfileChange.asObservable();
	}

	get onAuthChange (): Observable<boolean>
	{
		return this.auth.authStatus;
	}

	get onInitChange (): Observable<boolean>
	{
		return this._initState;
	}

	get cognito (): cognitoUserAuthStore
	{
		return {
			user: this._cognitoUser,
			session: this._cognitoSession
		}
	}

	set cognito (cognito: cognitoUserAuthStore)
	{
		const {user,session} = cognito;
		this._cognitoSession = session;
		this._cognitoUser = user;
	}

	async token (token?: string, provider?: string): Promise<string>
	{
		if (token) {
			await this.TokenSrvc.set(token, provider);
      this.auth.changeAuthStatus(!!(token = await this.TokenSrvc.get()));
			return token;
		}

		return await this.TokenSrvc.get();
	}

	async checkIsAuth (): Promise<boolean>
	{
		return this.authProvider.isTokenValid();
	}

	async logout (): Promise<boolean>
	{
		await this.authProvider.logout();
		this.ProfileSrvc.clear();
    return Promise.resolve(true);
	}

	retrieve (): boolean
	{
		const oId: string = this.id;

		if (!!this.ProfileSrvc.retrieve()) {
			if (!!this.ProfileSrvc.get() && `${this.ProfileSrvc.get().id()}` !== `${oId}`)
				this._onProfileChange.next(this.ProfileSrvc.get());
			return true;
		}

		return false;
	}

	publish (): void
	{
		this._onProfileChange.next(this.ProfileSrvc.get());
	}

	store (): void
	{
		this.ProfileSrvc.store();
	}
// this isntr qwoerkingf completely.
// the profile is being loaded somewhere else.
// im trying to get the profile's members to load
// so we know which memory chests belong to this
// user.  

// though we could also just put a quick service call 
// directly within the memory chest selector component.


	async getInstance (): Promise<ModelInstance|void>
	{
		if (this.instance)
			return Promise.resolve(this.instance as ModelInstance);

		// i was trying to solve the issue above, but i dont have associations on page restore.TokenSrvc
		// should i bother fixing this now? 
		// or change the way i load memory chests?
		// this needs to get fixed, but it might not be worth the headache now.
		// document it in jira and move on.

		if (this.retrieve() && !!this.ProfileSrvc.get()) {
			return Promise.resolve(this.ProfileSrvc.get() as ModelInstance);
		}

		const Profile: ModelInstance = ((await this.ProfileSrvc.search({_relationships: ['member','media']}))||[]).shift();
		if (Profile) {
			this.ProfileSrvc.set(Profile);
			this.ProfileSrvc.store();
			return Promise.resolve(Profile as ModelInstance);
		}

		return Promise.resolve();
	}

	async init (): Promise<boolean>
	{
		// try { 
		// 	await this.authProvider.init(); 
		// }
		// catch (ex) {}

		if (await this.authProvider.validateToken()) 
		{
			const results = await Promise.all([
								this.token(await this.authProvider.token(),this.authProvider.provider),
								this.getInstance()
							]);

			if (Array.isArray(results) && results[0]) {
				if (results[0])
					return Promise.resolve(this._isInitialized = true);
			}
		}

		return Promise.resolve(false);
	}

	constructor (private TokenSrvc: TokenService, private socialAuthService: SocialAuthService, private auth: AuthService, private ProfileSrvc: ProfileService, private authProvider: TheFutureAuthProvider) 
	{}
}