import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MemoryChestEditorWidgetComponent } from './memory-chest-editor-widget/memory-chest-editor-widget.component';
import { RearviewInterfaceModule } from 'src/app/shared/interface/rearview-interface.module';

@NgModule({
  declarations: [
    MemoryChestEditorWidgetComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RearviewInterfaceModule
  ],
  exports: [
  	MemoryChestEditorWidgetComponent
  ]
})
export class MemoryChestEditorModule { }
