import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SessionUser } from './../session/session-user.model';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpParams,
  HttpInterceptor
} from '@angular/common/http';
import { defer, from, Observable, throwError } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { ConfigService } from './../../shared/services/config.service';
import { TokenService } from './../services/token.service';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {

  private isHopeRequest (request: HttpRequest<any>): boolean
  {
    try { 
      return                    String(request.url).indexOf(this.ConfigSrvc.get("api.api_url")) > -1 ||
                                String(request.url).indexOf(this.ConfigSrvc.get("api.auth_url")) > -1 || 
                                (this.ConfigSrvc.get("production") ? false : String(request.url).indexOf('localhost') > -1);
    }
    catch (ex)                   { return false; }
  }

  private isAuthRequest (request: HttpRequest<any>): boolean
  {
  	return !!(`${request?.method}`.toUpperCase() === 'POST' && String(request.url).indexOf('oauth') > -1);
  }

  constructor (private token: TokenService, private ConfigSrvc: ConfigService, private SessionUser: SessionUser, private router: Router)
  {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> 
  {
  	if (!this.isHopeRequest(request))
    	return 										next.handle(request);

	  return defer(() => from(this.token.get()))
	        .pipe(
	          switchMap(token => {
					  	if (this.isAuthRequest(request)) {
					  		// [WIP] THIS IS AN AUTH REQUEST, DO NOT USE REFRESH TOKEN. LOG OUT USER.
					  		this.SessionUser.logout();
					    	// return 										next.handle(request);
					  	}

							let params = new HttpParams().set('tz', Intl.DateTimeFormat().resolvedOptions().timeZone);
							request.params.keys().forEach(key => params = params.set(key, request.params.get(key)));

							const headers 			= 	token 
																				? request.headers.set('Authorization', 'Bearer ' + token).append('Content-Type', 'application/json')
																				: request.headers.append('Content-Type', 'application/json');

							const requestClone 	= 	request.clone({params, headers});

							return 									next.handle(requestClone).pipe(catchError(err => {
								    if (err.status === 401) {
								    	this.router.navigate(['login']);
								    	this.SessionUser.logout();
								    }

								    // const error = err?.error?.message || err?.statusText || 'UNKNOWN_ERROR';								    
								    return throwError(err);
								}));
	          })
	         );
  }
}
