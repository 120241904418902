import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionUser } from './../session/session-user.model';

@Injectable({
  providedIn: 'root'
})
export class LoggedOutService implements CanActivate{

  constructor(
  	private User: SessionUser,
  	private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
  	return new Promise(async (resolve, reject) => resolve(!(await this.User.checkIsAuth())));
  }
}