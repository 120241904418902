import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MilestoneDeleterWidgetComponent } from './milestone-deleter-widget/milestone-deleter-widget.component';
import { RearviewInterfaceModule } from 'src/app/shared/interface/rearview-interface.module';


@NgModule({
  declarations: [
    MilestoneDeleterWidgetComponent
  ],
  imports: [
    CommonModule,
    RearviewInterfaceModule
  ],
  exports: [
  	MilestoneDeleterWidgetComponent
 	]
})
export class MilestoneDeleterModule { }
