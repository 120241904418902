import { Component, Input } from '@angular/core';
import { ModelInstance, ModelChildrenCollection } from '@getrearview/model-builder';
import { StoryService } from './../shared/story.service';

@Component({
  selector: 'rv-comment-like-button',
  templateUrl: './comment-like-button.component.html',
  styleUrls: ['./comment-like-button.component.scss']
})
export class CommentLikeButtonComponent {

	@Input('Comment')
	Comment: ModelInstance;

	@Input('Member')
	Member: ModelInstance;

	get Likes (): ModelChildrenCollection {
		return (this.Comment.getChildren('like') || {})  as ModelChildrenCollection;
	}

	get Like (): ModelInstance
	{
		return Object.values(this.Likes).filter(Like => Like.get('member_id') === this.Member?.id()).shift();
	}

	get isLiked (): boolean 
	{

		return !!this.Like;
	}

	get numLikes (): string
	{
		let i = Object.keys(this.Likes).length;
		return `${i} Like${i!==1?'s':''}`
	}

	async toggle (): Promise<void>
	{
		if (this.isLiked) {
			const likeId: string|number = this.Like.id();
			await this.Like.delete();
			await this.Comment.removeChild('like',likeId);			
		}
		else {
			await this.Comment.createChild('like',{member_id: this.Member?.id()});
		}

		return Promise.resolve();
	}

	constructor (private StorySrvc: StoryService)
	{}
}
