import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { SessionUser } from './../../../core/session/session-user.model';
import { AuthFormService } from './../shared/auth-form.service';
import { ConfigService } from './../../../shared/services/config.service';
import { AuthFormComponent } from './../shared/auth-form.component';
import { TrackJsService } from './../../../shared/services/trackjs.service';
import { TokenService } from './../../../core/services/token.service';
import { AuthService } from './../../../core/services/auth.service';
import { AuthFormStates } from './../shared/AuthFormStates.enum'
import { ApiService } from './../../../core/services/api.service';

import {
  SocialAuthService
} from '@abacritt/angularx-social-login';

@Component({
  selector: 'rv-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./../shared/auth-form.component.scss']
})
export class LoginFormComponent extends AuthFormComponent implements OnInit 
{
  constructor (
  	private socialAuthService: SocialAuthService, 
  	SessionUser: SessionUser, 
  	AuthFormSrvc: AuthFormService, 
  	ConfigSrvc: ConfigService, 
  	fb: FormBuilder, 
  	TrackJsSrvc: TrackJsService, 
  	private token: TokenService, 
  	private auth: AuthService, 
  	AuthApi: ApiService
  ) 
  {
  	super(SessionUser, AuthApi, AuthFormSrvc, ConfigSrvc, fb, TrackJsSrvc)
  }

  ngOnInit (): void 
  {} 
}