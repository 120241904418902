import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './../../core/guards/auth.guard';
import { SessionUserResolver } from './../../core/resolvers/session-user.resolver';
import { AccountPageComponent } from './account-page/account-page.component';
// import { LoggedInService } from './../../core/services/logged-in.service';
import { AccountPageResolver } from './account-page.resolver';

const routes: Routes = [
	{ 
		path:                       'account',
		component:                  AccountPageComponent,
		resolve: 										{SessionUser: SessionUserResolver, AccountPage: AccountPageResolver},
		canActivate:                [AuthGuard],
		data: 											{animation:'accountPage'}
	},
];


@NgModule({
  imports:                      [RouterModule.forChild(routes)],
  exports:                      [RouterModule]
})
export class AccountRoutingModule { }