import { Injectable } from '@angular/core';
import { MilestonePreviewService } from './../models/milestone-preview.service';
import { SessionUser } from 'src/app/core/session/session-user.model';
import { MemoryChestService } from 'src/app/models/memories/memory-chest.service';
import { MilestoneService } from 'src/app/models/memories/milestone.service';
import { MemberService } from 'src/app/models/memories/member.service';
import { ModelInstance } from '@getrearview/model-builder';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChestService {

	private _Chest!: ModelInstance;
	private _Chest$: BehaviorSubject<ModelInstance> = new BehaviorSubject(undefined);
	private _Milestones: Array<ModelInstance> = [];
	private _Milestones$: BehaviorSubject<Array<ModelInstance>> = new BehaviorSubject([]);
	private _milestonesPagination = {};
	private _Members: Array<ModelInstance> = [];
	private _Members$: BehaviorSubject<Array<ModelInstance>> = new BehaviorSubject([]);
	private _membersPagination = {};
	private _Previews$: {[milestoneId: string]: BehaviorSubject<ModelInstance>} = {};
	private _monthYears: {[milestoneId: string]: string} = {};
	public Me: ModelInstance;

	doMilestonesExist (chestId?: string): boolean
	{
		return this._Milestones.filter(M => !chestId || M.get('chest_id') === chestId).length > 0;
	}

	getIsMemoryChestEmpty (): boolean
	{
		return this.Milestones.length === 0;
	}

	get monthYears (): {[milestoneId: string]: string}
	{
		return this._monthYears;
	}

	get Chest$ (): Observable<ModelInstance>
	{
		return this._Chest$.asObservable();
	}

	get Chest (): ModelInstance
	{
		return this._Chest;
	}

	set Chest (Chest: ModelInstance)
	{
		this._Chest$.next(this._Chest = Chest);
	}

	get Milestones$ (): Observable<Array<ModelInstance>>
	{
		return this._Milestones$.asObservable();
	}

	get Milestones (): Array<ModelInstance>
	{
		return this._Milestones;
	}

	set Milestones (Milestones: Array<ModelInstance>)
	{
		Milestones = this.Milestones.concat(Milestones);

		let monthYears = {};
		Milestones.forEach(Milestone => {
			let d: string = [new Date(Milestone.attribs.start_at||Milestone.attribs.created_at).getMonth()+1, new Date(Milestone.attribs.start_at||Milestone.attribs.created_at).getFullYear()].join('/');
			if (!Object.values(monthYears).includes(d))
				monthYears[Milestone.id()] = d;
		});
		this._monthYears = monthYears;

		this._Milestones$.next(this._Milestones = this.Milestones.concat(Milestones));
	}

	get Members$ (): Observable<Array<ModelInstance>>
	{
		return this._Members$.asObservable();
	}

	get Members (): Array<ModelInstance>
	{
		return this._Members;
	}

	set Members (Members: Array<ModelInstance>)
	{
		const existing = this.Members.map(M => M.id());
		Members = this.Members.concat(Members.filter(M => !existing.includes(M.id())));
		this.Me = Members.filter(Member => Member.get('user_id') === this.SessionUser.id).shift();
		this._Members$.next(this._Members = Members);
	}

	enableSelector = false;
	async fetch (chestId: string, searchParams?: {[key: string]: any}): Promise<void>
	{
		searchParams = searchParams && typeof searchParams === 'object' ? searchParams : {};

		if (this.Chest = await this.MemoryChestSrvc.find(chestId)) {
			const [milestonesRes, membersRes] = await Promise.all([
					this.MilestoneSrvc.search({...searchParams, chest_id:this.Chest.id(), _relationships: ['location']},{do_not_store: true}),
					this.MemberSrvc.search({chest_id: this.Chest.id(), _relationships: ['media']}, {do_not_store: true}),
				]);
			this.Milestones = milestonesRes?.models;
			this._milestonesPagination = milestonesRes?.pagination;
			this.Members = membersRes?.models;
			this.Me = this.Members.filter(Member => Member.get('user_id') === this.SessionUser.id).shift();
			this._membersPagination = membersRes?.pagination;
		}

		this.enableSelector = true;
		return Promise.resolve();
	}

	async filterMilestonesByMember (users: string[]): Promise<void>
	{
		const milestones = await this.MilestoneSrvc.search({chest_id: this.Chest.id(), member_id: users},{do_not_store: true});
		this._Milestones = [];
		this.Milestones = milestones?.models;
	}

	async preview (Milestone: ModelInstance): Promise<void>
	{
		const getPreview = async (): Promise<ModelInstance> =>
			Promise.resolve(await this.MilestonePreviewSrvc.find(Milestone.id()));

		try {
				if (!this._Previews$.hasOwnProperty(Milestone.id())) {
					this._Previews$[Milestone.id()] = new BehaviorSubject(await getPreview());
					return Promise.resolve();
				}
				else if (!this._Previews$[Milestone.id()].getValue()) {
					this._Previews$[Milestone.id()].next(await getPreview());
				}
		}
		catch (ex) {
			console.error(`${ex}`);
			return Promise.reject();
		}

		return Promise.resolve();
	}

	onPreview (milestoneId: string): Observable<ModelInstance>
	{
		if (milestoneId && !this._Previews$.hasOwnProperty(milestoneId))
			this._Previews$[milestoneId] = new BehaviorSubject(undefined);

		return this._Previews$[milestoneId];
	}

	removeMilestone (Milestone: ModelInstance): void
	{
		const Milestones = this.Milestones.filter(M => M.id() !== Milestone?.id())

		let monthYears = {};
		Milestones.forEach(Milestone => {
			let d: string = [new Date(Milestone.attribs.start_at||Milestone.attribs.created_at).getMonth()+1, new Date(Milestone.attribs.start_at||Milestone.attribs.created_at).getFullYear()].join('/');
			if (!Object.values(monthYears).includes(d))
				monthYears[Milestone.id()] = d;
		});
		this._monthYears = monthYears;

		this._Milestones$.next(this._Milestones = Milestones);
	}

	async authenticateInvitor (milestoneId: string): Promise<{status: boolean, MemoryChest?:ModelInstance, Milestone?: ModelInstance, Member?: ModelInstance}>
	{
		const MemoryChest: ModelInstance = this.Chest && await this.MemoryChestSrvc.search({chest_id: this.Chest.id(), pagination: false, _relationships: {milestone: {id: milestoneId}, member: {user_id: this.SessionUser.id}}}, {do_not_store: true}).then(res => (res.models.shift() as ModelInstance));
		if (!MemoryChest)
			return Promise.resolve({status: false});


		const Member: ModelInstance = (MemoryChest && MemoryChest?.getChildren('member'))
			? Object.values(MemoryChest.getChildren('member')).filter((Member: ModelInstance) => Member.attribs.user_id === this.SessionUser.id).shift() as ModelInstance
			: undefined;


		const Milestone: ModelInstance = (MemoryChest && MemoryChest?.getChildren('milestone'))
			? Object.values(MemoryChest.getChildren('milestone')).filter((Milestone: ModelInstance) => Milestone.id() === milestoneId).shift() as ModelInstance
			: undefined;


		if (Member && ['owner','admin'].includes(Member.attribs.member_type))
			return {status: true, MemoryChest, Milestone, Member};


		return {status: false};
	}


	constructor (private MemoryChestSrvc: MemoryChestService, private MemberSrvc: MemberService, private MilestoneSrvc: MilestoneService, private MilestonePreviewSrvc: MilestonePreviewService, private SessionUser: SessionUser) 
	{}
}
