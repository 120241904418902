<div class="feed-milestone-preview-component">
	<div class="preview-imgs" [ngClass]="classes">
		<div class="gradient-overlay">
			<div class="preview-types">
				<span class="ind-audio"><rv-icon group="multimedia" off-white icon="music-sign"></rv-icon></span>
				<span class="ind-video"><rv-icon group="multimedia" off-white icon="photo"></rv-icon></span>
				<span class="ind-copy"><rv-icon group="education" off-white icon="notes"></rv-icon></span>
			</div>
		</div>
		<div class="preview-img-wrapper" *ngFor="let i of images; let idx = index">
			<fe-preview-img [primary]="idx === 0" [Milestone]="Milestone" [filename]="i" [Preview]="Preview"></fe-preview-img>
		</div>
	</div>
	<div class="milestone-detail">
		<div class="milestone-detail-col">
			<feed-milestone-members [headshots]="headshots"></feed-milestone-members>
		</div>
		<div class="milestone-detail-col">
			<feed-milestone-hashtags [hashtags]="hashtags" [milestoneId]="this.Milestone.id()"></feed-milestone-hashtags>
		</div>
	</div>
</div>