import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwitchboardRoutingModule } from './switchboard-routing.module';
import { SwitchboardPageComponent } from './switchboard-page/switchboard-page.component';


@NgModule({
  declarations: [
    SwitchboardPageComponent
  ],
  imports: [
    CommonModule,
    SwitchboardRoutingModule
  ]
})
export class SwitchboardModule { }
