import { Component, OnInit, HostBinding, Type, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { NgComponentOutlet } from '@angular/common';
import { Subscription } from 'rxjs';
import { filter, map, delay, startWith, switchMap } from 'rxjs/operators';
import { Router, ActivatedRoute, RouterOutlet, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { MessageBusService, Channels, Message } from 'src/app/core/services/message-bus.service';
import { RouteData } from 'src/app/core/values/route-data.value';
import { BehaviorSubject } from 'rxjs';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { FloatingControlService } from './../floating-control.service';
import { ModalService, modalParams } from 'src/app/widgets/modal/shared/modal.service';
import { CameraInterface } from 'src/app/shared/native/camera-interface.service';
import { MemoryContentCreatorWidgetComponent } from 'src/app/widgets/memory-content-creator/memory-content-creator-widget/memory-content-creator-widget.component';
import { CameraResultType, CameraSource } from '@capacitor/camera';
import { Keyboard } from '@capacitor/keyboard';
import { VideoInterfaceService } from 'src/app/shared/native/video-interface.service';

const COMPONENT_SELECTOR: string = 'rv-floating-controls-widget';

interface FcwBtnVisibility {
	multi: boolean;
	library: boolean;
	photo: boolean;
	comment: boolean;
	video: boolean;
}

const VIEW_MODES = {
	memoryCreator: {
		btnVisibility: {
			multi: true,
			library: true,
			photo: true,
			comment: true,
			video: true
		}
	},
	memoryChestEditor: {
		btnVisibility: {
			multi: true,
			library: true,
			photo: true,
			comment: true,
			video: true
		}
	},
}

Object.freeze(VIEW_MODES);

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './floating-controls-widget.component.html',
  styleUrls: ['./floating-controls-widget.component.scss'],
  animations: [
    trigger('onVisibilityToggle', [
      state('true', style({
				'pointer-events': 'auto',
				'opacity': 1,
				'filter': 'blur(0)'
      })),
      state('false', style({
				'pointer-events': 'none',
				'opacity': 0,
				'filter': 'blur(8px)',
				'transform': 'scale(90%) translateY(10%)'
      })),
      transition('true => false', [animate('0.35s')]),
      transition('false => true', [animate('0.35s')]),
    ]),
  ]
})
export class FloatingControlsWidgetComponent implements OnInit {

  @HostBinding('@onVisibilityToggle')
  isWidgetEnabled: boolean = false;

  @ViewChild('memoryContentCreator')
  memoryContentCreator: TemplateRef<MemoryContentCreatorWidgetComponent>;

  @ViewChild('milestoneLocationCreator')
  milestoneLocationCreator: TemplateRef<any>;

	@ViewChild('multiFuncBtn')
	multiFuncBtn;

  btnVisibility: {
  	multi: boolean;
  	library: boolean;
  	photo: boolean;
  	video: boolean;
  	comment: boolean;
  } = {
  	multi: true,
  	library: false,
  	photo: false,
  	video: false,
  	comment: false,
  };
	isModalOpen: boolean = false;
	isControlsDisabled: boolean = false;
	#subscriptions$ = new Subscription();
  #RouteData!: RouteData;
  #viewMode: string = '';
  #enableUpload: boolean = false;
  private enableUpload$: BehaviorSubject<boolean> = new BehaviorSubject(false)

  get canUpload (): boolean
	{
		return this.#enableUpload;
	}

  set enableUpload (enableUpload: boolean)
	{
		if (this.#enableUpload !== enableUpload)
			this.enableUpload$.next(this.#enableUpload = enableUpload);
	}

  private closeModalIfOpen (): boolean
  {
		if (this.isModalOpen) {
			this.modalService.close()
			return true;
		}

		return false;
  }

  private getViewTitle (): string
  {
		switch (this.#viewMode) 
		{
			case 'milestonePage':			return ''; // opts.title = 'Update Memories';
			case 'milestoneEditor': 	return 'Update A Milestone';
			case 'milestoneCreator': 	return 'Create A Milestone';
			case 'memoryCreator': 		return 'Create A Memory';
			case 'memoryChestEditor': return this.controlSrvc.memoryChestId ? 'Update A Chest' : 'Create A Chest';
			default: 									return '';
		}
  }

  async onBtnClick (btn: string, templateRefs?: TemplateRef<any>): Promise<void>
  {
		const opts: modalParams = {};

		let CameraRequest: any = false,
				VideoRequest: any = false,
				template: TemplateRef<any> = templateRefs && templateRefs.hasOwnProperty(this.#viewMode) ? templateRefs[this.#viewMode] : undefined;	

		switch (btn)
		{
			case 'multi':
		  	if (this.closeModalIfOpen())
		  		return;

				if (this.getViewTitle())
					opts.title = this.getViewTitle();

				this.modalService.open(template, template?opts:{});
				break;

			case 'photo': 
				this.disableWidget(); 
				try { CameraRequest = await this.cameraInf.getPhotoRequest({source: CameraSource.Camera, resultType: CameraResultType.DataUrl}); }
				catch (ex) { CameraRequest = false; this.close(); }
				break;

			case 'library': 
				this.disableWidget();
				try { CameraRequest = await this.cameraInf.getPhotoRequest({source: CameraSource.Photos, resultType: CameraResultType.DataUrl}); }
				catch (ex) { CameraRequest = false; this.close(); }
				break;

			case 'comment': 
				this.disableWidget();
				opts.title = 'Add A Message'; 
				this.modalService.open(this.memoryContentCreator, opts);
				break;

			case 'video': 
				this.disableWidget();
				if (Capacitor.isNative) {
					try { VideoRequest = await this.videoInf.startRecording(); }
					catch (ex) { VideoRequest = false; this.close(); }
				}
				break;

			case 'location': 
				this.disableWidget();
				opts.title = 'Add Date & Location'; 
				this.modalService.open(this.milestoneLocationCreator, opts);
				break;
		}

    if (typeof CameraRequest !== 'undefined' && CameraRequest !== false)
    	this.controlSrvc.popBtnEvent = {id: 'pop-btn-'+btn, result: CameraRequest.Photo}

		if (typeof VideoRequest !== 'undefined' && VideoRequest !== false) 
			this.controlSrvc.popBtnEvent = {id: 'pop-btn-video', result: VideoRequest}
  }

	onMilestoneLocationCreatorSuccess (Location): void
	{
		this.controlSrvc.popBtnEvent = {id: 'pop-btn-location', result: Location};
		this.close();
	}

	onMemoryChestEditorSuccess (MemoryChest): void
	{
		if (MemoryChest?.id()) {
			this.router.navigateByUrl(`/memory-chests/${MemoryChest?.id()}`);
		}
		else {
			console.error(`Failed to update route due to missing instance ID in floating controls.`);
		}
		this.close();
	}

	onMemoryMessageCreatorSuccess (Message): void
	{
		// if (Message?.id()) {
		// 	this.router.navigateByUrl(`/memory-chests/${MemoryChest?.id()}`);
		// }
		// else {
		// 	console.error(`Failed to update route due to missing instance ID in floating controls.`);
		// }
		this.close();
	}

	onMilestoneCreatorSuccess (Milestone): void
	{
		if (Milestone?.id()) {
			this.router.navigateByUrl(`/milestones/${Milestone?.id()}`);
		}
		else {
			console.error(`Failed to update route due to missing instance ID in floating controls.`);
		}
		this.close();
	}

	onMemoryCreatorSuccess (Memory): void
	{
		this.close();
	}

	close (): void
	{
		this.resetBtnVisibility();

		this.modalService.close();
	}

	onBusEvent (Msg: Message) 
	{}

	private enableWidget (): void
	{
		this.isWidgetEnabled = true;
	}

	private disableWidget (): void
	{
		this.isWidgetEnabled = false;
	}

	private setIsWidgetEnabled (): void
	{
		this.isWidgetEnabled = (this.isControlsDisabled !== true ? (this.#RouteData && this.#RouteData.get(`widgets.${COMPONENT_SELECTOR}.enable`) === true) : false);
	}

	private enableControls (): void
	{
		this.isControlsDisabled = false;
	}

	private disableControls (): void
	{
		this.isControlsDisabled = true;
	}

  // Not in use.
  // private showAllButtons (): void
  // {
  // 	Object.keys(this.btnVisibility).forEach(btn => this.btnVisibility[btn] = true);
  // }

  // Not in use.
	// private hideAllButtons (): void
	// {
	// 	Object.keys(this.btnVisibility).forEach(btn => this.btnVisibility[btn] = false);
	// }

	private getBtnVisibilityConf ()
	{
		if (this.#viewMode) {
			const viewConf = VIEW_MODES.hasOwnProperty(this.#viewMode) ? VIEW_MODES[this.#viewMode] : false;
			if (viewConf && viewConf?.btnVisibility)
				return viewConf.btnVisibility;
		}
	}

	private isBtnVisibleByDefault (btn: string): boolean
	{
		if (this.#viewMode) {
			const viewConf = this.getBtnVisibilityConf();
			if (viewConf && viewConf?.btnVisibility)
				return !!viewConf.btnVisibility?.[btn];
		}

		return false;
	}

	private isBtnVisible (btn: string): boolean
	{
		return !!this.btnVisibility?.[btn];
	}

	private resetBtnVisibility (btn?: string): void
	{
		this.btnVisibility = {...this.getBtnVisibilityConf()};
	}

  private setViewMode (viewMode: string): void
  {
  	viewMode = viewMode ? `${viewMode}`.trim() : this.#viewMode;

		if (this.#viewMode !== viewMode) {
			switch (this.#viewMode = viewMode) 
			{
				case 'milestonePage':				break;
				case 'milestoneEditor':			break;
				case 'milestoneCreator': 		break;
				case 'memoryCreator':				break;
				case 'memoryChestEditor':		break;
			}

			const viewConf = VIEW_MODES.hasOwnProperty(viewMode) ? VIEW_MODES[viewMode] : false;
			if (viewConf && viewConf?.btnVisibility)
				this.btnVisibility = {...viewConf.btnVisibility};
		}
  }

  private setEnableUpload (): void
  {
		this.enableUpload = (this.#RouteData && this.#RouteData.get(`widgets.${COMPONENT_SELECTOR}.options.enableUpload`)) === true;
  }

	private setRouteData = (RouteData:RouteData): void => 
	{
		this.#RouteData = (RouteData ? RouteData : undefined);

		const viewMode: string = (this.#RouteData && this.#RouteData.get(`widgets.${COMPONENT_SELECTOR}.options.viewMode`))||'';

		this.setViewMode(viewMode);
		this.setEnableUpload();

		// why is the widget being enabled dependent upon if the controls are hidden.
		// something smells here ......
		this.setIsWidgetEnabled()
	}

  constructor (public cameraInf: CameraInterface,public videoInf: VideoInterfaceService , private bus: MessageBusService, private router: Router, private activatedRoute: ActivatedRoute, public controlSrvc: FloatingControlService, public modalService: ModalService) 
  {}

  ngOnInit (): void 
  {
  	this.setRouteData(new RouteData());

  	this.#subscriptions$.add(this.modalService.isOpen.subscribe((isOpen: boolean) => this.isModalOpen = isOpen));
  	this.#subscriptions$.add(this.bus.subscribe(Channels.Widget, (Msg) => this.onBusEvent(Msg)));
  }

  ngOnDestroy (): void 
  {
  	this.#subscriptions$.unsubscribe();
  }

	ngAfterViewInit (): void 
	{
		/*TESTING*/
		// setTimeout(() => {
		// 	// this.isModalOpen?this.modalService.close():this.modalService.open()
		// 	// this.modalService.open()
		// 	setTimeout(() => this.multiFuncBtn.nativeElement.click(),250);
		// },250);

		this.#subscriptions$.add(
			this.router.events
				.pipe(
					filter((event) => event instanceof NavigationEnd),
					map(() => this.activatedRoute),
					map(route => route.firstChild),
					switchMap(route => route.data),
					map(obj => new RouteData(obj)),
					delay(0)
				)
				.subscribe(RouteData => this.setRouteData(RouteData))
			);
	}
}


/*
	primary (templateRefs?: TemplateRef<any>): void
	{
		if (this.isModalOpen) {
			this.modalService.close()
			return;
		}

		// templateRefs[this.#viewMode].getTitle() ?? 
		// options?: { size?: string; title?: string }
		const opts:modalParams = {};

		switch (this.#viewMode) {
			case 'milestonePage':	
				// if we arent viewing a memory or media, that means the 
				// button will primary button will start as uploader.
				if (this.controlSrvc.milestoneId) {
					if (!this.controlSrvc.memoryId) {
						// not viewing memory, use uploader.
						// skipping template.
					}
				}
				// opts.title = 'Update Memories';
				break;
			case 'milestoneEditor':
				opts.title = 'Update A Milestone';
				// this.controlSrvc.setParams({
				// 	MemoryChest: this.storyService
				// })
				break;
			case 'milestoneCreator':
				opts.title = 'Create A Milestone';
				break;
			case 'memoryCreator':
				opts.title = 'Create A Memory';
				break;
			case 'memoryChestEditor':
				opts.title = this.controlSrvc.memoryChestId ? 'Update A Chest' : 'Create A Chest';
				break;
		}

		let template: TemplateRef<any> = templateRefs && templateRefs.hasOwnProperty(this.#viewMode) ? templateRefs[this.#viewMode] : undefined;	
		// if (template)
		this.modalService.open(template, template?opts:{});
	}
	
	async start (mediaType: string, modalTemplate: any): Promise<void>
	{
		// Object.keys(this.btnVisibility).forEach(btn => this.btnVisibility[btn] = false);

		let CameraRequest, VideoRequest;
		
		if (mediaType === 'photo') 
		{
			this.disableControls = true;
			try { 
				CameraRequest = await this.cameraInf.getPhotoRequest({source: CameraSource.Camera, resultType: CameraResultType.DataUrl}); 
				// this.btnVisibility.multi = false;
			}
			catch (ex) { 
				CameraRequest = false; 
				// this.btnVisibility.multi = true;
			}
			VideoRequest = false;
		}
		else 
		if (mediaType === 'library') 
		{
			this.disableControls = true;
			try { 
				CameraRequest = await this.cameraInf.getPhotoRequest({source: CameraSource.Photos, resultType: CameraResultType.DataUrl});
				// this.btnVisibility.multi = false;
			}
			catch (ex) { 
				// this.btnVisibility.multi = true; 
				// console.log("cancel ?? ",ex)
				this.close();
			}
			VideoRequest = false;
		}
		else
		if (mediaType === 'comment') {
			this.disableControls = true;
			const opts:modalParams = {title: "Add A Message"};
			this.modalService.open(this.memoryContentCreator, opts);
			CameraRequest = false;
			VideoRequest = false;
			// this.btnVisibility.multi = false;
		} 
		else
		if (mediaType === 'video') {
			if (Capacitor.isNative) {
				// console.log(mediaType);
				this.disableControls = true;
				try { VideoRequest = await this.videoInf.startRecording(); }
				catch (ex) { VideoRequest = false; }
				CameraRequest = false;
				// this.btnVisibility.multi = false;
			}
			else {
				// notify user, cant do this.
			}
		}
		else
		if (mediaType === 'location') {
			this.disableControls = true;
			const opts:modalParams = {title: "Add Date & Location"};
			this.modalService.open(this.milestoneLocationCreator, opts);

			CameraRequest = false;
			// this.btnVisibility.multi = false;
		}

		if (CameraRequest === false) {
			this.disableControls = false;
			return Promise.resolve();
		}

		this.disableControls = false;
		// console.log('Request', CameraRequest);

    if (typeof CameraRequest !== 'undefined' && CameraRequest !== false)
    	this.controlSrvc.popBtnEvent = {id: 'pop-btn-'+mediaType, result: CameraRequest.Photo}
	if (typeof VideoRequest !== 'undefined' && VideoRequest !== false) 
		this.controlSrvc.popBtnEvent = {id: 'pop-btn-'+mediaType, result: VideoRequest}
    return Promise.resolve();
	}
*/