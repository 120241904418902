import { Component } from '@angular/core';
import { CameraInterface } from 'src/app/shared/native/camera-interface.service';
import { CameraResultType } from '@capacitor/camera';
import { MemoryMediaService } from 'src/app/models/memories/memory-media.service';
import { MemoryService } from 'src/app/models/memories/memory.service';
import { ModelInstance } from '@getrearview/model-builder';
import { Photo } from '@capacitor/camera'

const IMAGE_DIR = 'stored-images';

@Component({
  selector: 'rv-ui-uploader',
  templateUrl: './ui-uploader.component.html',
  styleUrls: ['./ui-uploader.component.scss']
})
export class UiUploaderComponent {

	images = [];

	takePhoto () 
	{}

	async uploadPhoto (Photo: Photo): Promise<ModelInstance|string>
	{
		const {dataUrl,exif,format} = (Photo||{}),
					filemime = dataUrl ? dataUrl.substring(dataUrl.indexOf(":")+1, dataUrl.indexOf(";")) : (format ? `image/${format}` : null),
					base64Data = dataUrl ? dataUrl.substr(dataUrl.indexOf(',') + 1) : '',
					Memory: ModelInstance 	= 	await this.MemorySrvc.find('d268e181-0fe6-456a-b370-ca4794ea7e9c', {do_not_store: true}),
					Media: 	ModelInstance 	= 	await Memory.addChild('media',{content: 'This is a test'}) as ModelInstance;

		if (filemime)
			Media.set('filemime', filemime);

		return new Promise((resolve, reject) => {
			Media.upload(base64Data, {include_attribs: Media.attribs})
						.subscribe(
								evt => {
									if (evt?.event === 'success')
										Promise.resolve(evt?.asset as ModelInstance)
									// if (evt?.event === 'progress') -> this doesn't work as intended while sending all base64 data on a single request.
								}, 
								err => Promise.reject(`${err}`)
							);
		});
	}

	async selectImage () 
	{
		const CameraRequest = await this.cameraInf.getPhotoRequest({resultType: CameraResultType.DataUrl});

		this.uploadPhoto(CameraRequest.Photo);
	}

	constructor (public cameraInf: CameraInterface, private MemoryMediaSrvc: MemoryMediaService, private MemorySrvc: MemoryService)
	{}
}