import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SettingsRoutingModule } from './settings-routing.module';
import { SettingsFormComponent } from './settings-form/settings-form.component';
import { SettingsWidgetComponent } from './settings-widget/settings-widget.component';
import { SettingsPageComponent } from './settings-page/settings-page.component';
import { LoadersModule } from './../../widgets/loaders/loaders.module';
import { USER_SETTINGS_CONFIG, UserSettingsConfig } from './user-settings.config';
import { USER_NOTIFICATIONS_CONFIG, UserNotificationsConfig } from './user-notifications.config';
import { RearviewInterfaceModule } from './../../shared/interface/rearview-interface.module';
import { ProfileFormComponent } from './profile-form/profile-form.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { NotificationsSelectorComponent } from './notifications-selector/notifications-selector.component';
import { EditorModule } from '@tinymce/tinymce-angular';

@NgModule({
  declarations: [SettingsFormComponent, SettingsWidgetComponent, SettingsPageComponent, ProfileFormComponent, NotificationsSelectorComponent, PrivacyPolicyComponent, TermsConditionsComponent],
  imports: [
    CommonModule,
    SettingsRoutingModule,
    ReactiveFormsModule,
    LoadersModule,
    RearviewInterfaceModule,
    EditorModule
  ],
  providers: [
  		{ provide: USER_SETTINGS_CONFIG, useValue: UserSettingsConfig },
  		{ provide: USER_NOTIFICATIONS_CONFIG, useValue: UserNotificationsConfig },
  	]
})
export class SettingsModule { }
