import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModelFactoryConfig, ModelService } from '@getrearview/model-builder';
import { ConfigService } from 'src/app/shared/services/config.service';
// import { FeedMilestonePhoneService } from './account-phone.service';

@Injectable({
  providedIn: 'root'
})
export class FeedMilestoneService extends ModelService {

  constructor (injector: Injector, httpClient: HttpClient) 
  {
  	let ConfigSrvc: ConfigService = injector.get<any>(ConfigService);
		let MFC: ModelFactoryConfig = (new ModelFactoryConfig()).setAll({
  				// Model Name
  				name: "FeedMilestone",
  				// Model Endpoint
					endpoint: ConfigSrvc.get("api.api_url")+"/services/feeds",
					// Model Fields
					fields: ['chest_id', 'member_id', 'place_id', 'event_date', 'tag_id'],
					// Model `Permitted` Fields
					permitted: [],
					// Model `Required` Fields
					required: [],
					// Model Field Labels
					labels: {},
					// Model Field Validation Patterns
					patterns: {},
					// Model Children Types
					children_types: {},
					// Model Association Types
					association_types: {},
					// Storage Type (local, session, cookies)
					storage_type: "local"
				});

  	super(MFC, httpClient);
  }
}