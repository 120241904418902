import { Injectable } from '@angular/core';
import { NativeInterfaceService } from './native-interface.service'
import { CameraInterfaceRequest } from './camera-interface.request'
import { Camera, CameraResultType, CameraSource, Photo, ImageOptions } from '@capacitor/camera';

/*

NSPhotoLibraryAddUsageDescription
Access your photo library to add to your Milestones & Memories.

NSCameraUsageDescription
Need to access your camera to capture a photo add and update profile picture.

NSPhotoLibraryUsageDescription
Need to access your photo library to select a photo add and update profile picture.

*/

@Injectable({
  providedIn: 'root'
})
export class CameraInterface extends NativeInterfaceService {

	// CameraSource: CameraSource = {
	// 	Prompt: CameraSource.Prompt,
	// 	Camera: CameraSource.Camera,
	// 	Photos: CameraSource.Photos,
	// };

	async getPhotoRequest (userOpts?: ImageOptions): Promise<CameraInterfaceRequest> {

		const imgOpts: ImageOptions = {
      quality: 			(userOpts&&userOpts?.quality)||90,
      allowEditing: (userOpts&&userOpts?.allowEditing)||false,
      resultType: 	(userOpts&&userOpts?.resultType)||CameraResultType.Uri, // CameraResultType: Uri, Base64, 
      source: 			(userOpts&&userOpts?.source)||CameraSource.Photos 			// CameraSource: Photos, Camera, Prompt
		}

		const req:CameraInterfaceRequest = new CameraInterfaceRequest();
		await req.getPhoto(imgOpts);
		return req;
	}

  constructor () 
  {
  	super();
  }
}