import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ModelInstance } from '@getrearview/model-builder';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EMAIL as EMAIL_PATTERN } from 'src/app/shared/patterns'
import { ClipboardInterface } from 'src/app/shared/native/clipboard-interface.service';

@Component({
  selector: 'rv-memory-chest-inviter',
  templateUrl: './memory-chest-inviter-widget.component.html',
  styleUrls: ['./memory-chest-inviter-widget.component.scss']
})
export class MemoryChestInviterWidgetComponent implements OnInit {

	@Input('MemoryChest')
	MemoryChest: ModelInstance;

	@Input('Milestone')
	Milestone: ModelInstance;

	@Input('Me')
	Me: ModelInstance;

	@Output('onCancel')
	onCancel: EventEmitter<void> = new EventEmitter<void>();

	@Output('onSuccess')
	onSuccess: EventEmitter<ModelInstance> = new EventEmitter<ModelInstance>();

	InviteToken!: ModelInstance;
	isCopied: boolean =  false;

	openExtendedForm: boolean = false;


	inviterForm: FormGroup = this.fb.group({
		name: 					[null, [Validators.required]],
		emailOrPhone: 	[null, [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
		relationship: 	[null],
		memberType: 		['standard', [Validators.required]],
	});

	isInviting: boolean = false;
	inviteSuccess: string = '';
	inviteError: string = '';

	async copyLink (invitees): Promise<void> 
	{
		if (this.inviterForm.valid) {

			try { await this.createInvite({is_anon: 0, invitees, method: 'link', ...(this.Milestone?.attribs?.chest_id === this.MemoryChest.id() ? {milestone_id: this.Milestone.id()} : {})}) }
			catch (ex) {
				this.handleError('link', ex);
				return Promise.resolve();
			}
		}
		else {
			try { await this.createInvite({is_anon: 1, invitees: [], method: 'link', ...(this.Milestone?.attribs?.chest_id === this.MemoryChest.id() ? {milestone_id: this.Milestone.id()} : {}) }) }
			catch (ex) {
				this.handleError('link', ex);
				return Promise.resolve();
			}
		}

		if (this.InviteToken.attribs?.share_url) {
			this.clipboardInf.url = this.InviteToken.attribs.share_url;
			this.isCopied = true;
			setTimeout(() => this.isCopied = false,2500);
		}

		return Promise.resolve();
	}

	private buildInvitees ()
	{
		return [Object.keys(this.inviterForm.controls).map(field => ({[field]: this.inviterForm.controls[field].value})).reduce((acc, cumm) => ({...acc, ...cumm}))];
	}

	async onFormSubmission ()
	{
		if (this.inviterForm.valid) {
			this.isInviting = true;
			if (this.output === 'email')
				await this.sendEmail(this.buildInvitees());
			if (this.output === 'link')
				await this.copyLink(this.buildInvitees());
			this.isInviting = false;
		}
	}

	initSendSms (): void { this.openExtendedForm = !this.openExtendedForm; }

	output: string = '';

	async sendEmail (invitees): Promise<void> 
	{
		let created = false;

		try { 
			created = await this.createInvite({is_anon: 0, invitees, method: 'email', ...(this.Milestone?.attribs?.chest_id === this.MemoryChest.id() ? {milestone_id: this.Milestone.id()} : {})});
		}
		catch (ex) {
			console.error(ex)
			created = false;
			this.handleError('email', ex);
			return Promise.resolve();
		}

		if (created) {
			this.handleSuccess('email');

			// reset form & set pristine
			this.inviterForm.reset()
			this.inviterForm.markAsPristine();
		}

		return Promise.resolve();
	}

	private async createInvite ({method, is_anon, invitees}): Promise<boolean>
	{
		try {
			this.InviteToken = await this.MemoryChest.createChild('invite', {is_anon, invitees}) as ModelInstance;
			return Promise.resolve(true);
		}
		catch (ex) {
			this.handleError(method, ex)
			this.InviteToken = null;
			return Promise.resolve(false);
		}
	}

	private handleSuccess (method)
	{
		switch (method) {
			case 'email':
				this.inviteError = '';
				this.inviteSuccess = "Great! We've sent your invitations.";
				break;
			case 'link':
				this.inviteError = '';
				this.inviteSuccess = "Great! Your invitation link has been copied to the clipboard.";
				break;
		}
	}

	private handleError (method, err)
	{
		this.inviteSuccess = '';
		this.inviteError = `${err||''}`.replace(/^could not create record./i,'').trim();
	}

	constructor (private fb: FormBuilder, public clipboardInf: ClipboardInterface)
	{}

	ngOnInit (): void
	{}
}
