import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MilestoneCreatorWidgetComponent } from './milestone-creator-widget/milestone-creator-widget.component';
import { RearviewInterfaceModule } from 'src/app/shared/interface/rearview-interface.module';
import { LocationsModule } from 'src/app/widgets/locations/locations.module';

@NgModule({
  declarations: [
    MilestoneCreatorWidgetComponent
  ],
  imports: [
    CommonModule,
    LocationsModule,
    ReactiveFormsModule,
    RearviewInterfaceModule
  ],
  exports: [
    MilestoneCreatorWidgetComponent
  ],
})
export class MilestoneCreatorModule { }
