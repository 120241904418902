import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SessionUserResolver } from './../../core/resolvers/session-user.resolver';
import { ProfilePageComponent } from './profile-page/profile-page.component';
import { RouteData } from 'src/app/core/values/route-data.value';
import { LoggedInService } from './../../core/services/logged-in.service';
import { ProfilePageResolver } from './profile-page.resolver';

const routes: Routes = [
	{ 
		path:                       'profile',
		component:                  ProfilePageComponent,
		resolve: 										{ProfilePage: ProfilePageResolver},
		canActivate: 								[LoggedInService],
		data: 											new RouteData({animation:'profilePage',widgets:{'rv-floating-controls-widget':{enable:false},'rv-header-widget':{enable:true}}})
	},
];

@NgModule({
  imports:                      [RouterModule.forChild(routes)],
  exports:                      [RouterModule]
})
export class ProfileRoutingModule { }