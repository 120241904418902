import { Component, OnInit, Input } from '@angular/core';

const NUM_HASHTAGS = 2

@Component({
  selector: 'milestone-hashtags',
  templateUrl: './milestone-hashtags.component.html',
  styleUrls: ['./milestone-hashtags.component.scss']
})
export class MilestoneHashtagsComponent implements OnInit {

	@Input('hashtags')
	_hashtags: Array<string> = []


	tags: Array<string> = [];

	_t: number;
	_e: number;

	get extra (): number
	{
		return this._e;
		// return this.total - this.hashtags.length;
	}

	get total (): number
	{
		return this._t;
		// return this._hashtags.length;
	}

	get hashtags (): Array<string>
	{
		return this._hashtags.slice(0,NUM_HASHTAGS);
	}

  constructor () 
  {}

  ngOnInit (): void 
  {
  	const opts = {
  		0: 'princess',
  		1: 'party-animal',
  		2: 'birthday',
  		3: 'camping',
  		4: 'first-time',
  		5: 'mommy-and-annabelle',
  		6: 'crashes',
  		7: 'karate-kid',
  		8: 'family-vacation',
  		9: 'best-fishing-catches'
  	}

  	let c = 0, 
  			m = Math.floor(Math.random()*10);

  	while (m) {
  		m--;
  		this.tags.push(opts[Math.floor(Math.random()*10)])
  	}

  	let d = 
  	this._t = this.tags.length;
  	this._e = Math.abs(NUM_HASHTAGS-this.tags.length?NUM_HASHTAGS-this.tags.length:0)
  	this.tags = this.tags.slice(0,NUM_HASHTAGS);
  }
}
