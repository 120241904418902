import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InterfaceCoreModule } from './../shared/interface-core.module';
import { UiAlertWidgetComponent } from './ui-alert-widget/ui-alert-widget.component';


@NgModule({
  declarations: [
    UiAlertWidgetComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
  	UiAlertWidgetComponent
  ]
})
export class UiAlertModule { }
