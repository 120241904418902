import { Component, Input } from '@angular/core';
import { StoryService } from './../shared/story.service';

@Component({
  selector: 'no-memories',
  templateUrl: './no-memories.component.html',
  styleUrls: ['./no-memories.component.scss']
})
export class NoMemoriesComponent {
	constructor (public storySrvc: StoryService)
	{}
}