import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialIconsWidgetComponent } from './social-icons-widget/social-icons-widget.component';
// import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [SocialIconsWidgetComponent],
  imports: [
    CommonModule,
    // MatButtonModule
  ],
  exports: [
  	SocialIconsWidgetComponent
  ]
})
export class SocialIconsModule { }
