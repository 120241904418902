import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ModelInstance } from '@getrearview/model-builder';
import { MemoryChestService } from 'src/app/models/memories/memory-chest.service';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { MessageBusService, Channels, Message } from 'src/app/core/services/message-bus.service';

@Component({
  selector: 'rv-memory-chest-editor-widget',
  templateUrl: './memory-chest-editor-widget.component.html',
  styleUrls: ['./memory-chest-editor-widget.component.scss']
})
export class MemoryChestEditorWidgetComponent {

	@Output('onCancel')
	onCancel: EventEmitter<void> = new EventEmitter<void>();

	@Output('onSuccess')
	onSuccess: EventEmitter<ModelInstance> = new EventEmitter<ModelInstance>();

	// @Output('onError')
	// onError: EventEmitter<string> = new EventEmitter<string>('');

	@Input('memoryChestId')
	memoryChestId!: string;

	isSaving: boolean = false;
	isFetching: boolean = false;
	isEditing: boolean = false;

	MemoryChest!: ModelInstance;
	memoryChestForm: FormGroup = new FormGroup({nickname: new FormControl('',Validators.required)});

	_subscriptions$:Subscription = new Subscription();

	onFocus ($e?: any): void
	{}

	onBlur ($e?: any): void
	{}

	close ()
	{
		this.onCancel.next();
	}

	async saveMemoryChest (): Promise<void>
	{
		this.isSaving = true;
		let success: boolean = false;

		if (this.MemoryChest) {
			try { 
				await this.MemoryChest.save();
				success = true;
			}
			catch (ex) {
				console.error(`Failed to save new Memory Chest. ${ex}`);
				/*TODO: complete error handling*/
				// this.onError.next(`${ex}`);
			}
		}

		if (success)
			this.onSuccess.next(this.MemoryChest);

		this.bus.emit(Channels.Model,new Message('memory-chest.updated',{MemoryChest:this.MemoryChest}))

		this.isSaving = false;
	}

	#updateModel (changes: {[key: string]: any}): void
	{
		if (!this.MemoryChest)
			this.MemoryChest = this.MemoryChestSrvc.factory().create({});
		this.MemoryChest.attribs = {...this.MemoryChest.attribs, ...changes};
  }

	updateForm (MemoryChest: ModelInstance): void
	{
		this.memoryChestForm.get('nickname').setValue((MemoryChest?.get('nickname')||''));
	}

	updateFormSilent (MemoryChest: ModelInstance): void
	{
		this.memoryChestForm.get('nickname').setValue((MemoryChest?.get('nickname')||''), { emitEvent: false });
	}

  constructor (private fb: FormBuilder, private MemoryChestSrvc: MemoryChestService, private bus: MessageBusService)
  {}

  ngOnInit (): void
  {
  	this._subscriptions$.add(this.memoryChestForm.valueChanges.pipe(distinctUntilChanged(), debounceTime(200)).subscribe(changes => this.#updateModel(changes)));

  	this.#fetchMemoryChest()
  					.finally(() => {
  						if (this.MemoryChest)
  							this.updateForm(this.MemoryChest)
  						this.isFetching = false;
  					})
  					.catch(() => {
  						this.isFetching = false;
  					})
  }

  ngOnDestroy (): void
  {
  	this._subscriptions$.unsubscribe();
  }

  async #fetchMemoryChest (): Promise<void>
  {
  	let MemoryChest: ModelInstance = this.MemoryChest;

  	if (this.memoryChestId) {
  		if (!MemoryChest || MemoryChest.id() !== this.memoryChestId) {
  			this.isFetching = true;
				try {
					MemoryChest = await this.MemoryChestSrvc.find(this.memoryChestId);
				}
				catch (ex) {
					console.error(`Failed to load memory chest in memory-chest-editor-widget. ${ex}`);
				}
				this.isFetching = false;
  		}
  	}

  	this.MemoryChest = MemoryChest||undefined;
  	return Promise.resolve();
  }
}
