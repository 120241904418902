import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import { SessionUser } from 'src/app/core/session/session-user.model';

@Component({
  selector: 'rv-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss'],
  animations: [
    trigger('welcome1', [
	    transition(':enter', [
	    		style({ opacity: 0, top: '17%' }), 
	    		animate('6s 0.5s ease-out',  keyframes([
	    				style({ opacity: 0, top: '17%', offset: 0 }),
	    				style({ opacity: 1, top: '15%', offset: 0.10 }),
	    				style({ opacity: 1, top: '15%', offset: 0.90 }),
	    				style({ opacity: 0.25, top: '10%', offset: 1 }),
	    			])),
	    	]),
    ]),
    trigger('welcome2', [
	    transition(':enter', [
	    		style({ opacity: 0, top: '48%' }), 
	    		animate('6s 6s ease-out', keyframes([
	    				style({ opacity: 0, offset: 0 }),
	    				style({ opacity: 1, top: '45%', offset: 0.10 }),
	    				style({ opacity: 1, offset: 0.90 }),
	    				style({ opacity: 1, offset: 1 })
	    			]))
	    	]),
    ]),
    trigger('welcome3', [
	    transition(':enter', [
	    		style({ opacity: 0, top: '69%' }), 
	    		animate('1s 10s ease-out',  keyframes([
	    				style({ opacity: 0, offset: 0 }),
	    				style({ opacity: 1, top: '68%', offset: 0.30 }),
	    				style({ opacity: 1, offset: 0.90 }),
	    				style({ opacity: 1, offset: 1 })
	    			]))
	    	]),
    ]),
  ]
})
export class WelcomePageComponent {
	seconds: number = 15;

	acknowledge (): void {
		this.SessionUser.api.acknowledgeWelcome();
		this.router.navigateByUrl('/feeds');
	}

	constructor (private router: Router, private SessionUser: SessionUser) 
	{}

	ngAfterViewInit (): void {
		let timer = setInterval(() => {
			if (this.seconds === 0 && timer) {
				window.clearInterval(timer)
				this.acknowledge();
			}
			this.seconds--;

		},1000);
	}
}
