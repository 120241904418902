import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { StorageService } from './../../shared/services/storage.service'

const SESSION_KEY_NAME = 'session._token',
			PROVIDER_KEY_NAME = 'session._provider',
			CAN_SURVIVE_INVALIDATION = [];

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor (private StorageSrvc: StorageService) 
  {}

  handle(token :string, provider? : string) {
    this.set(token,`${provider||''}`);
  }

  async set(token : string, provider? : string): Promise<void> 
  {
		await this.StorageSrvc.store(SESSION_KEY_NAME, encodeURIComponent(token))
		if (provider)
			await this.StorageSrvc.store(PROVIDER_KEY_NAME, provider)
		return Promise.resolve();
  }

  async get (): Promise<string>
  {
		return Promise.resolve((await this.StorageSrvc.retrieve(SESSION_KEY_NAME))||'');
  }

  async remove (): Promise<void>
  {
  	await Promise.all([
  			this.StorageSrvc.clear(SESSION_KEY_NAME),
  			this.StorageSrvc.clear(PROVIDER_KEY_NAME)
  		]);

  	// ((await this.StorageSrvc.keys())||[]).map(this.StorageSrvc.clear)

		return Promise.resolve();
  }

  async provider (): Promise<string>
  {
  	return Promise.resolve((await this.StorageSrvc.retrieve(PROVIDER_KEY_NAME))||'');
  }

  async isValid (): Promise<boolean> 
  {
    return Promise.resolve(!!(await this.get()));
  }

  async loggedIn () 
  {
    return await this.isValid();
  }

  async invalidate (): Promise<void>
  {
  	await Promise.all((await this.StorageSrvc.keys()).filter(key => !CAN_SURVIVE_INVALIDATION.includes(key)).map(key => this.StorageSrvc.clear(key)));
  	return Promise.resolve();
  }
}