import { Component, OnInit, Input } from '@angular/core';
import { FeedService } from './../shared/feed.service';

@Component({
  selector: 'rv-feed-chest-members',
  templateUrl: './feed-chest-members.component.html',
  styleUrls: ['./feed-chest-members.component.scss']
})
export class FeedChestMembersComponent {
  constructor (public feedSrvc: FeedService) 
  {}
}