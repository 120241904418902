<div id="ui-chests-list-component">
	<rv-button (click)="toggle()" iconLeft='arrows.arrows-1' sm open></rv-button>
	<div class="chests-list-wrapper" [ngClass]="{'open': isOpen|async}" (click)="toggle()">
		<div class="chest-list">
			<ul>
				<li 
					(click)="onSelect.next(Chest.id())" 
					*ngFor="let Chest of chests"
				>
					<div class="member-field">
						<div class="member-wrapper" *ngFor="let m of Chest.getChildren('member') | keyvalue | slice:0:3">
							<rv-mini-member-img [Member]="m.value"></rv-mini-member-img>
						</div>
					</div>
					{{Chest.get('nickname')}}
				</li>
			</ul>
		</div>
	</div>
</div>