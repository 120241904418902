import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, ControlContainer } from '@angular/forms';

const isActive = (val: any) => (typeof val === 'boolean' ? val : typeof val === 'string' && val === '')

const COMPONENT_TEMPLATE = 
	`<div [ngClass]="classes">`
+ 	`<label>{{label}}</label>`
+ 	`<span class="input-icon input-left-icon" *ngIf="!!iconLeftGroup&&!!iconLeftName">`
+ 		`<rv-icon [group]="iconLeftGroup" [icon]="iconLeftName"></rv-icon>`
+ 	`</span>`
+ 	`<div class="input-wrapper" [formGroup]="formGroup">`
+ 		`<ng-container *ngIf="isReactive;else standard">`
+ 			`<input #uiInput [type]="type" showMaskTyped="{{mask?.showMaskTyped}}" [specialCharacters]="mask?.specialCharacters" [mask]="mask?.mask" shownMaskExpression="{{mask?.shownMaskExpression}}" [formControl]="control" [placeholder]="placeholder" (focus)="onFocus()" (blur)="onBlur()" />`
+			`</ng-container>`
+ 		`<ng-template #standard>`
+ 			`<input #uiInput [form]="formRef" [type]="type" class="form-control" [formControlName]="controlName" [placeholder]="placeholder" (focus)="onFocus()" (blur)="onBlur()" />`
+			`</ng-template>`
+ 		`<ng-content class="input-btn-group"></ng-content>`
+ 	`</div>`
+ 	`<span class="msg error-msg" *ngIf="showError">{{error}}</span>`
+ `</div>`;

@Component({
  selector: 'rv-input',
  styleUrls: ['./ui-input-widget.component.scss'],
  template: COMPONENT_TEMPLATE
})
export class UiInputWidgetComponent implements OnInit {

	@ViewChild('uiInput') uiInput: ElementRef;

	isFocused: boolean = false;
	iconLeftGroup: string;
	iconLeftName: string;
	iconRightGroup: string;
	iconRightName: string;
	private _classes: Array<string> = ['rv-ui','ui-input'];

	// input labels
	@Input('label')
	private _label: string;
	@Input('placeholder')
	private _placeholder: string;
	@Input('name')
	private _name: string;
	@Input('type')
	type: string = 'text';

	// sizes
	@Input('xs')
	private _xs: boolean;
	@Input('sm')
	private _sm: boolean;
	@Input('md')
	private _md: boolean;
	@Input('lg')
	private _lg: boolean;
	@Input('xl')
	private _xl: boolean;

	// interactive
	@Input('disabled')
	private _disabled: boolean;
	@Input('readonly')
	private _readonly: boolean;
	@Input('valid')
	private _valid: boolean;
	@Input('invalid')
	private _invalid: string;
	@Input('reactive')
	private _reactive: boolean;

	// colors
	@Input('purple')
	private _purple: boolean;
	@Input('blue')
	private _blue: boolean;
	@Input('gray')
	private _gray: boolean;
	@Input('dk-gray')
	private _dkGray: boolean;	
	@Input('white')
	private _white: boolean;

	// style
	@Input('open')
	private _open: boolean;
	@Input('shadowed')
	private _shadowed: boolean;
	@Input('outline_disable')
	private _outline_disable: boolean;

	// icons
	@Input('iconLeft')
	private _iconLeft: string;
	@Input('iconRight')
	private _iconRight: string;

	// data 
	@Input('mask')
	_mask: string|{[key: string]: any}
	// mask: {[key: string]: any} = {
		// Detailed Example:
		// showMaskTyped: false,
		// specialCharacters: ['e', 'x', 't', ' ', '(', ')', '-', '.'],
		// shownMaskExpression: "(___) ___-____ ext. ______",
		// mask: "(000) 000-0000 ext. 000000"
	// }

	// Form Access & Controls.
	public form: FormGroup;
	@Input('formRef')
	public formRef;
	@Input('formGroup')
	private _formGroup: FormGroup;
	@Input('controlName')
	private _controlName: string;
	@Input('control')
	private _control: FormControl;
	private _emptyControl: FormControl = new FormControl();

	@Output('focus')
	private _focus:EventEmitter<void> = new EventEmitter();
	@Output('blur')
	private _blur:EventEmitter<void> = new EventEmitter();
	@Output('change')
	private _change:EventEmitter<void> = new EventEmitter();

	get mask (): {[key: string]: any} 
	{
		if (typeof this._mask === 'string')
			return {mask: this._mask}
		return this._mask;
	}

	get control (): FormControl
	{
		return this._control ? this._control : this._emptyControl
	}

	get controlName (): string
	{
		return `${this._controlName||''}`;
	}

	get error (): string 
	{
		return `${this._invalid||'Invalid'}`
	}

	get classes (): string
	{
		return this._classes.concat([
				this.sizeClass,
				this.labelClass,
				this.colorClass,
				this.styleClass,
				this.modifierClass,
				this.outlineClass,
				(!!this._iconLeft?'has-left-icon':''),
				(!!this._iconRight?'has-right-icon':''),
				(this.isFocused?'is-focused':''),
				(this._invalid?'is-invalid':isActive(this._valid)?'is-valid':'')
			]).join(' ');
	}

	get sizeClass (): string
	{
		if (isActive(this._xs)) return 'rv-ui-xs';
		if (isActive(this._sm)) return 'rv-ui-sm';
		if (isActive(this._md)) return 'rv-ui-md';
		if (isActive(this._lg)) return 'rv-ui-lg';
		if (isActive(this._xl)) return 'rv-ui-xl';
		return 'rv-ui-sm';
	}

	get colorClass (): string
	{
		if (isActive(this._purple)) 	return 'rv-input-purple';
		if (isActive(this._blue)) 		return 'rv-input-blue';
		if (isActive(this._gray)) 		return 'rv-input-gray';
		if (isActive(this._dkGray)) 	return 'rv-input-dk-gray';
		if (isActive(this._white)) 		return 'rv-input-white';
		return 'rv-input-dk-gray';
	}

	get labelClass (): string
	{
		if (isActive(this._label))
			return 'has-label';
		return '';
	}

	get styleClass (): string
	{
		if (isActive(this._open)) 		return 'rv-input-open';
	}

	get modifierClass (): string
	{
		if (isActive(this._shadowed)) return 'rv-input-shadowed';
	}

	get outlineClass (): string
	{
		if (isActive(this._outline_disable)) return 'rv-input-outline-disable'
	}

	get label (): string
	{
		return `${this._label||''}`
	}

	get placeholder (): string
	{
		return `${this._placeholder||''}`
	}

	get disabled (): boolean
	{
		return !!this._disabled;
	}

	get readonly (): boolean
	{
		return !!this._readonly;
	}

	get isValid (): boolean
	{
		return !!this.control.valid;
	}

	get showError (): boolean
	{
		if (this.control)
			return this.control.dirty ? this.isInvalid : false;

		return this.isInvalid;
	}

	get isInvalid (): boolean
	{
		return !!this.control.invalid;
	}

	get isReactive (): boolean
	{
		return isActive(this._reactive)
	}

	get formGroup (): FormGroup
	{
		return this._formGroup;
	}

	onFocus (): void 
	{
		this.isFocused = true;
		this._focus.emit();
	}

	onBlur (): void 
	{
		this.isFocused = false;
		this._blur.emit();
	}

	private setIcon (icon: string): void
	{
		if (this[`_${icon}`] && typeof this[`_${icon}`] === 'string' && /([a-z\-]+)\.([a-z\-]+)/i.test(this[`_${icon}`])) {
			let [g,i] = this[`_${icon}`].split('.');
			this[`${icon}Group`] = g;
			this[`${icon}Name`] = i;
		}
		else if (this[`_${icon}`] && Array.isArray(this[`_${icon}`]) && /([a-z\-]+)\.([a-z\-]+)/i.test(this[`_${icon}`].slice(0,2).join('.'))) {
			let [g,i] = this[`_${icon}`];
			this[`${icon}Group`] = g;
			this[`${icon}Name`] = i;
		}
		else {
			this[`${icon}Group`] = '';
			this[`${icon}Name`] = '';
		}
	}

	constructor (elementRef: ElementRef<HTMLElement>, public controlContainer: ControlContainer) 
	{}

  ngOnInit () 
  {
    // Set our form property to the parent control
    // (i.e. FormGroup) that was passed to us, so that our
    // view can data bind to it
    this.form = <FormGroup>this.controlContainer.control;
  }

  ngOnChanges (Changes: SimpleChanges): void
  {
  	if (Changes.hasOwnProperty('_iconLeft'))  this.setIcon('iconLeft');
  	if (Changes.hasOwnProperty('_iconRight')) this.setIcon('iconRight');
  }
}