<form id="memory-chest-editor-widget-component" class="container-fluid" [formGroup]="memoryChestForm">
	<div class="row">
		<div class="col-24">
			<rv-input sm [disabled]="isEditing||isFetching" [formGroup]="memoryChestForm" placeholder="Memory Chest Name" name="nickname" controlName="nickname" (focus)="onFocus()" (blur)="onBlur()" iconLeft="education.open-book"></rv-input>
			<!-- white lg open outline_disable class="fw-bold" -->
		</div>
	</div>
	<div class="row mt-4">
		<div class="col-12">
			<rv-button outline purple md block label="Cancel" (click)="close()"/>
		</div>
		<div class="col-12">
			<rv-button [purple]="memoryChestForm.valid" [gray]="!memoryChestForm.valid" md block [label]="memoryChestId?'Update':'Create'" (click)="memoryChestForm.valid && saveMemoryChest()" />
		</div>
	</div>
</form>