import { Component } from '@angular/core';

@Component({
  selector: 'rv-switchboard-page',
  templateUrl: './switchboard-page.component.html',
  styleUrls: ['./switchboard-page.component.scss']
})
export class SwitchboardPageComponent {

}
