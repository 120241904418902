import { Component, Input, OnInit } from '@angular/core';
import { ModelInstance } from '@getrearview/model-builder';
import { BehaviorSubject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SessionUser } from 'src/app/core/session/session-user.model';

const PROFILE_URL = `${environment.api.media_url}/users/{userId}/profile.jpg`;
const MEMBER_URL = `${environment.api.media_url}/chests/{chestId}/m/{memberId}/{filename}`;

@Component({
  selector: 'rv-mini-member-img',
  template: '<div class="mini-member-img-component"><rv-img round [src]="url$|async" /></div>',
  styleUrls: ['./mini-member-img.component.scss']
})
export class MiniMemberImgComponent
{
	@Input('Member')
	Member: ModelInstance;

	@Input('url')
	url: string = '';

	private _subscriptions$: Subscription = new Subscription();
	url$: BehaviorSubject<string> = new BehaviorSubject('');

	private setUrl ()
	{
		let Media: ModelInstance,
				url: string = '';

		if (this.url) {
			url = `${environment.api.media_url}`+(`${this.url}`.replace(`${environment.api.media_url}`,''));
		}
		else if (this.Member?.getChildren('media')) {
			if (Media = Object.values(this.Member.getChildren('media')).shift() as ModelInstance) {
				let chestId: string = `${this.Member.get('chest_id')||''}`,
						memberId: string = `${this.Member.id()||''}`,
						filename: string = `${Media.get('filename')||''}`;
				url = MEMBER_URL.replace('{chestId}', chestId).replace('{memberId}', memberId).replace('{filename}', filename);
			}
		}

		if (!url && this.User.instance?.getChildren('media')) {
			if (Media = Object.values(this.User.instance.getChildren('media')).shift() as ModelInstance)
				url = PROFILE_URL.replace('{userId}', `${this.Member.get('user_id')}`);
		}

		this.url$.next(url);
	}

	constructor (private User: SessionUser)
	{}

	ngOnChanges (): void
	{
		this.setUrl();
	}
}