import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { NonAuthGuard } from 'src/app/core/guards/non-auth.guard';
import { AuthPageComponent } from './auth-page/auth-page.component';
import { RouteData } from 'src/app/core/values/route-data.value';
import { LoggedOutService } from 'src/app/core/services/logged-out.service';

const routes: Routes = [
	{ 
		path:                       'login',
		component:                  AuthPageComponent,
		canActivate:                [NonAuthGuard],
		data: 											new RouteData({animation: 'authLogin'})
	},
	{ 
		path:                       'forgot-password',
		component:                  AuthPageComponent,
		canActivate:                [NonAuthGuard],
		data: 											new RouteData({animation:'authForgotPassword'})
	},
	{ 
		path:                       'reset-password/:token',
		component:                  AuthPageComponent,
		canActivate:                [NonAuthGuard],
		data: 											new RouteData({animation:'authResetPassword'})
	},
	{ 
		path:                       'logout',
		component:                  AuthPageComponent,
		canActivate:                [AuthGuard],
		data: 											new RouteData({animation:'authLogout'})
	},
	{ 
		path:                       'sign-up',
		component:                  AuthPageComponent,
		canActivate : 							[LoggedOutService],
		data: 											new RouteData({animation:'authSignUp'})
	}
];


@NgModule({
  imports:                      [RouterModule.forChild(routes)],
  exports:                      [RouterModule]
})
export class AuthRoutingModule { }