import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnimatableWidgetComponent } from './animatable-widget/animatable-widget.component';



@NgModule({
  declarations: [AnimatableWidgetComponent],
  imports: [
    CommonModule
  ],
  exports: [
  	AnimatableWidgetComponent
  ]
})
export class AnimatableModule { }
