import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { RouteData } from 'src/app/core/values/route-data.value';
import { MemoryChestPageComponent } from './memory-chest-page/memory-chest-page.component';

const routes: Routes = [
	{ 
		path:                       'memory-chests/:id',
		component:                  MemoryChestPageComponent,
		canActivate:                [AuthGuard],
		data: 											new RouteData({animation:'memoryChestPage',widgets:{'rv-floating-controls-widget':{enable:true,options:{viewMode:'milestoneCreator', enableUpload: false}},'rv-header-widget':{enable:true}}})
	}
];

@NgModule({
  imports:                      [RouterModule.forChild(routes)],
  exports:                      [RouterModule]
})
export class MemoryChestRoutingModule { }