import { Component, Input } from '@angular/core';

@Component({
  selector: 'rv-symbol',
  templateUrl: './symbol.component.html',
  styleUrls: ['./symbol.component.scss']
})
export class SymbolComponent {

	@Input('symbol')
	set symbolColor (color: string) {  this._symbolColor = this.getColor(color); }
	get symbolColor (): string { return this._symbolColor; }


	_symbolColor: string 			= 		"#5e36dd";


	private getColor (color: string): string
	{
		switch (color) {
			case "white": 	return "#ffffff";		break;
			case "purple": 	return "#5e36dd"; 	break;
			case "blue": 		return "#071956"; 	break;
			case "gray": 		return "#EDEFF7"; 	break;
				// 1D2C63
				// 071956
				// 7,26,86

			default: 				return "gray"; 			break;
		}
	}

  constructor () 
  {}
}
