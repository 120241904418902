<div id="ui-page-fonts-component" class="container-fluid">
	<div class="row">
		<div class="col-24 text-center mt-4 mb-4">
			<h4>UI Fonts</h4>
		</div>
		<div class="col-24 text-center">
			<div class="ui-font-card">
				<table class="ui-font-heading-table">
					<tr>
						<td colspan="2">Heading 1</td>
					</tr>
					<tr>
						<td colspan="2"><h1>Almost before we knew it, Rearview had left the ground.</h1></td>
					</tr>
					<tr>
						<td>Font-size: </td>
						<td>48px</td>
					</tr>
					<tr>
						<td>Font-weight: </td>
						<td>Regular</td>
					</tr>
					<tr>
						<td>Line-height: </td>
						<td>120%</td>
					</tr>
					<tr>
						<td>Letter-spacing: </td>
						<td>0%</td>
					</tr>
				</table>
			</div>
			<div class="ui-font-card">
				<table class="ui-font-heading-table">
					<tr>
						<td colspan="2">Heading 2</td>
					</tr>
					<tr>
						<td colspan="2"><h2>Almost before we knew it, Rearview had left the ground.</h2></td>
					</tr>
					<tr>
						<td>Font-size: </td>
						<td>40px</td>
					</tr>
					<tr>
						<td>Font-weight: </td>
						<td>Regular</td>
					</tr>
					<tr>
						<td>Line-height: </td>
						<td>120%</td>
					</tr>
					<tr>
						<td>Letter-spacing: </td>
						<td>0%</td>
					</tr>
				</table>
			</div>
			<div class="ui-font-card">
				<table class="ui-font-heading-table">
					<tr>
						<td colspan="2">Heading 3</td>
					</tr>
					<tr>
						<td colspan="2"><h3>Almost before we knew it, Rearview had left the ground.</h3></td>
					</tr>
					<tr>
						<td>Font-size: </td>
						<td>32px</td>
					</tr>
					<tr>
						<td>Font-weight: </td>
						<td>Regular</td>
					</tr>
					<tr>
						<td>Line-height: </td>
						<td>115%</td>
					</tr>
					<tr>
						<td>Letter-spacing: </td>
						<td>0%</td>
					</tr>
				</table>
			</div>
			<div class="ui-font-card">
				<table class="ui-font-heading-table">
					<tr>
						<td colspan="2">Heading 4</td>
					</tr>
					<tr>
						<td colspan="2"><h4>Almost before we knew it, Rearview had left the ground.</h4></td>
					</tr>
					<tr>
						<td>Font-size: </td>
						<td>28px</td>
					</tr>
					<tr>
						<td>Font-weight: </td>
						<td>Regular</td>
					</tr>
					<tr>
						<td>Line-height: </td>
						<td>115%</td>
					</tr>
					<tr>
						<td>Letter-spacing: </td>
						<td>0%</td>
					</tr>
				</table>
			</div>
			<div class="ui-font-card">
				<table class="ui-font-heading-table">
					<tr>
						<td colspan="2">Heading 5</td>
					</tr>
					<tr>
						<td colspan="2"><h5>Almost before we knew it, Rearview had left the ground.</h5></td>
					</tr>
					<tr>
						<td>Font-size: </td>
						<td>24px</td>
					</tr>
					<tr>
						<td>Font-weight: </td>
						<td>Regular</td>
					</tr>
					<tr>
						<td>Line-height: </td>
						<td>115%</td>
					</tr>
					<tr>
						<td>Letter-spacing: </td>
						<td>0%</td>
					</tr>
				</table>
			</div>
			<div class="ui-font-card">
				<table class="ui-font-heading-table">
					<tr>
						<td colspan="2">Heading 6</td>
					</tr>
					<tr>
						<td colspan="2"><h6>Almost before we knew it, Rearview had left the ground.</h6></td>
					</tr>
					<tr>
						<td>Font-size: </td>
						<td>20px</td>
					</tr>
					<tr>
						<td>Font-weight: </td>
						<td>Regular</td>
					</tr>
					<tr>
						<td>Line-height: </td>
						<td>115%</td>
					</tr>
					<tr>
						<td>Letter-spacing: </td>
						<td>0%</td>
					</tr>
				</table>
			</div>
			<hr />

			<div class="ui-font-card">
				<table class="ui-font-body-table">
					<tr>
						<td colspan="2">Body 24</td>
					</tr>
					<tr>
						<td colspan="2" class="rv-copy-24">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat ante rhoncus, id scelerisque at morbi. Bibendum ac arcu lorem iaculis. Risus non massa pellentesque vitae risus.</td>
					</tr>
					<tr>
						<td>Font-size: </td>
						<td>24px</td>
					</tr>
					<tr>
						<td>Font-weight: </td>
						<td>400</td>
					</tr>
					<tr>
						<td>Line-height: </td>
						<td>135%</td>
					</tr>
					<tr>
						<td>Letter-spacing: </td>
						<td>0%</td>
					</tr>
				</table>
			</div>

			<div class="ui-font-card">
				<table class="ui-font-body-table">
					<tr>
						<td colspan="2">Body 20</td>
					</tr>
					<tr>
						<td colspan="2" class="rv-copy-20">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat ante rhoncus, id scelerisque at morbi. Bibendum ac arcu lorem iaculis. Risus non massa pellentesque vitae risus.</td>
					</tr>
					<tr>
						<td>Font-size: </td>
						<td>20px</td>
					</tr>
					<tr>
						<td>Font-weight: </td>
						<td>400</td>
					</tr>
					<tr>
						<td>Line-height: </td>
						<td>135%</td>
					</tr>
					<tr>
						<td>Letter-spacing: </td>
						<td>0%</td>
					</tr>
				</table>
			</div>

			<div class="ui-font-card">
				<table class="ui-font-body-table">
					<tr>
						<td colspan="2">Body 16</td>
					</tr>
					<tr>
						<td colspan="2" class="rv-copy-16">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat ante rhoncus, id scelerisque at morbi. Bibendum ac arcu lorem iaculis. Risus non massa pellentesque vitae risus.</td>
					</tr>
					<tr>
						<td>Font-size: </td>
						<td>16px</td>
					</tr>
					<tr>
						<td>Font-weight: </td>
						<td>400</td>
					</tr>
					<tr>
						<td>Line-height: </td>
						<td>135%</td>
					</tr>
					<tr>
						<td>Letter-spacing: </td>
						<td>0%</td>
					</tr>
				</table>
			</div>

			<div class="ui-font-card">
				<table class="ui-font-body-table">
					<tr>
						<td colspan="2">Body 16 (Bold)</td>
					</tr>
					<tr>
						<td colspan="2" class="rv-copy-16-bold">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat ante rhoncus, id scelerisque at morbi. Bibendum ac arcu lorem iaculis. Risus non massa pellentesque vitae risus.</td>
					</tr>
					<tr>
						<td>Font-size: </td>
						<td>16px</td>
					</tr>
					<tr>
						<td>Font-weight: </td>
						<td>600</td>
					</tr>
					<tr>
						<td>Line-height: </td>
						<td>120%</td>
					</tr>
					<tr>
						<td>Letter-spacing: </td>
						<td>0%</td>
					</tr>
				</table>
			</div>

			<div class="ui-font-card">
				<table class="ui-font-body-table">
					<tr>
						<td colspan="2">Body 14</td>
					</tr>
					<tr>
						<td colspan="2" class="rv-copy-14">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat ante rhoncus, id scelerisque at morbi. Bibendum ac arcu lorem iaculis. Risus non massa pellentesque vitae risus.</td>
					</tr>
					<tr>
						<td>Font-size: </td>
						<td>14px</td>
					</tr>
					<tr>
						<td>Font-weight: </td>
						<td>400</td>
					</tr>
					<tr>
						<td>Line-height: </td>
						<td>135%</td>
					</tr>
					<tr>
						<td>Letter-spacing: </td>
						<td>0%</td>
					</tr>
				</table>
			</div>

		</div>
	</div>
</div>
