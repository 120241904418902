import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { SessionUser } from './../../../core/session/session-user.model';
import { ModelInstance } from '@getrearview/model-builder';
import { environment } from 'src/environments/environment';

// const PROFILE_IMG_URL = `${environment.api.media_url}/users/{id}/profile.{ext}`,
const PROFILE_URL = `${environment.api.media_url}/users/{id}/{filename}`,
			getProfileUrl = (Profile: ModelInstance, Media: ModelInstance): string => {
				return (Profile ? PROFILE_URL.replace('{id}',`${Profile.id()}`).replace('{filename}',Media?.get('filename')) : '');
				// return (Profile && Profile?.id() ? PROFILE_IMG_URL.replace('{id}',`${Profile.id()}`).replace('{ext}',`svg`) : '');
			}

@Component({
  selector: 'rv-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent implements OnInit {

	Profile: ModelInstance;
	profileUrl: string;
	isLoading: boolean = false;
	birthDateFormat: string = 'longDate';

	onSuccess (s) 
	{}

	setProfile (Profile: ModelInstance, Media?: ModelInstance)
	{
		Media = Media ? Media : this.getMedia(Profile);
  	this.profileUrl = getProfileUrl(this.Profile = Profile, Media);
	}

	onMediaUpdate (Media: ModelInstance) 
	{
		if (Media) {
			this.profileUrl = getProfileUrl(this.Profile, Media);
			this.User.store(); // store the changes locally.
			this.User.publish(); // forcefully re-publish the user profile so anything listening is updated.
		}
	}

	private getMedia (Profile: ModelInstance): ModelInstance 
	{
		if (Profile && Profile?.id() && Profile.getChildren('media')) {
			return Object.values(Profile.getChildren('media')).filter((M: ModelInstance) => !!M.get('filename')).sort((A: ModelInstance, B: ModelInstance) => {
				return `${new Date(A.get('created_at')).getTime()}`.localeCompare(`${new Date(B.get('created_at')).getTime()}`, undefined, { numeric: true });
			}).pop() as ModelInstance;
		}
	}

  constructor (private User: SessionUser) 
  {}

  ngOnInit (): void 
  {
  	this.setProfile(this.User.instance);
  }
}