import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ModelInstance, ModelChildrenCollection } from '@getrearview/model-builder';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rv-memory',
  templateUrl: './memory.component.html',
  styleUrls: ['./memory.component.scss']
})
export class MemoryComponent implements OnInit {

	@Input('Memory')
	Memory: ModelInstance;

	@Input('index')
	private index: number;

	@Input('numOfMedia')
	numOfMedia: number;

	Medias: ModelChildrenCollection;

	@Output('onDeleteMedia')
	onDeleteMedia: EventEmitter<{Media: ModelInstance, Memory: ModelInstance, $element: HTMLElement}> = new EventEmitter();

	showComments: boolean = false;
	isMultiImage: boolean = false;
	mediaIds: Array<string|number> = [];

	kids (): number
	{
		if (this?.Memory?.getChildren('media'))
			return Object.values(this.Memory.getChildren('media')).length;
		return 0;
	}

	onDelete (mediaDelete: {Media: ModelInstance, $element: HTMLElement})
	{
		this.onDeleteMedia.next({...mediaDelete, Memory: this.Memory});
	}

	onShowComments (showComments: boolean): void
	{
		this.showComments = showComments;
	}

	// 1. is there media?
	//	1a. is the media on the left or right?
	// 	 does this actually matter? the css is handling this.
	// 2. is there a label or description?
	// 	2a. 
	// 
	// are there multiple videos?
	// are there multiple pics?
	// are there multiple audio clips?
	// is it a mix?
	// what if it's an email or text message w/ multiple different media types.

	get isFlipped (): boolean 
	{
		return this.index % 2 === 0;
	}

	get isFullWidth (): boolean
	{
		return this.hasCaption !== this.hasMedia
	}

	get hasCaption (): boolean
	{
		// If using the Memory Content child.
		// if (this.Memory?.getChildren('caption') && typeof this.Memory.getChildren('caption') === 'object')
		// 	return Object.values(this.Memory.getChildren('caption')).length > 0

		if (this.Memory?.get('body'))
			return !!`${this.Memory.get('body')}`.trim().length;
		return false;
	}

	get hasMedia (): boolean
	{
		if (this.Memory?.getChildren('media') && typeof this.Memory.getChildren('media') === 'object')
			return Object.values(this.Memory.getChildren('media')).length > 0
		return false;
	}

	private setMediaType (): void
	{
		if (this.Memory?.getChildren('media')) {
			this.mediaIds = Object.keys(this.Memory.getChildren('media'));
			
			const isAllImages = this.mediaIds.every(mediaId => {
				const mediaInstance = this.Memory.getChild('media', mediaId) as ModelInstance;
				return mediaInstance.attribs.media_type === 'IMG';
			});

			const imgMediaCount = this.mediaIds.filter(mediaId => {
				const mediaInstance = this.Memory.getChild('media', mediaId) as ModelInstance;
				return mediaInstance.attribs.media_type === 'IMG';
			}).length;

			this.isMultiImage = isAllImages && imgMediaCount >= 2;
		}
	}

  constructor ()
  {}

  ngOnInit (): void 
  {}

  ngOnChanges (Changes: SimpleChanges): void
  {
  	this.setMediaType();
  }

  ngOnDestroy (): void 
  {}
}
