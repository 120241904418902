import { Component, OnInit, Input, ElementRef, SimpleChanges, HostBinding } from '@angular/core';
import { UiCoreWidgetComponent } from './../../shared/ui-core-widget.component';
import { timer } from 'rxjs';

const isActive = (val: any) => (typeof val === 'boolean' ? val : typeof val === 'string' && val === '')

@Component({
  selector: 'rv-alert',
  styleUrls: ['./ui-alert-widget.component.scss'],
  template: '<div [ngClass]="classes" rvDropdownContainer>'
  				+		'<div *ngIf="collapsible; else noncollapsible">'
  				+			`<div [ngClass]="{'is-open': !!message}" class="alert-box" [rvDropdownContent]="!!message">{{message}}<ng-content></ng-content></div>`
  				+ 	'</div>'
  				+ 	'<ng-template #noncollapsible><div class="alert-box">{{message}}<ng-content></ng-content></div></ng-template>'
  				+	'</div>'
})
export class UiAlertWidgetComponent {

	@HostBinding('style.display')
	hostDisplay:string = 'block';

	@Input('name')
	name: string;

	@Input('message')
	message: string;

	iconGroup: string;
	iconName: string;
	_classes: Array<string> = ['rv-ui','ui-alert'];


	// sizes
	@Input('xs')
	private _xs: boolean;
	@Input('sm')
	private _sm: boolean;
	@Input('md')
	private _md: boolean;
	@Input('lg')
	private _lg: boolean;
	@Input('xl')
	private _xl: boolean;


	// icons
	@Input('icon')
	private _icon: Array<string>|string;


	// host display modes
	// default: inline-block
	@Input('block')
	private _block: boolean;
	@Input('flex')
	private _flex: boolean;
	@Input('collapsible')
	private _collapsible: boolean;


	// Auto-close timer.
	@Input('timer')
	private _timer: number;
	private _timer$;
	// private _timerObv$: Observable<any>;

	// colors
	@Input('success')
	private _success: boolean;
	@Input('info')
	private _info: boolean;
	@Input('warning')
	private _warning: boolean;
	@Input('danger')
	private _danger: boolean;
	@Input('purple')
	private _purple: boolean;
	@Input('blue')
	private _blue: boolean;
	@Input('red')
	private _red: boolean;
	@Input('gray')
	private _gray: boolean;
	@Input('dk-gray')
	private _dkGray: boolean;
	@Input('off-white')
	private _offWhite: boolean;
	@Input('icon-purple')
	private _icon_purple: boolean
	@Input('icon-red')
	private _icon_red: boolean


	get classes (): string
	{
		return this._classes.concat([
				this.styleClass,
				// this.modifierClass,
				this.sizeClass,
				this.colorClass,
				(isActive(this.collapsible)?'is-collapsible':''),
				(this.message?'':'is-collapsed'),
				// (this._active?'is-active':''),
				// (!!this._label?'has-label':''),
				(!!this._icon?'has-icon':''),
				// (!!this._iconLeft?'has-left-icon':''),
				// (!!this._iconRight?'has-right-icon':'')
			]).join(' ');		
	}

	get sizeClass (): string
	{
		if (isActive(this._xs)) 		return 'rv-ui-xs';
		if (isActive(this._sm)) 		return 'rv-ui-sm';
		if (isActive(this._md)) 		return 'rv-ui-md';
		if (isActive(this._lg)) 		return 'rv-ui-lg';
		if (isActive(this._xl)) 		return 'rv-ui-xl';
	}

	get colorClass (): string
	{
		if (isActive(this._success)) 	return 'rv-alert-success';
		if (isActive(this._info)) 		return 'rv-alert-info';
		if (isActive(this._warning)) 	return 'rv-alert-warning';
		if (isActive(this._danger)) 	return 'rv-alert-danger';
		// if (isActive(this._purple)) 	return 'rv-alert-purple';
		// if (isActive(this._blue)) 		return 'rv-alert-blue';
		// if (isActive(this._red)) 			return 'rv-alert-red';
		// if (isActive(this._gray)) 		return 'rv-alert-gray';
		// if (isActive(this._dkGray)) 	return 'rv-alert-dk-gray';
		// if (isActive(this._offWhite)) return 'rv-alert-off-white';
		return 'rv-alert-purple';
	}

	get styleClass (): string
	{
		const classes: Array<string> = [
				(isActive(this._block) ? 'rv-alert-block' : ''),
			];

		return classes.join(' ');
	}

	get collapsible (): boolean
	{
		return isActive(this._collapsible);
	}

	private setHostDisplay (): void
	{
		if(isActive(this._flex))
			this.hostDisplay = 'flex';
		else if(isActive(this._block))
			this.hostDisplay = 'block';
		else 
			this.hostDisplay = 'inline-block';
  }

	private setIcon (icon: string): void
	{
		if (this[`_${icon}`] && typeof this[`_${icon}`] === 'string' && /([a-z\-]+)\.([a-z\-]+)/i.test(this[`_${icon}`])) {
			let [g,i] = this[`_${icon}`].split('.');
			this[`${icon}Group`] = g;
			this[`${icon}Name`] = i;
		}
		else if (this[`_${icon}`] && Array.isArray(this[`_${icon}`]) && /([a-z\-]+)\.([a-z\-]+)/i.test(this[`_${icon}`].slice(0,2).join('.'))) {
			let [g,i] = this[`_${icon}`];
			this[`${icon}Group`] = g;
			this[`${icon}Name`] = i;
		}
		else {
			this[`${icon}Group`] = '';
			this[`${icon}Name`] = '';
		}
	}

	private onMessageUpdate (): void
	{
		if (this.message) {
			if(this._timer$)
				this._timer$.unsubscribe();

			this._timer$ = timer(this._timer).subscribe(() => {
				this.message = '';
				if(this._timer$)
					this._timer$.unsubscribe();
				this._timer$ = undefined;
			});
		}
		else {
			if(this._timer$)
				this._timer$.unsubscribe();
			this._timer$ = undefined;
		}
	}

  constructor (elementRef: ElementRef<HTMLElement>) 
  {}

  ngOnInit (): void 
  {}

  ngOnChanges (Changes: SimpleChanges): void
  {
  	if (Changes.hasOwnProperty('_icon')) this.setIcon('icon');
  	if (Changes.hasOwnProperty('_block')) this.setHostDisplay();
  	if (Changes.hasOwnProperty('_flex')) this.setHostDisplay();

  	if (Changes?.message && this._timer > 0)
  		this.onMessageUpdate();
  }
}