import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { ModelInstance } from '@getrearview/model-builder';
import { SessionUser } from 'src/app/core/session/session-user.model';
import { MemoryChestService } from 'src/app/models/memories/memory-chest.service';
import { FeedService } from './../shared/feed.service';
import { ModalService } from 'src/app/widgets/modal/shared/modal.service';

@Component({
  selector: 'feed-milestone',
  templateUrl: './feed-milestone.component.html',
  styleUrls: ['./feed-milestone.component.scss']
})
export class FeedMilestoneComponent implements OnInit {

	@Input('FeedMilestone')
	FeedMilestone: ModelInstance;

	@Input('fetchPreview')
	fetchPreview: boolean;

	@Input('chestId')
	chestId: string;

	@ViewChild('memberInviter')
	memberInviter: TemplateRef<any>;
	
	MemoryChest: ModelInstance;
	Milestone: ModelInstance;
	Me: ModelInstance;

	birthDateFormat: string = 'mediumDate';

	isOpen: boolean = false;

	can_share: boolean = false;
	can_edit: boolean = false;
	can_delete: boolean = false;

	contextMenuPosition = {
		position: 'absolute',
		right: 0
	};

	async shareMilestone (): Promise<void>
	{
		const {
			status, MemoryChest, Milestone, Member
		} = await this.feedSrvc.authenticateInvitor(this.chestId, `${this.FeedMilestone.id()}`);

		if (status && this.memberInviter) {
			this.MemoryChest = MemoryChest;
			this.Milestone = Milestone;
			this.Me = Member;
			this.modalService.open(this.memberInviter, {title: 'Add Person'})
		}
	}

	editMilestone (): void 
	{
		console.log('editMilestone');
	}

	deleteMilestone (): void 
	{
		console.log('deleteMilestone');
	}

  constructor (private feedSrvc: FeedService, private MemoryChestSrvc: MemoryChestService, private User: SessionUser, public modalService: ModalService) 
  {}

  ngOnInit (): void 
  {
  	this.can_share 		= 	this.FeedMilestone?.attribs?.can_share === true || parseInt(this.FeedMilestone?.attribs?.can_share) === 1;
  	this.can_edit 		= 	false && (this.FeedMilestone?.attribs?.can_edit === true || parseInt(this.FeedMilestone?.attribs?.can_edit) === 1);
  	this.can_delete 	= 	false && (this.FeedMilestone?.attribs?.can_delete === true || parseInt(this.FeedMilestone?.attribs?.can_delete) === 1);
  }
}