<div id="milestone-deleter-widget-component" class="container-fluid">
	<div class="row">
		<div class="col-24">
			Do you really want to delete this Milestone?
		</div>
	</div>

	<div class="row mt-4">
		<div class="col-12">
			<rv-button outline purple md block label="Cancel" (click)="onCancel.emit()" />
		</div>
		<div class="col-12">
			<rv-button purple md block label="Yes, Delete" (click)="delete()" />
		</div>
	</div>
</div>
