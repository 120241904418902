import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServicesModule } from './services/services.module';
import { RearviewInterfaceModule } from './interface/rearview-interface.module';
import { DropdownContentDirective } from './interface/shared/directives/dropdown-content.directive';
import { DropdownContainerDirective } from './interface/shared/directives/dropdown-container.directive';
import { ObserveVisibilityDirective } from './directives/observe-visibility.directive';
import { FormControlErrorDirective } from './directives/form-control-error.directive';
import { FormSubmitDirective } from './directives/form-submit.directive';
import { FormControlErrorComponent } from './directives/form-control-error/form-control-error.component';
import { FormControlErrorContainerDirective } from './directives/form-control-error-container.directive';
import { MediaRetrievalService } from './services/media-retrieval.service';
import { StrReplacePipe } from './pipes';
import { SortByDatePipe } from './pipes';
import { NativeModule } from './native/native.module';
import { DirectivesModule } from './directives/directives.module';
import { FormsModule } from './forms/forms.module';
import { AuthenticationModule } from './authentication/authentication.module';

@NgModule({
  declarations: [ObserveVisibilityDirective, StrReplacePipe, SortByDatePipe],
  imports: [
    CommonModule,
    ServicesModule,
    RearviewInterfaceModule,
    NativeModule,
    FormsModule,
    DirectivesModule,
    AuthenticationModule
  ],
  exports: [
  	ObserveVisibilityDirective,
  	FormControlErrorDirective,
  	FormSubmitDirective,
  	StrReplacePipe,
  	SortByDatePipe,
  	DropdownContainerDirective,
  	DropdownContentDirective
  ],
  providers: [
  	MediaRetrievalService
  ]
})
export class SharedModule { }