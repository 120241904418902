import { Component, OnInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { ChestService } from './../shared/chest.service';
import { ModelInstance } from '@getrearview/model-builder';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/app/widgets/modal/shared/modal.service';

@Component({
  selector: 'rv-memory-chest-members',
  templateUrl: './memory-chest-members.component.html',
  styleUrls: ['./memory-chest-members.component.scss']
})
export class MemoryChestMembersComponent implements OnInit {

	@ViewChild('memberInviter')
	memberInviter: TemplateRef<any>;

	Me: ModelInstance;
	canInvite: boolean = false;
	hasLoaded: boolean = false;
	selectedMemberIds: string[] = [];

	_subscription$ = new Subscription();

	openMemberInviter (): void
	{
		if (this.memberInviter) 
			this.modalService.open(this.memberInviter, {title: 'Add Person'})
	}

  clickMember (id: string): void {
    this.selectedMemberIds.includes(id) ? 
      this.selectedMemberIds = this.selectedMemberIds.filter((existed: string) => existed !== id) : this.selectedMemberIds.push(id);
    this.chestSrvc.filterMilestonesByMember(this.selectedMemberIds);
  }

  constructor (public chestSrvc: ChestService, public modalService: ModalService) 
  {}

  ngOnInit (): void
  {
  	this._subscription$.add(this.chestSrvc.Members$.subscribe(Members => {
  		this.Me = this.chestSrvc.Me
  		this.hasLoaded = Members.length>0; 
  		this.canInvite = ['owner','admin'].includes(this.Me?.attribs?.member_type);
  	}));
  }

  ngOnDestroy (): void
  {
  	this._subscription$.unsubscribe();
  }
}