import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentLoaderWidgetComponent } from './component-loader-widget/component-loader-widget.component';
import { LoaderOverlayComponent } from './loader-overlay/loader-overlay.component';
import { LogoModule } from './../logo/logo.module';

@NgModule({
  declarations: [
    ComponentLoaderWidgetComponent,
    LoaderOverlayComponent
  ],
  imports: [
    CommonModule,
    LogoModule
  ],
  exports: [
  	ComponentLoaderWidgetComponent
  ]
})
export class LoadersModule { }
