import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ModelInstance } from '@getrearview/model-builder';
import { MediaRetrievalService } from 'src/app/shared/services/media-retrieval.service';
import { StoryService } from './../shared/story.service';
import { Subject, of } from 'rxjs';
import { distinctUntilChanged, timeout, delay, take } from 'rxjs/operators';

@Component({
  selector: 'rv-memory-media-image',
  templateUrl: './memory-media-image.component.html',
  styleUrls: ['./memory-media-image.component.scss']
})
export class MemoryMediaImageComponent implements OnInit {

	@Input("Media") Media: ModelInstance;
	@ViewChild('imgContainer') imgContainer: ElementRef;

	private _isLoading: boolean = true;

	private buildImage (imgData)
	{
		var reader 						= 		new FileReader();
		reader.readAsDataURL(imgData); 
		reader.onloadend 			= 		() => {
			var base64data 			= 		reader.result;
			this.imgContainer.nativeElement.src = base64data;
			this._isLoading 		=			false;
		}
	}

	private loadMedia ()
	{
		const chestId: string = this.storySrvc.Milestone?.get('chest_id'),
					milestoneId: string = chestId && `${this.storySrvc.Milestone.id()}`;

		this.imgContainer.nativeElement.src = this.MediaRetrievalSrvc.buildUrl(chestId, milestoneId, this.Media.attribs.filename);

		// this.MediaRetrievalSrvc
		// 		.fetchByFilename(chestId, milestoneId, this.Media.attribs.filename)
		// 		.subscribe(data => this.buildImage(data));
	}

  constructor (private storySrvc: StoryService, private MediaRetrievalSrvc: MediaRetrievalService) 
  {}

  ngOnInit(): void 
  {
		// this.loadMedia();
  }

  ngAfterViewInit (): void
  {
  	this.loadMedia();
  }
}
