<div class="feed-milestone-hashtags-component d-flex align-items-center h-100">
	<!-- <rv-hashtag-manager-widget [Hashtags]="hashtags"></rv-hashtag-manager-widget> -->
	<ul>
		<li *ngFor="let t of hashtags">
			<rv-hashtag [Hashtag]="this.memoryChestTagSrvc.factory().create({chest_id: this.milestoneId, tag: t})"></rv-hashtag>
		</li>
		<li
			*ngIf="extra"
			class="extra-item rounded-5 fw-semibold px-2 py-1"
		>
			{{extra}}+
		</li>
	</ul>
</div>