import { Component, Input, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModelInstance } from '@getrearview/model-builder';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rv-memory-creator-widget',
  templateUrl: './memory-creator-widget.component.html',
  styleUrls: ['./memory-creator-widget.component.scss']
})
export class MemoryCreatorWidgetComponent {

	@Output('onCancel')
	onCancel: EventEmitter<void> = new EventEmitter();

	@Output('onSuccess')
	onSuccess: EventEmitter<ModelInstance> = new EventEmitter();

	@Input('Milestone')
	Milestone: ModelInstance;

	@Input('Me')
	Me: ModelInstance;

	isSaving: boolean = false;
	isFetching: boolean = false;
	isEditing: boolean = false;

	Memory!: ModelInstance;
	memoryForm: FormGroup = new FormGroup({caption: new FormControl(''), description: new FormControl(''), member_ids: new FormControl([]), tag_ids: new FormControl([])});

	_subscriptions$:Subscription = new Subscription();

	close (): void
	{
		this.onCancel.next();
	}

	onFocus ($e?: any): void
	{}

	onBlur ($e?: any): void
	{}

	async saveMemory (): Promise<void>
	{
		this.isSaving = true;

		const attribs: {
			author_id: string;
			caption?: string;
			description?: string;
			member_ids?: Array<string>;
			tag_ids?: Array<string>;
		} = {author_id: (this.Me?.id() ? `${this.Me.id()}` : '')};

		Object.keys(this.memoryForm.controls).forEach(control => attribs[control] = this.memoryForm.get(control).value);

		let Media: ModelInstance;

		try {
			Media = await this.Milestone.createChild('memory', {
			author_id: attribs.author_id, 
			body: (attribs?.caption||''),
			// description: (attribs?.description||''),
			type: ''
		}) as ModelInstance;
		}
		catch (ex) {
			console.error(`Failed to create media instance on Milestone ${ex} (Milestone ID: ${this.Milestone?.id()}) `,attribs);
		}

		if (Media)
			this.onSuccess.next(Media);

		this.isSaving = false;
	}

	// updateForm (Memory: ModelInstance): void
	// {
	// 	// this.memoryForm.get('nickname').setValue((Memory?.get('nickname')||''));
	// }

	// updateFormSilent (Memory: ModelInstance): void
	// {
	// 	// this.memoryForm.get('nickname').setValue((Memory?.get('nickname')||''), { emitEvent: false });
	// }

  constructor (private fb: FormBuilder)
  {}

  ngOnInit (): void
  {
  	// this._subscriptions$.add(this.memoryForm.valueChanges.pipe(distinctUntilChanged(), debounceTime(200)).subscribe(changes => this.#updateModel(changes)));

  	// this.#fetchMemoryChest()
  	// 				.finally(() => {
  	// 					if (this.MemoryChest)
  	// 						this.updateForm(this.MemoryChest)
  	// 					this.isFetching = false;
  	// 				})
  	// 				.catch(() => {
  	// 					this.isFetching = false;
  	// 				})
  }

  ngOnDestroy (): void
  {
  	this._subscriptions$.unsubscribe();
  }
}