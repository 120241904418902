import { Component, OnInit, Input, ElementRef, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

const isActive = (val: any) => (typeof val === 'boolean' ? val : typeof val === 'string' && val === '')

@Component({
  selector: 'rv-input-checkbox',
  styleUrls: ['./ui-input-checkbox-widget.component.scss'],
  template: '<div [ngClass]="classes"><input type="checkbox" [formControl]="control" (focus)="isFocused = true" (blur)="isFocused = false" class="form-check-input" /><label class="form-check-label">{{label}}</label></div>'
})

export class UiInputCheckboxWidgetComponent implements OnInit {

	isFocused: boolean = false;
	private _classes: Array<string> = ['rv-ui','ui-input-checkbox', 'form-check'];

	// input labels
	@Input('label')
	private _label: string;
	@Input('placeholder')
	private _placeholder: string;

	// sizes
	@Input('xs')
	private _xs: boolean;
	@Input('sm')
	private _sm: boolean;
	@Input('md')
	private _md: boolean;
	@Input('lg')
	private _lg: boolean;

	// interactive
	@Input('disabled')
	private _disabled: boolean;
	@Input('readonly')
	private _readonly: boolean;
	@Input('valid')
	private _valid: boolean;
	@Input('invalid')
	private _invalid: string;

	@Input('control')
	private _control: FormControl;
	private _emptyControl: FormControl = new FormControl();



	get control (): FormControl
	{
		return this._control ? this._control : this._emptyControl
	}

	get classes (): string
	{
		return this._classes.concat([
				this.sizeClass,
				this.labelClass,
				(this.isFocused?'is-focused':''),
				(this._invalid?'is-invalid':isActive(this._valid)?'is-valid':'')
			]).join(' ');
	}

	get sizeClass (): string
	{
		if (isActive(this._sm)) return 'rv-ui-sm';
		if (isActive(this._md)) return 'rv-ui-md';
		if (isActive(this._lg)) return 'rv-ui-lg';
		return 'rv-ui-sm';
	}

	get labelClass (): string
	{
		if (isActive(this._label))
			return 'has-label';
		return '';
	}

	get label (): string
	{
		return `${this._label||''}`
	}

	get disabled (): boolean
	{
		return !!this._disabled;
	}

	get readonly (): boolean
	{
		return !!this._readonly;
	}

  constructor (elementRef: ElementRef<HTMLElement>) 
  {}

  ngOnInit (): void 
  {}
}