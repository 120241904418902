<div id="milestone-intro-component" class="container-fluid">
	<div class="row">
		<div class="col-24">
			<rv-location-selector />
		</div>
	</div>
	<br />
	<div class="row">
		<div class="col-24">
			<rv-textarea label='Description' iconLeft="solid.align-left-justify" placeholder='Details' />
		</div>
	</div>
	<br />
	<div class="row">
		<div class="col-24">
			<rv-datepicker />
		</div>
	</div>
	<br />
	<div class="row">
		<div class="col-24">
			<!-- <rv-member-inviter /> -->
		</div>
	</div>
	<br />
	<div class="row">
		<div class="col-12">
			<rv-button outline purple md block label="Cancel" (click)="onCancel.emit()"/>
		</div>
		<div class="col-12">
			<rv-button [purple]="milestoneForm.valid" [gray]="!milestoneForm.valid" md block label="Create" (click)="milestoneForm.valid && saveMilestone()" />
		</div>
	</div>
	<!--

		<rv-icon group=''></rv-icon>

		@Input('label')
		@Input('placeholder')
		@Input('name')
		@Input('xs')
		@Input('sm')
		@Input('md')
		@Input('lg')
		@Input('xl')
		@Input('disabled')
		@Input('readonly')
		@Input('valid')
		@Input('invalid')
		@Input('reactive')
		@Input('purple')
		@Input('blue')
		@Input('gray')
		@Input('dk-gray')
		@Input('white')
		@Input('open')
		@Input('shadowed')
		@Input('outline_disable')
		@Input('iconLeft')
		@Input('iconRight')
		@Input('mask')
		@Input('formGroup')
		@Input('controlName')
		@Input('control')

		<p>MemoryChest: {{MemoryChest?.id()}}</p>
		<hr />
		<p>Milestone: {{Milestone?.id()}}</p>
		location
		start time
		description 
		share & invite
	-->
</div>