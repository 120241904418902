<div class="feed-milestone-component">
	<div class="memory-chest">
		<rv-icon group='education' icon='open-book'></rv-icon>&nbsp;{{FeedMilestone.get('c_nn')}}
	</div>
	<div class="milestone-title">
		<div [routerLink]="['/milestones',FeedMilestone.id()]"><h6 class="font-color--purple">{{FeedMilestone.get('m_nn')}}</h6></div>
		<div class="context-wrapper" *ngIf="can_share||can_edit||can_delete">
			<div rvDropdownContainer> <!--  -->
				<rv-button (click)="isOpen = !isOpen" open sm iconLeft="user-interface.menu"></rv-button>
				<div [rvDropdownContent]="!!isOpen" [position]="contextMenuPosition">
					<div>
						<ul class="context-menu">
							<li><rv-button *ngIf="can_share" (click)="shareMilestone()" open sm dk-gray iconLeft="user-interface.share" label="Share Event" /></li>
							<li><rv-button *ngIf="can_edit" (click)="editMilestone()" open sm dk-gray iconLeft="user-interface.pencil" label="Edit" /></li>
							<li><rv-button *ngIf="can_delete" (click)="deleteMilestone()" open sm red iconLeft="user-interface.trash" label="Delete" /></li>
						</ul>
					</div>
				</div>
			</div>
			<!--
			<rv-dropdown-widget [isOpen]="isOpen" position="right">
				<ul class="context-menu">
					<li><rv-button *ngIf="can_share" (click)="shareMilestone()" open sm dk-gray iconLeft="user-interface.share" label="Share Event" /></li>
					<li><rv-button *ngIf="can_edit" (click)="editMilestone()" open sm dk-gray iconLeft="user-interface.pencil" label="Edit" /></li>
					<li><rv-button *ngIf="can_delete" (click)="deleteMilestone()" open sm red iconLeft="user-interface.trash" label="Delete" /></li>
				</ul>
			</rv-dropdown-widget>
			-->
		</div>
	</div>
	<div class="milestone-description">{{FeedMilestone.get('m_dsc')}}</div>
	<div class="milestone-preview">
		<feed-milestone-preview [Milestone]="FeedMilestone"></feed-milestone-preview>
	</div>
	<div class="milestone-timestamp font-color--grey-400">Story beings on {{FeedMilestone.get('m_birth')|date:birthDateFormat}}</div>
</div>

<ng-template #memberInviter>
	<rv-memory-chest-inviter [MemoryChest]="MemoryChest" [Me]="Me" [Milestone]="Milestone" />
</ng-template>
