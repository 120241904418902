import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { RouteData } from 'src/app/core/values/route-data.value';
import { MilestonePageComponent } from './milestone-page/milestone-page.component';

const routes: Routes = [
	{ 
		path:                       'milestones/:id',
		component:                  MilestonePageComponent,
		canActivate:                [AuthGuard],
		data: 											new RouteData({animation:'milestonePage',widgets:{'rv-floating-controls-widget':{enable:true,options:{viewMode:'memoryCreator', enableUpload: true}},'rv-header-widget':{enable:true, opts: {style: {backgroundColor: '#FFF'}}}}})
	}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MilestoneRoutingModule { }