<div class="milestone-members-component">
	<div class="member-wrappers">
		<div class="member-wrapper" *ngFor="let m of members">
			<!-- <rv-mini-member-img [Member]="this.memberSrvc.factory().create({chest_id: this.milestoneId, id: m})"></rv-mini-member-img> -->
			<rv-img round src="{{host}}/users/{{m}}/profile.jpg" />
		</div>
	</div> 
	<div *ngIf="extra" class="member-wrapper">
		{{extra}}+
	</div>
</div>