import { Injectable } from '@angular/core';
import { SessionUser } from 'src/app/core/session/session-user.model';
import { MilestoneService } from 'src/app/models/memories/milestone.service';
import { MemoryChestService } from 'src/app/models/memories/memory-chest.service';
import { MilestoneEventMapService } from 'src/app/models/memories/milestone-event-map.service';
import { MemberService } from 'src/app/models/memories/member.service';
import { ConfigService } from 'src/app/shared/services/config.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ModelInstance, ModelChildrenCollection } from '@getrearview/model-builder';
import { BehaviorSubject, Observable } from 'rxjs';
import * as moment from 'moment';

const getOrCreateEventMap = async (Milestone: ModelInstance, factory): Promise<ModelInstance> =>
{
	let EventMap: ModelInstance;
	if (Milestone?.getChildren('event_map'))
		EventMap 					=		Object.values(Milestone.getChildren('event_map')).shift() as ModelInstance;
	if (!EventMap && Milestone)
		EventMap 					=		factory.create({milestone_id: Milestone.id()});
	return EventMap;
}

const compileMilestoneEventMap = (MilestoneEvents: Array<ModelInstance>): Array<{model: string; idx: number; id: string}> =>
{
	const getEventPosition = (ME: ModelInstance, idx): {model: string; idx: number; id: string} => ({model: ME.getName(), idx: idx, id: `${ME.id()}`});
	return MilestoneEvents.filter(n => n).map(getEventPosition);
}

const sortMilestoneEventsByDate = (MilestoneEvents) =>
{
	return MilestoneEvents.sort((A: ModelInstance, B: ModelInstance) => {
		let a1 = (A.attribs.start_at||A.attribs.created_at||''),
				b1 = (B.attribs.start_at||B.attribs.created_at||'');
		return `${a1?(typeof a1 === 'string' ? new Date(a1).getTime() : a1.getTime()):''}`.localeCompare(`${b1?(typeof b1 === 'string' ? new Date(b1).getTime() : b1.getTime()):''}`, undefined, {numeric: true});
	});

	// const mapObject = EventMap.attribs.map_object;
	// if (mapObject && typeof mapObject === 'string')
	// 	return JSON.parse(mapObject).map(o => MilestoneEvents.filter(ME => ME.id() === o.id).shift());
	// if (mapObject && typeof mapObject === 'object')
	// 	return mapObject.map(o => MilestoneEvents.filter(ME => ME.id() === o.id).shift());
	// return MilestoneEvents;
}

const sortMilestoneEventsByMap = (MilestoneEvents, EventMap) =>
{
	const mapObject = EventMap.attribs.map_object;
	if (mapObject && typeof mapObject === 'string')
		return JSON.parse(mapObject).map(o => MilestoneEvents.filter(ME => ME.id() === o.id).shift());
	if (mapObject && typeof mapObject === 'object')
		return mapObject.map(o => MilestoneEvents.filter(ME => ME.id() === o.id).shift());
	return MilestoneEvents;
}

const MILESTONE_RELATIONSHIPS: Array<string> = [
	'media',
	'memory',
	'memory.media',
	'memory.media.ograph',
	'memory.content',
	'memory.comment',
	'memory.comment.like',
	'chest',
	'chest.member',
	'chest.tag',
	'location',
	'memory.tag',
	'tag',
	'event_map'
];

function clamp(value: number, max: number): number {
  return Math.max(0, Math.min(max, value));
}

interface MilestoneBookends {
	next: ModelInstance;
	previous: ModelInstance;
}

@Injectable({
  providedIn: 'root'
})
export class StoryService {

	// Populates UI 
	// Array of Milestone Event Instances (Locations, Memories)
	MilestoneEvents: Array<ModelInstance> = []; 

	// Collection of MilestoneDateLocation entries.
	Locations: ModelChildrenCollection; 

	// Tracks ordering of Milestone Events (Memories, Locations, etc.)
	EventMap: ModelInstance; 

	private _MemoryChest: ModelInstance;
	private _Members: Array<ModelInstance> = [];
	private _Milestone: ModelInstance;
	private _Next: ModelInstance;
	private _Previous: ModelInstance;
	private _Milestone$!: BehaviorSubject<ModelInstance>;
	private _Memories: ModelChildrenCollection;
	private _Memories$: BehaviorSubject<ModelChildrenCollection> = new BehaviorSubject(undefined);
	private _getIsMilestoneEmpty: boolean = false;
	// dateIds: Array<string> = [];

	// nextId: string = '';
	// previousId: string = '';

	get getIsMilestoneEmpty (): boolean
	{
		return this._getIsMilestoneEmpty;
	}

	get Profile (): ModelInstance
	{
		return this.SessionUser.instance;
	}

	get Milestone$ (): Observable<ModelInstance>
	{
		return this._Milestone$.asObservable();
	}

	get Milestone (): ModelInstance
	{
		return this._Milestone;
	}

	get Next (): ModelInstance
	{
		return this._Next;
	}

	get Previous (): ModelInstance
	{
		return this._Previous;
	}

	private createOrAdvanceMilestoneSubj (Milestone: ModelInstance): void
	{
		if (!this._Milestone$)
			this._Milestone$ = new BehaviorSubject(this._Milestone = Milestone);
		else
			this._Milestone$.next(this._Milestone = Milestone);
	}

	private recompileMap (models: {Milestone?: ModelInstance, EventMap?: ModelInstance}): void
	{
		let {Milestone,EventMap} = models||{};

		Milestone = Milestone ? Milestone : this.Milestone;
		const Memories: ModelChildrenCollection = Milestone?.getChildren('memory'),
					Locations: ModelChildrenCollection = Milestone?.getChildren('location'),
					MilestoneEvents: Array<ModelInstance> = Object.values(Memories).concat(Object.values(Locations));

		if (!EventMap.attribs.map_object)
			EventMap.attribs.map_object = compileMilestoneEventMap(Object.values(Memories).concat(Object.values(Locations)));

		if (EventMap.attribs.map_object && typeof EventMap.attribs.map_object === 'string')
			EventMap.attribs.map_object = JSON.parse(EventMap.attribs.map_object);

		this.EventMap = EventMap;
		this.MilestoneEvents = (Array.isArray(EventMap.attribs.map_object) && EventMap.attribs.map_object.length) ? sortMilestoneEventsByMap(MilestoneEvents, EventMap) : sortMilestoneEventsByDate(MilestoneEvents);
	}

	set Milestone (Milestone: ModelInstance)
	{
		// 1. check for event map.
		// 2. no map, created one.
		// 3. get map object
		//		3a. has map been initialized?
		// 		3b. create map object if needed.
		// 4. using map, sort memories and locations into MilestoneEvents.

		getOrCreateEventMap(Milestone, this.MilestoneEventMapSrvc.factory()).then((EventMap: ModelInstance) => this.recompileMap({Milestone, EventMap})).finally(() => this.createOrAdvanceMilestoneSubj(Milestone));
	}

	get Memories$ (): Observable<ModelChildrenCollection>
	{
		return this._Memories$.asObservable();
	}

	get Memories (): ModelChildrenCollection
	{
		return this._Memories;
	}

	set Memories (Memories: ModelChildrenCollection)
	{
		this._getIsMilestoneEmpty = Object.keys(Memories||{}).length === 0;

		if (!this._Memories$)
			this._Memories$ = new BehaviorSubject(this._Memories = Memories)
		else 
			this._Memories$.next(this._Memories = Memories);
	}

	get Members (): Array<ModelInstance>
	{
		return this._Members;
	}

	get Me (): ModelInstance
	{
		return this._Members.filter(M => M.get('user_id') === this.SessionUser.instance?.id()).shift();
	}

	get MemoryChest (): ModelInstance
	{
		return this._MemoryChest;
	}

	async getMilestoneBookends (Milestone?: ModelInstance): Promise<MilestoneBookends|HttpErrorResponse>
	{
		const milestoneId: string = (Milestone?.id() || this.Milestone?.id() || '0') as string;

		return new Promise((resolve, reject) => {
			this.httpClient.get<any>(`${this.ConfigSrvc.get('api.api_url')}/memories/milestones/${milestoneId}/bookends`)
					.subscribe(
							payload => {
								resolve({
									next: 		payload?.data?.next ? this.MilestoneSrvc.factory().create(payload.data.next) : undefined,
									previous: payload?.data?.previous ? this.MilestoneSrvc.factory().create(payload.data.previous) : undefined
								} as MilestoneBookends)
							}, 
							HttpErrorResponse => { reject(HttpErrorResponse); }
						);
		});
	}

	reset (): void
	{
		// Populates UI 
		// Array of Milestone Event Instances (Locations, Memories)
		this.MilestoneEvents = []; 

		// Collection of MilestoneDateLocation entries.
		this.Locations = undefined;

		// Tracks ordering of Milestone Events (Memories, Locations, etc.)
		this.EventMap = undefined;

		this._MemoryChest = undefined;
		this._Members = [];
		this._Milestone = undefined;
		this._Next = undefined;
		this._Previous = undefined;
		if (this._Milestone$)
			this._Milestone$.next(undefined);
		this._Memories = undefined;
		if (this._Memories$)
			this._Memories$.next(undefined)
		this._getIsMilestoneEmpty = false;
		// dateIds: Array<string> = [];

		// nextId: string = '';
		// previousId: string = '';

		this.Milestone 			= 	undefined;
		this._Next 					= 	undefined;
		this._Previous 			= 	undefined;
		this._MemoryChest 	= 	undefined;
		this._Members 			= 	[];
	}

	private async initMilestone (Milestone: ModelInstance): Promise<void>
	{
		if (!Milestone){
			this.reset();
			return Promise.resolve();
		}

		let NextMilestone: ModelInstance,
				PrevMilestone: ModelInstance;

		try {
			const {next, previous} = await this.getMilestoneBookends(Milestone) as MilestoneBookends;
			NextMilestone = next;
			PrevMilestone = previous;
		}
		catch (ex) {
			console.error(`${ex}`);
		}

		let MemoryChest: ModelInstance,
				Members: Array<ModelInstance> = [];

		try {
			MemoryChest = Milestone.attribs?.chest ? this.MemoryChestSrvc.factory().create(Milestone.attribs.chest) : undefined;
			Members = Array.isArray(Milestone.attribs.chest?.member) ? Milestone.attribs.chest.member.map(member => this.MemberSrvc.factory().create(member)).filter(n => n && n instanceof ModelInstance) : [];
		}
		catch (ex) {
			console.error(`${ex}`);
		}

		this._Next 					= 	NextMilestone;
		this._Previous 			= 	PrevMilestone;
		this._MemoryChest 	= 	MemoryChest;
		this._Members 			= 	Members;

		this.Milestone 			= 	Milestone;

		return 									Promise.resolve();
	}

	async fetch (milestoneId?: string): Promise<void>
	{
		let Milestone: ModelInstance;

		try {
			Milestone = (await this.MilestoneSrvc.search({id:milestoneId, _relationships: MILESTONE_RELATIONSHIPS})).shift();
		}
		catch (ex) {
			console.error(`${ex}`);
			return Promise.reject();
		}

		await this.initMilestone(Milestone);
	}

	appendMedia (Media: ModelInstance, Memory: ModelInstance)
	{
		const mediaId = Media?.id(), 
					memoryId = Memory?.id();

		Memory = !!(Memory?.id() && !!this._Milestone.getChild('memory', Memory.id())) ? this._Milestone.getChild('memory', Memory.id()) as ModelInstance : Memory;

		Memory.setChild('media', Media);

		this._Milestone.setChild('memory', Memory);

		this.Memories = this._Milestone.getChildren('memory');

		this.recompileMap({Milestone: this.Milestone, EventMap: this.EventMap});
	}

	private async updateEventMap (): Promise<void>
	{
		if (this.EventMap) {
			this.EventMap.set('map_object', JSON.stringify(compileMilestoneEventMap(this.MilestoneEvents)));
			this.EventMap.save();
		}
	}

	/**
	 * Moves an item one index in an array to another.
	 * @param array Array in which to move the item.
	 * @param fromIndex Starting index of the item.
	 * @param toIndex Index to which the item should be moved.
	 */
	moveItemInEventMap<T = any>(fromIndex: number, toIndex: number): void {

		const MilestoneEvents: Array<ModelInstance> = this.MilestoneEvents,
	  			from = clamp(fromIndex, MilestoneEvents.length - 1),
	  			to = clamp(toIndex, MilestoneEvents.length - 1);

	  if (from === to)
	    return;

	  const target = MilestoneEvents[from],
	  			delta = to < from ? -1 : 1;

	  for (let i = from; i !== to; i += delta)
	    MilestoneEvents[i] = MilestoneEvents[i + delta];

	  MilestoneEvents[to] = target;

	  this.MilestoneEvents = Object.values(MilestoneEvents);

	  this.updateEventMap();
	}

	constructor (private MilestoneSrvc: MilestoneService, private MemoryChestSrvc: MemoryChestService, private MemberSrvc: MemberService, private httpClient: HttpClient, private ConfigSrvc: ConfigService, private SessionUser: SessionUser, private MilestoneEventMapSrvc: MilestoneEventMapService) 
	{}

	ngOnDestroy (): void
	{
		this.reset();
	}
}
