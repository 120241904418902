import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { USER_SETTINGS_CONFIG, UserSettingsConfig } from './../user-settings.config';

@Component({
  selector: 'rv-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss']
})
export class SettingsPageComponent implements OnInit {

	isLoading: boolean = false;
	activeChild: string = '';

	activateChild (evt) 
	{
		if (evt?.route)
			return this.router.navigate([evt.route]); 

		this.activeChild = evt?.component || ''
	}

  constructor (private router: Router, @Inject(USER_SETTINGS_CONFIG) public config: UserSettingsConfig) 
  {}

  ngOnInit (): void 
  {}
}

