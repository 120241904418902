import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FloatingCloudsWidgetComponent } from './floating-clouds-widget/floating-clouds-widget.component';
import { SharedModule } from './../../shared/shared.module';


@NgModule({
  declarations: [FloatingCloudsWidgetComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
  	FloatingCloudsWidgetComponent
  ]
})
export class FloatingCloudsModule { }
