import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MemoryCreatorWidgetComponent } from './memory-creator-widget/memory-creator-widget.component';
import { RearviewInterfaceModule } from 'src/app/shared/interface/rearview-interface.module';
import { LocationsModule } from 'src/app/widgets/locations/locations.module';
import { MemberModule } from 'src/app/widgets/member/member.module';
import { HashtagManagerModule } from 'src/app/widgets/hashtag-manager/hashtag-manager.module';

@NgModule({
  declarations: [
    MemoryCreatorWidgetComponent
  ],
  imports: [
    CommonModule,
    RearviewInterfaceModule,
    LocationsModule,
    MemberModule,
    HashtagManagerModule
  ],
  exports: [
  	MemoryCreatorWidgetComponent
  ]
})
export class MemoryCreatorModule { }
