import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'ngx-webstorage';
import { Preferences, KeysResult } from '@capacitor/preferences';

const isJson = (s: any) => 
{
	try { JSON.parse(s); return true; }
	catch (ex) { return false; }
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {

	private _isLocalStorageAvail: boolean = false;
	private _isSessionStorageAvail: boolean = false;
	protected _storageGroup: string = "_";

	async store (sKey: string, sVal: any): Promise<void>
	{
		return Promise.resolve(await Preferences.set({key: this.getSKey(sKey), value: this.setToStorage(sVal)}));
	}

	async retrieve (sKey: string, output?: string): Promise<any>
	{
		const value = (await Preferences.get({key: this.getSKey(sKey)}))?.value;

		if (typeof value === 'string' && isJson(value)) {
			if (output === 'object')
				return Promise.resolve(JSON.parse(`${value}`));
			if (output === 'string')
				return Promise.resolve(`${value}`);
		}

		if (output === 'object' && typeof value !== 'object')
			return Promise.reject();

		return Promise.resolve(value);
	}

	async clear (sKey: string, useSession?: boolean): Promise<void>
	{
		await Preferences.remove({key: this.getSKey(sKey)})
		return Promise.resolve();
	}

	async keys (): Promise<Array<string>>
	{
		const keys:KeysResult = await Preferences.keys();
		return Promise.resolve((Array.isArray(keys?.keys) ? keys.keys : []).map(key => `${key}`.replace(/^_:/,'')));
	}

	protected getSKey (sKey: string)
	{
		return this._storageGroup ? [this._storageGroup, sKey].join(":") : sKey;
	}

	protected setToStorage (sVal): string
	{
		if (typeof sVal === 'object')
			return JSON.stringify(sVal)

		if (sVal === null)
			return JSON.stringify(null);

		return `${sVal}`;
	}

	constructor ()
	{}
}