<div id="invite-tokens-page-component" class="container-fluid page-container-fluid" [ngClass]="{loaded: loaded}" rvHeaderTrigger>
	<div class="row vh-100">
		<div class="col-xs-24">
			<div class="invite-greeting">
				<h4 class="font-color--purple text-center">Hey, welcome to Rearview!</h4>
				<!-- <p>Rearview is the best way to save our milestones & share our story's with those that matter most.</p> -->
				<p>Looks like you've been invited to a Memory Chest.  Get started below!</p>
			</div>
		</div>

		<div class="col-xs-24">
			<div class="invite-body">
				<rv-component-loader-widget [is-loading]="isLoading">
					<div class="form-wrapper" [ngSwitch]="tokenState">


						<div style="padding:0 1rem;">
							<rv-alert name='invite-token' warning collapsible [message]="errorMsg$|async" />
						</div>


						<!-- INITIALIZED: Show authentication forms. -->
						<div *ngSwitchCase="'INITIALIZED'">
							<div class="social-signin" [rvDropdownContent]="showingSocial" name='social-signin' onExpandDone="onExpandDone('social')" onCollapseDone="onCollapseDone('social')">
								<rv-social-signin [InviteToken]="InviteToken" (onError)="onSocialLoginError($event)" style="visibility: visible;" />
								<rv-link open transparent label="Continue with email" (click)="showLegacy()" style="visibility: visible;" />
							</div>
			 				<div class="legacy-signin" [rvDropdownContent]="showingLegacy" name='legacy-signin' onExpandDone="onExpandDone('legacy')" onCollapseDone="onCollapseDone('legacy')">
								<rv-legacy-signin-form [InviteToken]="InviteToken" (onError)="onLegacyLoginError($event)" style="visibility: visible;" />
								<div class="row" style="visibility: visible;" >
									<div class="col-md-12">
										<rv-link open transparent label="Continue with Social" (click)="showSocial()" />
									</div>
									<div class="col-md-12">
										<rv-button open transparent label="Create My Account" />
									</div>
								</div>
							</div>
						</div>
						<!-- /INITIALIZED -->


						<!-- INIT_ERROR: Show alert component w/ error message. -->
						<div *ngSwitchCase="'INIT_ERROR'">
							<div class="row mt-4">
								<div class="col-md-12 text-center">
									<rv-link label="Go to Login" href='/login' iconRight="arrows.right-arrow" />
								</div>
								<!--
									<div class="col-md-12">
										<rv-button open transparent label="Create My Account" [routerLink]="['/register']" />
									</div>
								-->
							</div>
						</div>
						<!-- /INIT_ERROR -->


						<div *ngSwitchCase="'AUTHENTICATING'">
							Loading Spinner, Authenticationg
						</div>

						<div *ngSwitchCase="'USER_UNKNOWN'">
							Creating an account ... 
						</div>

						<div *ngSwitchCase="'USER_UNAUTHENTICATED'">
							Invalid password.
						</div>

						<div *ngSwitchCase="'USER_NOT_INVITED'">
							Error: yours not invited.
						</div>

						<div *ngSwitchCase="'USER_SET_IDENTITY'">
							Whats your name?
						</div>
					</div>
				</rv-component-loader-widget>
			</div>
		</div>

		<div class="col-xs-24">
			<div class="invite-footer">
				<h6 class="font-color--grey-500 text-center">Be a #MemoryMaker</h6>
			</div>
		</div>
		
	</div>

	<!--
		<div class="col-24">
			<rv-input iconLeft='user-interface.adjust' [formGroup]="inviterForm" controlName='relationship' placeholder="Relationship" />
		</div>

		relationship
		<rv-icon group='user-interface' icon='adjust-1'></rv-icon>

		email
		<rv-icon group='communication' icon='fast-message-1'></rv-icon>

		send invite
		<rv-icon group='user-interface' icon='paper-plane'></rv-icon>
	-->
</div>