<div id="memory-chests-page-component" class="container-fluid page-container-fluid" [ngClass]="{loaded: loaded}" rvHeaderTrigger>
	<div class="row" *ngIf="enableSearch">
		<div class="col-24">
			<rv-memory-chest-selector-widget></rv-memory-chest-selector-widget>
		</div>
	</div>
	<div class="row">
		<div class="col-24">
			<rv-memory-chest-creator-btn (onSuccess)="onChestCreated($event)"></rv-memory-chest-creator-btn>
		</div>
	</div>
	<div class="row">
		<div class="col-24 mt-1 mb-3">
			<div class="memory-chest-preview-card" *ngFor="let MemoryChest of chestsSrvc.Chests$|async">
				<chest-preview [MemoryChest]="MemoryChest"></chest-preview>
			</div>
		</div>
	</div>
</div>