import { Component, Input, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'rv-form-control-error',
  template: `<div class="control-error" [ngClass]="{'show': showErr}">{{errMsg}}</div>`,
  styleUrls: ['./form-control-error.component.scss'],
 	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormControlErrorComponent {
 public errMsg: string;
 public showErr: boolean = false;

 @Input() set text(value) {
   if (value !== this.errMsg) {
     this.errMsg 	= value;
     this.showErr = !!value;
     this.cdr.detectChanges();
   }
 };

  constructor (private cdr: ChangeDetectorRef) 
  {}
}
