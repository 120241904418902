import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthRoutingModule } from './auth-routing.module';
import { LogoModule } from './../../widgets/logo/logo.module';
import { SwayingLandscapeModule } from './../../widgets/swaying-landscape/swaying-landscape.module';
import { FloatingCloudsModule } from './../../widgets/floating-clouds/floating-clouds.module';
import { AuthPageComponent } from './auth-page/auth-page.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { LogoutFormComponent } from './logout-form/logout-form.component';
import { SignUpFormComponent } from './signup-form/signup-form.component';
import { ForgotPasswordFormComponent } from './forgot-password-form/forgot-password-form.component';
import { ForgotPasswordConfirmFormComponent } from './forgot-password-confirm-form/forgot-password-confirm-form.component';
import { SignUpConfirmFormComponent } from './signup-confirm-form/signup-confirm-form.component';
import { NotificationsComponent } from './shared/notifications/notifications.component';
import { SocialLoginModule } from '@abacritt/angularx-social-login';
import { GoogleSigninButtonDirective } from './shared/google-signin-button.directive';
import { SocialLoginComponent } from './social-login/social-login.component';
import { SocialSignupComponent } from './social-signup/social-signup.component';
import { RearviewInterfaceModule } from './../../shared/interface/rearview-interface.module';
import { AuthenticationModule } from 'src/app/shared/authentication/authentication.module';

@NgModule({
  declarations: [AuthPageComponent, LoginFormComponent, LogoutFormComponent, SignUpFormComponent, ForgotPasswordFormComponent, ForgotPasswordConfirmFormComponent, SignUpConfirmFormComponent, NotificationsComponent, GoogleSigninButtonDirective, SocialLoginComponent, SocialSignupComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule,
		LogoModule,
		SwayingLandscapeModule,
		FloatingCloudsModule,
		SharedModule,
		SocialLoginModule,
		RearviewInterfaceModule,
		AuthenticationModule
  ]
})
export class AuthModule { }
