import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { IntersectionObserverModule } from '@ng-web-apis/intersection-observer';
import { FloatingControlsModule } from './../../widgets/floating-controls/floating-controls.module';
import { MemoryChestPageComponent } from './memory-chest-page/memory-chest-page.component';
import { MemoryChestRoutingModule } from './memory-chest.routing';
import { RearviewInterfaceModule } from 'src/app/shared/interface/rearview-interface.module';
import { MemoryChestSelectorModule } from './../../widgets/memory-chest-selector/memory-chest-selector.module';
import { MemoryChestMemberModule } from 'src/app/widgets/memory-chest-member/memory-chest-member.module';
import { MemoryChestMembersComponent } from './memory-chest-members/memory-chest-members.component';
import { DropdownMenuModule } from 'src/app/widgets/dropdown-menu/dropdown-menu.module';
import { MilestonePreviewComponent } from './milestone-preview/milestone-preview.component';
import { MemoryChestSearchComponent } from './memory-chest-search/memory-chest-search.component';
import { MilestoneDateIndicatorComponent } from './milestone-date-indicator/milestone-date-indicator.component';
import { MemoryChestPreviewService } from './models/memory-chest-preview.service';
import { MilestoneComponent } from './milestone/milestone.component';
import { MilestoneHashtagsComponent } from './milestone-hashtags/milestone-hashtags.component';
import { MilestoneMembersComponent } from './milestone-members/milestone-members.component';
import { NoMemoriesComponent } from './no-memories/no-memories.component';
import { NoMilestonesComponent } from './no-milestones/no-milestones.component';
import { PreviewImgComponent } from './preview-img/preview-img.component';
import { MemoryChestInviterModule } from 'src/app/widgets/memory-chest-inviter/memory-chest-inviter.module';
import { MilestoneDeleterModule } from 'src/app/widgets/milestone-deleter/milestone-deleter.module';
import { MilestoneEditorModule } from 'src/app/widgets/milestone-editor/milestone-editor.module';
import { MilestoneLocationCreatorModule } from 'src/app/widgets/milestone-location-creator/milestone-location-creator.module';


@NgModule({
  declarations: [
    MemoryChestPageComponent,
    MemoryChestMembersComponent,
    MilestoneComponent,
    MilestonePreviewComponent,
    MemoryChestSearchComponent,
    MilestoneDateIndicatorComponent,
    MilestoneMembersComponent,
    MilestoneHashtagsComponent,
		NoMemoriesComponent,
		NoMilestonesComponent,
		PreviewImgComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MemoryChestRoutingModule,
    RearviewInterfaceModule,
    DirectivesModule,
    DropdownMenuModule,
    MemoryChestSelectorModule,
    IntersectionObserverModule,
    MemoryChestMemberModule,
    MemoryChestInviterModule,
    MilestoneDeleterModule,
    MilestoneEditorModule,
    MilestoneLocationCreatorModule
  ],
  providers: [
  	MemoryChestPreviewService
  ]
})
export class MemoryChestModule { }
