import { Injectable } from '@angular/core';
import { MemoryChestPreviewService } from './../models/memory-chest-preview.service';
import { MemoryChestService } from 'src/app/models/memories/memory-chest.service';
import { MemberService } from 'src/app/models/memories/member.service';
import { ModelInstance } from '@getrearview/model-builder';
import { BehaviorSubject, Observable } from 'rxjs';

interface MilestonePreview {
	images: Array<string>;
	media_types: Array<string>;
}

@Injectable({
  providedIn: 'root'
})
export class ChestsService {

	private _Chests: Array<ModelInstance> = [];
	private _Chests$: BehaviorSubject<Array<ModelInstance>> = new BehaviorSubject([]);

	get Chests$ (): Observable<Array<ModelInstance>>
	{
		return this._Chests$.asObservable();
	}

	get Chests (): Array<ModelInstance>
	{
		return this._Chests;
	}

	set Chests (Chests: Array<ModelInstance>)
	{
		this._Chests$.next(this._Chests = Chests);
	}

	async fetch (Profile: ModelInstance): Promise<void>
	{
		this.Chests = (await this.MemoryChestPreviewSrvc.search({})||[]);
		return Promise.resolve();
	}

	replace (Replacement: ModelInstance): void
	{
		const replacementId = `${Replacement.id()}`;
		this.Chests = this.Chests.map(Chest => `${Chest.id()}` === replacementId ? Replacement : Chest);
	}

	remove (Deleted: ModelInstance): void
	{
		const deletedId = `${Deleted.id()}`;
		this.Chests = this.Chests.filter(Chest => `${Chest.id()}` !== deletedId);
	}

	constructor (private MemberSrvc: MemberService, private MemoryChestSrvc: MemoryChestService, public MemoryChestPreviewSrvc: MemoryChestPreviewService) 
	{}
}
