import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { ReactiveFormsModule } from '@angular/forms';
import { HeaderModule } from './../../widgets/header/header.module';
import { FeedRoutingModule } from './feed-routing.module';
import { IntersectionObserverModule } from '@ng-web-apis/intersection-observer';
import { FloatingControlsModule } from './../../widgets/floating-controls/floating-controls.module';
import { FeedPageComponent } from './feed-page/feed-page.component';
import { FeedMilestoneComponent } from './feed-milestone/feed-milestone.component';
import { FeedMilestoneHashtagsComponent } from './feed-milestone-hashtags/feed-milestone-hashtags.component';
import { FeedMilestoneMembersComponent } from './feed-milestone-members/feed-milestone-members.component';
import { FeedMilestonePreviewComponent } from './feed-milestone-preview/feed-milestone-preview.component';
import { RearviewInterfaceModule } from './../../shared/interface/rearview-interface.module';
import { PreviewImgComponent } from './preview-img/preview-img.component';
import { MemoryChestSelectorModule } from './../../widgets/memory-chest-selector/memory-chest-selector.module';
import { FeedDateIndicatorComponent } from './feed-date-indicator/feed-date-indicator.component';
import { FeedMilestoneService } from './models/feed-milestone.service';
import { FeedService } from './shared/feed.service';
import { ActiveMemoryChestPipe } from './shared/active-memory-chest.pipe';
import { HashtagManagerModule } from 'src/app/widgets/hashtag-manager/hashtag-manager.module';
import { FeedChestSearchComponent } from './feed-chest-search/feed-chest-search.component';
import { FeedChestMembersComponent } from './feed-chest-members/feed-chest-members.component';
import { MemoryChestMemberModule } from 'src/app/widgets/memory-chest-member/memory-chest-member.module';
import { MemberModule } from 'src/app/widgets/member/member.module';
import { NoMilestonesComponent } from './no-milestones/no-milestones.component';
import { NoMemoriesComponent } from './no-memories/no-memories.component';
import { DropdownMenuModule } from 'src/app/widgets/dropdown-menu/dropdown-menu.module';
import { LocationsModule } from 'src/app/widgets/locations/locations.module';
import { FilterMemoryChestSelectorComponent } from './filter-memory-chest-selector/filter-memory-chest-selector.component';
import { FilterMemberSelectorComponent } from './filter-member-selector/filter-member-selector.component';
import { FeedFilterComponent } from './feed-filter/feed-filter.component';
import { MemoryChestInviterModule } from 'src/app/widgets/memory-chest-inviter/memory-chest-inviter.module';

@NgModule({
  declarations: [FeedPageComponent, FeedMilestoneComponent, FeedMilestoneHashtagsComponent, FeedMilestoneMembersComponent, FeedMilestonePreviewComponent, PreviewImgComponent, FeedDateIndicatorComponent, ActiveMemoryChestPipe, FeedChestSearchComponent, FeedChestMembersComponent, NoMilestonesComponent, NoMemoriesComponent, FilterMemoryChestSelectorComponent, FilterMemberSelectorComponent, FeedFilterComponent],
  imports: [
    CommonModule,
    FeedRoutingModule,
    ReactiveFormsModule,
    RearviewInterfaceModule,
    MemoryChestSelectorModule,
    IntersectionObserverModule,
    HeaderModule,
    HashtagManagerModule,
    MemoryChestMemberModule,
    FloatingControlsModule,
    DirectivesModule,
    DropdownMenuModule,
    LocationsModule,
    MemberModule,
    MemoryChestInviterModule
  ],
  providers: [
  	FeedMilestoneService,
  	FeedService
  ]
})
export class FeedModule { }
