import { Component, Input } from '@angular/core';
import { FeedService } from './../shared/feed.service';

@Component({
  selector: 'rv-no-memories',
  templateUrl: './no-memories.component.html',
  styleUrls: ['./no-memories.component.scss']
})
export class NoMemoriesComponent {

	@Input('chestId')
	chestId: string;

	get showMe (): boolean
	{
		return this.feedSrvc.getIsMemoryChestEmpty(this.chestId);
	}

	constructor (public feedSrvc: FeedService)
	{}
}