<div id="payment-method-manager-component">
	<form #f="ngForm" id="payment-form" (ngSubmit)="onSubmit($event)">
	  <div id="card-element">
	    <!-- Elements will create input elements here -->
	  </div>

	  <!-- We'll put the error messages in this element -->
	  <div id="card-errors" role="alert"></div>

	  <button id="submit">Submit Payment</button>
	</form>
	<hr />
	<ul>
		<li *ngFor="let pm of paymentMethods">
			<div class="card-brand">{{pm?.card?.brand}}</div>
			<div class="card-last4">{{pm?.card?.last4}}</div>
			<div class="card-exp">{{pm?.card?.exp_month}}/{{pm?.card?.exp_year}}</div>
			<div class="card-btn">
				<button [ngDisabled]="!!isSaving || !!isDeleting" [ngClass]="{'is-active': pm?.is_default}" (click)="!pm?.is_default && setAsDefault(pm?.id)" mat-icon-button>
					<mat-icon *ngIf="!isSaving || isSaving != pm?.id">credit_card</mat-icon>
					<mat-spinner *ngIf="isSaving == pm?.id" [diameter]="20"></mat-spinner>
      	</button>
			</div>
			<div class="card-btn">
				<button [ngDisabled]="pm?.is_default || !!isSaving || !!isDeleting" (click)="!pm?.is_default && deletePaymentMethod(pm?.id)" mat-icon-button>
					<mat-icon *ngIf="!isDeleting || isDeleting != pm?.id">delete</mat-icon>
					<mat-spinner *ngIf="isDeleting == pm?.id" [diameter]="20"></mat-spinner>
      	</button>
			</div>
		</li>
	</ul>
</div>
