import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MemoryWidgetComponent } from './memory-widget/memory-widget.component';

@NgModule({
  declarations: [MemoryWidgetComponent],
  imports: [
    CommonModule
  ],
  exports: [
  	MemoryWidgetComponent
  ]
})
export class MemoryModule { }