import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MemoryChestSelectorWidgetComponent } from './memory-chest-selector-widget/memory-chest-selector-widget.component';
import { ChestsListComponent } from './chests-list/chests-list.component';
import { RearviewInterfaceModule } from './../../shared/interface/rearview-interface.module';
import { MemberModule } from 'src/app/widgets/member/member.module';

@NgModule({
  declarations: [MemoryChestSelectorWidgetComponent, ChestsListComponent],
  imports: [
    CommonModule,
    RearviewInterfaceModule,
    RouterModule,
    MemberModule
  ],
  exports: [
  	MemoryChestSelectorWidgetComponent
  ]
})
export class MemoryChestSelectorModule { }
