import { Component, Input } from '@angular/core';
import { ModalService } from './../shared/modal.service';

@Component({
  selector: 'rv-modal-close-btn',
  templateUrl: './modal-close-btn.component.html',
  styleUrls: ['./modal-close-btn.component.scss']
})
export class ModalCloseBtnComponent {

	@Input()
	icon!: string;

	get iconGroup (): string
	{
		return this.icon ? `${this.icon}`.split('.').shift() : ''
	}

	get iconName (): string
	{
		return this.icon ? `${this.icon}`.split('.').pop() : ''
	}

	constructor (public modalService: ModalService) 
	{}
}
