import { Component } from '@angular/core';
import { ModalService } from './../shared/modal.service';

@Component({
  selector: 'rv-modal-widget',
  templateUrl: './modal-widget.component.html',
  styleUrls: ['./modal-widget.component.scss']
})
export class ModalWidgetComponent {
  constructor (public modalService: ModalService)
  {}
}