import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiIconModule } from './../ui-icon/ui-icon.module';
import { UiButtonWidgetComponent } from './ui-button-widget/ui-button-widget.component';

@NgModule({
  declarations: [UiButtonWidgetComponent],
  imports: [
    CommonModule,
    UiIconModule
  ],
  exports: [
  	UiButtonWidgetComponent
  ]
})
export class UiButtonModule { }
