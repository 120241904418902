import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NativeInterfaceService } from './native-interface.service';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule
  ],
  providers: [
  	NativeInterfaceService
  ]
})
export class NativeModule { }