<div id="legacy-signin-form-component" class="container-fluid auth-form-component">
	<div class="row">
		<div class="col-24">
			<form [formGroup]="signinForm" class="rv-form rv-auth-form rv-form-bg-blue" name="signinForm" layout layout-align="center" layout-padding novalidate (ngSubmit)="handleLogin()" #signinFormRef [ngClass]="{'is-submitting': isSubmitting}">

				<div class="inputs mb-2">
					<div class="form-group">
						<rv-input class="form-control" type="text" [formGroup]="signinForm" [control]="signinForm.get('email_or_phone')" controlName='email_or_phone' required placeholder="Email or Phone" />
					</div>
					<div class="form-group" [ngClass]="{'show-manual-error': hasManualError()}">
						<rv-input class="form-control" type="password" [formGroup]="signinForm" [control]="signinForm.get('password')" controlName='password' required placeholder="Password" />
						<span *ngIf="isManualError('UNKNOWN_ERROR')" class="manual-error">Unable to reach server</span>
						<span *ngIf="isManualError('INVALID_PASS')" class="manual-error">Invalid Password</span>
						<span *ngIf="isManualError('TOO_MANY_ATTEMPTS')" class="manual-error">Too Many Attempts.  Try again later.</span>
					</div>
				</div>

				<div class="buttons mb-5">
					<rv-button sm [disabled]="isSubmitting" class="btn-block" #submitBtnRef type='submit' aria-label="">
				  	<div class="btn-content" style="padding: 0 2rem;">
				  		<div class="btn-label" [innerHTML]="isSubmitting ? 'Logging In ' : 'Login'"></div>
				  		<!-- <div class="btn-spinner">(spin)</div> -->
				  	</div>
					</rv-button>
				</div>

				<!--
					<rv-button outline sm (click)="goToLogin()" label='Use Social Login'></rv-button>
					<rv-button outline sm (click)="goToForgotPassword()" label='Forgot Password'></rv-button>
				-->
			</form>

			<!-- <rv-auth-notifications></rv-auth-notifications> -->
		</div>
	</div>
</div>