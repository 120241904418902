import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { InviteToken } from './invite-token.class';

@Injectable({ 
  providedIn: 'root' 
})
export class InviteTokensGuard implements CanActivate {

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean>
  {
		return true;
  }

  constructor(private router: Router, private InviteToken: InviteToken) 
  {}
}