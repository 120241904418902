import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewSelectorWidgetComponent } from './view-selector-widget/view-selector-widget.component';



@NgModule({
  declarations: [ViewSelectorWidgetComponent],
  imports: [
    CommonModule
  ],
  exports: [
  	ViewSelectorWidgetComponent
  ]
})
export class ViewSelectorModule { }
