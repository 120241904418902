import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { InviteTokensRoutingModule } from './invite-tokens-routing.module';
import { InviteTokensPageComponent } from './invite-tokens-page/invite-tokens-page.component';
import { AuthenticationModule } from 'src/app/shared/authentication/authentication.module';
import { HeaderModule } from 'src/app/widgets/header/header.module';
import { RearviewInterfaceModule } from 'src/app/shared/interface/rearview-interface.module';
import { LoadersModule } from 'src/app/widgets/loaders/loaders.module';

@NgModule({
  declarations: [
    InviteTokensPageComponent
  ],
  imports: [
    CommonModule,
    LoadersModule,
    InviteTokensRoutingModule,
    AuthenticationModule,
    HeaderModule,
    DirectivesModule,
    RearviewInterfaceModule
  ]
})
export class InviteTokensModule { }
