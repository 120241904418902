import { Injectable } from '@angular/core';
import { FeedMilestoneService } from './../models/feed-milestone.service';
import { MilestonePreviewService } from './../models/milestone-preview.service';
import { SessionUser } from 'src/app/core/session/session-user.model';
import { UserService } from 'src/app/models/users/user.service';
import { MemoryChestService } from 'src/app/models/memories/memory-chest.service';
import { MemberService } from 'src/app/models/memories/member.service';
import { ModelInstance } from '@getrearview/model-builder';
import { BehaviorSubject, Observable } from 'rxjs';

interface MilestonePreview {
	images: Array<string>;
	media_types: Array<string>;
}

@Injectable({
  providedIn: 'root'
})
export class FeedService {

	private _filterSources: {
		MemoryChests: BehaviorSubject<Array<ModelInstance>>;
		Members: BehaviorSubject<Array<ModelInstance>>;
		Hashtags: BehaviorSubject<Array<ModelInstance>>;
	} = {
		MemoryChests: new BehaviorSubject([]),
		Members: new BehaviorSubject([]),
		Hashtags: new BehaviorSubject([])
	};

	MemoryChests: Array<ModelInstance> = [];
	Me: ModelInstance;
	private _Milestones: Array<ModelInstance> = [];
	private _Milestones$: BehaviorSubject<Array<ModelInstance>> = new BehaviorSubject([]);
	private _Members: Array<ModelInstance> = [];
	private _Members$: BehaviorSubject<Array<ModelInstance>> = new BehaviorSubject([]);
	private _Previews$: {[milestoneId: string]: BehaviorSubject<ModelInstance>} = {};
	private _monthYears: {[milestoneId: string]: string} = {};

	doMilestonesExist (chestId?: string): boolean
	{
		return this._Milestones.filter(M => !chestId || M.get('chest_id') === chestId).length > 0;
	}

	getIsMemoryChestEmpty (chestId: string): boolean
	{
		return this._Milestones.length === 0;
	}

	getIsMemoryChestsEmpty (): boolean
	{
		return this._Milestones.length === 0;
	}

	get filterSources$ (): Observable<{MemoryChests: Array<ModelInstance>, Members: Array<ModelInstance>, Hashtags: Array<ModelInstance>}>
	{
		return 
	}

	get isMemoryChestsEmpty (): boolean
	{
		return this._Milestones.length === 0;
	}

	get isMilestoneEmpty (): boolean
	{
		return this._Milestones.length === 0;
	}

	get monthYears (): {[milestoneId: string]: string}
	{
		return this._monthYears;
	}

	get Milestones$ (): Observable<Array<ModelInstance>>
	{
		return this._Milestones$.asObservable();
	}

	get Milestones (): Array<ModelInstance>
	{
		return this._Milestones;
	}

	set Milestones (Milestones: Array<ModelInstance>)
	{
		let monthYears = {};
		Milestones.forEach(Milestone => {
			let d: string = [new Date(Milestone.attribs.m_birth).getMonth()+1, new Date(Milestone.attribs.m_birth).getFullYear()].join('/');
			if (!Object.values(monthYears).includes(d))
				monthYears[Milestone.id()] = d;
		});
		this._monthYears = monthYears;

		this._Milestones$.next(this._Milestones = this.Milestones.length === 0 ? this.Milestones.concat(Milestones) : Milestones);
	}

	get Members$ (): Observable<Array<ModelInstance>>
	{
		return this._Members$.asObservable();
	}

	get Members (): Array<ModelInstance>
	{
		return this._Members;
	}

	set Members (Members: Array<ModelInstance>)
	{
		this._Members$.next(this._Members = this.Members.concat(Members));
	}

	onPreview (milestoneId: string): Observable<ModelInstance>
	{
		if (milestoneId && !this._Previews$.hasOwnProperty(milestoneId))
			this._Previews$[milestoneId] = new BehaviorSubject(undefined);

		return this._Previews$[milestoneId];
	}

	async preview (Milestone: ModelInstance): Promise<void>
	{
		try {
			const Preview = await this.MilestonePreviewSrvc.factory().fetch(Milestone.id()) as ModelInstance;

			if (!this._Previews$.hasOwnProperty(Milestone.id())) {
				this._Previews$[Milestone.id()] = new BehaviorSubject(Preview);
				return Promise.resolve();
			}
			else if (!this._Previews$[Milestone.id()].getValue()) 
				this._Previews$[Milestone.id()].next(Preview);
		}
		catch (ex) {
			console.error(`${ex}`);
			return Promise.reject();
		}

		return Promise.resolve();
	}

	get filterMemoryChests$ (): BehaviorSubject<Array<ModelInstance>>
	{
		return this._filterSources.MemoryChests;
	}

	get filterMembers$ (): BehaviorSubject<Array<ModelInstance>>
	{
		return this._filterSources.Members;
	}

	get filterHashtags$ (): BehaviorSubject<Array<ModelInstance>>
	{
		return this._filterSources.Hashtags;
	}

	async fetchFilters (): Promise<void>
	{
		const MemoryChests = {},
					Members = {},
					Hashtags = {};

		(await this.MemoryChestSrvc.search({pagination: false, _relationships: ['member','tag']}, {do_not_save: true})||[]).forEach((MemoryChest: ModelInstance) => {
			MemoryChests[MemoryChest.id()] = MemoryChest;
			Object.values(MemoryChest.getChildren('member')||{}).forEach((Member: ModelInstance) => Members[Member.id()] = Member);
			Object.values(MemoryChest.getChildren('tag')||{}).forEach((Hashtag: ModelInstance) => Hashtags[Hashtag.id()] = Hashtag);
		});

		this._filterSources.MemoryChests.next(Object.values(MemoryChests));
		this._filterSources.Members.next(Object.values(Members));
		this._filterSources.Hashtags.next(Object.values(Hashtags));

		return Promise.resolve();
	}

	async fetchFeed (chestId?: string, searchParams?: {[key: string]: any}): Promise<void>
	{
		let Milestones: Array<ModelInstance> = [],
				Members: Array<ModelInstance> = [];

		searchParams = searchParams && typeof searchParams === 'object' ? searchParams : {};

		/* 
		------------------------------------------------------------------------------------

		Searching Examples (More to follow):

		// searching hashtags
		searchParams._relationships = 
		{
			tag: {id: ... } // get from models passed back from <rv-hashtag-selector [chestId]='chestId' />
		}

		// searching members
		// i expect this one to fail for relationship definition not complete/accurate.  this is easy fix if so.
		searchParams._relationships = 
		{
			member: {id: ... } // get from models passed back from <rv-member-selector [chestId]='chestId' />
		}

		------------------------------------------------------------------------------------
		*/

		try {
			const [M,S] = await Promise.all([
						this.FeedMilestoneSrvc.search({...searchParams, ...(chestId?{chest_id:chestId}:{})}),
						chestId?this.MemberSrvc.search({chest_id:chestId}):Promise.resolve([])
					]);

			Milestones 	= 	Array.isArray(M) ? M : [];
			Members 		= 	Array.isArray(S) ? S : [];
		}
		catch (ex) {
			console.error(`${ex}`);
			return Promise.reject();
		}

		this.Milestones = Milestones;
		this.Members = Members;

		(await this.MemoryChestSrvc.search({pagination: false}, {do_not_save: true})||[]).forEach((MemoryChest: ModelInstance) => {
			if (this.MemoryChests.map(MC => MC.id()).includes(MemoryChest.id()))
				return;
			this.MemoryChests.push(MemoryChest);
		});

		return Promise.resolve();
	}

	async authenticateInvitor (chestId: string, milestoneId: string): Promise<{status: boolean, MemoryChest?:ModelInstance, Milestone?: ModelInstance, Member?: ModelInstance}>
	{
		const MemoryChest: ModelInstance = await this.MemoryChestSrvc.search({chest_id: chestId, pagination: false, _relationships: {milestone: {id: milestoneId}, member: {user_id: this.User.id}}}, {do_not_store: true}).then(res => (res.models.shift() as ModelInstance));
		if (!MemoryChest)
			return Promise.resolve({status: false});


		const Member: ModelInstance = (MemoryChest && MemoryChest?.getChildren('member'))
			? Object.values(MemoryChest.getChildren('member')).filter((Member: ModelInstance) => Member.attribs.user_id === this.User.id).shift() as ModelInstance
			: undefined;


		const Milestone: ModelInstance = (MemoryChest && MemoryChest?.getChildren('milestone'))
			? Object.values(MemoryChest.getChildren('milestone')).filter((Milestone: ModelInstance) => Milestone.id() === milestoneId).shift() as ModelInstance
			: undefined;


		if (Member && ['owner','admin'].includes(Member.attribs.member_type))
			return {status: true, MemoryChest, Milestone, Member};


		return {status: false};
	}

	getMeByChestId (chestId: string): ModelInstance
	{
		return this.Members.filter(Member => Member.get('user_id') === this.User.id).shift() as ModelInstance;
	}

	reset (): void
	{
		this._Milestones = [];
		this._Milestones$.next([]);
		this._Members = [];
		this._Members$.next([]);
		this._Previews$ = {};
		this._monthYears = {};
	}

	constructor (private FeedMilestoneSrvc: FeedMilestoneService, private UserSrvc: UserService, private MilestonePreviewSrvc: MilestonePreviewService, private MemberSrvc: MemberService, private MemoryChestSrvc: MemoryChestService, private User: SessionUser) 
	{}
}
