import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InterfaceCoreModule } from './shared/interface-core.module';
import { UiButtonModule } from './ui-button/ui-button.module';
import { UiButtonWidgetComponent } from './ui-button/ui-button-widget/ui-button-widget.component';
import { UiIconModule } from './ui-icon/ui-icon.module';
import { UiIconWidgetComponent } from './ui-icon/ui-icon-widget/ui-icon-widget.component';
import { UiImgModule } from './ui-img/ui-img.module';
import { UiImgWidgetComponent } from './ui-img/ui-img-widget/ui-img-widget.component';
import { UiInputModule } from './ui-input/ui-input.module';
import { UiInputWidgetComponent } from './ui-input/ui-input-widget/ui-input-widget.component';
import { UiInputCheckboxModule } from './ui-input-checkbox/ui-input-checkbox.module';
import { UiInputCheckboxWidgetComponent } from './ui-input-checkbox/ui-input-checkbox-widget/ui-input-checkbox-widget.component';
import { UiFormModule } from './ui-form/ui-form.module';
import { UiFormWidgetComponent } from './ui-form/ui-form-widget/ui-form-widget.component';
import { UiTextareaModule } from './ui-textarea/ui-textarea.module';
import { UiTextareaWidgetComponent } from './ui-textarea/ui-textarea-widget/ui-textarea-widget.component';
import { UiMultiSelectModule } from './ui-multi-select/ui-multi-select.module';
import { UiMultiSelectWidgetComponent } from './ui-multi-select/ui-multi-select-widget/ui-multi-select-widget.component';
import { UiDatepickerModule } from './ui-datepicker/ui-datepicker.module';
import { UiDatepickerWidgetComponent } from './ui-datepicker/ui-datepicker-widget/ui-datepicker-widget.component';
import { UiAlertWidgetComponent } from './ui-alert/ui-alert-widget/ui-alert-widget.component';
import { UiAlertModule } from './ui-alert/ui-alert.module';
import { UiLinkWidgetComponent } from './ui-link/ui-link-widget/ui-link-widget.component';
import { UiLinkModule } from './ui-link/ui-link.module';
import { DropdownContentDirective } from './shared/directives/dropdown-content.directive';
import { DropdownContainerDirective } from './shared/directives/dropdown-container.directive';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    InterfaceCoreModule,
		UiIconModule,
		UiButtonModule,
		UiInputModule,
		UiImgModule,
		UiInputCheckboxModule,
		UiFormModule,
		UiMultiSelectModule,
		UiTextareaModule,
  	UiDatepickerModule,
  	UiAlertModule,
   	UiLinkModule
  ],
  exports: [
		UiButtonWidgetComponent,
		UiIconWidgetComponent,
		UiImgWidgetComponent,
		UiInputWidgetComponent,
		UiInputCheckboxWidgetComponent,
		UiFormWidgetComponent,
		UiTextareaWidgetComponent,
		UiMultiSelectWidgetComponent,
		UiDatepickerWidgetComponent,
		UiAlertWidgetComponent,
		UiLinkWidgetComponent,
  	DropdownContentDirective,
  	DropdownContainerDirective
  ]
})
export class RearviewInterfaceModule { }