<div id="profile-form-component">
	<rv-component-loader-widget [is-loading]="isLoading">
		<form [formGroup]="profileForm" class="container-fluid rv-form rv-profile-form rv-form-bg-blue" name="profileForm" layout layout-align="center" layout-padding novalidate (ngSubmit)="handleSubmit()" #profileFormRef>
			<div class="row">
				<div class="col-24">
					<div class="form-group" [ngClass]="{'show-manual-error': hasManualError()}">
						<rv-input [formGroup]="profileForm" label="name" type="type" isReactive="false" controlName="name" placeholder="name" />
						<span *ngIf="isManualError('UNKNOWN_ERROR')" class="manual-error">Unable to reach server</span>
						<span *ngIf="isManualError('INVALID_PASS')" class="manual-error">Invalid</span>
					</div>
					<div class="form-group" [ngClass]="{'show-manual-error': hasManualError()}">
						<rv-input [formGroup]="profileForm" label="email" type="email" isReactive="false" controlName="email" placeholder="email" />
					</div>
					<div class="form-group" [ngClass]="{'show-manual-error': hasManualError()}">
						<rv-input [formGroup]="profileForm" label="phone" type="telephone" isReactive="false" controlName="phone"  placeholder="phone" />
					</div>
					<rv-button type="submit" [purple]="profileForm.valid && profileForm.dirty" [gray]="!(profileForm.valid && profileForm.dirty)" md block label="Save Profile" />
				</div>
			</div>
		</form>
	</rv-component-loader-widget>
</div>