import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiRoutingModule } from './ui-routing.module';
import { UiComponent } from './ui/ui.component';
import { UiWidgetSelectorComponent } from './ui-widget-selector/ui-widget-selector.component';
import { UiButtonsComponent } from './ui-buttons/ui-buttons.component';
import { UiColorsComponent } from './ui-colors/ui-colors.component';
import { UiColorCardComponent } from './ui-colors/ui-color-card.component';
import { UiInputsComponent } from './ui-inputs/ui-inputs.component';
import { UiFontsComponent } from './ui-fonts/ui-fonts.component';
import { UiIconsComponent } from './ui-icons/ui-icons.component';
import { UiPaginationComponent } from './ui-pagination/ui-pagination.component';
import { UiImgsComponent } from './ui-imgs/ui-imgs.component';

import { RearviewInterfaceModule } from './../../shared/interface/rearview-interface.module'
import { DropdownMenuModule } from './../../widgets/dropdown-menu/dropdown-menu.module'
import { SharedModule } from './../../shared/shared.module';
import { NgxCopyPasteDirective } from 'ngx-copypaste';
import { UiUploaderComponent } from './ui-uploader/ui-uploader.component';
import { UiMultiSelectComponent } from './ui-multi-select/ui-multi-select.component';

import { HashtagManagerModule } from 'src/app/widgets/hashtag-manager/hashtag-manager.module'
import { MemberModule } from 'src/app/widgets/member/member.module';
import { LocationsModule } from 'src/app/widgets/locations/locations.module';
import { UiLocationsComponent } from './ui-locations/ui-locations.component'
import { GoogleMapsModule } from '@angular/google-maps'


@NgModule({
  declarations: [UiComponent, UiWidgetSelectorComponent, UiButtonsComponent, UiColorsComponent, UiColorCardComponent, UiInputsComponent, UiFontsComponent, UiPaginationComponent, UiIconsComponent, UiImgsComponent, UiUploaderComponent, UiMultiSelectComponent, UiLocationsComponent],
  imports: [
    CommonModule,
    UiRoutingModule,
    SharedModule,
    LocationsModule,
    HashtagManagerModule,
    NgxCopyPasteDirective,
    RearviewInterfaceModule,
    DropdownMenuModule,
    MemberModule,
    GoogleMapsModule
  ]
})
export class UiModule { }
