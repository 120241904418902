import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[rvDropdownContainer]'
})
export class DropdownContainerDirective {

	@Input() rvDropdownContainer;

  constructor () 
  {}
}
