import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModelFactoryConfig, ModelService } from '@getrearview/model-builder';
import { ConfigService } from './../../shared/services/config.service';

@Injectable({
  providedIn: 'root'
})
export class MilestoneLocationService extends ModelService {

  constructor (injector: Injector, httpClient: HttpClient) 
  {
  	let ConfigSrvc: ConfigService = injector.get<any>(ConfigService);
		let MFC: ModelFactoryConfig = (new ModelFactoryConfig()).setAll({
  				// Model Name
  				name: "Milestone Location",
  				// Model Endpoint
					endpoint: ConfigSrvc.get("api.api_url")+"/memories/memories/milestone-locations",
					// Model Fields
					fields: ['milestone_id', 'author_id', 'coordinates', 'place_id', 'start_at', 'end_at', 'nickname', 'is_initial'],
					// Model `Permitted` Fields
					permitted: [],
					// Model `Required` Fields
					required: [],
					// Model Field Labels
					labels: {},
					// Model Field Validation Patterns
					patterns: {},
					// Parent ID Field Name
					parent_id_field: "milestone_id",
					// Model Children Types
					children_types: {},
					// Model Association Types
					association_types: {},
					// Storage Type (local, session, cookies)
					storage_type: "local"
				});

  	super(MFC, httpClient);
  }
}