import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SessionUser } from './../../../core/session/session-user.model';
import { ProfileService } from './../../../models/users/profile.service';
import { ModelInstance } from '@getrearview/model-builder';
import { environment } from 'src/environments/environment';
import EMAIL_PATTERN from 'src/app/shared/patterns/email.pattern';

const PROFILE_IMG_URL = `${environment.api.media_url}/users/{id}/profile.jpg`,
			getProfileUrl = (Profile: ModelInstance): string => (Profile && Profile?.id() ? PROFILE_IMG_URL.replace('{id}',`${Profile.id()}`) : '');

@Component({
  selector: 'rv-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss']
})
export class ProfileFormComponent implements OnInit {

	@Input()
	Profile: ModelInstance;

	isLoading: boolean = false;
	isSaving: boolean = false;
	profileUrl: string;
	profileForm = this.fb.group({
		name: 				[null, [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
		email: 				[null, [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
		phone: 				[null],
	});

	async handleSubmit ()
	{
		if (!this.profileForm.valid) 
			return;

		this.isSaving = true;

		const Profile: ModelInstance = this.ProfileSrvc.get();
		Profile.attribs.name = `${this.profileForm.get('name').value}`.trim();
		Profile.attribs.email = `${this.profileForm.get('email').value}`.trim();
		Profile.attribs.phone = this.profileForm.get('phone').value ? `${this.profileForm.get('phone').value}`.replace(/\D/g,'') : Profile.attribs.phone;
		await Profile.save();

		this.ProfileSrvc.set(Profile);
		this.ProfileSrvc.store();

		this.isSaving = false;
	}
	
	setProfile ()
	{
		this.profileForm.get('email').disable();
  	this.profileForm.get('name').setValue(this.Profile.attribs.name);
  	this.profileForm.get('email').setValue(this.Profile.attribs.email);
  	this.profileForm.get('phone').setValue(this.Profile.attribs.phone);
	}

	hasManualError ()
	{}
	
	isManualError (str:string)
	{}

  constructor (private fb: FormBuilder, private User: SessionUser, private ProfileSrvc: ProfileService) 
  {}

  ngOnInit (): void 
  {
  	this.setProfile();
  }
}
