<div class="memory-chest-member-widget-component layout-{{layout}}">
	<div class='img-outer-wrapper' *ngIf="url" [ngClass]="{'selected': Selected}">
		<div class='img-inner-wrapper'>
			<rv-img round [src]="url" (click)="viewMember()" height="3.75rem" />
		</div>
	</div>
	<div class='name-wrapper'>
		<div class='two-line-limit'>
			{{Member.get('nickname')}}
		</div>
	</div>
</div>