import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tagged'
})
export class TaggedPipe implements PipeTransform {
  transform(tag: string, ...args: unknown[]): unknown {
  	return tag?`#${tag.replace(/#+/,'')}`:tag;
  }
}
