import { Component, Input } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ModalService } from './../shared/modal.service';

@Component({
  selector: 'rv-modal-backdrop',
  templateUrl: './modal-backdrop.component.html',
  styleUrls: ['./modal-backdrop.component.scss'],
  animations: [
    trigger('onVisibilityToggle', [
      state('visible', style({
				'pointer-events': 'auto',
				'backdrop-filter': 'blur(1px)',
				'opacity': 1
      })),
      state('hidden', style({
				'pointer-events': 'none',
				'opacity': 0
      })),
      transition('visible => hidden', [animate('0.2s')]),
      transition('hidden => visible', [animate('0.2s')]),
    ]),
  ]
})
export class ModalBackdropComponent {

	@Input()
	isVisible: boolean = false;

  constructor (private modalService: ModalService)
  {}
}