import { Component, OnInit, Input } from '@angular/core';
import { ModelInstance, ModelChildrenCollection } from '@getrearview/model-builder';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'rv-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent {

	@Input('Memory')
	Memory: ModelInstance;

	@Input('showComments')
	showComments: boolean;

	ReplyTo: ModelInstance;
	ReplyToComment: BehaviorSubject<ModelInstance> = new BehaviorSubject(undefined);

	onReplyTo (ReplyTo?: ModelInstance): void {
		this.ReplyTo = ReplyTo;
	}

	get comments (): ModelChildrenCollection
	{
		return (this.Memory.getChildren('comment') || {} as ModelChildrenCollection)
	}

	constructor ()
	{}

	ngOnInit (): void
	{}
}