import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ChestsService } from './../shared/chests.service';
import { ModelInstance } from '@getrearview/model-builder';
import { Subscription } from 'rxjs';
import { FloatingControlService } from './../../../widgets/floating-controls/floating-control.service';
import { ModalService, modalParams } from 'src/app/widgets/modal/shared/modal.service';
import { SessionUser } from 'src/app/core/session/session-user.model';


@Component({
  selector: 'rv-memory-chests-page',
  templateUrl: './memory-chests-page.component.html',
  styleUrls: ['./memory-chests-page.component.scss']
})
export class MemoryChestsPageComponent implements OnInit {

	loaded: boolean = false;
	chestId: string = '';
	isModalOpen: boolean = false;
	enableSearch: boolean = false;
	#subscriptions$ = new Subscription();

	onChestCreated (memoryChestId: string): void
	{
		this.router.navigate([`/memory-chests`, memoryChestId]);
	}

  constructor (private router: Router, private modalService: ModalService, public chestsSrvc: ChestsService, private route: ActivatedRoute, private floatingControl: FloatingControlService, private SessionUser: SessionUser) 
  {}

  ngOnInit (): void 
  {
  	this.#subscriptions$.add(this.modalService.isOpen.subscribe((isOpen: boolean) => this.isModalOpen = isOpen));
  	this.SessionUser.getInstance().then((Profile:ModelInstance) => {
		  this.#subscriptions$.add(this.route.params.subscribe(params => {
		  	this.chestsSrvc.fetch(Profile).finally(() => {
		  		this.loaded = true
		  	});
		  }));
  	});
  }

  ngOnDestroy (): void 
  {
    this.#subscriptions$.unsubscribe();
		this.floatingControl.reset();
  }
}