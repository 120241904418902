import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { ModelInstance } from '@getrearview/model-builder';
import { ChestService } from './../shared/chest.service';
import { SessionUser } from 'src/app/core/session/session-user.model';
import { MemoryChestService } from 'src/app/models/memories/memory-chest.service';
import { ModalService } from 'src/app/widgets/modal/shared/modal.service';

@Component({
  selector: 'milestone',
  templateUrl: './milestone.component.html',
  styleUrls: ['./milestone.component.scss']
})
export class MilestoneComponent implements OnInit {

	@Input('Milestone')
	Milestone: ModelInstance;

	@Input('fetchPreview')
	fetchPreview: boolean;

	@Input('chestId')
	chestId: string;

	@ViewChild('milestoneInviter')
	milestoneInviter: TemplateRef<any>;

	@ViewChild('milestoneEditor')
	milestoneEditor: TemplateRef<any>;

	@ViewChild('milestoneDeleter')
	milestoneDeleter: TemplateRef<any>;

	birthDateFormat: string = 'mediumDate';
	Location: ModelInstance;
	isOpen: boolean = false;
	can_share: boolean = false;
	can_edit: boolean = false;
	can_delete: boolean = false;

	contextMenuPosition = {
		position: 'absolute',
		right: 0
	};

	onDeletedSuccess (Milestone: ModelInstance): void
	{
		this.chestSrvc.removeMilestone(Milestone);
		this.modalService.close();
	}

	onEditSuccess (Milestone: ModelInstance): void
	{
		this.modalService.close();
	}

	onSharedSuccess (): void
	{
		this.modalService.close();
		// MemoryChest Milestone Me onCancel onSuccess
	}

	shareMilestone (): void 
	{
		this.isOpen = false;

		if (this.can_edit && this.milestoneInviter) {
			this.setMilestoneLocation();
			this.modalService.open(this.milestoneInviter, {title: 'Add A Person'})
		}

		// const {
		// 	status, MemoryChest, Milestone, Member
		// } = await this.feedSrvc.authenticateInvitor(this.chestId, `${this.FeedMilestone.id()}`);

		// if (status && this.memberInviter) {
		// 	this.MemoryChest = MemoryChest;
		// 	this.Milestone = Milestone;
		// 	this.Me = Member;
		// 	this.modalService.open(this.memberInviter, {title: 'Add Person'})
		// }
	}

	editMilestone (): void 
	{
		this.isOpen = false;

		if (this.can_edit && this.milestoneEditor) {
			this.setMilestoneLocation();
			this.modalService.open(this.milestoneEditor, {title: 'Update Milestone'})
		}
	}

	deleteMilestone (): void 
	{
		this.isOpen = false;

		if (this.can_delete && this.milestoneDeleter) {
			this.modalService.open(this.milestoneDeleter, {title: 'Are You Sure?'})
		}
	}

	private setMilestoneLocation (): void
	{
  	if (this.Milestone?.getChildren('location'))
			this.Location = Object.values(this.Milestone.getChildren('location')).filter((Location: ModelInstance) => Location.get('is_initial')).shift() as ModelInstance;
	}

  constructor (public chestSrvc: ChestService, private MemoryChestSrvc: MemoryChestService, private User: SessionUser, public modalService: ModalService) 
  {}

  ngOnInit (): void 
  {}

  ngOnChanges (): void 
  {
  	this.can_share = this.can_edit = this.can_delete = ['admin','owner'].includes(this.chestSrvc.Me?.attribs?.member_type);
  }
}