import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ModelInstance } from '@getrearview/model-builder';
import { MilestoneService } from 'src/app/models/memories/milestone.service';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { MessageBusService, Channels, Message } from 'src/app/core/services/message-bus.service';

// Intl.DateTimeFormat().resolvedOptions().timeZone
// https://en.wikipedia.org/wiki/List_of_tz_database_time_zones

@Component({
  selector: 'rv-milestone-editor-widget',
  templateUrl: './milestone-editor-widget.component.html',
  styleUrls: ['./milestone-editor-widget.component.scss']
})
export class MilestoneEditorWidgetComponent {

	@Output('onCancel')
	onCancel: EventEmitter<void> = new EventEmitter<void>();

	@Output('onSuccess')
	onSuccess: EventEmitter<ModelInstance> = new EventEmitter<ModelInstance>();

	@Output('onError')
	onError: EventEmitter<string> = new EventEmitter<string>();

	@Input('Milestone')
	Milestone: ModelInstance;

	@Input('Location')
	Location: ModelInstance;

	isSaving: boolean = false;
	isFetching: boolean = false;
	isEditing: boolean = false;

	milestoneForm: FormGroup = new FormGroup({
																	nickname: new FormControl('',Validators.required),
																	start_at: new FormControl(''),
																	place_id: new FormControl(''),
																});

	_subscriptions$:Subscription = new Subscription();

	onFocus ($e?: any): void
	{}

	onBlur ($e?: any): void
	{}

	async saveMilestone (): Promise<void>
	{
		this.isSaving = true;
		let success: boolean = false;

		if (this.Milestone) {
			try { 
				await this.Milestone.save();
				success = true;
			}
			catch (ex) {
				console.log(`Failed to update Milestone. ${ex}`);
				/*TODO: complete error handling*/
				// this.onError.next(`${ex}`);
			}
		}

		if (success)
			this.onSuccess.next(this.Milestone);

		this.bus.emit(Channels.Model,new Message('milestone.updated',{Milestone:this.Milestone}))

		this.isSaving = false;
	}

	#updateModel (changes: {[key: string]: any}): void
	{
		if (this.Milestone)
			Object.keys(changes).forEach(key => this.Milestone.set(key, changes[key]));
  }

  getFormModelValues (): {nickname: string; start_at: string; place_id: string;}
  {
  	// let startAt: Date;
		// if (this.Location?.get('start_at')) {
		// 	if (typeof this.Location?.get('start_at') === 'string')
		// 		startAt = new Date(this.Location?.get('start_at'));
		// 	else if (typeof this.Location?.get('start_at') === 'object')
		// 		startAt = this.Location?.get('start_at');
		// }
		// else if (this.Milestone?.get('start_at')) {
		// 	if (typeof this.Milestone?.get('start_at') === 'string')
		// 		startAt = new Date(this.Milestone?.get('start_at'));
		// 	else if (typeof this.Milestone?.get('start_at') === 'object')
		// 		startAt = this.Milestone?.get('start_at');
		// }

  	let startAt: string;
		if (this.Location?.get('start_at'))
			startAt = `${this.Location.get('start_at')}`;
		else if (this.Milestone?.get('start_at'))
			startAt = `${this.Milestone.get('start_at')}`;

  	return {
			nickname:  `${this.Milestone?.get('nickname')||''}`,
			start_at:  `${(startAt.match(/\d{2,4}-\d{1,2}-\d{1,2}T\d{1,2}:\d{1,2}/)||[]).shift()||''}`,
			place_id:  `${this.Location?.get('place_id')||this.Milestone?.get('place_id')||''}`,
		}
  }

	updateFormSilent (): void
	{
		const formModelValues = this.getFormModelValues();
		Object.keys(formModelValues).forEach(fld => this.milestoneForm.get(fld).setValue(formModelValues[fld], { emitEvent: false }));
	}

  constructor (private fb: FormBuilder, private MilestoneSrvc: MilestoneService, private bus: MessageBusService)
  {}

  ngOnInit (): void
  {
  	this._subscriptions$.add(this.milestoneForm.valueChanges.pipe(distinctUntilChanged(), debounceTime(200)).subscribe(changes => this.#updateModel(changes)));
  	this.updateFormSilent();
  }

  ngOnChanges (Changes: SimpleChanges): void
  {
  	if (Changes?.Milestone && this.Milestone)
			this.Location = [this.Milestone.getChildren('location') && Object.values(this.Milestone.getChildren('location')).filter((Location: ModelInstance) => Location.get('is_initial')).shift()].shift() as ModelInstance;
  }

  ngOnDestroy (): void
  {
  	this._subscriptions$.unsubscribe();
  }
}