import { Component, Input } from '@angular/core';
import { ModelInstance } from '@getrearview/model-builder';
import { SessionUser } from 'src/app/core/session/session-user.model';
import { environment } from 'src/environments/environment';

const PROFILE_URL = `${environment.api.media_url}/users/{userId}/profile.jpg`;
const MEMBER_URL = `${environment.api.media_url}/chests/{chestId}/m/{memberId}/{filename}`;

@Component({
  selector: 'rv-memory-chest-member-widget',
  templateUrl: './memory-chest-member-widget.component.html',
  styleUrls: ['./memory-chest-member-widget.component.scss']
})
export class MemoryChestMemberWidgetComponent {

	@Input('Member')
	Member: ModelInstance;

	@Input('Selected')
	Selected: boolean = false;

	@Input('layout')
	layout: string = 'round';

	url: string = '';

	private setUrl ()
	{
		let Media: ModelInstance;

		if (this.Member?.getChildren('media')) {
			if (Media = Object.values(this.Member.getChildren('media')).shift() as ModelInstance) {
				let chestId: string = `${this.Member.get('chest_id')||''}`,
						memberId: string = `${this.Member.id()||''}`,
						filename: string = `${Media.get('filename')||''}`;
				this.url = MEMBER_URL.replace('{chestId}', chestId).replace('{memberId}', memberId).replace('{filename}', filename);
				// console.log("Good.1: ",this.url);
			}
		}

		if (!this.url && this.User.instance?.getChildren('media')) {
			if (Media = Object.values(this.User.instance.getChildren('media')).shift() as ModelInstance) {
				this.url = PROFILE_URL.replace('{userId}', `${this.Member.get('user_id')}`);
				// console.log("Good.2: ",this.url);
			}
		}


	}

	viewMember (): void 
	{}

	ngOnChanges (): void 
	{
		this.setUrl();
	}

	constructor (private User: SessionUser)
	{}
}