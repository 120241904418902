import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ModelInstance } from '@getrearview/model-builder';
import { SessionUser } from 'src/app/core/session/session-user.model';
import { TheFutureAuthProvider } from 'src/app/core/session/the-future-auth.provider';

@Injectable({ 
  providedIn: 'root' 
})
export class SwitchboardGuard implements CanActivate {

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean|UrlTree>
  {
		const User:ModelInstance = (await this.SessionUser.getInstance()) as ModelInstance;
		if (!User) {
			console.error("Switchboard missing operator.");
			return this.router.createUrlTree(['/feeds']);
		}

  	// go to welcome screen?
  	if (await this.SessionUser.api.shouldWelcome())
  		return this.router.createUrlTree(['/welcome']);

  	// go to show-me-around?

  	// go to creator?

  	// go to feeds?

  	// If all else fails, just go to the memories page.
  	return this.router.createUrlTree(['/feeds']);
  }

  constructor(private router: Router, private SessionUser: SessionUser, private authProvider: TheFutureAuthProvider) 
  {}
}