import { Component, TemplateRef, EventEmitter, Output, Input } from '@angular/core';
import { ModelInstance } from '@getrearview/model-builder';
import { Selectable } from './../shared/selectable.d'

@Component({
  selector: 'rv-selectable',
  templateUrl: './selectable.component.html',
  styleUrls: ['./selectable.component.scss']
})
export class SelectableComponent 
{
	@Output('onSelect')
	onSelect: EventEmitter<any> = new EventEmitter();

	@Input('Selectable')
	Selectable!: Selectable;

	@Input('template')
	template!: TemplateRef<any>;

	@Input('model')
	model!: ModelInstance;

	set selected (selected: boolean)
	{
		if (this.Selectable)
			this.Selectable.selected = selected;
	}
	get selected (): boolean 
	{
		return (this.Selectable && !!this.Selectable?.selected);
	}

	toggle () 
	{
		this.selected = !this.selected;
		this.onSelect.next(this.Selectable);
	}
}
