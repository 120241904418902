import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ui-color-card',
  template: '<div class="ui-color-card-component"><div class="bg-color--{{color}}">&nbsp;</div><div class="text--ellipsis">{{color|titlecase}}</div><div>{{hex}}</div><div>{{rgb}}</div></div>',
  styleUrls: ['./ui-color-card.component.scss']
})
export class UiColorCardComponent implements OnInit {

	@Input('color')
	color: string;

	@Input('hex')
	hex: string;

	@Input('rgb')
	rgb: string;

  constructor () 
  {}

  ngOnInit (): void 
  {}
}
