import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Geolocation } from '@capacitor/geolocation';
import { GeolocationInterface } from 'src/app/shared/native/geolocation-interface.service';
import { Toast } from '@capacitor/toast';
import { Dialog } from '@capacitor/dialog';

async function getCurrentPosition () 
{
	let coordinates;

	try {
		coordinates = await Geolocation.getCurrentPosition();
		// accuracy: 26.168
		// altitude: null
		// altitudeAccuracy: null
		// heading: null
		// latitude: 28.4560374
		// longitude: -82.5472121
	}
	catch (ex) {
		if (!isNaN(ex?.code) && parseInt(ex.code) === 1)
			Dialog.alert({title: 'Stop', message: 'You must enable location services in your device settings before using this feature.'});
	}

	if (coordinates)
		return {lat: coordinates?.coords?.latitude||`${coordinates.coords.latitude}`, lng: coordinates?.coords?.longitude||`${coordinates.coords.longitude}`};
}

@Component({
  selector: 'rv-ui-locations',
  templateUrl: './ui-locations.component.html',
  styleUrls: ['./ui-locations.component.scss']
})
export class UiLocationsComponent {

	demoForm: FormGroup = this.fb.group({
		location: 				['']
	});
	controlName: string = 'location';

	_coords: {lat: number, lng: number} = {lat: null, lng: null};

	get coordinates (): Array<number>
	{
		return [];
	}

	async getCoords () {
		const coords = await getCurrentPosition();
		// example: coords = {lat: 28.4557485, lng: -82.5472908}
		return coords;
	}

  constructor (private fb: FormBuilder) 
  {}

  ngOnInit (): void 
  {}
}