import { Component } from '@angular/core';

@Component({
  selector: 'rv-home-btn',
  templateUrl: './home-btn.component.html',
  styleUrls: ['./home-btn.component.scss']
})
export class HomeBtnComponent {

}
