import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SessionUser } from './../../../core/session/session-user.model';
import { ProfileService } from './../../../models/users/profile.service';
import { ModelInstance } from '@getrearview/model-builder';

@Component({
  selector: 'rv-settings-widget',
  templateUrl: './settings-widget.component.html',
  styleUrls: ['./settings-widget.component.scss']
})
export class SettingsWidgetComponent implements OnInit {

	@Input('setting')
	setting: any;

	@Output('onActivate')
	onActivate: EventEmitter<any> = new EventEmitter();

	isLoading: boolean = false;
	isSaving: boolean = false;

	settingsWidget = this.fb.group({
		name: 				[null, [Validators.required]],
		email: 				[null, [Validators.required]]
	});

	async handleSubmit ()
	{
		if (!this.settingsWidget.valid) return;

		this.isSaving = true;

		const Settings: ModelInstance = this.ProfileSrvc.get();

		Settings.attribs.name = this.settingsWidget.get('name').value;
		Settings.attribs.email = this.settingsWidget.get('email').value;
		// await Settings.save();

		// this.ProfileSrvc.set(Settings);
		// this.ProfileSrvc.store();

		this.isSaving = false;
	}
	
	hasManualError ()
	{}
	
	isManualError (str:string)
	{}

  constructor (private fb: FormBuilder, private User: SessionUser, private ProfileSrvc: ProfileService) {}

  ngOnInit (): void 
  {
  	this.settingsWidget.get('name').setValue(this.ProfileSrvc.get().attribs.name);
  	this.settingsWidget.get('email').setValue(this.ProfileSrvc.get().attribs.email);
  }
}
