import { Directive, OnInit, ElementRef, HostListener } from '@angular/core';
import { MessageBusService, Channels, Message } from './../../../core/services/message-bus.service';

@Directive({
  selector: '[rvHeaderTrigger]'
})
export class HeaderTriggerDirective implements OnInit 
{
	private _lastScrollTop: number = 0;

	@HostListener('scroll', ['$event'])
	onScroll (event) 
	{
		if (+event.target.scrollTop !== this._lastScrollTop) {
			this.bus.emit(Channels.Scroll,new Message('header.'+(+event.target.scrollTop > this._lastScrollTop ? 'hide' : 'show')))
			this._lastScrollTop = event.target.scrollTop;
		}
	}

  constructor (private bus: MessageBusService) 
  {}

  ngOnInit (): void 
  {}
}