import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MilestoneEditorWidgetComponent } from './milestone-editor-widget/milestone-editor-widget.component';
import { RearviewInterfaceModule } from 'src/app/shared/interface/rearview-interface.module';
import { LocationsModule } from 'src/app/widgets/locations/locations.module';

@NgModule({
  declarations: [
    MilestoneEditorWidgetComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RearviewInterfaceModule,
    LocationsModule
  ],
  exports: [
    MilestoneEditorWidgetComponent
  ],
})
export class MilestoneEditorModule { }