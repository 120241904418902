import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { SessionUser } from './../../../core/session/session-user.model';
import { AuthFormService } from './../shared/auth-form.service';
import { ConfigService } from './../../../shared/services/config.service';
import { AuthFormComponent } from './../shared/auth-form.component';
import { TrackJsService } from './../../../shared/services/trackjs.service';
import { TokenService } from './../../../core/services/token.service';
import { AuthService, SigninRequestResponse } from './../../../core/services/auth.service';
import { ApiService } from './../../../core/services/api.service';

import {
  SocialAuthService,
  SocialUser,
} from '@abacritt/angularx-social-login';

@Component({
  selector: 'social-signup',
  templateUrl: './social-signup.component.html',
  styleUrls: ['./../shared/auth-form.component.scss']
})
export class SocialSignupComponent extends AuthFormComponent implements OnInit 
{
	private _provider!: string;
  private _isLoggedin?: boolean = undefined;

  onSuccess (response: SigninRequestResponse): void
  {
  	this.AuthFormSrvc.notify = `${response?.translate()||''}`;
  	this.goToLogin();
  }

  onError (response: SigninRequestResponse): void
  {
  	this.AuthFormSrvc.fail = `${response?.translate()||''}`;
  }

  registerWithGoogle (): void 
  {
  	this.auth.register('GOOGLE').then(response => this.onSuccess(response), response => this.onError(response));
  }

  registerWithFB (): void 
  {
  	this.auth.register('FACEBOOK').then(response => this.onSuccess(response), response => this.onError(response));
  }

  registerWithCognito (): void
  {
  	this.goToSignupForm();
  }

  private async signup (User: SocialUser): Promise<void>
  {
  	return new Promise((resolve,reject) => {
			this.AuthApi.signup(User).subscribe((response: any) => {
		    if(response && response?.success && response?.access_token){
		      this.token.handle(response?.access_token, this._provider);
		      this.auth.changeAuthStatus(true);
		    }
			  resolve();
			});
  	});
  }

  private onSocialAuthStateChange = async (User: SocialUser): Promise<void> => 
  {
    this._provider = `${(User && User?.provider)||''}`;

    if (User && !this._isLoggedin)
    {
      this._isLoggedin = User != null;
  		await this.signup(User);
    }

    return Promise.resolve();
  }

  constructor (
  	private socialAuthService: SocialAuthService, 
  	SessionUser: SessionUser, 
  	AuthFormSrvc: AuthFormService, 
  	ConfigSrvc: ConfigService, 
  	fb: FormBuilder, 
  	TrackJsSrvc: TrackJsService, 
  	private token: TokenService, 
  	private auth: AuthService, 
  	AuthApi: ApiService
  ) 
  {
  	super(SessionUser, AuthApi, AuthFormSrvc, ConfigSrvc, fb, TrackJsSrvc)
  }

  ngOnInit () 
  {
  	this._subscriptions$.add(this.socialAuthService.authState.subscribe(this.onSocialAuthStateChange));
  }

  ngOnDestroy (): void
  {
  	this._subscriptions$.unsubscribe();
  }

  signOut (): void 
  {
    this.socialAuthService.signOut();
  }
}