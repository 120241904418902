import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './../../shared/services/config.service';
import { ModelFactoryConfig, ModelService, ModelInstance } from '@getrearview/model-builder';
import { Photo } from '@capacitor/camera'
import { MemoryMediaOpenGraphService } from './memory-media-open-graph.service';

@Injectable({
  providedIn: 'root'
})
export class MemoryMediaService extends ModelService {

  constructor (injector: Injector, httpClient: HttpClient) 
  {
  	let ConfigSrvc: ConfigService = injector.get<any>(ConfigService);
		let MFC: ModelFactoryConfig = (new ModelFactoryConfig()).setAll({
  				// Model Name
  				name: "Memory Media",
  				// Model Endpoint
					endpoint: ConfigSrvc.get("api.api_url")+"/memories/memories/memory-medias",
					// Model Fields
					fields: ["memory_id", "idx", "filename", "filesize", "filemime"],
					// Model `Permitted` Fields
					permitted: ["memory_id", "idx", "filename", "filesize", "filemime"],
					// Model `Required` Fields
					required: [],
					// Model Field Labels
					labels: {},
					// Model Field Validation Patterns
					patterns: {},
					// Parent ID Field Name
					parent_id_field: "memory_id",
					// Model Children Types
					children_types: {
						ograph: injector.get<any>(MemoryMediaOpenGraphService)
					},
					// Model Association Types
					association_types: {},
					// Storage Type (local, session, cookies)
					storage_type: "local",
					// Instance methods definied specifically to this model definition.
					instance_methods: {}
				});

  	super(MFC, httpClient);
  }
}