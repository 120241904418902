<div class="rv-ui ui-multi-select">
	<div class="selected-zone">
		<ul>
			<li class="input-icon input-left-icon" *ngIf="!!iconLeftGroup&&!!iconLeftName" (click)="isFocused = !isFocused">
				<rv-icon [group]="iconLeftGroup" [icon]="iconLeftName" />
			</li>
			<li *ngFor="let Selectable of Selectables" [hidden]="!Selectable.selected">
				<rv-selectable [Selectable]="Selectable" (onSelect)="onSelect($event)" />
			</li>
		</ul>
	</div>
	<div *ngIf="booted" class="demo-drop-wrapper selectable-zone" rvDropdownContainer>
		<ul [rvDropdownContent]="isFocused">
			<li *ngFor="let Selectable of Selectables" [hidden]="Selectable.selected">
				<rv-selectable [Selectable]="Selectable" (onSelect)="onSelect($event)" />
			</li>
		</ul>
	</div>
</div>