import { Component, OnInit, Input, ElementRef, SimpleChanges, HostBinding } from '@angular/core';

const isActive = (val: any) => (typeof val === 'boolean' ? val : typeof val === 'string' && val === '')

@Component({
  selector: 'rv-button',
  styleUrls: ['./ui-button-widget.component.scss'],
  template: '<button [disabled]="disabled" [ngClass]="classes" [type]="type">'
  				+		'<ng-content></ng-content>'
  				+ 	'<span class="btn-icon btn-left-icon" *ngIf="!!iconLeftGroup&&!!iconLeftName">'
  				+ 		'<rv-icon group="{{iconLeftGroup}}" icon="{{iconLeftName}}" [purple]="iconPurple" [white]="iconWhite" [off-white]="iconOffWhite"></rv-icon>'
  				+ 	'</span>'
  				+ 	'<span *ngIf="!!label">{{label}}</span>'
  				+ 	'<span class="btn-icon" *ngIf="!label&&!!iconGroup&&!!iconName">'
  				+ 		'<rv-icon group="{{iconGroup}}" icon="{{iconName}}" [spin]="iconSpin" [purple]="iconPurple"></rv-icon>'
  				+		'</span>'
  				+ 	'<span class="btn-icon btn-right-icon" *ngIf="!!iconRightGroup&&!!iconRightName">'
  				+ 		'<rv-icon group="{{iconRightGroup}}" icon="{{iconRightName}}" [purple]="iconPurple"></rv-icon>'
  				+		'</span>'
  				+	'</button>'
})
export class UiButtonWidgetComponent implements OnInit {

	@HostBinding('style.display')
	hostDisplay:string = 'inline-block';

	iconGroup: string;
	iconName: string;
	iconLeftGroup: string;
	iconLeftName: string;
	iconRightGroup: string;
	iconRightName: string;
	_classes: Array<string> = ['rv-ui','ui-button'];

	// button label
	@Input('label')
	private _label: string;

	@Input('type')
	private _type: string = '';
	set type (t: string) { t = `${t||''}`.trim().toLowerCase(); this._type = `${['button','type','submit'].includes(t) ? t : ''}`; }

	// sizes
	@Input('xs')
	private _xs: boolean;
	@Input('sm')
	private _sm: boolean;
	@Input('md')
	private _md: boolean;
	@Input('lg')
	private _lg: boolean;
	@Input('xl')
	private _xl: boolean;

	// icons
	@Input('icon')
	private _icon: Array<string>|string;
	@Input('iconSpin')
	private _iconSpin: boolean;
	@Input('iconLeft')
	private _iconLeft: string;
	@Input('iconRight')
	private _iconRight: string;

	// interactive
	@Input('disabled')
	private _disabled: boolean;
	@Input('active')
	private _active: boolean;

	// host display modes
	// default: inline-block
	@Input('block')
	private _block: boolean;
	@Input('flex')
	private _flex: boolean;

	// styles
	@Input('solid')
	private _solid: boolean;	
	@Input('outline')
	private _outline: boolean;
	@Input('open')
	private _open: boolean;
	@Input('dotted')
	private _dotted: boolean;
	@Input('circle')
	private _circle: boolean;
	@Input('round')
	private _round: boolean;
	@Input('submit')
	private _submit: boolean;
	@Input('shadowed')
	private _shadowed: boolean;

	// colors
	@Input('purple')
	private _purple: boolean;
	@Input('blue')
	private _blue: boolean;
	@Input('red')
	private _red: boolean;
	@Input('gray')
	private _gray: boolean;
	@Input('dk-gray')
	private _dkGray: boolean;
	@Input('off-white')
	private _offWhite: boolean;
	@Input('icon-purple')
	private _icon_purple: boolean
	@Input('icon-red')
	private _icon_red: boolean
	@Input('icon-white')
	private _icon_white: boolean
	@Input('icon-off-white')
	private _icon_off_white: boolean


	get classes (): string
	{
		return this._classes.concat([
				this.styleClass,
				this.modifierClass,
				this.sizeClass,
				this.colorClass,
				(this._active?'is-active':''),
				(!!this._label?'has-label':''),
				(!!this._icon?'has-icon':''),
				(!!this._iconLeft?'has-left-icon':''),
				(!!this._iconRight?'has-right-icon':'')
			]).join(' ');		
	}

	get sizeClass (): string
	{
		if (isActive(this._xs)) 		return 'rv-ui-xs';
		if (isActive(this._sm)) 		return 'rv-ui-sm';
		if (isActive(this._md)) 		return 'rv-ui-md';
		if (isActive(this._lg)) 		return 'rv-ui-lg';
		if (isActive(this._xl)) 		return 'rv-ui-xl';
	}

	get colorClass (): string
	{
		if (isActive(this._purple)) 	return 'rv-btn-purple';
		if (isActive(this._blue)) 		return 'rv-btn-blue';
		if (isActive(this._red)) 			return 'rv-btn-red';
		if (isActive(this._gray)) 		return 'rv-btn-gray';
		if (isActive(this._dkGray)) 	return 'rv-btn-dk-gray';
		if (isActive(this._offWhite)) return 'rv-btn-off-white';
		return 'rv-btn-purple';
	}

	get styleClass (): string
	{
		const classes: Array<string> = [
				(isActive(this._block) ? 'rv-btn-block' : ''),
				(isActive(this._circle) || isActive(this._round) ? 'rv-btn-circle' : ''),
			];

		// if (isActive(this._open)) 		return 'rv-btn-open';
		// if (isActive(this._outline)) 	return 'rv-btn-outline';
		// if (isActive(this._solid)) 		return 'rv-btn-solid';
		if (isActive(this._open)) 		classes.push('rv-btn-open');
		if (isActive(this._dotted)) 	classes.push('rv-btn-dotted');
		if (isActive(this._outline)) 	classes.push('rv-btn-outline');
		if (isActive(this._solid)) 		classes.push('rv-btn-solid');

		return classes.join(' ');
	}

	get modifierClass (): string
	{
		// these action verbs should be past-tense.
		if (isActive(this._shadowed)) return 'rv-btn-shadowed';
	}

	get type () 
	{
		if (this._type) return this._type;
		if (isActive(this._submit)) return 'submit';
		return 'button';
	}

	get label (): string
	{
		return `${this._label||''}`
	}

	get disabled (): boolean
	{
		return !!this._disabled;
	}

	get iconSpin (): boolean
	{
		return isActive(this._iconSpin)
	}

	get iconPurple (): boolean
	{
		return isActive(this._icon_purple)
	}

	get iconWhite (): boolean
	{
		return isActive(this._icon_white)
	}

	get iconOffWhite (): boolean
	{
		return isActive(this._icon_off_white)
	}

	private setHostDisplay (): void
	{
		if(isActive(this._flex))
			this.hostDisplay = 'flex';
		else if(isActive(this._block))
			this.hostDisplay = 'block';
		else 
			this.hostDisplay = 'inline-block';
  }
  
	private setIcon (icon: string): void
	{
		if (this[`_${icon}`] && typeof this[`_${icon}`] === 'string' && /([a-z\-]+)\.([a-z\-]+)/i.test(this[`_${icon}`])) {
			let [g,i] = this[`_${icon}`].split('.');
			this[`${icon}Group`] = g;
			this[`${icon}Name`] = i;
		}
		else if (this[`_${icon}`] && Array.isArray(this[`_${icon}`]) && /([a-z\-]+)\.([a-z\-]+)/i.test(this[`_${icon}`].slice(0,2).join('.'))) {
			let [g,i] = this[`_${icon}`];
			this[`${icon}Group`] = g;
			this[`${icon}Name`] = i;
		}
		else {
			this[`${icon}Group`] = '';
			this[`${icon}Name`] = '';
		}
	}

  constructor (elementRef: ElementRef<HTMLElement>) 
  {}

  ngOnInit (): void 
  {}

  ngOnChanges (Changes: SimpleChanges): void
  {
  	if (Changes.hasOwnProperty('_iconLeft'))  this.setIcon('iconLeft');
  	if (Changes.hasOwnProperty('_iconRight')) this.setIcon('iconRight');
  	if (Changes.hasOwnProperty('_icon')) this.setIcon('icon');
  	if (Changes.hasOwnProperty('_block')) this.setHostDisplay();
  	if (Changes.hasOwnProperty('_flex')) this.setHostDisplay();
  }
}