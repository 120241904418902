import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModelInstance } from '@getrearview/model-builder';
import { MediaRetrievalService } from 'src/app/shared/services/media-retrieval.service';
import { StoryService } from './../shared/story.service';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'rv-memory-media-url',
  templateUrl: './memory-media-url.component.html',
  styleUrls: ['./memory-media-url.component.scss']
})
export class MemoryMediaUrlComponent implements OnInit {

	@Input('Media') Media: ModelInstance;
	@ViewChild('imgContainer') imgContainer: ElementRef;

	href: string = '';
	isClickable: boolean = false;

	private _OGraph$!: BehaviorSubject<ModelInstance>;
	private _graphId: string = '';
	private _subscription$ = new Subscription();

	onImageLoad ($event) {}

	onImageClick ($event) 
	{
		if (!this.isClickable) {
			$event.preventDefault();
			return false;
		}
	}

	setMediaElement (OGraph: ModelInstance): void
	{
		if (this.imgContainer && !!OGraph && this._graphId !== OGraph.id()) {
			const chestId: string = this.storySrvc.Milestone?.get('chest_id'),
						milestoneId: string = chestId && `${this.storySrvc.Milestone.id()}`;

			if (this.imgContainer.nativeElement && OGraph.attribs?.ograph_data?.image)
				this.imgContainer.nativeElement.src = OGraph.attribs.ograph_data.image;
			if (this.imgContainer.nativeElement && OGraph.attribs?.ograph_data?.['image:alt'])
				this.imgContainer.nativeElement.alt = OGraph.attribs.ograph_data['image:alt'];
		}
	}

	setHrefByOGraph (OGraph: ModelInstance): void
	{
		if (OGraph?.attribs?.ograph_data?.url && this.href !== OGraph.attribs.ograph_data.url) {
			this.href = OGraph.attribs.ograph_data.url;
			this.setIsClickable();
		}
	}
		

	private findGraphId (): string
	{
		let graphId: string = Object.keys(this.Media?.getChildren('ograph')||{}).shift();
		return `${graphId?graphId:''}`;
	}

	private initHref (href: string): void
	{
		if (href !== this.href && href) {
			this.isClickable 		= 		this.href && window.location.href !== this.href;

		}
	}

	private setIsClickable (): void
	{
		this.isClickable 			= 		this.href && window.location.href !== this.href;
	}

	private initOGraph (): void
	{
		let graphId: string 	= 		this.findGraphId(),
				OGraph!: ModelInstance;

		if (graphId && this.Media.getChild('ograph', graphId))
			OGraph 							= 		this.Media.getChild('ograph', graphId) as ModelInstance;

		this._OGraph$ = new BehaviorSubject<ModelInstance>(OGraph);
		this.setHrefByOGraph(OGraph);
	}

	private updateOGraph (): void
	{
		let graphId: string 	= 		this.findGraphId(),
				OGraph!: ModelInstance;

		if (graphId && this.Media.getChild('ograph', graphId))
			OGraph 							= 		this.Media.getChild('ograph', graphId) as ModelInstance;

		if (OGraph && OGraph.id() === graphId && this._OGraph$)
			this._OGraph$.next(OGraph);
	}

  constructor (private storySrvc: StoryService) 
  {}

	ngOnInit (): void
	{
		this.initOGraph();
	}

	ngOnChanges (): void
	{
		this.updateOGraph();
	}

  ngAfterViewInit (): void
  {
		this._subscription$.add(this._OGraph$.subscribe((OGraph: ModelInstance) => this.setMediaElement(OGraph)));
  }

	ngOnDestroy (): void
	{
		this._subscription$.unsubscribe();
	}
}