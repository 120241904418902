import { Component, OnInit, ViewChild, Input, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModelInstance, ModelChildrenCollection } from '@getrearview/model-builder';
import { StoryService } from './../shared/story.service';
import { ModalService, modalParams } from 'src/app/widgets/modal/shared/modal.service';
import { Subscription } from 'rxjs';
import { FloatingControlService } from './../../../widgets/floating-controls/floating-control.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Photo } from '@capacitor/camera';
import { getRecentMemory, uploadMedia } from './../shared/functions';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

/** Clamps a number between zero and a maximum. 
function clamp(value: number, max: number): number {
  return Math.max(0, Math.min(max, value));
}

/**
 * Moves an item one index in an array to another.
 * @param array Array in which to move the item.
 * @param fromIndex Starting index of the item.
 * @param toIndex Index to which the item should be moved.

function moveItemInArray<T = any>(array: T[], fromIndex: number, toIndex: number): void {
  const from = clamp(fromIndex, array.length - 1);
  const to = clamp(toIndex, array.length - 1);

  if (from === to) {
    return;
  }

  const target = array[from];
  const delta = to < from ? -1 : 1;

  for (let i = from; i !== to; i += delta) {
    array[i] = array[i + delta];
  }

  array[to] = target;
}
 */
const SHOULD_PROMPT = false;

@Component({
  selector: 'rv-milestone-page',
  templateUrl: './milestone-page.component.html',
  styleUrls: ['./milestone-page.component.scss']
})
export class MilestonePageComponent implements OnInit {

	@ViewChild('milestoneIntro')
	milestoneIntro: TemplateRef<any>;

	@ViewChild('memoryCreator')
	memoryCreator: TemplateRef<any>;

	Memories: Array<ModelInstance> = [];
	Memories$: BehaviorSubject<ModelChildrenCollection> = new BehaviorSubject(undefined);

	isModalOpen: boolean = false;
	loaded: boolean = false;
	milestoneId: string = '';

	// When true, this will lock the previous/next buttons down.
	isDisabled: boolean = false;

	private stagedMedia!: Photo;

	#subscriptions$ = new Subscription();

	DeleteMedia!: ModelInstance;

	getNumOfMedia (Memory: ModelInstance): number
	{
		if (Memory?.getChildren('media'))
			return Object.values(Memory.getChildren('media')).filter((M: ModelInstance) => !M.attribs.deleted_at).length
		return 0;
	}

	canGoPrevious (): boolean
	{
		return true;
	}

	previous () 
	{
		if (this.canGoPrevious()) {}
	}

	canGoNext (): boolean
	{
		return true;
	}

	next () 
	{
		if (this.canGoNext()) {}
	}

	drop (event)
	{
		this.storySrvc.moveItemInEventMap(event.previousIndex, event.currentIndex);
	}

	onDeleteMedia (deleteMedia: {Media: ModelInstance, $element: HTMLElement}, deleteConfirmRef: TemplateRef<any>): void
	{
		if (this.isModalOpen) {
			this.modalService.close()
			return;
		}

		this.DeleteMedia = deleteMedia.Media;
		// this.deletePreview = deleteMedia.$element;

		// options?: { size?: string; title?: string }
		const opts:modalParams = {title: 'Are you sure you want to delete this?'};

		this.modalService.open(deleteConfirmRef, opts);
	}

	onConfirmDelete (Media: ModelInstance|boolean): void
	{
		if (Media) {
			Media = Media as ModelInstance;

			if (Media.get('memory_id') && !!this.storySrvc.Milestone.getChild('media', Media.get('memory_id'))) {
				(this.storySrvc.Milestone.getChild('media', Media.get('memory_id')) as ModelInstance).delete();
				this.storySrvc.Milestone.removeChild('media', Media.get('memory_id'));
				this.modalService.close();
				return;
			}

			Media.delete();
		}

		if (this.isModalOpen)
			this.modalService.close();
	}

  constructor (public storySrvc: StoryService, private route: ActivatedRoute, private floatingControl: FloatingControlService, private modalService: ModalService)
  {}

  private async getRecentOrCreateMemory (): Promise<ModelInstance>
  {
		let Memory: ModelInstance = getRecentMemory(this.storySrvc.Milestone, this.storySrvc.Me)
		if (Memory) return Promise.resolve(Memory);

		return await this.storySrvc.Milestone.createChild('memory', {author_id: this.storySrvc.Me.id(), body: '', type: ''}) as ModelInstance;
  }

  private async updateFloatingControlParams ()
  {
		this.floatingControl.setParams({
								milestoneId: 	this.storySrvc.Milestone?.id(),
								Milestone: 		this.storySrvc.Milestone,
								MemoryChest: 	this.storySrvc.MemoryChest,
								Me: 					this.storySrvc.Me
							});
  }



  /* Memory Creator --------------------------------------------- */
  async onMemoryCreatorSuccess (Memory: ModelInstance): Promise<void>
  {
  	// console.log('3) onMemoryCreatorSuccess');

  	uploadMedia(this.stagedMedia, Memory).then((Media: ModelInstance) => this.storySrvc.appendMedia(Media, Memory)); // returns Promise<ModelInstance> of Media element.

  	// Memory = Memory?.id() ? this.storySrvc.Milestone.getChild('memory', Memory.id()) as ModelInstance : undefined;



		if (this.isModalOpen) {
			this.modalService.close()
			// this.floatingControl.reset();
			// console.log("modal closed.");
		}

		return Promise.resolve();
  }

  private async promptForMediaDetails (): Promise<void>
  {
  	this.modalService.open(this.memoryCreator, {title: 'Add Media'})
  }

  private async stageMediaForUpload (Media: Photo): Promise<void>
  {
		// console.log('1) stageMediaForUpload.1', Media);
  	if (this.stagedMedia = Media) {
  		const Memory: ModelInstance = getRecentMemory(this.storySrvc.Milestone, this.storySrvc.Me, 600000);
  		// console.log('2) stageMediaForUpload.2', Memory);
  		if (Memory) 	uploadMedia(this.stagedMedia, Memory).then((Media: ModelInstance) => this.storySrvc.appendMedia(Media, Memory)).finally(() => this.isModalOpen && this.modalService.close()); // returns Promise<ModelInstance> of Media element.;
  		else 					this.promptForMediaDetails();
  	}
  }
  /* /Memory Creator --------------------------------------------- */




  newMilestoneLocation (Location: ModelInstance) {}

  ngOnInit (): void 
  {
  	this.#subscriptions$.add(this.modalService.isOpen.subscribe((isOpen: boolean) => this.isModalOpen = isOpen));

		this.#subscriptions$.add(this.route.params.subscribe(params => {
			this.storySrvc
						.fetch(this.milestoneId = params.id)
						.finally(() => {
							this.loaded = true;
							this.updateFloatingControlParams();

							if (this.storySrvc.getIsMilestoneEmpty && this.milestoneIntro) {
								// there are no memories.
								// auto-open modal w/ <milestone-intro />
								if (!this.isModalOpen && SHOULD_PROMPT) {
									this.modalService.open(this.milestoneIntro, {title: this.storySrvc.Milestone.get('nickname')});
									// this.floatingControl.enableUpload = false;
								}
							}
						});
		}));

		this.#subscriptions$.add(this.floatingControl.popBtns$.subscribe(popBtnEvent => {
			switch (popBtnEvent&&popBtnEvent?.id) {
				case 'pop-btn-library':
				case 'pop-btn-video':
				case 'pop-btn-photo':
					this.stageMediaForUpload(popBtnEvent.result);
					break;
				case 'pop-btn-location':
					this.newMilestoneLocation(popBtnEvent.result);
					break;
			}
		}));

		this.#subscriptions$.add(this.storySrvc.Memories$.subscribe((Memories: ModelChildrenCollection) => {
			this.updateMemories(Memories)
		}));
  }

  updateMemories (Memories: ModelChildrenCollection): void
  {
		this.Memories$.next(Memories);
		this.Memories = Object.values(Memories||{});
  }

  ngOnDestroy (): void 
  {
  	this.storySrvc.reset();
    this.#subscriptions$.unsubscribe();
    this.floatingControl.reset();
  }
}
