import { Component, OnInit, Input, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { ModelInstance, ModelChildrenCollection } from '@getrearview/model-builder';
import { MediaRetrievalService } from 'src/app/shared/services/media-retrieval.service';
import { StoryService } from './../shared/story.service';

@Component({
  selector: 'rv-memory-media-multi-image',
  // standalone: true,
  // imports: [],
  templateUrl: './memory-media-multi-image.component.html',
  styleUrls: ['./memory-media-multi-image.component.scss']
})
export class MemoryMediaMultiImageComponent implements OnInit {

  @Input("Memory") Memory: ModelInstance;
  @Input("Medias") Medias: ModelChildrenCollection;

  imgUrls: Array<string>;
  topUrls: Array<string>;
  bottomUrls: Array<string>;

  private setUrls (): void
  {
    const chestId: string = this.storySrvc.Milestone?.get('chest_id'),
    			milestoneId: string = chestId && `${this.storySrvc.Milestone.id()}`;

    let tempUrlArray = [];

    Object.values(this.Medias).filter((Media: ModelInstance) => Media && !Media?.isNew()).forEach((Media, index)=>{
      tempUrlArray.push(this.MediaRetrievalSrvc.buildUrl(chestId, milestoneId, Media.attribs.filename));
    });

    this.imgUrls = (tempUrlArray = tempUrlArray.filter((val,idx,self) => self.indexOf(val) === idx));
    this.topUrls = tempUrlArray.filter((element, index) => index % 2 !== 0);
    this.bottomUrls = tempUrlArray.filter((element, index) => index % 2 === 0).slice(1);
  }

  constructor (private storySrvc: StoryService, private MediaRetrievalSrvc: MediaRetrievalService) 
  {}

  ngOnInit(): void 
  {}

  ngOnChanges (Changes: SimpleChanges): void
  {
  	this.setUrls();
  }
}
