import { Component, TemplateRef, Output, EventEmitter } from '@angular/core';
import { ModalService, modalParams } from 'src/app/widgets/modal/shared/modal.service';
import { Subscription } from 'rxjs';
import { ModelInstance } from '@getrearview/model-builder';

@Component({
  selector: 'rv-memory-chest-creator-btn',
  templateUrl: './memory-chest-creator-btn.component.html',
  styleUrls: ['./memory-chest-creator-btn.component.scss']
})
export class MemoryChestCreatorBtnComponent {

	isModalOpen: boolean = false;

	#subscriptions$ = new Subscription();

	@Output('onSuccess')
	onSuccess: EventEmitter<string> = new EventEmitter();


	redirectTo (MemoryChest: ModelInstance) {
		this.onSuccess.next(`${MemoryChest.id()}`);
	}

	onCancel (a,b,c) {}

	open (memoryChestCreator: TemplateRef<any>) 
	{
		if (this.isModalOpen)
			this.modalService.close();

		this.modalService.open(memoryChestCreator, {title: 'Create A Memory Chest'} as modalParams);
	}

  constructor (public modalService: ModalService) 
  {

  }

  ngOnInit (): void 
  {
  	this.#subscriptions$.add(this.modalService.isOpen.subscribe((isOpen: boolean) => this.isModalOpen = isOpen));
  }

  ngOnDestroy(): void 
  {
  	this.#subscriptions$.unsubscribe();
  }
}
