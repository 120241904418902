import { Component, OnInit, Input } from '@angular/core';
import { ModelInstance } from '@getrearview/model-builder';
import { MemberService } from 'src/app/models/memories/member.service'
import { ConfigService } from 'src/app/shared/services/config.service'

const NUM_MEMBERS = 3

@Component({
  selector: 'feed-milestone-members',
  templateUrl: './feed-milestone-members.component.html',
  styleUrls: ['./feed-milestone-members.component.scss']
})
export class FeedMilestoneMembersComponent implements OnInit {

	@Input('members')
	_members: Array<string> = [];
	@Input('milestoneId')
	_milestoneId: string = ''

	@Input('headshots')
	_headshots: Array<string> = [];

	get members (): Array<string>
	{
		return (this._members||[]).slice(0,NUM_MEMBERS);
	}

	get milestoneId (): string
	{
		return this._milestoneId
	}

  constructor (public memberSrvc: MemberService, private ConfigSrvc: ConfigService) 
  {}

  ngOnInit (): void 
  {}
}
