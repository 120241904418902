<div id="forgot-password-confirm-form-component" class="container-fluid auth-form-component">
	<div class="row">
		<div class="col-24">
			<div class="font-color--gray text-center rv-heading-sm">Sign in to Rearview</div>

			<form [formGroup]="confirmForm" class="rv-form rv-auth-form rv-form-bg-blue" name="confirmForm" layout layout-align="center" layout-padding novalidate (ngSubmit)="handleVerifyResetPass()" #resetFormRef>

				<div class="inputs mb-2">
					<div class="form-group" *ngIf="showEmail">
						<input class="form-control" type="text" formControlName="email_or_phone" required placeholder="Email or Phone" />
					</div>
					<hr />
					<div class="form-group" [ngClass]="{'show-manual-error': hasManualError()}">
						<input class="form-control" type="text" formControlName="code" required placeholder="Verification Code" />
						<span *ngIf="isManualError('INVALID_CODE')" class="manual-error">Invalid Code</span>
					</div>
					<hr />
					<div class="form-group">
						<input class="form-control" type="text" formControlName="password" required placeholder="New Password" />
					</div>
				</div>

				<div class="buttons mb-5">
					<rv-button type='submit' sm purple label='Verify Code' #submitBtnRef class='mb-4' />
				</div>

				<rv-link outline sm (click)="goToLogin()" iconLeft='arrows.left-arrow-1' label='Back to Login' off-white icon-off-white />
			</form>

			<rv-auth-notifications></rv-auth-notifications>
		</div>
	</div>
</div>