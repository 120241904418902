<div id="feed-page-component" class="container-fluid page-container-fluid" [ngClass]="{loaded: loaded}" rvHeaderTrigger>
	<div *ngIf="enableFilter; else noFilter" class="row">
		<div class="col-20">
			<rv-memory-chest-selector-widget [chestId]="chestId" (onSelect)="onChestSelected($event)"></rv-memory-chest-selector-widget>
		</div>
		<div class="col-4 filter-btn">
			<rv-button [disabled]="!canFilter" open sm icon="user-interface.filters" (click)="openFilter(feedFilter)" />
		</div>
	</div>
	<ng-template #noFilter>
		<div class="row">
			<div class="col-24">
				<rv-memory-chest-selector-widget [chestId]="chestId" (onSelect)="onChestSelected($event)"></rv-memory-chest-selector-widget>
			</div>
		</div>
	</ng-template>
	<div class="row">
		<div class="col-24 mt-1 mb-3">
			<rv-feed-chest-search />
		</div>
		<div class="col-24" *ngIf="chestId">
			<rv-feed-chest-members />
		</div>
		<div class="col-24" waIntersectionObserver waIntersectionThreshold="0.05">
			<ng-container *ngFor="let M of feedSrvc.Milestones$|async|activeMemoryChest:chestId; let idx = index">
				<div *ngIf="feedSrvc.monthYears[M.id()]" class="feed-date-indicator">
					<feed-date-indicator [date]="feedSrvc.monthYears[M.id()]"></feed-date-indicator>
				</div>
				<div class="feed-milestone">
					<feed-milestone [chestId]="chestId" [FeedMilestone]="M" [fetchPreview]="idx < 4" (waIntersectionObservee)="onIntersection(M, $event)"></feed-milestone>
				</div>
			</ng-container>
			<rv-no-milestones [chestId]="chestId" />
			<rv-no-memories [chestId]="chestId" />
		</div>
	</div>
</div>

<ng-template #feedFilter>
	<feed-filter *ngIf="canFilter" [chestId]="chestId" />
</ng-template>