import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ModelFactoryConfig, ModelService } from '@getrearview/model-builder';
import { ConfigService } from './../../shared/services/config.service';
import { UserMediaService } from './user-media.service';
import { UserMetaService } from './user-meta.service';
import { UserSettingService } from './user-setting.service';
import { UserNotificationService } from './user-notification.service';
// import { AccountService } from './../accounts/account.service';

const PROFILE_URL = `${environment.api.media_url}/users/{id}/profile.jpg`;

@Injectable({
  providedIn: 'root'
})
export class UserService extends ModelService {

  constructor (injector: Injector, httpClient: HttpClient) 
  {
  	let ConfigSrvc: ConfigService = injector.get<any>(ConfigService);
		let MFC: ModelFactoryConfig = (new ModelFactoryConfig()).setAll({
  				// Model Name
  				name: "User",
  				// Model Endpoint
					endpoint: ConfigSrvc.get("api.api_url")+"/users",
					// Model Fields
					fields: ["name", "email", "phone"],
					// Model `Permitted` Fields
					permitted: ["name", "email", "phone"],
					// Model `Required` Fields
					required: [],
					// Model Field Labels
					labels: {},
					// Model Field Validation Patterns
					patterns: {},
					// Model Children Types
					children_types: {
						media: injector.get<any>(UserMediaService),
						meta: injector.get<any>(UserMetaService),
						setting: injector.get<any>(UserSettingService),
						notification: injector.get<any>(UserNotificationService),
						// account: injector.get<any>(AccountService),
					},
					// Model Association Types
					// association_types: {"chest": null},
					association_types: {},
					// Storage Type (local, session, cookies)
					storage_type: "local",

					instance_methods: {
						getProfileImageUrl: () => PROFILE_URL
					}
				});

  	super(MFC, httpClient);
  }
}