<button type='button' class="btn btn-primary btn-facebook facebook-button-component">
	<span class='social-icon'>
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			 viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
		<style type="text/css">
			.st0{fill:#FFFFFF;}
			.st1{fill:#3975EA;}
		</style>
		<g>
			<path class="st0" d="M8.4,19.8C3.6,19,0.1,14.9,0.1,10C0.1,4.5,4.5,0,10,0S20,4.5,20,10c0,4.9-3.6,9-8.3,9.8l-0.5-0.4H8.9L8.4,19.8
				z"/>
			<path class="st1" d="M13.9,12.8l0.4-2.8h-2.6V8c0-0.8,0.3-1.4,1.5-1.4h1.3V4.1C13.8,4,13,3.9,12.3,3.9c-2.3,0-3.9,1.4-3.9,3.9V10
				H5.9v2.8h2.5v7c0.5,0.1,1.1,0.1,1.6,0.1s1.1,0,1.6-0.1v-7H13.9z"/>
		</g>
		</svg>
	</span>
	&nbsp;
	<span class='social-label'>
		{{label}}
	</span>
</button>