import { ModelInstance } from '@getrearview/model-builder';
import { Photo } from '@capacitor/camera';

async function addMediaToMemory (Memory: ModelInstance, Photo: Photo): Promise<ModelInstance>
{
	const {dataUrl,exif,format} 	= 	(Photo||{}),
				filemime 								= 	dataUrl ? dataUrl.substring(dataUrl.indexOf(":")+1, dataUrl.indexOf(";")) : (format ? `image/${format}` : null),
				base64Data 							= 	dataUrl ? dataUrl.substr(dataUrl.indexOf(',')+1) : '';

	return new Promise((resolve, reject) => {
  	Memory.addChild('media',{}).then((Media: ModelInstance) => {
			const include_attribs = {...Media.attribs, media_type: 'IMG', _filemime: filemime, _fileexif: exif||{}};
			Media.upload(base64Data, {include_attribs})
					.subscribe(
							evt => {
								if (evt?.event === 'success') {
									resolve(evt?.asset as ModelInstance)
								}
								if (evt?.event === 'progress') {
									// upload progress.
								}
								else {
									reject();
								}
							}, 
							err => {
								console.error("6) > ERR >    ", `${err}`)
								reject()
							}
						);
  	})
	})
}

export async function uploadMedia (Photo: Photo, Memory: ModelInstance): Promise<ModelInstance|void>
{
	return new Promise(async (resolve, reject) => addMediaToMemory(Memory, Photo).then((Media: ModelInstance) => resolve(Media), () => reject()));
}

export function getRecentMemory (Milestone: ModelInstance, Me: ModelInstance, threshold?: number): ModelInstance
{
	const Memory: ModelInstance = Object.values(Milestone.getChildren('memory')||{}).filter((Memory: ModelInstance) => Me?.id() && Memory.get('author_id') === Me.id()).sort((A: ModelInstance, B: ModelInstance) => {
		const v1 = A && A?.attribs?.created_at ? new Date(A.attribs.created_at).getTime() : 0,
					v2 = B && B?.attribs?.created_at ? new Date(B.attribs.created_at).getTime() : 0;
		return `${v2}`.localeCompare(`${v1}`, undefined, {numeric: true});
	}).shift() as ModelInstance;

	if (threshold !== null && !isNaN(threshold)) {
		if (Memory && (new Date().getTime() - threshold) < new Date(Memory.get('created_at')).getTime())
			return Memory;
	}
	else {
		return Memory;
	}
}
