<form id="memory-chest-inviter-widget-component" class="container" [formGroup]="inviterForm" (ngSubmit)="onFormSubmission()" #inviterFormRef novalidate>
	<div class="row mt-4">
		<div class="col-24">
			<p class="rv-copy ">
				Add a family member or friend.  Memories were meant to me shared!
			</p>
			<p class="rv-copy">
				<small>Members added here will have access to all Memory Chest Milestones.</small>
			</p>
		</div>
	</div>

	<div class="row mt-4">
		<div class="col-24">
			<rv-input [formGroup]="inviterForm" [formRef]="inviterFormRef" controlName='name' type='text' iconLeft='users.account' placeholder="Nickname"  />
		</div>
		<div class="col-24">
			<rv-input [formGroup]="inviterForm" [formRef]="inviterFormRef" controlName='emailOrPhone' type='email' iconLeft='users.account' placeholder="Email"  />
		</div>
	</div>

	<div class="row mt-4">
		<div class="col-12 centered">
			<rv-button [outline]="!isCopied" [disabled]="isCopied || isInviting" [purple]="!isCopied" sm icon-purple iconLeft='user-interface.link' (click)="!isCopied&&(output = 'link')" label="{{isCopied?'Copied!':'Copy Link'}}" />
		</div>
		<div class="col-12 centered">
			<rv-button type="submit" outline purple sm icon-purple [disabled]="isInviting" iconLeft='communication.add-mail' (click)="output = 'email'" label='Send Invite' />
		</div>
		<!--
			<div class="col-8">
				<rv-button outline off-white md icon-purple iconLeft='chat.chat' (click)="initSendSms()" />
				Send SMS
			</div>
		-->
		<!-- Group SMS: <rv-icon group='chat' icon='chat-7'></rv-icon> -->
		<!-- Post to Instagram: <rv-icon group='social-media.instagram'></rv-icon> -->
	</div>

	<div rvDropdownContainer class="container-fluid">
		<!-- <span (click)="openExtendedForm = !openExtendedForm">extra&nbsp;>>&nbsp;</span> -->
		<div class="row" [rvDropdownContent]="openExtendedForm">
			<div class="col-24">
				<!-- <rv-input iconLeft='user-interface.adjust' [formGroup]="inviterForm" controlName='relationship' placeholder="Relationship" /> -->
			</div>
			<div class="col-24">
				<!-- <rv-input iconLeft='user-interface.adjust' [formGroup]="inviterForm" controlName='memberType' placeholder="Member Type" /> -->
			</div>			
		</div>
	</div>

	<div class="row mt-4">
		<div class="col-24">
			<rv-alert name='invitee-form' warning collapsible [message]="inviteError" timer="15000" />
			<rv-alert name='invitee-form' success collapsible [message]="inviteSuccess" timer="15000" />
		</div>
	</div>

	<!--
		<div class="row mt-4">
			<div class="col-12">
				<rv-button outline purple md block label="Cancel" (click)="onCancel.emit()"/>
			</div>
			<div class="col-12">
				<rv-button [purple]="inviterForm.valid" [gray]="!inviterForm.valid" md block label="Send Invite" (click)="inviterForm.valid && inviteMember()" />
			</div>
		</div>
	-->

</form>