import { Component, QueryList, SimpleChanges, ViewContainerRef, ViewChild, OnInit, Input, Output, EventEmitter, ElementRef, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ModelInstance } from '@getrearview/model-builder';
import { Selectable } from './../shared/selectable.d';

const isActive = (val: any) => (typeof val === 'boolean' ? val : typeof val === 'string' && val === '')

@Component({
  selector: 'rv-multi-select',
  templateUrl: './ui-multi-select-widget.component.html',
  styleUrls: ['./ui-multi-select-widget.component.scss']
})
export class UiMultiSelectWidgetComponent {

	@Input('models') 
	models!: Array<ModelInstance>;

	@Input('templates') 
	templates!: QueryList<TemplateRef<any>>;
	_templates$;

	options = [];

	Selectables: Array<Selectable> = [];

	booted: boolean = false;
	isOpen: boolean = false;

	// icons
	@Input('iconLeft')
	private _iconLeft: string;
	@Input('iconRight')
	private _iconRight: string;

	iconLeftGroup: string;
	iconLeftName: string;
	iconRightGroup: string;
	iconRightName: string;
	private _classes: Array<string> = ['rv-ui','ui-input'];


	@Input('formGroup')
	private _formGroup: FormGroup;
	@Input('controlName')
	private _controlName: string;
	@Input('control')
	private _control: FormControl;
	private _emptyControl: FormControl = new FormControl();


	// interactive
	@Input('disabled')
	private _disabled: boolean;
	@Input('readonly')
	private _readonly: boolean;
	// @Input('valid')
	// private _valid: boolean;
	// @Input('invalid')
	// private _invalid: string;
	@Input('reactive')
	private _reactive: boolean;

	isFocused: boolean = false;
	@Output('focus')
	private _focus:EventEmitter<void> = new EventEmitter();
	@Output('blur')
	private _blur:EventEmitter<void> = new EventEmitter();



	buildSelectables (QueryList: QueryList<any>) 
	{
		this.Selectables = this.models.map((model, idx) => ({model, idx, template: QueryList.get(idx), selected: false} as Selectable));
		if (this.Selectables.length)
			this.booted = true;
		this.booted = true;
	}

	toggle () 
	{
		this.isOpen = !this.isOpen;
	}

	ngOnChanges (Changes: SimpleChanges) 
	{
  	if (Changes.hasOwnProperty('_iconLeft'))  this.setIcon('iconLeft');
  	if (Changes.hasOwnProperty('_iconRight')) this.setIcon('iconRight');

		if (!this._templates$ && this.templates) {
			this._templates$ = this.templates.changes.subscribe(Q => this.buildSelectables(Q))
			this.buildSelectables(this.templates)
		}
	}

	ngOnDestroy (): void
	{
		if (this._templates$)
			this._templates$.unsubscribe();
	}

	onSelect (event): void 
	{
		const modelUpdate = this.Selectables.filter(Selectable => Selectable?.selected === true).map(Selectable => Selectable.model);

		if (this.control) {
			this.control.setValue(modelUpdate)
		}
		else if (this.formGroup && this.controlName) {
			if (this.formGroup?.get(this.controlName))
				this.formGroup.get(this.controlName).setValue(modelUpdate);
		}
	}

  constructor (private cd: ChangeDetectorRef) 
  {}

	get showError (): boolean
	{ 
		if (this.control)
			return this.control.dirty ? this.isInvalid : false;

		return this.isInvalid;
	}

	get isInvalid (): boolean
	{
		return !!this.control.invalid;
	}

	get isReactive (): boolean
	{
		return isActive(this._reactive)
	}

	get formGroup (): FormGroup
	{
		return this._formGroup;
	}

	get control (): FormControl
	{
		return this._control ? this._control : this._emptyControl
	}

	get controlName (): string
	{
		return `${this._controlName||''}`;
	}

	ngOnInit (): void
	{}

	onFocus (): void 
	{
		this.isFocused = true;
		this._focus.emit();
	}

	onBlur (): void 
	{
		this.isFocused = false;
		this._blur.emit();
	}

	private setIcon (icon: string): void
	{
		if (this[`_${icon}`] && typeof this[`_${icon}`] === 'string' && /([a-z\-]+)\.([a-z\-]+)/i.test(this[`_${icon}`])) {
			let [g,i] = this[`_${icon}`].split('.');
			this[`${icon}Group`] = g;
			this[`${icon}Name`] = i;
		}
		else if (this[`_${icon}`] && Array.isArray(this[`_${icon}`]) && /([a-z\-]+)\.([a-z\-]+)/i.test(this[`_${icon}`].slice(0,2).join('.'))) {
			let [g,i] = this[`_${icon}`];
			this[`${icon}Group`] = g;
			this[`${icon}Name`] = i;
		}
		else {
			this[`${icon}Group`] = '';
			this[`${icon}Name`] = '';
		}
	}
}